import { JourneyFilterSelectOption } from 'types/projects/selectOptions/journeyFilterSelectOption';
import { ISelectOption } from 'types/ui/select';

import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';

import { generalFilterSelectOption } from './generalFilterSelectOption';
import { projectJourneysUI } from '../projectJourneysUI';

export const projectJourneysSelectOptions: ISelectOption<ProjectJourneys>[] =
	Object.values(ProjectJourneys).map((projectJourney) => ({
		label: projectJourneysUI[projectJourney],
		value: projectJourney,
	}));

export const projectJourneyFilterSelectOptions: ISelectOption<JourneyFilterSelectOption>[] =
	[generalFilterSelectOption, ...projectJourneysSelectOptions];
