import cn from 'classnames';
import { FC } from 'react';

import { ICreateProspects } from 'api/models/responses/projects/createProspects';
import { Button } from 'components/FormControls/Button';

interface IAddProspectsConfirmationProps {
	erredRecordsMessage: string;
	showAddProspectsReportModal: () => void;
	confirmationData: ICreateProspects | null;
	hideAddProspectsConfirmationModal: () => void;
}

export const AddProspectsConfirmation: FC<IAddProspectsConfirmationProps> = ({
	confirmationData,
	erredRecordsMessage,
	showAddProspectsReportModal,
	hideAddProspectsConfirmationModal,
}) => {
	const handleViewReport = () => {
		showAddProspectsReportModal();
		hideAddProspectsConfirmationModal();
	};

	if (!confirmationData) return null;

	const { totalNumberOfRowsWithData, totalNumberOfImportedRecords } =
		confirmationData;

	const isValid = totalNumberOfRowsWithData === totalNumberOfImportedRecords;

	return (
		<div>
			{totalNumberOfImportedRecords ? (
				<div className="modal-prospect-info">
					<h2 className="modal-prospect-heading">
						Adding {totalNumberOfRowsWithData} Prospects
					</h2>
					{/* TODO: add Email and Phone statistic in future */}
					{false && (
						<>
							<span className="modal-prospect-value">
								<b>497</b>&nbsp;Email Addresses
							</span>
							<span className="modal-prospect-value">
								<b>258</b>&nbsp;Phone Numbers
							</span>
						</>
					)}
				</div>
			) : (
				<span className="modal-prospect-notice">
					The columns you selected did not contain any valid email address or
					phone numbers. Please try again.
				</span>
			)}
			{erredRecordsMessage && (
				<p className="modal-prospect-notice">{erredRecordsMessage}.</p>
			)}
			<div
				className={cn('modal-prospect-footer', {
					'is-single': isValid,
				})}
			>
				<Button
					value="Close"
					handleClick={hideAddProspectsConfirmationModal}
					className={isValid ? 'btn-primary' : 'btn-secondary'}
				/>
				{!isValid && (
					<Button
						value="View Report"
						handleClick={handleViewReport}
						className="org-modal-btn btn-primary"
					/>
				)}
			</div>
		</div>
	);
};
