import { IStatisticRenderHelper } from 'types/statistic/statisticRenderHelper';

export const organizationStatisticKeys: IStatisticRenderHelper[] = [
	{
		key: 'projectsCount',
		title: 'Projects',
	},
	{
		key: 'subjectsCount',
		title: 'Subjects',
	},
	{
		key: 'imagesCount',
		title: 'Images',
	},
	{
		key: 'prospectsCount',
		title: 'Prospects',
	},
	{
		key: 'leadsCount',
		title: 'Leads',
	},
	{
		key: 'buyersCount',
		title: 'Buyers',
	},
];
