import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';

import presetDetailsA from 'images/projects/presetDetails/preset-details-A.png';
import presetDetailsB from 'images/projects/presetDetails/preset-details-B.png';
import presetDetailsC from 'images/projects/presetDetails/preset-details-C.png';
import presetDetailsD from 'images/projects/presetDetails/preset-details-D.png';
import presetDetailsE from 'images/projects/presetDetails/preset-details-E.png';

export const presetDetailsImages: Record<ProjectJourneys, string> = {
	[ProjectJourneys.A]: presetDetailsA,
	[ProjectJourneys.B]: presetDetailsB,
	[ProjectJourneys.C]: presetDetailsC,
	[ProjectJourneys.D]: presetDetailsD,
	[ProjectJourneys.E]: presetDetailsE,
};
