import { useMemo } from 'react';

import { CampaignsSortLabels } from 'constants/campaigns/campaignsSortLabels';
import { DataOrder } from 'constants/general/dataOrder';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useSortParams } from 'hooks/useSortParams';

import { ICampaign } from 'api/models/responses/campaigns/campaign';
import { filteredCampaignsSelector } from 'store/campaigns';

interface ISortCampaignsResult {
	campaigns: ICampaign[];
	orderDirection: DataOrder;
	orderBy: CampaignsSortLabels;
	setSortParams: (sortLabel: CampaignsSortLabels) => void;
}

export const useSortCampaigns = (): ISortCampaignsResult => {
	const { orderBy, setSortParams, orderDirection } =
		useSortParams<CampaignsSortLabels>(CampaignsSortLabels.Name);

	const filteredCampaigns = useAppSelector(filteredCampaignsSelector);

	const sortedCampaigns = useMemo(() => {
		if (!filteredCampaigns.length) return [];

		const sortByAlphabet = (key: keyof ICampaign) => {
			return [...filteredCampaigns].sort((a, b) => {
				const valueA = a[key] as string;
				const valueB = b[key] as string;

				return orderDirection === DataOrder.ASC
					? valueA.localeCompare(valueB)
					: valueB.localeCompare(valueA);
			});
		};

		switch (orderBy) {
			case CampaignsSortLabels.Name: {
				return sortByAlphabet('name');
			}

			case CampaignsSortLabels.Target: {
				return sortByAlphabet('campaignTarget');
			}

			case CampaignsSortLabels.Journey: {
				return sortByAlphabet('journey');
			}

			case CampaignsSortLabels.BroadcastsCount: {
				const sortedCampaignsByCount = [...filteredCampaigns].sort((a, b) =>
					orderDirection === DataOrder.ASC
						? a.broadcastsCount - b.broadcastsCount
						: b.broadcastsCount - a.broadcastsCount
				);

				return sortedCampaignsByCount;
			}

			default:
				return filteredCampaigns;
		}
	}, [filteredCampaigns, orderBy, orderDirection]);

	return {
		orderBy,
		setSortParams,
		orderDirection,
		campaigns: sortedCampaigns,
	};
};
