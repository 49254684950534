import { FC, useEffect, useRef, useState } from 'react';
import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';

import { useCampaignsBreadcrumbs } from 'pages/Campaigns/hooks/useCampaignsBreadcrumbs';
import { useSortCampaigns } from 'pages/Campaigns/hooks/useSortCampaigns';

import { ColumnLabelContainer } from 'components/Table/ColumnLabelContainer';
import { ConfirmDelete } from 'components/Modal/components/ConfirmDelete';
import { UpgradePlan } from 'components/Modal/components/UpgradePlan';
import { Agreement } from 'components/Modal/components/Agreement';
import { CellActionMenu } from 'components/Table/CellActionMenu';
import { NoItemsFound } from 'components/NoItemsFound';
import { Modal, ModalRef } from 'components/Modal';
import { Loader } from 'components/Loader';
import { Table } from 'components/Table';

import { CampaignsSortLabels } from 'constants/campaigns/campaignsSortLabels';
import { CampaignActionMenu } from 'constants/campaigns/campaignActionMenu';
import { ROUTES } from 'constants/ROUTES';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useActionMenu } from 'hooks/useActionMenu';

import { subscriptionSelector } from 'store/subscriptions';
import {
	setIsCopySuccess,
	getCampaignsAsync,
	deleteCampaignAsync,
	isCopySuccessSelector,
	setIsDeleteCampaignReject,
	isCampaignsPendingSelector,
	setIsDeleteCampaignSuccess,
	isDeleteCampaignRejectSelector,
	isDeleteCampaignSuccessSelector,
	copiedCampaignSelector,
} from 'store/campaigns';

import { ICampaign } from 'api/models/responses/campaigns/campaign';

import { CampaignDeleteReject } from './components/CampaignDeleteReject';
import { CampaignsHeader } from './components/CampaignsHeader';
import { CampaignCopy } from './components/CampaignCopy';

interface IActionMenuPayload {
	isCopy: boolean;
	campaignKey: number;
	campaignName: string;
	menuItem: CampaignActionMenu;
}

export const Campaigns: FC = () => {
	const [campaignKeyInfo, setCampaignKeyInfo] = useState<number | null>(null);
	const [campaignNameInfo, setCampaignNameInfo] = useState('');

	const isCopyAvailable = useAppSelector(subscriptionSelector)?.customCampaigns;
	const isDeleteCampaignReject = useAppSelector(isDeleteCampaignRejectSelector);
	const isCampaignsPending = useAppSelector(isCampaignsPendingSelector);
	const copiedCampaign = useAppSelector(copiedCampaignSelector);
	const isCopySuccess = useAppSelector(isCopySuccessSelector);
	const isDeleteCampaignSuccess = useAppSelector(
		isDeleteCampaignSuccessSelector
	);

	const confirmDeleteModalRef = useRef<ModalRef>(null);
	const successDeleteModalRef = useRef<ModalRef>(null);
	const rejectDeleteModalRef = useRef<ModalRef>(null);
	const copyCampaignModalRef = useRef<ModalRef>(null);
	const upgradePlanModalRef = useRef<ModalRef>(null);
	const successCopyModalRef = useRef<ModalRef>(null);

	const { orderBy, campaigns, setSortParams, orderDirection } =
		useSortCampaigns();

	const { actionMenuId, setActionMenuId } = useActionMenu();

	useCampaignsBreadcrumbs();

	const dispatch = useAppDispatch();

	const showConfirmDeleteModal = () => {
		confirmDeleteModalRef.current?.open();
	};

	const hideConfirmDeleteModal = () => {
		confirmDeleteModalRef.current?.close();
	};

	const showSuccessDeleteModal = () => {
		successDeleteModalRef.current?.open();
	};

	const hideSuccessDeleteModal = () => {
		successDeleteModalRef.current?.close();
	};

	const showRejectDeleteModal = () => {
		rejectDeleteModalRef.current?.open();
	};

	const hideRejectDeleteModal = () => {
		rejectDeleteModalRef.current?.close();
	};

	const showCopyCampaignModal = () => {
		copyCampaignModalRef.current?.open();
	};

	const hideCopyCampaignModal = () => {
		copyCampaignModalRef.current?.close();
	};

	const showSuccessCopyModal = () => {
		successCopyModalRef.current?.open();
	};

	const hideSuccessCopyModal = () => {
		successCopyModalRef.current?.close();
	};

	const showUpgradePlanModal = () => {
		upgradePlanModalRef.current?.open();
	};

	const hideUpgradePlanModal = () => {
		upgradePlanModalRef.current?.close();
	};

	const handleConfirmSuccessCopy = () => {
		dispatch(setIsCopySuccess(false));

		hideSuccessCopyModal();
	};

	const handleConfirmSuccessDelete = () => {
		dispatch(setIsDeleteCampaignSuccess(false));

		hideSuccessDeleteModal();
	};

	const handleConfirmRejectDelete = () => {
		dispatch(setIsDeleteCampaignReject(false));

		hideRejectDeleteModal();
	};

	const handleActionMenuItemClick = ({
		isCopy,
		menuItem,
		campaignKey,
		campaignName,
	}: IActionMenuPayload) => {
		setCampaignNameInfo(campaignName);
		setCampaignKeyInfo(campaignKey);

		if (menuItem === CampaignActionMenu.DuplicateCampaign) {
			if (!isCopyAvailable) return showUpgradePlanModal();

			return showCopyCampaignModal();
		}

		if (menuItem === CampaignActionMenu.DeletePermanently) {
			if (!isCopy && !isCopyAvailable) return showUpgradePlanModal();

			return showConfirmDeleteModal();
		}
	};

	const deleteCampaign = () => {
		if (!campaignKeyInfo) return;

		void dispatch(deleteCampaignAsync(campaignKeyInfo));
	};

	const columns: Column<ICampaign>[] = [
		{
			Header: (
				<ColumnLabelContainer<CampaignsSortLabels>
					label="Name"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={CampaignsSortLabels.Name}
				/>
			),
			accessor: 'name',
			Cell: ({ row }: CellProps<ICampaign>) => {
				const { name, campaignKey } = row.original;

				return (
					<Link
						title={name}
						className="card-table-link"
						to={`${ROUTES.CAMPAIGNS.CAMPAIGNS}/${campaignKey}`}
					>
						{name}
					</Link>
				);
			},
		},
		{
			Header: (
				<ColumnLabelContainer<CampaignsSortLabels>
					label="Target"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={CampaignsSortLabels.Target}
				/>
			),
			accessor: 'campaignTarget',
		},
		{
			Header: (
				<ColumnLabelContainer<CampaignsSortLabels>
					label="Journey"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={CampaignsSortLabels.Journey}
				/>
			),
			accessor: 'journey',
		},
		{
			Header: (
				<ColumnLabelContainer<CampaignsSortLabels>
					orderBy={orderBy}
					label="# of broadcasts"
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={CampaignsSortLabels.BroadcastsCount}
				/>
			),
			accessor: 'broadcastsCount',
		},
		{
			accessor: 'campaignKey',
			Header: <span className="thead-empty">_</span>,
			Cell: ({ row }: CellProps<ICampaign>) => {
				const { name, isCopy, campaignKey } = row.original;

				return (
					<CellActionMenu
						handleMenuItemClick={(menuItem) =>
							handleActionMenuItemClick({
								isCopy,
								menuItem,
								campaignKey,
								campaignName: name,
							})
						}
						menuList={Object.values(CampaignActionMenu)}
						showActionMenu={actionMenuId === campaignKey}
						setActiveActionMenu={() => setActionMenuId(campaignKey)}
					/>
				);
			},
		},
	];

	useEffect(() => {
		void dispatch(getCampaignsAsync());
	}, []);

	useEffect(() => {
		if (!isDeleteCampaignReject) return;

		showRejectDeleteModal();
	}, [isDeleteCampaignReject]);

	useEffect(() => {
		if (!isDeleteCampaignSuccess) return;

		hideConfirmDeleteModal();
		showSuccessDeleteModal();
	}, [isDeleteCampaignSuccess]);

	useEffect(() => {
		if (!isCopySuccess) return;

		hideCopyCampaignModal();
		showSuccessCopyModal();
	}, [isCopySuccess]);

	const showCampaignsTable = !!campaigns.length && !isCampaignsPending;
	const showNoItemsFound = !campaigns.length && !isCampaignsPending;

	return (
		<>
			<div className="campaign-container">
				<CampaignsHeader />
				{isCampaignsPending && <Loader />}
				{showCampaignsTable && (
					<div className="campaign-table-wrapper">
						<Table
							data={campaigns}
							columns={columns}
							className="org-table table-action-menu"
						/>
					</div>
				)}
				{showNoItemsFound && <NoItemsFound title="campaigns" />}
			</div>
			<Modal
				title="Delete Campaign"
				ref={confirmDeleteModalRef}
				subtitle={`Are you sure you would like to delete the Campaign ${campaignNameInfo} and it’s broadcasts? This action cannot be undone.`}
			>
				<ConfirmDelete
					handlePermanentlyDelete={deleteCampaign}
					hideConfirmDeleteModal={hideConfirmDeleteModal}
				/>
			</Modal>
			<Modal
				title="Campaign Deleted"
				ref={successDeleteModalRef}
				subtitle={`The Campaign ${campaignNameInfo} has been deleted.`}
			>
				<Agreement handleAgreement={handleConfirmSuccessDelete} />
			</Modal>
			<Modal
				ref={rejectDeleteModalRef}
				title="Cannot Delete Campaign"
				subtitle="This Campaign cannot be deleted. The following projects and/or presets are use this campaign.
				 You can delete this campaign once it has been replaced on the following projects and/or presets.
				 Or after they have been deleted."
			>
				<CampaignDeleteReject hideModal={handleConfirmRejectDelete} />
			</Modal>
			<Modal
				ref={upgradePlanModalRef}
				title="Upgrade Your Plan"
				subtitle="This option is not available on your current plan. Upgrade your plan now to get access to this feature and more."
			>
				<UpgradePlan hideModal={hideUpgradePlanModal} />
			</Modal>
			<Modal
				title="Copy Campaign"
				ref={copyCampaignModalRef}
				subtitle={`What would you like to copy of ${campaignNameInfo} to be called? And what journey should this campaign be used in?`}
			>
				<CampaignCopy
					campaignKey={campaignKeyInfo}
					hideCampaignCopyModal={hideCopyCampaignModal}
				/>
			</Modal>
			<Modal
				title="Campaign Copied"
				ref={successCopyModalRef}
				subtitle={`The new campaign ${
					copiedCampaign?.name || ''
				} is being created and will appear on your campaign list when ready.`}
			>
				<Agreement handleAgreement={handleConfirmSuccessCopy} />
			</Modal>
		</>
	);
};
