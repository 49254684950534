import { IGeneralPriceListOption } from '../../generalPriceListOption';

export enum PackageOptionTypes {
	BuildYourOwn = 'BuildYourOwn',
}

export interface IOwnPackageOption
	extends Omit<IGeneralPriceListOption, 'createdDate'> {
	price: number;
	points: number;
	description: string;
	referenceCode: string;
	previewImageUrl: string;
	priceListStudioFulfilledKey: number;
	priceListStudioFulfilledOwnPackageOptionKey: number;
}
