import { generalFilterSelectOption } from 'constants/projects/selectOptions/generalFilterSelectOption';
import { CampaignsTarget } from 'api/models/responses/campaigns/campaign';
import { TargetFilterSelectOption } from 'store/campaigns';
import { ISelectOption } from 'types/ui/select';

export const targetSelectOptions: ISelectOption<CampaignsTarget>[] =
	Object.values(CampaignsTarget).map((target) => ({
		value: target,
		label: target,
	}));

export const targetFilterSelectOptions: ISelectOption<TargetFilterSelectOption>[] =
	[generalFilterSelectOption, ...targetSelectOptions];
