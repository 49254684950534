import { ISubjectFormQuestion } from 'api/models/responses/projects/subjectFormQuestion';
import { IPreset, IPresetSale } from 'api/models/responses/presets/presets';

import { IPriceListSaleParams } from '../projects/createBlankProject';

export interface ICreatePresetFromProjectBody {
	presetName: string;
}

export enum SaleCondition {
	Until = 'Until',
	After = 'After',
}

export interface ICreatePresetSale
	extends Pick<
			IPresetSale,
			| 'condition'
			| 'projectGreeting'
			| 'saleExpiresInDays'
			| 'isKioskModeEnabled'
			| 'orderNotificationEmail'
			| 'orderConfirmationMessage'
		>,
		IPriceListSaleParams {}

export interface ICreatePreset
	extends Pick<
		IPreset,
		| 'journey'
		| 'presetName'
		| 'projectType'
		| 'smsSpecialMessage'
		| 'accessCodeMessage'
		| 'isMarketingEnabled'
		| 'emailSpecialMessage'
	> {
	leadCampaignKey: number | null;
	buyerCampaignKey: number | null;
	presetSales: ICreatePresetSale[];
	prospectCampaignKey: number | null;
	presetSubjectFormQuestions: ISubjectFormQuestion[];
}
