import { FC, ReactNode } from 'react';

interface Props {
	title: string;
	subtitle: string;
	columnFit?: string;
	children: ReactNode;
	bodyClassName?: string;
}

export const Card: FC<Props> = ({
	title,
	subtitle,
	columnFit,
	children,
	bodyClassName,
}) => (
	<div className={`card ${columnFit || ''}`}>
		<div className="card-header-thumb">
			<h2 className="card-title">{title}</h2>
			<span className="card-subtitle">{subtitle}</span>
		</div>
		<div className={`card-body ${bodyClassName || ''}`}>{children}</div>
	</div>
);
