import { salesNotAvailableSelectOption } from 'constants/priceLists/selectOptions/salesNotAvailableSelectOption';
import { PriceListFulfillment } from 'constants/priceLists/priceListFulfillment';

export const getPriceListSelectOptionKey = (
	priceListKey: number,
	fulfillment: PriceListFulfillment
) =>
	fulfillment === PriceListFulfillment.NA
		? salesNotAvailableSelectOption.value
		: `${priceListKey}-${fulfillment}`;
