import { Dispatch } from '@reduxjs/toolkit';

import { PoseOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/poseOption';
import PoseOptionsService from 'api/services/PriceListsService/studio/PoseOptionsService';

import { deletePoseOptionSection } from './poseOptionsSlice';

export const deletePoseOptionSectionAsync =
	(priceListKey: number, poseOptionType: PoseOptionTypes) =>
	async (dispatch: Dispatch) => {
		dispatch(deletePoseOptionSection(poseOptionType));

		try {
			await PoseOptionsService.deletePoseOptions(priceListKey, poseOptionType);
		} catch (error) {
			console.log(error);
		}
	};
