import { Column } from 'react-table';
import { FC, ReactNode } from 'react';

import { ToggleSection } from 'components/ToggleSection';
import { Table } from 'components/Table';

import { useToggleSection } from 'hooks/useToggleSection';
import {
	ICampaignDetails,
	IMarketingBroadcast,
} from 'api/models/responses/projects/marketing';

interface ICampaignProps
	extends Omit<ICampaignDetails, 'campaignName' | 'campaignKey'> {
	header: ReactNode;
}

export const Campaign: FC<ICampaignProps> = ({
	header,
	broadcasts,
	campaignTarget,
}) => {
	const { isOpen, handleToggle } = useToggleSection(false);

	const columns: Column<IMarketingBroadcast>[] = [
		{
			Header: 'Broadcast',
			accessor: 'broadcast',
		},
		{
			Header: 'Triggered',
			accessor: 'triggered',
		},
		{
			Header: 'Date',
			accessor: 'date',
		},
		{
			Header: 'Start Sending After',
			accessor: 'startSendingAfter',
		},
		{
			Header: 'Status',
			accessor: 'status',
		},
	];

	return (
		<ToggleSection
			isOpen={isOpen}
			header={header}
			handleToggle={handleToggle}
			className="marketing-section"
			title={`${campaignTarget} Campaign`}
		>
			<div className="marketing-table">
				<Table
					columns={columns}
					data={broadcasts}
					className="org-table project-details-table"
				/>
			</div>
		</ToggleSection>
	);
};
