export enum CatalogProductCategoryTypes {
	Free = 'Free',
	Prints = 'Prints',
	Packages = 'Packages',
	Downloads = 'Downloads',
	Retouching = 'Retouching',
	GiftsAndAccessories = 'GiftsAndAccessories',
}

export interface ICatalogProduct {
	name: string;
	cost: number;
	catalogProductKey: number;
	previewImageGuid: string | null;
	previewDescription: string | null;
	previewImageCdnUrl: string | null;
	catalogCategoryType: CatalogProductCategoryTypes;
}
