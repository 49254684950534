import { FC } from 'react';

interface ILoaderProps {
	isFullHeight?: boolean;
}

export const Loader: FC<ILoaderProps> = ({ isFullHeight }) => (
	<div
		className={`page-loader-container ${
			isFullHeight ? 'is-page' : 'is-section'
		}`}
	>
		<div className="loader" />
	</div>
);

Loader.defaultProps = {
	isFullHeight: false,
};
