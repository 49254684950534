import { FC, ReactElement, useEffect } from 'react';

import { validatePercentValue } from 'utils/validations/priceLists/validatePercentValue';
import { validateRetailValue } from 'utils/validations/priceLists/validateRetailValue';

import { useAsyncOptimizedSelect } from 'hooks/formControls/useAsyncOptimizedSelect';
import { useLazyPercentInput } from 'hooks/formControls/useLazyPercentInput';
import { useLazyDollarInput } from 'hooks/formControls/useLazyDollarInput';

import { SelectComponent } from 'components/FormControls/Select';
import { InputField } from 'components/InputField';

import { chargeUnitTypesSelectOptions } from 'constants/priceLists/studio/selectOptions/chargeUnitTypesSelectOptions';
import { UnitTypes } from 'api/models/responses/general/unitTypes';

import { IGeneralColumnProps } from '../../';

interface IAmountColumnProps extends IGeneralColumnProps {
	amount: number;
	amountError: string;
	chargeUnitType: UnitTypes;
}

export const AmountColumn: FC<IAmountColumnProps> = ({
	amount,
	sequence,
	amountError,
	chargeUnitType,
	additionalChargeOptionKey,
	updateAdditionalChargeOption,
	clearAdditionalChargeOptionError,
}) => {
	const clearAmountError = () => {
		if (!amountError) return;

		clearAdditionalChargeOptionError({ sequence, fieldKey: 'amount' });
	};

	const handleUpdateChargeUnitType = async (value: UnitTypes) => {
		await updateAdditionalChargeOption({
			value,
			sequence,
			validationMessage: '',
			additionalChargeOptionKey,
			fieldKey: 'chargeUnitType',
		});
	};

	const { value, isPending, handleChange } = useAsyncOptimizedSelect({
		initialValue: chargeUnitType,
		updateSelectValue: handleUpdateChargeUnitType,
	});

	const updateAmount = (valueParam: number, validationMessage: string) => {
		void updateAdditionalChargeOption({
			sequence,
			value: valueParam,
			validationMessage,
			fieldKey: 'amount',
			additionalChargeOptionKey,
		});
	};

	const dollarInput = useLazyDollarInput({
		initialValue: amount,
		handleUpdate: updateAmount,
		clearError: clearAmountError,
	});

	const percentInput = useLazyPercentInput({
		initialValue: amount,
		handleUpdate: updateAmount,
		clearError: clearAmountError,
	});

	const AmountInputs: Record<UnitTypes, ReactElement> = {
		[UnitTypes.Dollar]: (
			<InputField
				touched
				error={amountError}
				placeholder="Amount"
				value={dollarInput.value}
				handleBlur={dollarInput.handleBlur}
				handleChange={dollarInput.handleChange}
				handleKeyDown={dollarInput.handleKeyDown}
			/>
		),
		[UnitTypes.Percent]: (
			<InputField
				touched
				error={amountError}
				placeholder="Amount"
				value={percentInput.value}
				handleBlur={percentInput.handleBlur}
				handleChange={percentInput.handleChange}
				handleKeyDown={percentInput.handleKeyDown}
			/>
		),
	};

	useEffect(() => {
		const validationMessage =
			chargeUnitType === UnitTypes.Dollar
				? validateRetailValue(amount)
				: validatePercentValue(amount);

		void updateAdditionalChargeOption({
			sequence,
			value: amount,
			validationMessage,
			fieldKey: 'amount',
			additionalChargeOptionKey,
		});
	}, [chargeUnitType]);

	return (
		<td className="price-option-td">
			<div className="price-table-container">
				<SelectComponent
					value={value}
					disabled={isPending}
					onChange={handleChange}
					selectOptions={chargeUnitTypesSelectOptions}
				/>
				{value && AmountInputs[value]}
			</div>
		</td>
	);
};
