import { ChangeEvent, useCallback, useEffect, useState } from 'react';

interface IAsyncOptimizedCheckboxResult {
	checked: boolean;
	isPending: boolean;
	handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const useAsyncOptimizedCheckbox = (
	initialValue: boolean | undefined,
	updateValue: (value: boolean) => Promise<void>
): IAsyncOptimizedCheckboxResult => {
	const [value, setValue] = useState<boolean | undefined>(initialValue);
	const [isPending, setIsPending] = useState(false);

	const isEqual = value === initialValue;
	const isUndefinedValue = value === undefined;
	const isUndefinedInitial = initialValue === undefined;

	const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target;

		setValue(checked);
	}, []);

	const handleUpdate = useCallback(async () => {
		if (isEqual || isUndefinedValue) return;

		setIsPending(true);

		await updateValue(value);

		setIsPending(false);
	}, [value]);

	useEffect(() => {
		if (isEqual || isUndefinedInitial) return;

		setValue(initialValue);
	}, [initialValue]);

	useEffect(() => {
		void handleUpdate();
	}, [handleUpdate]);

	return {
		isPending,
		handleChange,
		checked: !!value,
	};
};
