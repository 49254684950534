import { FC } from 'react';

import { Button } from 'components/FormControls/Button';

interface IUploadingMediaLimitProps {
	addedImagesCount: number;
	skippedImagesCount: number;
	hideUploadMediaLimitModal: () => void;
}

export const UploadingMediaLimit: FC<IUploadingMediaLimitProps> = ({
	addedImagesCount,
	skippedImagesCount,
	hideUploadMediaLimitModal,
}) => {
	return (
		<div className="modal-body">
			<div className="org-modal">
				<h4 className="media-modal-title">
					Adding {addedImagesCount} files to your library
				</h4>
				<p className="media-modal-subtitle">
					There are {skippedImagesCount} files we are not able to add. You have
					exceeded the limit for your media library. Please remove some existing
					images from the library before adding more.
				</p>
				<div className="media-modal-btns">
					<Button
						value="Close"
						className="btn-secondary"
						handleClick={hideUploadMediaLimitModal}
					/>
					<Button className="btn-primary" value="View Report" />
				</div>
			</div>
		</div>
	);
};
