import {
	FC,
	useRef,
	useState,
	useEffect,
	useCallback,
	ChangeEvent,
} from 'react';

import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';
import { IProject } from 'api/models/responses/projects/projectDetails';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import ProjectsService from 'api/services/ProjectsService';
import {
	ICampaignDetails,
	ICampaignStatistic,
} from 'api/models/responses/projects/marketing';

import { Agreement } from 'components/Modal/components/Agreement';
import { Checkbox } from 'components/FormControls/Checkbox';
import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Modal, ModalRef } from 'components/Modal';

import { useToggleSection } from 'hooks/useToggleSection';
import { IUpdateTimeline } from 'pages/Projects/types';

import { Marketing } from './Marketing';
import { SendBroadcast } from './Marketing/components/SendBroadcast';

export interface IMarketingContainerProps extends IUpdateTimeline {
	projectKey: number;
	journey?: ProjectJourneys;
	accessCodeMessage: string;
	smsSpecialMessage: string;
	emailSpecialMessage: string;
	isMarketingEnabled?: boolean;
	hasAnySentBroadcast: boolean;
	setProjectDetails: (projectDetails: IProject) => void;
}

export const MarketingContainer: FC<IMarketingContainerProps> = ({
	journey,
	projectKey,
	accessCodeMessage,
	smsSpecialMessage,
	setProjectDetails,
	isMarketingEnabled,
	emailSpecialMessage,
	hasAnySentBroadcast,
	setIsUpdateTimeline,
}) => {
	const [campaignStatistics, setCampaignStatistics] = useState<
		ICampaignStatistic[]
	>([]);
	const [campaignDetails, setCampaignDetails] = useState<ICampaignDetails[]>(
		[]
	);

	const [isMarketingEnabledState, setIsMarketingEnabledState] =
		useState(isMarketingEnabled);

	const [sentToNumberOfPeople, setSentToNumberOfPeople] = useState(0);

	const [isStatisticsPending, setIsStatisticsPending] = useState(false);
	const [isCampaignDetailsPending, setIsCampaignDetailsPending] =
		useState(false);
	const [isPartialUpdatePending, setIsPartialUpdatePending] = useState(false);

	const specialBroadcastRejectModalRef = useRef<ModalRef>(null);
	const sendSpecialBroadcastInfoRef = useRef<ModalRef>(null);
	const sendSpecialBroadcastRef = useRef<ModalRef>(null);
	const confirmModalRef = useRef<ModalRef>(null);
	const infoModalRef = useRef<ModalRef>(null);

	const isPending = isStatisticsPending || isCampaignDetailsPending;

	const { isOpen, handleToggle } = useToggleSection(false);

	const hideConfirmModal = () => {
		confirmModalRef?.current?.close();
	};

	const hideInfoModal = () => {
		infoModalRef?.current?.close();
	};

	const showSendSpecialBroadcastModal = () => {
		sendSpecialBroadcastRef?.current?.open();
	};

	const hideSpecialBroadcastModal = () => {
		sendSpecialBroadcastRef?.current?.close();
	};

	const showSendSpecialBroadcastInfo = () => {
		sendSpecialBroadcastInfoRef?.current?.open();
	};

	const hideSendSpecialBroadcastInfo = () => {
		sendSpecialBroadcastInfoRef?.current?.close();
	};

	const hideSpecialBroadcastRejectModal = () => {
		specialBroadcastRejectModalRef?.current?.close();
	};

	const showSpecialBroadcastRejectModal = () => {
		specialBroadcastRejectModalRef?.current?.open();
	};

	const updateIsMarketingEnabled = useCallback(
		(isMarketingEnabledParam: boolean) => {
			if (!projectKey) return;

			setIsPartialUpdatePending(true);

			const updatedProjectFields: IPatchBody = {
				op: 'replace',
				path: 'isMarketingEnabled',
				value: isMarketingEnabledParam,
			};

			const projectUpdateBody: IPatchBody[] = [updatedProjectFields];

			ProjectsService.partialProjectUpdate(projectKey, projectUpdateBody)
				.then((data) => {
					if (!data) return;

					setProjectDetails(data);
					setIsPartialUpdatePending(false);

					if (hasAnySentBroadcast) {
						infoModalRef?.current?.open();
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		[projectKey, hasAnySentBroadcast]
	);

	const handleIsMarketingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.currentTarget;

		if (hasAnySentBroadcast && isMarketingEnabledState) {
			confirmModalRef?.current?.open();
			return;
		}

		setIsMarketingEnabledState(checked);
	};

	const handleConfirm = () => {
		updateIsMarketingEnabled(false);
		confirmModalRef?.current?.close();
	};

	const disabledMarketingToggle =
		(!isMarketingEnabledState && hasAnySentBroadcast) || isPartialUpdatePending;

	const sentBroadcastInfo = `Sending broadcast to ${sentToNumberOfPeople} ${
		sentToNumberOfPeople > 1 ? 'people' : 'person'
	}`;

	useEffect(() => {
		if (
			isMarketingEnabled === undefined ||
			isMarketingEnabled === isMarketingEnabledState
		)
			return;

		setIsMarketingEnabledState(isMarketingEnabled);
	}, [isMarketingEnabled]);

	useEffect(() => {
		if (
			hasAnySentBroadcast ||
			isMarketingEnabledState === undefined ||
			isMarketingEnabled === isMarketingEnabledState
		)
			return;

		updateIsMarketingEnabled(isMarketingEnabledState);
	}, [isMarketingEnabledState, updateIsMarketingEnabled]);

	return (
		<>
			<ToggleSection
				isOpen={isOpen}
				title="Marketing"
				handleToggle={handleToggle}
				className="preset-details-header"
				header={
					<div className="preset-header-double">
						<Checkbox
							id="isMarketingEnabled"
							checked={!!isMarketingEnabledState}
							disabled={disabledMarketingToggle}
							label="Enable Marketing For This Project"
							handleChange={handleIsMarketingEnabledChange}
						/>
						<Button
							value="Send Special Broadcast"
							disabled={!isMarketingEnabled}
							className="btn-secondary org-details-btn"
							handleClick={showSendSpecialBroadcastModal}
						/>
					</div>
				}
			>
				<Marketing
					journey={journey}
					isPending={isPending}
					projectKey={projectKey}
					campaignDetails={campaignDetails}
					accessCodeMessage={accessCodeMessage}
					smsSpecialMessage={smsSpecialMessage}
					setProjectDetails={setProjectDetails}
					isMarketingEnabled={isMarketingEnabled}
					setCampaignDetails={setCampaignDetails}
					campaignStatistics={campaignStatistics}
					emailSpecialMessage={emailSpecialMessage}
					setIsUpdateTimeline={setIsUpdateTimeline}
					setCampaignStatistics={setCampaignStatistics}
					setIsStatisticsPending={setIsStatisticsPending}
					setIsCampaignDetailsPending={setIsCampaignDetailsPending}
				/>
			</ToggleSection>
			{/* TODO: add styles for modal children */}
			<Modal
				title="Are you sure?"
				ref={confirmModalRef}
				subtitle="Disabling marketing is immediate and cannot be undone. Once disabled, you will not be able to turn marketing back on."
			>
				<div className="">
					<Button
						className="btn-secondary"
						value="I'm sure. Disable marketing forever."
						handleClick={handleConfirm}
					/>
					<Button
						className="btn-primary"
						value="Keep Marketing Enabled"
						handleClick={hideConfirmModal}
					/>
				</div>
			</Modal>
			{/* TODO: add styles for modal children */}
			<Modal
				ref={infoModalRef}
				title="Marketing Disabled"
				subtitle="All marketing functions have been disabled. No more marketing messages will be sent. Only order notifications and download notifications will be sent."
			>
				<Button
					value="Close"
					className="btn-primary"
					handleClick={hideInfoModal}
				/>
			</Modal>
			<Modal
				ref={sendSpecialBroadcastRef}
				title="Send a Special Message"
				subtitle="Send a one-time message to the people in your marketing funnel"
				className="modal-broadcast"
			>
				<SendBroadcast
					projectKey={projectKey}
					setSentToNumberOfPeople={setSentToNumberOfPeople}
					hideSendBroadcastModal={hideSpecialBroadcastModal}
					showSendSpecialBroadcastInfo={showSendSpecialBroadcastInfo}
					showSpecialBroadcastRejectModal={showSpecialBroadcastRejectModal}
				/>
			</Modal>
			<Modal
				ref={sendSpecialBroadcastInfoRef}
				title="Sending your Broadcast"
				subtitle="This broadcast has been added to the queue and will be sending out shortly. It is OK to close this message."
			>
				{/* TODO: add class name  */}
				<h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
					{sentBroadcastInfo}
				</h2>
				<Button
					value="Close"
					className="btn-primary"
					handleClick={hideSendSpecialBroadcastInfo}
				/>
			</Modal>
			<Modal
				ref={specialBroadcastRejectModalRef}
				title="Marketing currently is disabled for this project!"
			>
				<Agreement handleAgreement={hideSpecialBroadcastRejectModal} />
			</Modal>
		</>
	);
};
