import { useNavigate } from 'react-router-dom';
import { FC, useEffect } from 'react';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { SubscriptionNames } from 'constants/subscriptions/subscriptionNames';
import { ROUTES } from 'constants/ROUTES';

import SubscriptionsService from 'api/services/SubscriptionsService';
import { IErrorResponse } from 'api/models/responses/errors/errorResponse';

import { studioKeySelector } from 'store/studio';
import {
	setSubscriptions,
	subscriptionsSelector,
	activeSubscriptionKeySelector,
	setActiveSubscriptionKey,
	setSubscriptionLevelKey,
} from 'store/subscriptions';

import { Button } from 'components/FormControls/Button';
import { useToastify } from 'hooks/useToastify';

import { TableRowNames } from './components/TableRowLabels';
import { StepHeader } from '../components/StepHeader';
import { TableColumn } from './components/TableColumn';
import { SideWrapper } from '../components/SideWrapper';

export const SelectPlan: FC = () => {
	const subscriptions = useAppSelector(subscriptionsSelector);
	const studioKey = useAppSelector(studioKeySelector);
	const activeSubscriptionLevelKey = useAppSelector(
		activeSubscriptionKeySelector
	);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { showErrorsToastify } = useToastify();

	const selectSubscription = (subscriptionLevelKey: number) => {
		dispatch(setActiveSubscriptionKey(subscriptionLevelKey));
	};

	const handleChoosePlan = () => {
		SubscriptionsService.setSubscriptionForStudio(
			activeSubscriptionLevelKey,
			studioKey
		)
			.then(() => {
				dispatch(setSubscriptionLevelKey(activeSubscriptionLevelKey));
				navigate(ROUTES.STUDIO_STEPS.GET_PAID);
			})
			.catch((error: IErrorResponse) => {
				const toastifyError = {
					type: error.type,
					errors: error.errors as Record<string, string>,
				};

				showErrorsToastify(toastifyError);
			});
	};

	const TableColumnsList = subscriptions.map((subscription) => (
		<TableColumn
			{...subscription}
			key={subscription.subscriptionLevelKey}
			selectSubscription={selectSubscription}
			activeSubscriptionLevelKey={activeSubscriptionLevelKey}
		/>
	));

	useEffect(() => {
		SubscriptionsService.getSubscriptionsList()
			.then((data) => {
				if (!data) return;

				dispatch(setSubscriptions(data));
			})
			.catch((error: IErrorResponse) => {
				const toastifyError = {
					type: error.type,
					errors: error.errors as Record<string, string>,
				};

				showErrorsToastify(toastifyError);
			});
	}, []);

	useEffect(() => {
		const standardSubscriptionKey = subscriptions.find(
			({ name }) => name === SubscriptionNames.STANDARD
		)?.subscriptionLevelKey;

		if (!standardSubscriptionKey) return;

		dispatch(setActiveSubscriptionKey(standardSubscriptionKey));
	}, [subscriptions]);

	return (
		<div className="plan-container">
			<div className="plan-panel">
				<StepHeader title="Choose a Plan" stepCount="Step 2 of 3" />
				<h2 className="studio-title">
					Choose a plan that best suits your business needs
				</h2>
				<div className="plans">
					<div className="panel-plans">
						<TableRowNames />
						{TableColumnsList}
					</div>
				</div>
				<span className="plans-note">
					* Of transaction amount. Does not include 3% credit card processing
					fee.
				</span>
				<div className="plan-controls">
					<Button
						value="Choose Plan"
						handleClick={handleChoosePlan}
						className="plan-btn btn-primary"
					/>
				</div>
			</div>
			<SideWrapper />
		</div>
	);
};
