import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { parseTwoDigitYear } from 'moment';
import { FC } from 'react';

import { toastifyRequiredFields } from 'constants/general/toast/toastifyRequiredFields';
import { statesSelectOptions } from 'constants/general/states/statesSelectOptions';
import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import { ROUTES } from 'constants/ROUTES';

import { parseEnumToSelectValues } from 'utils/ui/parseEnumToSelectValues';
import { splitCamelCaseString } from 'utils/general/splitCamelCaseString';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToastify } from 'hooks/useToastify';

import { ButtonTypes } from 'types/ui/buttonTypes';
import { Payments } from 'types/ui/payments';

import { IErrorResponse } from 'api/models/responses/errors/errorResponse';
import { Button } from 'components/FormControls/Button';
import { createStudioAsync } from 'store/studio';

import { BillingInformation } from './components/BillingInformation';
import { StudioInformation } from './components/StudioInformation';
import { SideWrapper } from '../components/SideWrapper';
import { StepHeader } from '../components/StepHeader';

import { createStudioScheme } from './validations';

export interface ICreateStudioForm {
	studioName: string;
	phone: string;
	street: string;
	city: string;
	zip: string;
	cardNumber: string;
	expiration: string;
	verificationCode: string;
	billingZip: string;
	cardType: string;
	state: string;
	networkDomain: string;
}

export const CreateStudio: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { showErrorsToastify } = useToastify();

	const initialValues: ICreateStudioForm = {
		studioName: '',
		phone: '',
		street: '',
		city: '',
		zip: '',
		cardNumber: '',
		expiration: '',
		verificationCode: '',
		billingZip: '',
		cardType: '',
		state: '',
		networkDomain: '',
	};

	const onSubmit = async (
		values: ICreateStudioForm,
		{ setErrors }: FormikHelpers<ICreateStudioForm>
	) => {
		try {
			const {
				studioName,
				phone,
				city,
				street,
				zip,
				cardNumber,
				verificationCode,
				cardType,
				billingZip,
				expiration,
				state,
				networkDomain,
			} = values;

			const stateIndex = statesSelectOptions.findIndex(
				({ value }) => value === state
			);

			const cardTypeIndex = parseEnumToSelectValues(Payments).findIndex(
				({ value }) => value === cardType
			);

			const studioContactInfo = {
				website: networkDomain,
				phone,
				street,
				state: stateIndex,
				city,
				zip,
			};

			const [expireMonth, expireYear] = expiration.split('/');

			const fullYear = parseTwoDigitYear(expireYear);

			const billingCardInfo = {
				cardType: cardTypeIndex,
				cardNumber,
				expireMonth: +expireMonth,
				expireYear: fullYear,
				verificationCode,
				billingZip,
			};

			const createStudioBody = {
				studioName,
				networkDomain,
				studioContactInfo,
				billingCardInfo,
			};

			await dispatch(createStudioAsync(createStudioBody));

			navigate(ROUTES.STUDIO_STEPS.SELECT_PLAN);
		} catch (error) {
			const typedError = error as IErrorResponse;

			const isValidationErrors = !!typedError.traceId;

			if (!isValidationErrors) {
				const updatedToastifyError = {
					type: splitCamelCaseString(typedError.type),
					errors: typedError.errors as Record<string, string>,
				};

				showErrorsToastify(updatedToastifyError);
			}

			const updatedErrorMessages = Object.entries(typedError.errors).reduce<
				FormikErrors<ICreateStudioForm>
			>((acc, [key, message]) => {
				const splittedKey = key.split('.');

				const parsedKey = splittedKey[splittedKey.length - 1];

				const doesKeyExist = parsedKey in values;

				const parsedMessage = isValidationErrors
					? (message as string[]).join(' ')
					: (message as string);

				if (parsedKey === 'expireYear' || parsedKey === 'expireMonth') {
					return {
						...acc,
						expiration: parsedMessage,
					};
				}

				if (!doesKeyExist) return acc;

				return {
					...acc,
					[parsedKey]: parsedMessage,
				};
			}, {});

			void setErrors(updatedErrorMessages);
		}
	};

	const validateHandler = async (
		validateForm: FormikHelpers<ICreateStudioForm>['validateForm']
	) => {
		const errors = await validateForm();

		const isError = Object.values(errors).some(
			(errorMessage) => errorMessage === requiredFieldMessage
		);

		if (isError) {
			showErrorsToastify(toastifyRequiredFields);
		}
	};

	return (
		<div className="studio-container">
			<Formik
				onSubmit={onSubmit}
				initialValues={initialValues}
				validationSchema={createStudioScheme}
			>
				{({ validateForm }) => (
					<Form className="panel">
						<StepHeader title="Create a New Studio" stepCount="Step 1 of 3" />
						<div className="form-wrapper">
							<h2 className="studio-title">Studio Information</h2>
							<StudioInformation />
							<h2 className="studio-title">Billing Information</h2>
							<BillingInformation />
						</div>
						<Button
							value="Create Studio"
							type={ButtonTypes.SUBMIT}
							className="studio-btn btn-primary"
							handleClick={() => {
								void validateHandler(validateForm);
							}}
						/>
					</Form>
				)}
			</Formik>
			<SideWrapper />
		</div>
	);
};
