import { FC } from 'react';

import { Button } from 'components/FormControls/Button';

interface IConfirmDeleteOrganizationProps {
	hideConfirmDeleteModal: () => void;
	handlePermanentlyDelete: () => Promise<void> | void;
}

export const ConfirmDelete: FC<IConfirmDeleteOrganizationProps> = ({
	hideConfirmDeleteModal,
	handlePermanentlyDelete,
}) => (
	<div className="org-modal-confirm">
		<Button
			className="btn-secondary"
			value="Permanently Delete"
			handleClick={handlePermanentlyDelete}
		/>
		<Button
			value="Cancel"
			className="btn-primary"
			handleClick={hideConfirmDeleteModal}
		/>
	</div>
);
