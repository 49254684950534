import { IMarketingStatistic } from 'api/models/responses/dashboard/marketingStatistic';
import { IStudioStatistic } from 'api/models/responses/dashboard/studioStatistic';

import { dashboardUrls } from 'constants/apiUrls/dashboardUrls';

import { BaseService } from '../BaseService';

class DashboardService extends BaseService {
	async getMarketingStatistic(
		dateFrom: string,
		dateTo: string
	): Promise<IMarketingStatistic[] | null> {
		return this.get<IMarketingStatistic[]>(dashboardUrls.marketingStatistic, {
			params: {
				dateFrom,
				dateTo,
			},
		});
	}

	async getStudioStatistic(
		dateFrom: string,
		dateTo: string
	): Promise<IStudioStatistic | null> {
		return this.get<IStudioStatistic>(dashboardUrls.studio, {
			params: {
				dateFrom,
				dateTo,
			},
		});
	}
}

export default new DashboardService();
