import { date, object, string } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	maxOrderNotificationEmailLength,
	maxOrderConfirmationMessageLength,
	orderNotificationEmailErrorMessage,
	orderConfirmationMessageErrorMessage,
} from 'constants/projects/validation/generalProjectFields';
import {
	emailRegEx,
	emailErrorMessage,
} from 'constants/general/validation/emailValidation';

import { getDateErrorMessage } from 'pages/Projects/validation';
import { TestContextExtended } from 'pages/Projects/types';

import { IEditSaleValues } from '.';

export const editSaleScheme = object().shape({
	orderNotificationEmail: string()
		.notRequired()
		.max(maxOrderNotificationEmailLength, orderNotificationEmailErrorMessage)
		.matches(emailRegEx, emailErrorMessage),
	orderConfirmationMessage: string()
		.notRequired()
		.max(
			maxOrderConfirmationMessageLength,
			orderConfirmationMessageErrorMessage
		),
	saleStartDate: date()
		.default(null)
		.required(requiredFieldMessage)
		.test('', '', (saleStartDate, context) => {
			const { parent } = context as TestContextExtended;
			const { saleEndDate } = parent as IEditSaleValues;

			if (!saleStartDate || !saleEndDate) return true;

			if (saleStartDate >= saleEndDate) {
				return context.createError(getDateErrorMessage('End Sale Date', false));
			}

			return true;
		})
		.nullable(),
	saleEndDate: date()
		.default(null)
		.required(requiredFieldMessage)
		.test('', '', (saleEndDate, context) => {
			const { parent } = context as TestContextExtended;
			const { saleStartDate } = parent as IEditSaleValues;

			if (!saleStartDate || !saleEndDate) return true;

			if (saleEndDate <= saleStartDate) {
				return context.createError(getDateErrorMessage('Start Sale Date'));
			}

			return true;
		})
		.nullable(),
});
