import { MediaLibrary } from 'pages/WatermarkMedia/pages/MediaLibrary';
import { WatermarkMedia } from 'pages/WatermarkMedia/pages/Watermark';

import { ROUTES } from 'constants/ROUTES';

export const watermarkMediaRoutesList = [
	{
		path: ROUTES.WATERMARK_MEDIA.WATERMARK_MEDIA,
		element: <WatermarkMedia />,
	},
	{
		path: ROUTES.WATERMARK_MEDIA.MEDIA_LIBRARY,
		element: <MediaLibrary />,
	},
];
