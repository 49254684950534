import { FC, useCallback, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { Modal, ModalRef } from 'components/Modal';
import { Loader } from 'components/Loader';

import { imageUploadTypes } from 'constants/images/validation/imageUploadTypes';
import {
	addedImagesCountSelector,
	uploadMultipleImagesAsync,
	skippedImagesCountSelector,
	isUploadImagesPendingSelector,
} from 'store/imageGallery';

import { UploadingMediaLimit } from '../UploadingMediaLimit';
import { UploadingMedia } from '../UploadingMedia';

const uploadModalTitle = 'Uploading Your Media';
const uploadModalSubtitle =
	'Your request has been added to the queue and will shortly be available to use. It is OK to close this message.';

export const UploadNewMedia: FC = () => {
	const isUploadImagesPending = useAppSelector(isUploadImagesPendingSelector);
	const skippedImagesCount = useAppSelector(skippedImagesCountSelector);
	const addedImagesCount = useAppSelector(addedImagesCountSelector);

	const uploadMediaLimitModalRef = useRef<ModalRef>(null);
	const uploadMediaModalRef = useRef<ModalRef>(null);

	const dispatch = useAppDispatch();

	const showUploadMediaModal = () => {
		uploadMediaModalRef?.current?.open();
	};

	const hideUploadMediaModal = () => {
		uploadMediaModalRef?.current?.close();
	};

	const showUploadMediaLimitModal = () => {
		uploadMediaLimitModalRef?.current?.open();
	};

	const hideUploadMediaLimitModal = () => {
		uploadMediaLimitModalRef?.current?.close();
	};

	const onDrop = (acceptedFiles: File[]) => {
		void dispatch(uploadMultipleImagesAsync(acceptedFiles));
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		multiple: true,
		accept: imageUploadTypes,
	});

	useEffect(() => {
		if (!skippedImagesCount && !addedImagesCount) return;

		if (!skippedImagesCount) {
			return showUploadMediaModal();
		}

		return showUploadMediaLimitModal();
	}, [addedImagesCount, skippedImagesCount]);

	return (
		<>
			<div className="media-section">
				<h4 className="media-section-title">Upload new Media</h4>
				{isUploadImagesPending ? (
					<Loader />
				) : (
					<div
						className={cn('media-drop', {
							'media-drop-active': isDragActive,
						})}
						{...getRootProps()}
					>
						<input {...getInputProps()} />
						<span className="media-drop-title">Drop Files to Upload</span>
						<span className="media-drop-divider">or</span>
						<button className="btn-primary media-drop-btn">Select Files</button>
					</div>
				)}
			</div>
			<Modal
				title={uploadModalTitle}
				ref={uploadMediaModalRef}
				subtitle={uploadModalSubtitle}
			>
				<UploadingMedia
					addedImagesCount={addedImagesCount}
					hideUploadMediaModal={hideUploadMediaModal}
				/>
			</Modal>
			<Modal
				title={uploadModalTitle}
				ref={uploadMediaLimitModalRef}
				subtitle={uploadModalSubtitle}
			>
				<UploadingMediaLimit
					addedImagesCount={addedImagesCount}
					skippedImagesCount={skippedImagesCount}
					hideUploadMediaLimitModal={hideUploadMediaLimitModal}
				/>
			</Modal>
		</>
	);
};
