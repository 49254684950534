import { FC, ReactNode } from 'react';

import { splitDecimalPricePart } from 'utils/general/splitDecimalPricePart';
import { normalizeDigit } from 'utils/ui/normalizeDigit';

import { IStatisticRenderHelper } from 'types/statistic/statisticRenderHelper';

interface IPanelDataProps {
	children?: ReactNode;
	statistic: Record<string, number> | null;
	statisticKeys: IStatisticRenderHelper[];
	statisticPriceKeys: IStatisticRenderHelper[];
}

interface IStatisticItems {
	title: string;
	value: string;
}

interface IStatisticPriceItems {
	title: string;
	intPrice: string;
	decimalPrice: string;
}

export const PanelData: FC<IPanelDataProps> = ({
	children,
	statistic,
	statisticKeys,
	statisticPriceKeys,
}) => {
	const statisticItems: IStatisticItems[] = statisticKeys.map(
		({ key, title }) => ({
			title,
			value: statistic ? normalizeDigit({ value: statistic[key] }) : '',
		})
	);

	const statisticPriceItems: IStatisticPriceItems[] = statisticPriceKeys.map(
		({ key, title }) => {
			if (!statistic) {
				return {
					title,
					intPrice: '',
					decimalPrice: '',
				};
			}

			const price = statistic[key];

			const isDecimal = price % 1;

			if (!isDecimal) {
				return {
					title,
					decimalPrice: '00',
					intPrice: normalizeDigit({ value: price }),
				};
			}

			const normalizedPriceExcludeDollar = normalizeDigit({
				value: price,
				minimumFractionDigits: 2,
			});

			const { intString, decimalString } = splitDecimalPricePart(
				normalizedPriceExcludeDollar
			);

			return {
				title,
				intPrice: intString,
				decimalPrice: decimalString,
			};
		}
	);

	const StatisticList = statisticItems.map(({ title, value }) => (
		<li key={title} className="data-item">
			<span className="data-sum">{value}</span>
			<span className="data-label">{title}</span>
		</li>
	));

	const StatisticPriceList = statisticPriceItems.map(
		({ title, intPrice, decimalPrice }) => (
			<li key={title} className="data-item">
				<span className="data-sum data-sup-sum">
					<sup>$</sup> {intPrice}
					<sup className="data-pennies">{decimalPrice}</sup>
				</span>
				<span className="data-label">{title}</span>
			</li>
		)
	);

	return (
		<div className="data">
			<div className="data-content">
				<ul className="data-list">
					{StatisticList}
					{StatisticPriceList}
				</ul>
			</div>
			{children}
		</div>
	);
};
