import { FC, useEffect, useState } from 'react';
import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { useToggleSection } from 'hooks/useToggleSection';
import { Loader } from 'components/Loader';
import { ProjectDetails } from './ProjectDetails';
import { IDetailsGeneralProps } from '../..';

export const ProjectDetailsContainer: FC<IDetailsGeneralProps> = ({
	isPending,
	projectDetails,
	setProjectDetails,
}) => {
	const { isOpen, handleToggle } = useToggleSection(true);
	const [showComponent, setShowComponent] = useState(window.innerWidth < 768);

	useEffect(() => {
		const handleResize = () => setShowComponent(window.innerWidth < 768);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return showComponent ? null : (
		<ToggleSection
			title="Details"
			isOpen={isOpen}
			className="preset-details-header"
			handleToggle={handleToggle}
			header={
				<Button
					value="View Project Page"
					className="btn-secondary org-details-btn"
				/>
			}
		>
			{!isPending && projectDetails ? (
				<ProjectDetails
					projectDetails={projectDetails}
					setProjectDetails={setProjectDetails}
				/>
			) : (
				<Loader />
			)}
		</ToggleSection>
	);
};
