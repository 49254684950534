import { ISelectOption } from 'types/ui/select';

import { PriceListFulfillment } from '../priceListFulfillment';

export const priceListFulfillmentSelectOptions: ISelectOption<PriceListFulfillment>[] =
	Object.values(PriceListFulfillment)
		.filter((fulfillment) => fulfillment !== PriceListFulfillment.NA)
		.map((fulfillment) => ({
			label: fulfillment,
			value: fulfillment,
		}));
