import { ICreateNewOrganizationBody } from 'api/models/requests/organizations/createNewOrganization';
import { IOrganizationStatistic } from 'api/models/responses/organizations/organizationStatistic';
import { IArchivedOrganization } from 'api/models/responses/organizations/archivedOrganization';
import { IOrganizationDetails } from 'api/models/responses/organizations/organizationDetails';
import { IDeleteOrganization } from 'api/models/responses/organizations/deleteOrganization';
import { IActiveOrganization } from 'api/models/responses/organizations/activeOrganization';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { organizationsUrls } from 'constants/apiUrls/organizationsUrls';
import { multipartHeaders } from 'constants/images/multipartHeaders';

import { BaseService } from '../BaseService';

class OrganizationService extends BaseService {
	async getOrganizationStatistic(
		orgKey: number
	): Promise<IOrganizationStatistic | null> {
		return this.get(organizationsUrls.getOrganizationStatistic(orgKey));
	}

	async getActiveOrganizations(): Promise<IActiveOrganization[] | null> {
		return this.get(organizationsUrls.getActiveOrganizations);
	}

	async getArchivedOrganizations(): Promise<IArchivedOrganization[] | null> {
		return this.get(organizationsUrls.getArchivedOrganizations);
	}

	async getOrganizationDetails(
		orgKey: number
	): Promise<IOrganizationDetails | null> {
		return this.get(organizationsUrls.organizationUrlIncludingKey(orgKey));
	}

	async fullOrganizationUpdate(
		orgKey: number,
		body: Partial<IOrganizationDetails>
	): Promise<IOrganizationDetails | null> {
		return this.put(
			organizationsUrls.organizationUrlIncludingKey(orgKey),
			body
		);
	}

	async partialOrganizationUpdate(
		orgKey: number,
		body: IPatchBody[]
	): Promise<IOrganizationDetails | null> {
		return this.patch(
			organizationsUrls.organizationUrlIncludingKey(orgKey),
			body
		);
	}

	async deleteOrganization(
		orgKey: number
	): Promise<IDeleteOrganization | null> {
		return this.delete(organizationsUrls.organizationUrlIncludingKey(orgKey));
	}

	async createNewOrganization(
		createOrganizationBody: ICreateNewOrganizationBody
	): Promise<IOrganizationDetails | null> {
		return this.post(
			organizationsUrls.createNewOrganization,
			createOrganizationBody
		);
	}

	async setCoverImage(orgKey: number, file: File): Promise<string | null> {
		const formData = new FormData();

		formData.set('imageFile', file);

		return this.post(organizationsUrls.coverImageUrl(orgKey), formData, {
			...multipartHeaders,
		});
	}

	async setSearchResultImage(
		orgKey: number,
		file: File
	): Promise<string | null> {
		const formData = new FormData();

		formData.set('imageFile', file);

		return this.post(
			organizationsUrls.searchResultsImageUrl(orgKey),
			formData,
			{
				...multipartHeaders,
			}
		);
	}

	async deleteCoverImage(orgKey: number): Promise<boolean | null> {
		return this.delete(organizationsUrls.coverImageUrl(orgKey));
	}

	async deleteSearchResultImage(orgKey: number): Promise<boolean | null> {
		return this.delete(organizationsUrls.searchResultsImageUrl(orgKey));
	}
}

export default new OrganizationService();
