import { FC } from 'react';

import { useWatermarkMediaBreadcrumbs } from 'pages/WatermarkMedia/hooks/useWatermarkMediaBreadcrumbs';

import { MediaLibrarySection } from './components/MediaLibrarySection';
import { WatermarkSection } from './components/WatermarkSection';

export const WatermarkMedia: FC = () => {
	useWatermarkMediaBreadcrumbs();

	return (
		<div className="watermark-container">
			<WatermarkSection />
			<MediaLibrarySection />
		</div>
	);
};
