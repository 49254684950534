import cn from 'classnames';
import { FC } from 'react';

import { IInputProps } from 'types/ui/inputProps';

export const Input: FC<IInputProps> = ({
	id,
	type,
	label,
	value,
	disabled,
	readOnly,
	required,
	className,
	handleBlur,
	placeholder,
	handleChange,
	handleKeyDown,
}) => (
	<>
		{label && (
			<label
				htmlFor={id}
				className={cn('label', {
					'label-required': required,
				})}
			>
				{label}
			</label>
		)}
		<input
			id={id}
			type={type}
			value={value}
			disabled={disabled}
			readOnly={readOnly}
			onBlur={handleBlur}
			onChange={handleChange}
			placeholder={placeholder}
			onKeyDown={handleKeyDown}
			className={`input exp-wrapper ${className || ''}`}
		/>
	</>
);

Input.defaultProps = {
	type: 'text',
	className: '',
	required: false,
};
