import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';

import { subscriptionLevelKeySelector } from 'store/subscriptions';
import { studioKeySelector } from 'store/studio';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { ROUTES } from 'constants/ROUTES';

export const useRedirectToRegistrationSteps = () => {
	const [isStepsAvailable, setIsStepsAvailable] = useState(true);

	const selectedSubscriptionLevelKey = useAppSelector(
		subscriptionLevelKeySelector
	);
	const studioKey = useAppSelector(studioKeySelector);

	const { accounts } = useMsal();
	const navigate = useNavigate();

	useEffect(() => {
		if (!accounts.length) return;

		const registrationStepCompleted =
			accounts[0].idTokenClaims?.extension_RegistrationStepCompleted;

		if (!registrationStepCompleted) {
			if (selectedSubscriptionLevelKey && studioKey) {
				return navigate(ROUTES.STUDIO_STEPS.GET_PAID);
			}

			if (studioKey) {
				return navigate(ROUTES.STUDIO_STEPS.SELECT_PLAN);
			}

			return navigate(ROUTES.STUDIO_STEPS.CREATE_STUDIO);
		}

		if (registrationStepCompleted === 1) {
			return navigate(ROUTES.STUDIO_STEPS.SELECT_PLAN);
		}

		if (registrationStepCompleted === 2) {
			return navigate(ROUTES.STUDIO_STEPS.GET_PAID);
		}

		setIsStepsAvailable(false);
	}, [accounts, navigate]);

	return isStepsAvailable;
};
