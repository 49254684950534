import { FC, SyntheticEvent, useState } from 'react';

import { IErrorResponse } from 'api/models/responses/errors/errorResponse';
import { CampaignsTarget } from 'api/models/responses/campaigns/campaign';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { InputGrid } from 'components/FormControls/InputGrid';

interface ICampaignDetailsHeaderProps {
	name?: string;
	disabledEdit: boolean;
	target?: CampaignsTarget;
	updateCampaign: (body: IPatchBody[]) => Promise<void>;
}

export const CampaignDetailsHeader: FC<ICampaignDetailsHeaderProps> = ({
	name,
	target,
	disabledEdit,
	updateCampaign,
}) => {
	const [nameError, setNameError] = useState('');

	const updateName = async (value: string) => {
		try {
			const updatedField: IPatchBody = {
				value,
				path: 'name',
				op: 'replace',
			};

			const body: IPatchBody[] = [updatedField];

			await updateCampaign(body);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			if (errors.name) {
				setNameError(errors.name as string);
			}
		}
	};

	const handleChangeName = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		void updateName(value);
	};

	const clearNameError = () => {
		setNameError('');
	};

	return (
		<div className="campaign-details-header">
			<InputGrid
				row
				isLazy
				required={false}
				error={nameError}
				defaultValue={name}
				className="autorow"
				label="Campaign Name"
				disabled={disabledEdit}
				clearError={clearNameError}
				placeholder="Prospect Campaign"
				handleLazyChange={handleChangeName}
			/>
			<InputGrid
				row
				readOnly
				value={target}
				disabled={false}
				required={false}
				className="autorow"
				label="Campaign Target"
				placeholder="Prospect Campaign"
			/>
		</div>
	);
};
