import { useEffect, useState } from 'react';

import { AlbumsSortLabels } from 'constants/albums/albumsSortLabels';
import { DataOrder } from 'constants/general/dataOrder';

import { IAlbum } from 'api/models/responses/albums/album';
import { useSortParams } from 'hooks/useSortParams';

import { IAlbumUI } from '../components/Albums/types';

interface ISortAlbumsResult {
	sortedAlbums: IAlbumUI[];
	orderDirection: DataOrder;
	orderBy: AlbumsSortLabels;
	setSortParams: (sortLabel: AlbumsSortLabels) => void;
}

export const useSortAlbums = (albums: IAlbumUI[]): ISortAlbumsResult => {
	const [sortedAlbums, setSortedAlbums] = useState<IAlbumUI[]>([]);

	const { orderBy, setSortParams, orderDirection } =
		useSortParams<AlbumsSortLabels>(AlbumsSortLabels.Group);

	useEffect(() => {
		if (!albums.length) return;

		const albumsCopy = [...albums];

		const isAsc = orderDirection === DataOrder.ASC;

		const sortByAlphabet = (key: keyof IAlbum) => {
			return albumsCopy.sort((a, b) => {
				const valueA = a[key] as string;
				const valueB = b[key] as string;

				if (valueA === null) return isAsc ? 1 : -1;
				if (valueB === null) return isAsc ? -1 : 1;

				return isAsc
					? valueA.localeCompare(valueB)
					: valueB.localeCompare(valueA);
			});
		};

		switch (orderBy) {
			case AlbumsSortLabels.Group: {
				const sortedAlbumsByGroup = sortByAlphabet('name');

				return setSortedAlbums(sortedAlbumsByGroup);
			}

			case AlbumsSortLabels.AccessCode: {
				const sortedAlbumsByAccessCode = sortByAlphabet('password');

				return setSortedAlbums(sortedAlbumsByAccessCode);
			}

			case AlbumsSortLabels.Images: {
				const sortedAlbumsByImages = albumsCopy.sort((a, b) =>
					isAsc ? a.imagesCount - b.imagesCount : b.imagesCount - a.imagesCount
				);

				return setSortedAlbums(sortedAlbumsByImages);
			}

			default:
				return setSortedAlbums(albums);
		}
	}, [orderBy, orderDirection, albums]);

	return { orderBy, sortedAlbums, orderDirection, setSortParams };
};
