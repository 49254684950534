import { imageGalleryUrls } from 'constants/apiUrls/imageGalleryUrls';
import {
	IGalleryImage,
	IUploadedGalleryImages,
} from 'api/models/responses/imageGallery';

import { BaseService } from '../BaseService';

class ImageGalleryService extends BaseService {
	async getGalleryImages(): Promise<IGalleryImage[] | null> {
		return this.get(imageGalleryUrls.imageGalleryUrl);
	}

	async uploadMultipleImages(
		imageFiles: File[]
	): Promise<IUploadedGalleryImages | null> {
		const formData = new FormData();

		imageFiles.forEach((file) => {
			formData.append('images', file);
		});

		return this.post(imageGalleryUrls.uploadMultipleImages, formData);
	}

	async deleteGalleryImage(galleryImageKey: number): Promise<string | null> {
		return this.delete(imageGalleryUrls.deleteGalleryImage(galleryImageKey));
	}
}

export default new ImageGalleryService();
