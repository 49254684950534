/* eslint-disable @typescript-eslint/no-throw-literal */
import { AxiosError, AxiosRequestConfig } from 'axios';

import { loginRequest } from 'config/authConfig';
import { api } from 'config/api';

import { IPatchBody } from 'api/models/requests/general/patchBody';
import { msalInstance } from 'index';

export class BaseService {
	errorProcessing(exception: AxiosError) {
		if (exception?.response) {
			const statusCode = exception?.response?.status;

			switch (statusCode) {
				case 404:
					throw exception.response.data;

				case 401:
					return void msalInstance.loginRedirect(loginRequest);

				case 403:
					return void msalInstance.loginRedirect(loginRequest);

				case 400:
					throw exception.response.data;

				case 500:
					throw exception.response.data;

				default:
					console.log(exception);
					return;
			}
		}
	}

	async get<Response>(url: string, config: AxiosRequestConfig = {}) {
		try {
			const { data } = await api.get<Response>(url, config);

			return data;
		} catch (error) {
			const exception = error as AxiosError;

			this.errorProcessing(exception);

			return null;
		}
	}

	async post<Response, Request>(
		url: string,
		body: Request,
		config: AxiosRequestConfig = {}
	) {
		try {
			const { data } = await api.post<Response>(url, body, config);

			return data;
		} catch (error) {
			const exception = error as AxiosError;

			this.errorProcessing(exception);

			return null;
		}
	}

	async put<Response, Request>(
		url: string,
		body: Request,
		config: AxiosRequestConfig = {}
	) {
		try {
			const { data } = await api.put<Response>(url, body, config);

			return data;
		} catch (error) {
			const exception = error as AxiosError;

			this.errorProcessing(exception);

			return null;
		}
	}

	async patch<Response>(url: string, body: IPatchBody[]) {
		try {
			const { data } = await api.patch<Response>(url, body);

			return data;
		} catch (error) {
			const exception = error as AxiosError;

			this.errorProcessing(exception);

			return null;
		}
	}

	async delete<Response>(url: string) {
		try {
			const { data } = await api.delete<Response>(url);

			return data;
		} catch (error) {
			const exception = error as AxiosError;

			this.errorProcessing(exception);

			return null;
		}
	}
}
