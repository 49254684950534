import {
	maxSmsSpecialMessageLength,
	smsSpecialMessageErrorMessage,
} from 'constants/projects/validation/generalProjectFields';

export const validateSmsSpecialMessage = (
	smsSpecialMessage: string
): string => {
	if (smsSpecialMessage.length > maxSmsSpecialMessageLength) {
		return smsSpecialMessageErrorMessage;
	}

	return '';
};
