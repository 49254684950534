export interface IGeneralFormQuestion {
	sequence: number;
	formQuestion: string;
	isAnswerRequired: boolean;
	dataFieldType: FormQuestionDataFieldType;
}

export enum FormQuestionDataFieldType {
	Address1 = 'Address1',
	Address2 = 'Address2',
	Age = 'Age',
	Background = 'Background',
	Banner = 'Banner',
	Battalion = 'Battalion',
	City = 'City',
	CLASS = 'CLASS',
	ClientID = 'ClientID',
	Coach = 'Coach',
	Company = 'Company',
	Counter = 'Counter',
	Coupon = 'Coupon',
	Custom1 = 'Custom1',
	Custom10 = 'Custom10',
	Custom2 = 'Custom2',
	Custom3 = 'Custom3',
	Custom4 = 'Custom4',
	Custom5 = 'Custom5',
	Custom6 = 'Custom6',
	Custom7 = 'Custom7',
	Custom8 = 'Custom8',
	Custom9 = 'Custom9',
	CustomerName = 'CustomerName',
	Dance = 'Dance',
	Date = 'Date',
	Date2 = 'Date2',
	DateOfBirth = 'DateOfBirth',
	Distribution = 'Distribution',
	Division = 'Division',
	Email = 'Email',
	Email2 = 'Email2',
	Email3 = 'Email3',
	Email4 = 'Email4',
	EventName = 'EventName',
	ExpirationDate = 'ExpirationDate',
	Father = 'Father',
	FavoritePro = 'FavoritePro',
	FirstName = 'FirstName',
	Frame = 'Frame',
	FullName = 'FullName',
	Gender = 'Gender',
	Grade = 'Grade',
	GroupPictureTitle = 'GroupPictureTitle',
	Height = 'Height',
	HexCode = 'HexCode',
	HomePhone = 'HomePhone',
	HomeRoom = 'HomeRoom',
	Honorific = 'Honorific',
	ImageCount = 'ImageCount',
	JerseyNumber = 'JerseyNumber',
	JpgOptions = 'JpgOptions',
	LastName = 'LastName',
	League = 'League',
	Level = 'Level',
	Location = 'Location',
	Logo = 'Logo',
	LOGOFILE = 'LOGOFILE',
	Major = 'Major',
	Mascot = 'Mascot',
	MiddleName = 'MiddleName',
	MobilePhone = 'MobilePhone',
	MobilePhone2 = 'MobilePhone2',
	MobilePhone3 = 'MobilePhone3',
	MobilePhone4 = 'MobilePhone4',
	Monogram = 'Monogram',
	Mother = 'Mother',
	NeedYB = 'NeedYB',
	NickName = 'NickName',
	OrderBatchID = 'OrderBatchID',
	OrderDate = 'OrderDate',
	PackageOrderNumber = 'PackageOrderNumber',
	Packages = 'Packages',
	ParentName = 'ParentName',
	Password = 'Password',
	Period = 'Period',
	Personalization1 = 'Personalization1',
	Personalization2 = 'Personalization2',
	Photographer = 'Photographer',
	Pose = 'Pose',
	Position = 'Position',
	'Position#' = 'Position#',
	POSTACTION1 = 'POSTACTION1',
	POSTACTION2 = 'POSTACTION2',
	Principal = 'Principal',
	PurchaserFirstName = 'PurchaserFirstName',
	PurchaserLastName = 'PurchaserLastName',
	RecordNumber = 'RecordNumber',
	'Ref#' = 'Ref#',
	RefundSpecialInstructions = 'RefundSpecialInstructions',
	RetakeDate = 'RetakeDate',
	Roll = 'Roll',
	'Roster#' = 'Roster#',
	Row1 = 'Row1',
	Row2 = 'Row2',
	Row3 = 'Row3',
	Row4 = 'Row4',
	Row5 = 'Row5',
	SchoolID = 'SchoolID',
	SchoolName = 'SchoolName',
	SequenceNumber = 'SequenceNumber',
	ShipToAddress1 = 'ShipToAddress1',
	ShipToAddress2 = 'ShipToAddress2',
	ShipToCity = 'ShipToCity',
	ShipToEmail = 'ShipToEmail',
	ShipToMobilePhone = 'ShipToMobilePhone',
	ShipToName = 'ShipToName',
	ShipToState = 'ShipToState',
	ShipToZip = 'ShipToZip',
	SPATEXT1 = 'SPATEXT1',
	SPATEXT2 = 'SPATEXT2',
	SPATEXT3 = 'SPATEXT3',
	SPATEXT4 = 'SPATEXT4',
	SPATEXT5 = 'SPATEXT5',
	SportType = 'SportType',
	Squad = 'Squad',
	State = 'State',
	StatisticsString = 'StatisticsString',
	StudentID = 'StudentID',
	Style = 'Style',
	Teacher = 'Teacher',
	TeamColor = 'TeamColor',
	TeamID = 'TeamID',
	TeamImageFileName = 'TeamImageFileName',
	TeamName = 'TeamName',
	Template = 'Template',
	TEMPLATEFILE = 'TEMPLATEFILE',
	TicketCode = 'TicketCode',
	Title = 'Title',
	Track = 'Track',
	WalletPersonalization1 = 'WalletPersonalization1',
	WalletPersonalization2 = 'WalletPersonalization2',
	'WebOrder#' = 'WebOrder#',
	WebURL = 'WebURL',
	Weight = 'Weight',
	WorkPhone = 'WorkPhone',
	Year = 'Year',
	Year2 = 'Year2',
	Zip = 'Zip',
}
