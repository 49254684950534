import { DataOrder } from 'constants/general/dataOrder';

import { ColumnLabel } from './ColumnLabel';

interface IColumnLabelArrowContainerLabelProps<SortLabels> {
	label: string;
	orderBy: SortLabels;
	sortLabel: SortLabels;
	orderDirection: DataOrder;
	setSortParams: (sortLabel: SortLabels) => void;
}

export const ColumnLabelContainer = <SortLabels,>({
	label,
	orderBy,
	sortLabel,
	setSortParams,
	orderDirection,
}: IColumnLabelArrowContainerLabelProps<SortLabels>) => {
	const showArrowIcon = sortLabel === orderBy;
	const isActive = orderDirection === DataOrder.ASC;

	const handleSort = () => {
		setSortParams(sortLabel);
	};

	return (
		<ColumnLabel
			label={label}
			isActive={isActive}
			handleSort={handleSort}
			showArrowIcon={showArrowIcon}
		/>
	);
};
