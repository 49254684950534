import { object, string } from 'yup';

import { campaignNameRegex } from 'constants/campaigns/validation';
import {
	requiredFieldMessage,
	invalidCharactersMessage,
} from 'constants/general/validation/generalMessages';

export const copyCampaignValidationSchema = object().shape({
	name: string()
		.required(requiredFieldMessage)
		.matches(campaignNameRegex, invalidCharactersMessage),
});
