import { FC, useEffect, useState } from 'react';

import { ITimelineItem } from 'api/models/responses/projects/timeline';
import { useToggleSection } from 'hooks/useToggleSection';
import { ToggleSection } from 'components/ToggleSection';
import { IUpdateTimeline } from 'pages/Projects/types';

import { Timeline } from './Timeline';

export interface IToggleTimelineContainerProps extends IUpdateTimeline {
	projectKey?: number;
	setIsUpdateProject: (isUpdate: boolean) => void;
}

export const TimelineContainer: FC<IToggleTimelineContainerProps> = ({
	projectKey,
	isUpdateTimeline,
	setIsUpdateProject,
	setIsUpdateTimeline,
}) => {
	const [timeline, setTimeline] = useState<ITimelineItem[]>([]);
	const [isPending, setIsPending] = useState(false);

	const { isOpen, handleToggle } = useToggleSection(false);
	const [showComponent, setShowComponent] = useState(window.innerWidth < 768);

	useEffect(() => {
		const handleResize = () => setShowComponent(window.innerWidth < 768);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return showComponent ? null : (
		<ToggleSection
			isOpen={isOpen}
			title="Timeline"
			handleToggle={handleToggle}
			className="preset-details-header"
		>
			<Timeline
				timeline={timeline}
				isPending={isPending}
				projectKey={projectKey}
				setTimeline={setTimeline}
				setIsPending={setIsPending}
				isUpdateTimeline={isUpdateTimeline}
				setIsUpdateProject={setIsUpdateProject}
				setIsUpdateTimeline={setIsUpdateTimeline}
			/>
		</ToggleSection>
	);
};
