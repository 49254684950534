import { FC } from 'react';

import { poseAvailableForSelectOptions } from 'constants/priceLists/poseAvailableFor/poseAvailableForSelectOptions';
import { PoseAvailableFor } from 'constants/priceLists/poseAvailableFor/poseAvailableFor';

import { SelectComponent } from 'components/FormControls/Select';
import { LazyInput } from 'components/FormControls/LazyInput';

import { useAsyncOptimizedSelect } from 'hooks/formControls/useAsyncOptimizedSelect';

interface IProductOptionsProps {
	maxNumberOfPoses: number;
	availableFor: PoseAvailableFor;
	showMaxNumberOfPoses?: boolean;
	disabledAvailableFor?: boolean;
	updateAvailableFor: (value: PoseAvailableFor) => Promise<void>;
}

export const ProductOptions: FC<IProductOptionsProps> = ({
	availableFor,
	maxNumberOfPoses,
	updateAvailableFor,
	showMaxNumberOfPoses,
	disabledAvailableFor,
}) => {
	const { value, isPending, handleChange } = useAsyncOptimizedSelect({
		initialValue: availableFor,
		updateSelectValue: updateAvailableFor,
	});

	return (
		<div className="price-textarea-label">
			<span className="price-textarea-label">Pose Options</span>
			<div className="price-textarea-container">
				<SelectComponent
					value={value}
					label="Available for"
					onChange={handleChange}
					disabled={disabledAvailableFor || isPending}
					selectOptions={poseAvailableForSelectOptions}
				/>
			</div>
			{showMaxNumberOfPoses && (
				<LazyInput
					label="Max # of poses"
					placeholder="Max # of poses"
					defaultValue={maxNumberOfPoses.toString()}
				/>
			)}
		</div>
	);
};
