import { FC } from 'react';

import { StepSubjectHeader } from 'components/StepSubjectHeader';
import { IStepControl } from 'pages/Projects/types';

import { PresetDetails } from './components/PresetDetails';
import { Form } from './components/Form';

export const CreateProjectFromPresetStep2: FC<IStepControl> = ({ setStep }) => (
	<div className="project-thumb">
		<StepSubjectHeader step="Step 2 of 2" title="Name and Date Your Project" />
		<div className="project-blank-main">
			<Form setStep={setStep} />
			<PresetDetails />
		</div>
	</div>
);
