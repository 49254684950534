import { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import { BroadcastEmail } from 'components/Broadcast/BroadcastEmail';
import { TextEditorRef } from 'components/TextEditor/types';

import { validateEmailBody } from 'utils/validations/broadcasts/validateEmailBody';

import { IGeneralBroadcastProps, ISendBroadcastValues } from '../../types';

interface IEmailSectionProps extends IGeneralBroadcastProps {
	editorRef: TextEditorRef;
}

export const EmailSection: FC<IEmailSectionProps> = ({
	editorRef,
	broadcastInfo,
}) => {
	const { values, errors, touched, isSubmitting, handleChange, setFieldValue } =
		useFormikContext<ISendBroadcastValues>();

	const [emailBodyError, setEmailBodyError] = useState('');

	const clearEmailBodyError = () => {
		setEmailBodyError('');
	};

	useEffect(() => {
		if (!isSubmitting) return;

		const emailMessage = editorRef.current?.editor?.data.get() || '';

		if (!values.sendByEmail) return;

		const emailBodyMessage = validateEmailBody(emailMessage);

		setEmailBodyError(emailBodyMessage);
	}, [isSubmitting]);

	useEffect(() => {
		if (values.sendByEmail) return;

		void setFieldValue('emailSubject', '');
		void setFieldValue('sendToEmailAddress', '');

		editorRef.current?.editor?.data.set('');

		setEmailBodyError('');
	}, [values.sendByEmail]);

	return (
		<BroadcastEmail
			editorRef={editorRef}
			handleChange={handleChange}
			emailBodyError={emailBodyError}
			replyTo={broadcastInfo?.replyTo}
			emailSubject={values.emailSubject}
			emailFrom={broadcastInfo?.emailFrom}
			senderName={broadcastInfo?.senderName}
			emailSubjectError={errors.emailSubject}
			clearEmailBodyError={clearEmailBodyError}
			emailSubjectTouched={touched.emailSubject}
			disabledEmailSubject={!values.sendByEmail}
		/>
	);
};
