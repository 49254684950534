export const albumsUrls = {
	createAlbum: 'api/album',

	getAlbumUrl: (albumKey: string, projectKey: number) =>
		`api/album/${albumKey}/for-project/${projectKey}`,

	getAlbums: (projectKey: number) => `api/album/for-project/${projectKey}`,

	getImageUploadSasUrl:
		'imago-images-management/imago-studio/GetImageUploadSasUrl',
};
