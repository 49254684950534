import { ICreateAlaCarteBody } from 'api/models/requests/priceLists/studio/priceListAlaCarte/createAlaCarteBody';
import { IAlaCarteFormQuestion } from 'api/models/responses/priceLists/studio/alaCarteFormQuestion';
import { IGeneralFormQuestion } from 'api/models/responses/general/generalFormQuestion';
import { IAlaCarte } from 'api/models/responses/priceLists/studio/priceListAlaCarte';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { BaseService } from 'api/services/BaseService';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';

class AlaCarteService extends BaseService {
	async getAlaCartes(priceListKey: number): Promise<IAlaCarte[] | null> {
		return this.get(
			priceListsUrls.studioFulfilled.alaCarte.getAlaCarteUrlByPriceListKey(
				priceListKey
			)
		);
	}

	async createAlaCarte(
		priceListKey: number,
		{
			name,
			sequence,
			description,
			retailPrice,
			availableFor,
			referenceCode,
			previewImageFile,
			requiredPoseOptions,
			isWaiveShippingCharge,
			isIncludeImagesDownload,
			isBackgroundOptionRequired,
		}: ICreateAlaCarteBody
	): Promise<IAlaCarte | null> {
		const formData = new FormData();

		formData.set('Name', name);
		formData.set(
			'IsBackgroundOptionRequired',
			isBackgroundOptionRequired.toString()
		);
		formData.set('Description', description);
		formData.set('AvailableFor', availableFor);
		formData.set('ReferenceCode', referenceCode);
		formData.set('Sequence', sequence.toString());
		formData.set('RetailPrice', retailPrice.toString());
		formData.set('PreviewImage', previewImageFile ?? '');
		formData.set('RequiredPoseOptions', requiredPoseOptions);
		formData.set('IsWaiveShippingCharge', isWaiveShippingCharge.toString());
		formData.set('IsIncludeImagesDownload', isIncludeImagesDownload.toString());

		return this.post(
			priceListsUrls.studioFulfilled.alaCarte.getAlaCarteUrlByPriceListKey(
				priceListKey
			),
			formData
		);
	}

	async updateAlaCarte(
		alaCarteKey: number,
		body: IPatchBody[]
	): Promise<IAlaCarte | null> {
		return this.patch(
			priceListsUrls.studioFulfilled.alaCarte.getAlaCarteUrlByAlaCarteKey(
				alaCarteKey
			),
			body
		);
	}

	async deleteAlaCarte(alaCarteKey: number): Promise<string | null> {
		return this.delete(
			priceListsUrls.studioFulfilled.alaCarte.getAlaCarteUrlByAlaCarteKey(
				alaCarteKey
			)
		);
	}

	async reorderAlaCartes(
		priceListKey: number,
		body: Record<number, number>
	): Promise<string | null> {
		return this.put(
			priceListsUrls.studioFulfilled.alaCarte.reorderAlaCartes(priceListKey),
			body
		);
	}

	async uploadAlaCartePreviewImage(
		alaCarteKey: number,
		imageFile: File
	): Promise<string | null> {
		const formData = new FormData();

		formData.append('imageFile', imageFile);

		return this.post(
			priceListsUrls.studioFulfilled.alaCarte.getAlaCartePreviewImageUrl(
				alaCarteKey
			),
			formData,
			{ ...multipartHeaders }
		);
	}

	async deleteAlaCartePreviewImage(
		alaCarteKey: number
	): Promise<string | null> {
		return this.delete(
			priceListsUrls.studioFulfilled.alaCarte.getAlaCartePreviewImageUrl(
				alaCarteKey
			)
		);
	}

	async createAlaCarteFormQuestion(
		alaCarteKey: number,
		body: IGeneralFormQuestion
	): Promise<IAlaCarteFormQuestion | null> {
		return this.post(
			priceListsUrls.studioFulfilled.alaCarte.createAlaCarteFormQuestion(
				alaCarteKey
			),
			body
		);
	}

	async updateAlaCarteFormQuestion(
		formQuestionKey: number,
		body: IPatchBody[]
	): Promise<IAlaCarteFormQuestion | null> {
		return this.patch(
			priceListsUrls.studioFulfilled.alaCarte.getAlaCarteFormQuestionsUrl(
				formQuestionKey
			),
			body
		);
	}

	async deleteAlaCarteFormQuestion(
		formQuestionKey: number
	): Promise<string | null> {
		return this.delete(
			priceListsUrls.studioFulfilled.alaCarte.getAlaCarteFormQuestionsUrl(
				formQuestionKey
			)
		);
	}

	async reorderAlaCarteFormQuestions(
		alaCarteKey: number,
		body: Record<number, number>
	): Promise<string | null> {
		return this.put(
			priceListsUrls.studioFulfilled.alaCarte.reorderAlaCarteFormQuestions(
				alaCarteKey
			),
			body
		);
	}
}

export default new AlaCarteService();
