import {
	maxAccessCodeMessageLength,
	accessCodeMessageErrorMessage,
} from 'constants/projects/validation/generalProjectFields';

export const validateAccessCodeMessage = (accessCodeMessage: string) => {
	if (accessCodeMessage.length > maxAccessCodeMessageLength) {
		return accessCodeMessageErrorMessage;
	}

	return '';
};
