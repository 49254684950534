import { IStatisticRenderHelper } from 'types/statistic/statisticRenderHelper';

export const projectStatisticKeys = (
	subjects: string
): IStatisticRenderHelper[] => [
	{
		key: 'subjectsCount',
		title: subjects,
	},
	{
		key: 'imagesCount',
		title: 'Images',
	},
	{
		key: 'prospectsCount',
		title: 'Prospects',
	},
	{
		key: 'leadsCount',
		title: 'Leads',
	},
	{
		key: 'buyersCount',
		title: 'Orders',
	},
];
