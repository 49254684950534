import { SingleValue } from 'react-select';
import { ChangeEvent, FC } from 'react';

import { saleConditionSelectOptions } from 'constants/projects/selectOptions/saleConditionSelectOptions';
import { ISaleUI } from 'constants/projects/salesUI';

import { SaleCondition } from 'api/models/requests/presets/createPresets';
import { SaleTypes } from 'api/models/responses/projects/sales';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';
import { Checkbox } from 'components/FormControls/Checkbox';
import { TextArea } from 'components/FormControls/TextArea';
import { SaleTitle } from 'components/Sales/SaleTitle';
import { TextEditor } from 'components/TextEditor';

import { ISelectOption } from 'types/ui/select';

import {
	SaleErrors,
	ISaleValues,
	SaleTouched,
	IUpdateSalePayload,
} from './types';

interface ISaleProps {
	saleUI: ISaleUI;
	errors: SaleErrors;
	saleType: SaleTypes;
	values: ISaleValues;
	touched: SaleTouched;
	disabledSale?: boolean;
	disabledSaleExpiresInDays?: boolean;
	priceListsSelectOptions: ISelectOption<string>[];
	updateSale: (payload: IUpdateSalePayload) => void;
}

export const Sale: FC<ISaleProps> = ({
	saleUI,
	errors,
	values,
	touched,
	saleType,
	updateSale,
	disabledSale,
	priceListsSelectOptions,
	disabledSaleExpiresInDays,
}) => {
	const { icon, title, saleExpiresLabel, enableKioskMode } = saleUI;

	const handleChangePriceListKey = (
		option: SingleValue<ISelectOption<string>>
	) => {
		if (!option) return;

		updateSale({
			saleType,
			key: 'priceListKey',
			value: option.value,
		});
	};

	const handleChangeCondition = (
		option: SingleValue<ISelectOption<SaleCondition>>
	) => {
		if (!option) return;

		updateSale({
			saleType,
			key: 'condition',
			value: option.value,
		});
	};

	const handleChangeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		updateSale({
			value,
			saleType,
			key: name as keyof ISaleValues,
		});
	};

	const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = e.target;

		updateSale({
			saleType,
			value: checked,
			key: name as keyof ISaleValues,
		});
	};

	const handleChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
		const { name, value } = e.target;

		updateSale({
			value,
			saleType,
			key: name as keyof ISaleValues,
		});
	};

	const priceListSelectLabel = `${title} Price List`;

	return (
		<>
			<SaleTitle id="presetSale" icon={icon} title={title} />
			<div className="preset-form-layout">
				<div className="preset-grid preset-header-border">
					<div className="preset-section-wrapper">
						<SelectComponent
							disabled={disabledSale}
							value={values.priceListKey}
							error={errors.priceListKey}
							label={priceListSelectLabel}
							touched={touched.priceListKey}
							onChange={handleChangePriceListKey}
							selectPlaceholder="Select Price List"
							selectOptions={priceListsSelectOptions}
						/>
					</div>
				</div>
				<div className="preset-grid">
					<InputGrid
						placeholder="Days"
						label="Sale Expires"
						name="saleExpiresInDays"
						value={values.saleExpiresInDays}
						error={errors.saleExpiresInDays}
						className="autorow preset-expires"
						touched={touched.saleExpiresInDays}
						handleChange={handleChangeInputValue}
						disabled={disabledSale || disabledSaleExpiresInDays}
					/>
					<span className="preset-days">
						Days
						<SelectComponent
							disabled={disabledSale}
							value={values.condition}
							onChange={handleChangeCondition}
							selectOptions={saleConditionSelectOptions}
						/>
						{saleExpiresLabel}
					</span>
					{enableKioskMode && (
						<Checkbox
							disabled={disabledSale}
							label="Enable Kiosk Mode"
							name="isKioskModeEnabled"
							checked={values.isKioskModeEnabled}
							handleChange={handleCheckboxChange}
							id={`${saleType}-isKioskModeEnabled`}
						/>
					)}
				</div>
				<div className="preset-message preset-header-border">
					<InputGrid
						required={false}
						className="autorow"
						disabled={disabledSale}
						name="orderNotificationEmail"
						label="Order Notification Email"
						handleChange={handleChangeInputValue}
						value={values.orderNotificationEmail}
						error={errors.orderNotificationEmail}
						placeholder="Order Notification Email"
						touched={touched.orderNotificationEmail}
					/>
					<TextArea
						disabled={disabledSale}
						className="textarea-preset"
						name="orderConfirmationMessage"
						label="Order Confirmation Message"
						handleChange={handleChangeTextArea}
						value={values.orderConfirmationMessage}
						error={errors.orderConfirmationMessage}
						placeholder="Order Confirmation Message"
						touched={touched.orderConfirmationMessage}
					/>
				</div>
				<div className="project-section preset-header-border">
					<h2 className="project-section-title">Project Greeting</h2>
					{!disabledSale ? (
						<TextEditor initialContent={values.projectGreeting} />
					) : (
						<div
							// TODO: add styles for rendering html content
							dangerouslySetInnerHTML={{
								__html: values.projectGreeting,
							}}
						/>
					)}
				</div>
			</div>
		</>
	);
};
