import { IPriceListImago } from 'api/models/responses/priceLists/imago/priceListImago';
import { ICreatePriceList } from 'api/models/requests/priceLists/createPriceList';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { BaseService } from 'api/services/BaseService';

import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';

class PriceListImagoService extends BaseService {
	async getPriceListImago(
		priceListKey: number
	): Promise<IPriceListImago | null> {
		return this.get(
			priceListsUrls.imagoFulfilled.getPriceListImagoFulfilledUrl(priceListKey)
		);
	}

	async createPriceListImago(
		body: ICreatePriceList
	): Promise<IPriceListImago | null> {
		return this.post(
			priceListsUrls.imagoFulfilled.createPriceListImagoFulfilled,
			body
		);
	}

	async updatePriceListImago(
		priceListKey: number,
		body: IPatchBody[]
	): Promise<IPriceListImago | null> {
		return this.patch(
			priceListsUrls.imagoFulfilled.getPriceListImagoFulfilledUrl(priceListKey),
			body
		);
	}
}

export default new PriceListImagoService();
