import { useCallback, useMemo } from 'react';

import { JourneyPresetsSortLabels } from 'constants/journeyPresets/journeyPresetsSortLabels';
import { DataOrder } from 'constants/general/dataOrder';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useSortParams } from 'hooks/useSortParams';

import { filteredJourneyPresetsSelector } from 'store/journeyPresets';
import { IPreset } from 'api/models/responses/presets/presets';

interface ISortJourneyPresetsResult {
	orderDirection: DataOrder;
	sortedJourneyPresets: IPreset[];
	orderBy: JourneyPresetsSortLabels;
	setSortParams: (sortLabel: JourneyPresetsSortLabels) => void;
}

export const useSortJourneyPresets = (): ISortJourneyPresetsResult => {
	const { orderBy, setSortParams, orderDirection } =
		useSortParams<JourneyPresetsSortLabels>(JourneyPresetsSortLabels.Name);

	const filteredJourneyPresets = useAppSelector(filteredJourneyPresetsSelector);

	const sortByAlphabet = useCallback(
		(key: keyof IPreset) => {
			return [...filteredJourneyPresets].sort((a, b) => {
				const valueA = a[key] as string;
				const valueB = b[key] as string;

				return orderDirection === DataOrder.ASC
					? valueA.localeCompare(valueB)
					: valueB.localeCompare(valueA);
			});
		},
		[orderDirection, filteredJourneyPresets]
	);

	const sortedJourneyPresets = useMemo(() => {
		if (!filteredJourneyPresets.length) return filteredJourneyPresets;

		switch (orderBy) {
			case JourneyPresetsSortLabels.Name:
				return sortByAlphabet('presetName');

			case JourneyPresetsSortLabels.Journey:
				return sortByAlphabet('journey');

			case JourneyPresetsSortLabels.ProjectType:
				return sortByAlphabet('projectType');

			default:
				return filteredJourneyPresets;
		}
	}, [orderBy, sortByAlphabet, filteredJourneyPresets]);

	return { orderBy, setSortParams, orderDirection, sortedJourneyPresets };
};
