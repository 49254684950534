import moment from 'moment';

import {
	publishDate,
	lastPictureDate,
	firstPictureDate,
} from 'constants/projects/timeline/journeyTimelines';

export const getDisabledDateById = (id: string, date: Date | null): boolean => {
	if (!date) return false;

	const currentDate = moment().startOf('day').toDate();
	const noTimeDate = moment(date).startOf('day').toDate();

	switch (id) {
		case publishDate.id:
			return currentDate >= noTimeDate;

		case firstPictureDate.id:
			return currentDate > noTimeDate;

		case lastPictureDate.id:
			return currentDate > noTimeDate;

		default:
			return false;
	}
};
