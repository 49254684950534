import { FC } from 'react';

interface IQrCodeProps {
	qrUrl: string;
	label: string;
	fileName: string;
}

export const QrCode: FC<IQrCodeProps> = ({ qrUrl, label, fileName }) => (
	<div className="org-form-qr">
		<div className="org-qr">
			<span className="label">{label}</span>
			<a
				href={qrUrl}
				download={`${fileName} QR code`}
				className="btn-secondary"
			>
				Download QR
			</a>
		</div>
		<img src={qrUrl} alt="qr" className="org-qr-img" />
	</div>
);
