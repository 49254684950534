import { salesNotAvailableSelectOption } from 'constants/priceLists/selectOptions/salesNotAvailableSelectOption';
import { IPriceListSale } from 'api/models/responses/priceLists/priceListSale';
import { ISelectOption } from 'types/ui/select';

import { getPriceListSelectOptionKey } from './getPriceListSelectOptionKey';

export const getPriceListsSelectOptions = (
	priceLists: IPriceListSale[]
): ISelectOption<string>[] => [
	salesNotAvailableSelectOption,
	...priceLists.map(({ priceListKey, priceListName, fulfillmentType }) => ({
		label: priceListName,
		value: getPriceListSelectOptionKey(priceListKey, fulfillmentType),
	})),
];
