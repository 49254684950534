interface IValidationParams {
	imageRatio: number;
	requiredImageRatio: number;
	requiredImageRatioUI: string;
}

export const validateImageRatio = ({
	imageRatio,
	requiredImageRatio,
	requiredImageRatioUI,
}: IValidationParams) => {
	if (Math.abs(imageRatio - requiredImageRatio) > 0.01) {
		return `Image ratio should be ${requiredImageRatioUI}.`;
	}

	return '';
};
