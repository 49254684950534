import { useNavigate } from 'react-router-dom';
import { FC } from 'react';

import { useOrganizationKeyExist } from 'pages/Projects/hooks/useOrganizationKeyExist';
import { ROUTES } from 'constants/ROUTES';

export const CreateProject: FC = () => {
	const navigate = useNavigate();

	useOrganizationKeyExist();

	const handleCreateBlankProject = () => {
		navigate(ROUTES.PROJECTS.CREATE_BLANK_PROJECT);
	};

	const handleCreateProjectFromPreset = () => {
		navigate(ROUTES.PROJECTS.CREATE_PROJECT_FROM_PRESET);
	};

	return (
		<div className="org">
			<h1 className="project-header">Create a New Project</h1>
			<div className="project-create">
				<span className="project-create-header">
					How would you like to start?
				</span>
				<div className="project-create-form">
					<div
						className="project-create-btn"
						onClick={handleCreateBlankProject}
					>
						<div className="project-create-blank" />
						<span className="project-create-title">Blank Project</span>
						<span className="project-create-subtitle">Start from Scratch</span>
					</div>
					<div
						className="project-create-btn"
						onClick={handleCreateProjectFromPreset}
					>
						<div className="project-create-journey" />
						<span className="project-create-title">Use Preset</span>
						<span className="project-create-subtitle">Choose from Library</span>
					</div>
				</div>
			</div>
		</div>
	);
};
