import { IStudioMarketing } from 'api/models/responses/studio/studioMarketing';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { studioMarketingUrls } from 'constants/apiUrls/studioMarketingUrls';

import { BaseService } from '../BaseService';

class StudioMarketingService extends BaseService {
	async getStudioMarketing(): Promise<IStudioMarketing | null> {
		return this.get(studioMarketingUrls.getStudioMarketing);
	}

	async updateStudioMarketing(
		body: IPatchBody[]
	): Promise<IStudioMarketing | null> {
		return this.patch(studioMarketingUrls.getStudioMarketing, body);
	}

	async createPhoneNumber(): Promise<IStudioMarketing | null> {
		return this.put(studioMarketingUrls.createPhoneNumber, {});
	}
}

export default new StudioMarketingService();
