import { IAdditionalChargeOption } from 'api/models/responses/priceLists/studio/priceListOptions/additionalChargeOption';
import { ICreateAdditionalChargeOption } from 'api/models/requests/priceLists/studio/priceListOptions/createAdditionalChargeOption';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { BaseService } from 'api/services/BaseService';

import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';

class AdditionalChargeOptionsService extends BaseService {
	async createAdditionalChargeOption(
		priceListKey: number,
		body: ICreateAdditionalChargeOption
	): Promise<IAdditionalChargeOption | null> {
		return this.post(
			priceListsUrls.studioFulfilled.additionalChargeOptions.createAdditionalChargeOption(
				priceListKey
			),
			body
		);
	}

	async updateAdditionalChargeOption(
		priceListKey: number,
		additionalChargeOptionKey: number,
		body: IPatchBody[]
	): Promise<IAdditionalChargeOption | null> {
		return this.patch(
			priceListsUrls.studioFulfilled.additionalChargeOptions.additionalChargeOptionUrl(
				priceListKey,
				additionalChargeOptionKey
			),
			body
		);
	}

	async deleteAdditionalChargeOption(
		priceListKey: number,
		additionalChargeOptionKey: number
	): Promise<string | null> {
		return this.delete(
			priceListsUrls.studioFulfilled.additionalChargeOptions.additionalChargeOptionUrl(
				priceListKey,
				additionalChargeOptionKey
			)
		);
	}

	async reorderAdditionalChargeOptions(
		priceListKey: number,
		body: Record<number, number>
	): Promise<string | null> {
		return this.put(
			priceListsUrls.studioFulfilled.additionalChargeOptions.reorderAdditionalChargeOptions(
				priceListKey
			),
			body
		);
	}
}

export default new AdditionalChargeOptionsService();
