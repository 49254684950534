import { createSelector } from 'reselect';

import { RootState } from 'store/store';

const organizationsSelector = (state: RootState) => state.organizations;

export const activeOrganizationSelector = createSelector(
	organizationsSelector,
	({ activeOrganizations }) => activeOrganizations
);

export const archivedOrganizationsSelector = createSelector(
	organizationsSelector,
	({ archivedOrganizations }) => archivedOrganizations
);

export const createdOrganizationSelector = createSelector(
	organizationsSelector,
	({ createdOrganization }) => createdOrganization
);

export const isOrgPendingSelector = createSelector(
	organizationsSelector,
	({ isOrgPending }) => isOrgPending
);
