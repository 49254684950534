import { useFormikContext } from 'formik';
import { FC } from 'react';

import { InputGrid } from 'components/FormControls/InputGrid';
import { TextArea } from 'components/FormControls/TextArea';
import { ToggleSection } from 'components/ToggleSection';

import { useToggleSection } from 'hooks/useToggleSection';

import { IEditSaleValues } from '../..';

interface IOrderConfirmationProps {
	disabled: boolean;
}

export const OrderConfirmation: FC<IOrderConfirmationProps> = ({
	disabled,
}) => {
	const { values, errors, touched, handleChange } =
		useFormikContext<IEditSaleValues>();

	const { isOpen, handleToggle } = useToggleSection(true);

	return (
		<ToggleSection
			isOpen={isOpen}
			title="Order Confirmation"
			className="presale-section"
			handleToggle={handleToggle}
		>
			<div className="project-textarea-section order">
				<div className="project-message">
					<InputGrid
						required={false}
						className="autorow"
						disabled={disabled}
						id="orderNotificationEmail"
						handleChange={handleChange}
						label="Order Notification Email"
						value={values.orderNotificationEmail}
						error={errors.orderNotificationEmail}
						placeholder="Order Notification Email"
						touched={touched.orderNotificationEmail}
					/>
				</div>
				<TextArea
					disabled={disabled}
					handleChange={handleChange}
					id="orderConfirmationMessage"
					label="Order Confirmation Message"
					value={values.orderConfirmationMessage}
					error={errors.orderConfirmationMessage}
					touched={touched.orderConfirmationMessage}
					placeholder="Print a copy of your receipt and bring it with you on picture day."
				/>
			</div>
		</ToggleSection>
	);
};
