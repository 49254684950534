import { FC } from 'react';

import { Info } from 'components/CardContent/Info';
import { Card } from 'components/Card';

export const CreateNew: FC = () => (
	<Card title="Create a New" subtitle="">
		<div className="card-content-info">
			<ul className="card-info-list">
				<Info
					title="Organization"
					paragraph="Create a place to host several projects that share a common asso-
					ciation (like school, district, or league)."
					iconType="picture-add"
				/>
				<Info
					title="Price List"
					paragraph="Launch a new collection of packages and products to offer to your customers."
					iconType="picture-move"
				/>
				<Info
					title="Promotion"
					paragraph="Generate a coupon or offer to help your customers and generate sales."
					iconType="picture-static"
				/>
			</ul>
		</div>
	</Card>
);
