import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IBroadcastsState } from './types';

const initialState: IBroadcastsState = {
	campaignKey: null,
	isCreateBroadcast: false,
	copiedBroadcastKey: null,
};

const broadcastsSlice = createSlice({
	name: 'broadcasts',
	initialState,
	reducers: {
		setCampaignKey: (state, { payload }: PayloadAction<number | null>) => {
			state.campaignKey = payload;
		},

		setIsCreateBroadcast: (state, { payload }: PayloadAction<boolean>) => {
			state.isCreateBroadcast = payload;
		},

		setCopiedBroadcastKey: (
			state,
			{ payload }: PayloadAction<number | null>
		) => {
			state.copiedBroadcastKey = payload;
		},
	},
});

export const broadcastsReducer = broadcastsSlice.reducer;
export const { setCampaignKey, setIsCreateBroadcast, setCopiedBroadcastKey } =
	broadcastsSlice.actions;
