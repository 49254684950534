import { FC, useRef } from 'react';

import { IBackgroundOptionsVisibilityProps } from 'pages/PriceLists/types/studio/priceListOptions/backgroundOptionsVisibilityProps';
import { PoseOptionsSubTitle } from 'pages/PriceLists/components/BackgroundOptions/components/PoseOptionsSubTitle';
import { BackgroundOptions } from 'pages/PriceLists/components/BackgroundOptions';
import { IHeaderConfig } from 'pages/PriceLists/components/PriceTable';

import { isPendingBaseOptionsSelector } from 'store/priceLists/priceListBaseOptions';
import {
	addPoseOptionType,
	showPoseOptionsSelector,
	showStyleOptionsSelector,
	groupedPoseOptionsSelector,
	deletePoseOptionSectionAsync,
	showColorFavorOptionsSelector,
	filteredPoseOptionTypesSelectOptionsSelector,
} from 'store/priceLists/priceListPoseOptions';

import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Modal, ModalRef } from 'components/Modal';
import { Loader } from 'components/Loader';

import { useToggleSection } from 'hooks/useToggleSection';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { PoseOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/poseOption';
import { PriceListFulfillment } from 'constants/priceLists/priceListFulfillment';

import { AddOptionTypeModalBody } from '../AddOptionTypeModalBody';
import { PoseOptions } from './PoseOptions';

const poseOptionsHeaders: IHeaderConfig[] = [
	{ name: 'Preview' },
	{ name: 'Name', required: true, left: true },
	{ name: 'Reference Code', required: true, left: true },
	{
		left: true,
		required: true,
		name: 'Retail Value',
		subtitle: 'charged per pose',
	},
];

interface IPoseOptionsContainerProps extends IBackgroundOptionsVisibilityProps {
	priceListKey?: number;
	isPriceListPending: boolean;
}

export const PoseOptionsContainer: FC<IPoseOptionsContainerProps> = ({
	priceListKey,
	isPriceListPending,
	updatePriceListStudio,
	backgroundOptionVisibilityType,
}) => {
	const showColorFavorOptions = useAppSelector(showColorFavorOptionsSelector);
	const isPendingBaseOptions = useAppSelector(isPendingBaseOptionsSelector);
	const groupedPoseOptions = useAppSelector(groupedPoseOptionsSelector);
	const showStyleOptions = useAppSelector(showStyleOptionsSelector);
	const showPoseOptions = useAppSelector(showPoseOptionsSelector);
	const filteredPoseOptionTypesSelectOptions = useAppSelector(
		filteredPoseOptionTypesSelectOptionsSelector
	);

	const addPoseOptionModalRef = useRef<ModalRef>(null);

	const { isOpen, handleToggle } = useToggleSection(true);

	const dispatch = useAppDispatch();

	const showAddPoseOptionModal = () => {
		addPoseOptionModalRef?.current?.open();
	};

	const hideAddPoseOptionModal = () => {
		addPoseOptionModalRef?.current?.close();
	};

	const addPoseOptionTypeHandler = (
		selectedPoseOptionType: PoseOptionTypes
	) => {
		dispatch(addPoseOptionType(selectedPoseOptionType));
		hideAddPoseOptionModal();
	};

	const deletePoseOptionsSection = async (poseOptionType: PoseOptionTypes) => {
		if (!priceListKey) return;

		await dispatch(deletePoseOptionSectionAsync(priceListKey, poseOptionType));
	};

	const disabledAddPoseOptionBtn =
		showStyleOptions && showPoseOptions && showColorFavorOptions;

	const showLoader = isPendingBaseOptions || isPriceListPending;

	return (
		<>
			<ToggleSection
				isOpen={isOpen}
				title="Pose Options"
				handleToggle={handleToggle}
				className="price-search-container"
				header={
					<PoseOptionsSubTitle title="(prompted when pose is selected or, if presale, when product is selected)" />
				}
			>
				{showLoader ? (
					<Loader />
				) : (
					<div className="price-container">
						<BackgroundOptions
							priceListKey={priceListKey}
							fulfillment={PriceListFulfillment.Studio}
							updatePriceListStudio={updatePriceListStudio}
							backgroundOptionVisibilityType={backgroundOptionVisibilityType}
						/>
						{showPoseOptions && (
							<PoseOptions
								priceListKey={priceListKey}
								headers={poseOptionsHeaders}
								poseOptionType={PoseOptionTypes.Pose}
								poseOptionsUI={groupedPoseOptions.Pose}
								deletePoseOptionsSection={deletePoseOptionsSection}
							/>
						)}
						{showStyleOptions && (
							<PoseOptions
								priceListKey={priceListKey}
								headers={poseOptionsHeaders}
								poseOptionType={PoseOptionTypes.Style}
								poseOptionsUI={groupedPoseOptions.Style}
								deletePoseOptionsSection={deletePoseOptionsSection}
							/>
						)}
						{showColorFavorOptions && (
							<PoseOptions
								priceListKey={priceListKey}
								headers={poseOptionsHeaders}
								poseOptionType={PoseOptionTypes.ColorFlavor}
								poseOptionsUI={groupedPoseOptions.ColorFlavor}
								deletePoseOptionsSection={deletePoseOptionsSection}
							/>
						)}
						<div className="price-prompt">
							<span className="price-prompt-label">
								Yearbook Pose Options <i>(coming soon)</i>
							</span>
							<span className="price-prompt-info">
								Prompt parents to confirm the selected yearbook pose
							</span>
						</div>
						<Button
							disabled={disabledAddPoseOptionBtn}
							handleClick={showAddPoseOptionModal}
							className="price-add-type btn-secondary"
							value="+ Add Another Type of Pose Option"
						/>
					</div>
				)}
			</ToggleSection>
			<Modal ref={addPoseOptionModalRef} title="Add Type of Pose Option">
				<AddOptionTypeModalBody<PoseOptionTypes>
					selectLabel="Pose Option"
					btnValue="Add This Pose Option"
					addOptionType={addPoseOptionTypeHandler}
					selectPlaceholder="Select Pose Option Type"
					selectOptions={filteredPoseOptionTypesSelectOptions}
				/>
			</Modal>
		</>
	);
};
