import { ChangeEvent, FC } from 'react';

interface CheckboxProps {
	id?: string;
	name?: string;
	label?: string;
	checked: boolean;
	suplabel?: string;
	disabled?: boolean;
	handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox: FC<CheckboxProps> = ({
	id,
	name,
	label,
	suplabel,
	checked,
	disabled,
	handleChange,
}) => {
	return (
		<div className="switch">
			<input
				id={id}
				name={name}
				type="checkbox"
				checked={checked}
				disabled={disabled}
				onChange={handleChange}
				className="switch-input"
			/>
			<label htmlFor={id} className="switch-label" />
			{label && (
				<span className="switch-text">
					{label}
					{suplabel && <span className="switch-suplabel">{suplabel}</span>}
				</span>
			)}
		</div>
	);
};

Checkbox.defaultProps = {
	disabled: false,
};
