import { ISelectOption } from 'types/ui/select';

import { poseAvailableForUI } from './poseAvailableForUI';
import { PoseAvailableFor } from './poseAvailableFor';

export const poseAvailableForSelectOptions: ISelectOption<PoseAvailableFor>[] =
	Object.values(PoseAvailableFor).map((poseAvailableFor) => ({
		value: poseAvailableFor,
		label: poseAvailableForUI[poseAvailableFor],
	}));
