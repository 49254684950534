import { FC } from 'react';

interface IProjectJourneyViewProps {
	projectTimelineImage: string;
}

export const JourneyView: FC<IProjectJourneyViewProps> = ({
	projectTimelineImage,
}) => (
	<div className="project-journey-view">
		<span className="project-view-title">Project Timeline</span>
		<img
			alt="journey"
			src={projectTimelineImage}
			className="project-journey-img"
		/>
	</div>
);
