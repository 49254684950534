import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { getInitialPaginationResponse } from 'constants/general/pagination/getInitialPaginationResponse';
import { SKIP, TAKE } from 'constants/general/generalGetDataParams';
import { ROUTES } from 'constants/ROUTES';

import { IOrganizationProject } from 'api/models/responses/projects/organizationProjects';
import { IPaginationResponse } from 'api/models/responses/general/paginationResponse';
import ProjectsService from 'api/services/ProjectsService';
import {
	GeneralProjectSortLabels,
	UnitedOrgDetailsProjectSortLabels,
} from 'api/models/requests/projects/projectsParams';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToggleSection } from 'hooks/useToggleSection';
import { useSortParams } from 'hooks/useSortParams';

import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { NoItemsFound } from 'components/NoItemsFound';
import { TableLoader } from 'components/TableLoader';

import { setOrganizationKey } from 'store/projects';
import { GeneralTabs } from 'types/generalTabs';

import { ProjectList } from './ProjectList';

interface IOrganizationProjectsProps {
	organizationKey: number;
}

interface IProjectTab {
	title: string;
	projectType: GeneralTabs;
}

const projectsTabs: IProjectTab[] = [
	{ title: GeneralTabs.Active, projectType: GeneralTabs.Active },
	{
		title: `${GeneralTabs.Archived} > 60 Days`,
		projectType: GeneralTabs.Archived,
	},
];

const initialProjects = getInitialPaginationResponse<IOrganizationProject>();

export const OrganizationProjects: FC<IOrganizationProjectsProps> = ({
	organizationKey,
}) => {
	const [projectsType, setProjectType] = useState<GeneralTabs>(
		GeneralTabs.Active
	);

	const [activeProjects, setActiveProjects] =
		useState<IPaginationResponse<IOrganizationProject>>(initialProjects);
	const [expiredProjects, setExpiredProjects] =
		useState<IPaginationResponse<IOrganizationProject>>(initialProjects);
	const [isPending, setIsPending] = useState(false);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { orderBy, orderDirection, setSortParams } =
		useSortParams<UnitedOrgDetailsProjectSortLabels>(
			GeneralProjectSortLabels.ProjectName
		);
	const { isOpen, handleToggle } = useToggleSection(true);

	const tableData =
		projectsType === GeneralTabs.Active ? activeProjects : expiredProjects;

	const handleSelectTab = (projectsTypeParam: GeneralTabs) => {
		setProjectType(projectsTypeParam);
	};

	const handleCreateProject = () => {
		if (!organizationKey) return;

		dispatch(setOrganizationKey(organizationKey));
		navigate(ROUTES.PROJECTS.CREATE_PROJECT);
	};

	const setPaginatedProjects = () => {
		const isPaginationLimit =
			tableData.totalCount === tableData.results.length &&
			tableData.totalCount >= 0;

		if (isPaginationLimit || isPending || !organizationKey) return;

		const projectParams = {
			orderBy,
			take: TAKE,
			orderDirection,
			skip: tableData.results.length,
		};

		setIsPending(true);

		if (projectsType === GeneralTabs.Active) {
			ProjectsService.getActiveOrganizationProjects(
				organizationKey,
				projectParams
			)
				.then((data) => {
					if (!data) return;

					setActiveProjects({
						totalCount: data.totalCount,
						results: [...tableData.results, ...data.results],
					});
					setIsPending(false);
				})
				.catch((error) => {
					console.log(error);
					setIsPending(false);
				});
		}

		if (projectsType === GeneralTabs.Archived) {
			ProjectsService.getExpiredOrganizationProjects(
				organizationKey,
				projectParams
			)
				.then((data) => {
					if (!data) return;

					setExpiredProjects({
						totalCount: data.totalCount,
						results: [...tableData.results, ...data.results],
					});

					setIsPending(false);
				})
				.catch((error) => {
					console.log(error);
					setIsPending(false);
				});
		}
	};

	const ProjectTabsList = projectsTabs.map((projectTab) => (
		<li
			key={projectTab.title}
			className={cn('price-header-item', {
				'is-active': projectsType === projectTab.projectType,
			})}
			onClick={() => handleSelectTab(projectTab.projectType)}
		>
			{projectTab.title}
		</li>
	));

	useEffect(() => {
		if (!organizationKey || isPending) return;

		const projectParams = {
			orderBy,
			skip: SKIP,
			take: TAKE,
			orderDirection,
		};

		setIsPending(true);

		if (projectsType === GeneralTabs.Active) {
			ProjectsService.getActiveOrganizationProjects(
				organizationKey,
				projectParams
			)
				.then((data) => {
					if (!data) return;

					setActiveProjects(data);
					setIsPending(false);
				})
				.catch((error) => {
					console.log(error);
					setIsPending(false);
				});
		}

		if (projectsType === GeneralTabs.Archived) {
			ProjectsService.getExpiredOrganizationProjects(
				organizationKey,
				projectParams
			)
				.then((data) => {
					if (!data) return;

					setExpiredProjects(data);
					setIsPending(false);
				})
				.catch((error) => {
					console.log(error);
					setIsPending(false);
				});
		}
	}, [orderBy, projectsType, orderDirection, organizationKey]);

	return (
		<ToggleSection
			isOpen={isOpen}
			title="Projects"
			className="price-org-title"
			handleToggle={handleToggle}
			header={
				<Button
					value="+ New Project"
					handleClick={handleCreateProject}
					className="btn-primary org-details-btn"
				/>
			}
		>
			<section className="org-section-price">
				<div className="org-header-list-container">
					<ul className="org-header-list">{ProjectTabsList}</ul>
				</div>
				{!!tableData.results.length && (
					<ProjectList
						orderBy={orderBy}
						projects={tableData}
						className="price-table"
						setSortParams={setSortParams}
						orderDirection={orderDirection}
						setPaginatedProjects={setPaginatedProjects}
					/>
				)}
				{!tableData.results.length && !isPending && (
					<NoItemsFound title="projects" />
				)}
				{isPending && <TableLoader />}
			</section>
		</ToggleSection>
	);
};
