import { FC } from 'react';

import { orderShippingTypesSelectOptions } from 'constants/priceLists/studio/selectOptions/orderShippingTypesSelectOptions';
import { OrderShippingTypes } from 'api/models/responses/priceLists/studio/priceListOptions/shippingOption';
import { useAsyncOptimizedSelect } from 'hooks/formControls/useAsyncOptimizedSelect';
import { SelectComponent } from 'components/FormControls/Select';

import { IGeneralColumnProps } from '../../';

interface INameColumnProps extends IGeneralColumnProps {
	orderShippingTypeError: string;
	orderShippingType: OrderShippingTypes;
	disableShippingTypeSelection: boolean;
}

export const NameColumn: FC<INameColumnProps> = ({
	sequence,
	orderShippingType,
	shippingOptionKey,
	updateShippingOption,
	orderShippingTypeError,
	disableShippingTypeSelection,
}) => {
	const handleUpdateShippingType = async (value: OrderShippingTypes) => {
		await updateShippingOption({
			value,
			sequence,
			shippingOptionKey,
			validationMessage: '',
			fieldKey: 'orderShippingType',
		});
	};

	const { value, isPending, handleChange } = useAsyncOptimizedSelect({
		initialValue: orderShippingType,
		updateSelectValue: handleUpdateShippingType,
	});

	const disabled = disableShippingTypeSelection || isPending;

	return (
		<>
			<td className="price-option-td"></td>
			<td className="price-option-td">
				<SelectComponent
					touched
					value={value}
					disabled={disabled}
					onChange={handleChange}
					error={orderShippingTypeError}
					selectOptions={orderShippingTypesSelectOptions}
				/>
			</td>
		</>
	);
};
