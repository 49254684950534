import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import { FC } from 'react';

import { priceListRouteByFulfillment } from 'constants/priceLists/priceListRouteByFulfillment';
import { PriceListsSortLabels } from 'constants/priceLists/priceListsSortLabels';

import { ColumnLabelContainer } from 'components/Table/ColumnLabelContainer';
import { Table } from 'components/Table';

import { IPriceList } from 'api/models/responses/priceLists/priceList';
import { normalizeDate } from 'utils/ui/normalizeDate';
import { usePagination } from 'hooks/usePagination';
import { ISearchResult } from 'types/searchParams';
import { useDefineDevice } from 'hooks/useDefineDevice';

type SearchPriceListsProps = Omit<
	ISearchResult<PriceListsSortLabels, IPriceList[]>,
	'isPending' | 'handleSearch'
>;

export const PriceListsList: FC<SearchPriceListsProps> = ({
	orderBy,
	results,
	setSortParams,
	orderDirection,
	setPaginatedEntity,
}) => {
	const ref = usePagination(setPaginatedEntity);

	const { isMobile } = useDefineDevice();

	const columns: Column<IPriceList>[] = [
		{
			id: 'name',
			Header: (
				<ColumnLabelContainer
					label="Name"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={PriceListsSortLabels.Name}
				/>
			),
			Cell: ({ row }: CellProps<IPriceList>) => {
				const { key, name, fulfillment } = row.original;

				const path = `${priceListRouteByFulfillment[fulfillment]}/${key}`;

				return (
					<Link to={path} className="price-table-name">
						{name}
					</Link>
				);
			},
		},
		{
			Header: (
				<ColumnLabelContainer
					orderBy={orderBy}
					label="Fulfillment"
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={PriceListsSortLabels.Fulfillment}
				/>
			),
			accessor: 'fulfillment',
		},
		{
			Header: (
				<ColumnLabelContainer
					orderBy={orderBy}
					label="# of Products"
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={PriceListsSortLabels.ProductsCount}
				/>
			),
			accessor: 'productsCount',
		},
		{
			Header: (
				<ColumnLabelContainer
					label="Projects"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={PriceListsSortLabels.ActiveProjectsCount}
				/>
			),
			accessor: 'activeProjectsCount',
			Cell: ({
				value,
			}: CellProps<IPriceList, IPriceList['activeProjectsCount']>) => {
				return value ? (
					<Link to="/" className="price-table-link">
						{value}
					</Link>
				) : (
					<>{value}</>
				);
			},
		},
		{
			Header: (
				<ColumnLabelContainer
					orderBy={orderBy}
					label="Last Modified"
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={PriceListsSortLabels.LastModifyDate}
				/>
			),
			accessor: 'lastModifyDate',
			Cell: ({
				value,
			}: CellProps<IPriceList, IPriceList['lastModifyDate']>) => (
				<>{normalizeDate(value)}</>
			),
		},
	];

	const mobileColumns = columns.slice(0, 2);

	const processedColumns = isMobile ? mobileColumns : columns;

	return (
		<div className="price-table">
			{/* TODO: add correct height for price lists pagination */}
			<div className="org-project-search-container">
				<Table<IPriceList> columns={processedColumns} data={results} />
				<div ref={ref} style={{ height: '1px' }} />
			</div>
		</div>
	);
};
