import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import { FC, useEffect } from 'react';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { ILatestProject } from 'api/models/responses/projects/latestProjects';
import ProjectsService from 'api/services/ProjectsService';

import { Table } from 'components/Table';
import { Card } from 'components/Card';

import { latestProjectsSelector, setLatestProjects } from 'store/dashboard';
import { normalizeDate } from 'utils/ui/normalizeDate';
import { ROUTES } from 'constants/ROUTES';

export const LatestProjects: FC = () => {
	const latestProjects = useAppSelector(latestProjectsSelector);

	const dispatch = useAppDispatch();

	const columns: Column<ILatestProject>[] = [
		{
			Header: 'Project Name',
			Cell: ({ row }: CellProps<ILatestProject>) => {
				const { projectKey, projectName } = row.original;

				return (
					<Link
						title={projectName}
						className="card-table-link"
						to={`${ROUTES.PROJECTS.PROJECTS}/${projectKey}`}
					>
						{projectName}
					</Link>
				);
			},
		},
		{
			Header: 'Logins',
			accessor: 'loginsCount',
		},
		{
			Header: 'Orders',
			accessor: 'ordersCount',
		},
		{
			Header: 'Picture Day',
			accessor: 'pictureDate',
			Cell: ({
				value,
			}: CellProps<ILatestProject, ILatestProject['pictureDate']>) => (
				<>{normalizeDate(value)}</>
			),
		},
	];

	useEffect(() => {
		void ProjectsService.getLatestProjects().then((data) => {
			if (!data) return;

			dispatch(setLatestProjects(data));
		});
	}, []);

	return (
		<Card
			bodyClassName="card-table"
			title="Latest Projects"
			subtitle="(created in the next 7 Days) "
		>
			{/* TODO: add base className for table */}
			<Table<ILatestProject>
				className=""
				columns={columns}
				data={latestProjects}
			/>
		</Card>
	);
};
