import { ImageSasUrlResponse } from 'api/models/responses/photos/imageSasUrl';
import { CloudPhotoType } from 'api/models/responses/photos/cloudPhotoType';

export const prepareSasUrl = (
	photoType: CloudPhotoType,
	sasUrl?: ImageSasUrlResponse
) => {
	const invalidType = typeof sasUrl === 'string';

	if (invalidType || !sasUrl) return '';

	return sasUrl.SaSUrls?.[photoType] || '';
};
