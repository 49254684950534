import { FC, SyntheticEvent } from 'react';

import { SelectComponent } from 'components/FormControls/Select';
import { LazyInput } from 'components/FormControls/LazyInput';

import { chargeOptionTypesSelectOptions } from 'constants/priceLists/studio/selectOptions/chargeOptionTypesSelectOptions';
import { ChargeOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/additionalChargeOption';
import { validateDaysAmount } from 'utils/validations/priceLists/studio/validateDaysAmount';
import { useAsyncOptimizedSelect } from 'hooks/formControls/useAsyncOptimizedSelect';

import { IGeneralColumnProps } from '../../';

interface IOptionColumnProps extends IGeneralColumnProps {
	daysAmount: number;
	daysAmountError: string;
	chargeOptionType: ChargeOptionTypes;
}

export const OptionColumn: FC<IOptionColumnProps> = ({
	sequence,
	daysAmount,
	daysAmountError,
	chargeOptionType,
	additionalChargeOptionKey,
	updateAdditionalChargeOption,
	clearAdditionalChargeOptionError,
}) => {
	const updateChargeOptionType = async (value: ChargeOptionTypes) => {
		await updateAdditionalChargeOption({
			value,
			sequence,
			validationMessage: '',
			additionalChargeOptionKey,
			fieldKey: 'chargeOptionType',
		});
	};

	const {
		isPending,
		handleChange,
		value: chargeOptionTypeValue,
	} = useAsyncOptimizedSelect({
		initialValue: chargeOptionType,
		updateSelectValue: updateChargeOptionType,
	});

	const handleChangeDaysAmount = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		const valueNumber = +value;

		const isValueNan = isNaN(valueNumber);

		const validationMessage = validateDaysAmount(valueNumber);

		void updateAdditionalChargeOption({
			sequence,
			validationMessage,
			fieldKey: 'daysAmount',
			additionalChargeOptionKey,
			value: isValueNan ? value : valueNumber,
		});
	};

	const clearDaysAmountError = () => {
		if (!daysAmountError) return;

		clearAdditionalChargeOptionError({
			sequence,
			fieldKey: 'daysAmount',
		});
	};

	return (
		<td className="price-option-td">
			<div className="price-table-container charge">
				<label className="label">Charge</label>
				<LazyInput
					error={daysAmountError}
					placeholder="Days amount"
					className="input exp-wrapper"
					clearError={clearDaysAmountError}
					defaultValue={daysAmount.toString()}
					handleChange={handleChangeDaysAmount}
				/>
				<SelectComponent
					label="Days"
					disabled={isPending}
					onChange={handleChange}
					value={chargeOptionTypeValue}
					selectOptions={chargeOptionTypesSelectOptions}
				/>
			</div>
		</td>
	);
};
