import { useEffect, useState } from 'react';

import { getInitialPaginationResponse } from 'constants/general/pagination/getInitialPaginationResponse';
import { TAKE } from 'constants/general/generalGetDataParams';

import { IPaginationResponse } from 'api/models/responses/general/paginationResponse';
import { ISearchProjectParams } from 'api/models/requests/projects/searchProjectParams';
import { ISearchedProject } from 'api/models/responses/projects/organizationProjects';
import ProjectsService from 'api/services/ProjectsService';
import {
	GeneralProjectSortLabels,
	UnitedSearchedProjectSortLabels,
} from 'api/models/requests/projects/projectsParams';

import { useSortParams } from 'hooks/useSortParams';
import { ISearchResult } from 'types/searchParams';

const initialProjects = getInitialPaginationResponse<ISearchedProject>();

export const useSearchProjects = (
	showSearch: () => void
): ISearchResult<UnitedSearchedProjectSortLabels, ISearchedProject[]> => {
	const [projects, setProjects] =
		useState<IPaginationResponse<ISearchedProject>>(initialProjects);

	const [searchValue, setSearchValue] = useState('');
	const [isPending, setIsPending] = useState(false);

	const { orderBy, setSortParams, orderDirection } =
		useSortParams<UnitedSearchedProjectSortLabels>(
			GeneralProjectSortLabels.ProjectName
		);

	const handleSearch = (projectName: string) => {
		setSearchValue(projectName);
	};

	const setPaginatedProjects = () => {
		const isPaginationLimit =
			projects.totalCount === projects.results.length &&
			projects.totalCount > 0;

		if (!searchValue || isPaginationLimit || isPending) return;

		const params: ISearchProjectParams = {
			orderBy,
			take: TAKE,
			searchValue,
			orderDirection,
			skip: projects.results.length,
		};

		setIsPending(true);

		ProjectsService.searchProject(params)
			.then((data) => {
				if (!data) return;

				setProjects({
					results: [...projects.results, ...data.results],
					totalCount: data.totalCount,
				});

				setIsPending(false);
			})
			.catch((error) => {
				console.log(error);
				setIsPending(false);
			});
	};

	useEffect(() => {
		if (!searchValue) return;

		const params: ISearchProjectParams = {
			take: TAKE,
			orderBy,
			searchValue,
			orderDirection,
			skip: 0,
		};

		setIsPending(true);
		showSearch();

		ProjectsService.searchProject(params)
			.then((data) => {
				if (!data) return;

				if (!data.results.length) {
					setProjects(initialProjects);
				} else {
					setProjects(data);
				}

				setIsPending(false);
			})
			.catch((error) => {
				console.log(error);
				setIsPending(false);
			});
	}, [orderBy, searchValue, orderDirection]);

	return {
		orderBy,
		isPending,
		handleSearch,
		setSortParams,
		orderDirection,
		results: projects.results,
		setPaginatedEntity: setPaginatedProjects,
	};
};
