import { FC, SyntheticEvent } from 'react';

import { InputGrid } from 'components/FormControls/InputGrid';
import { ToggleSection } from 'components/ToggleSection';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToggleSection } from 'hooks/useToggleSection';

import {
	studioMarketingSelector,
	updateStudioMarketingAsync,
} from 'store/studio';

export const EmailSettings: FC = () => {
	const studioMarketing = useAppSelector(studioMarketingSelector);

	const dispatch = useAppDispatch();

	const handleChangeSenderName = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		void dispatch(updateStudioMarketingAsync('emailSenderName', value));
	};

	const handleChangeReplyToAddress = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		void dispatch(updateStudioMarketingAsync('replyToEmailAddress', value));
	};

	const { isOpen, handleToggle } = useToggleSection(true);

	const initialSenderName = studioMarketing?.emailSenderName || '';
	const initialReplyToAddress = studioMarketing?.replyToEmailAddress || '';

	return (
		<ToggleSection
			isOpen={isOpen}
			title="Email Settings"
			handleToggle={handleToggle}
			className="marketing-header"
		>
			<div className="marketing-wrapper">
				<p className="marketing-text">
					All marketing broadcasts will be sent from noreply@accessmyevent.com.
					The name displayed in your consumer’s inbox will be the sender name
					below. Any consumer reply, will be sent to the “reply to” address
					listed below. This should be an inbox you monitor regularly.
				</p>
				<div className="marketing-form">
					<InputGrid
						isLazy
						label="Sender Name:"
						placeholder="Sender Name"
						defaultValue={initialSenderName}
						handleLazyChange={handleChangeSenderName}
					/>
					<InputGrid
						isLazy
						label="“Reply to” Address:"
						placeholder="Email Address"
						defaultValue={initialReplyToAddress}
						handleLazyChange={handleChangeReplyToAddress}
					/>
				</div>
			</div>
		</ToggleSection>
	);
};
