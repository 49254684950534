import { SyntheticEvent, useId } from 'react';

export interface ICellActionMenuProps<T> {
	menuList: T[];
	className?: string;
	showActionMenu: boolean;
	setActiveActionMenu: () => void;
	handleMenuItemClick: (value: T) => void;
}

export const CellActionMenu = <T extends string>({
	menuList,
	className,
	showActionMenu,
	setActiveActionMenu,
	handleMenuItemClick,
}: ICellActionMenuProps<T>) => {
	const id = useId();

	const handleShowActionMenu = (e: SyntheticEvent<HTMLButtonElement>) => {
		e.stopPropagation();

		setActiveActionMenu();
	};

	const ActionMenuList = menuList.map((value) => (
		<li
			key={`${id}-${value}`}
			className="table-dropdown-item"
			onClick={() => handleMenuItemClick(value)}
		>
			{value}
		</li>
	));

	return (
		<div className={`table-dropdown ${className || ''}`}>
			<button className="table-btn-menu" onClick={handleShowActionMenu}>
				...
			</button>
			{showActionMenu && (
				<ul className="table-dropdown-list">{ActionMenuList}</ul>
			)}
		</div>
	);
};
