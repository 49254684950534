import { useFormikContext } from 'formik';
import { ChangeEvent, FC } from 'react';

import { ICreateProjectFromPresetValues } from 'pages/Projects/pages/CreateProjectFromPreset';
import { useProjectTypeSubjects } from 'pages/Projects/hooks/useProjectTypeSubjects';
import { IStepControl, Steps } from 'pages/Projects/types';

import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { commaRegEx } from 'constants/projects/validation/estimatedSubjectNumber';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { studioNetworkDomainSelector } from 'store/studio';
import { normalizeDigit } from 'utils/ui/normalizeDigit';

import { PresetDates } from '../PresetDates';

interface IInputField {
	id: string;
	label: string;
	supLabel?: string;
	placeholder: string;
}

const getInputFields = (domain: string, subjects: string): IInputField[] => [
	{
		id: 'projectName',
		label: 'Project Name',
		placeholder: 'Project Name',
		supLabel: 'visible to consumers',
	},
	{
		id: 'projectUrl',
		label: 'Project URL',
		placeholder: 'Project URL',
		supLabel: `${domain}.accessmyevent.com/`,
	},
	{
		id: 'estimatedNumberOfSubjects',
		label: `Estimated # of ${subjects}`,
		placeholder: `Estimated # of ${subjects.toLocaleLowerCase()}`,
	},
];

export const Form: FC<IStepControl> = ({ setStep }) => {
	const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
		useFormikContext<ICreateProjectFromPresetValues>();

	const studioNetworkDomain = useAppSelector(studioNetworkDomainSelector);

	const { subjects } = useProjectTypeSubjects(
		values?.journeyPreset?.projectType || ''
	);

	const handleBack = () => {
		setStep(Steps.STEP1);
	};

	const handleChangeEstimatedNumber = (e: ChangeEvent<HTMLInputElement>) => {
		const { id, value } = e.target;

		const purifiedValue = value.replace(commaRegEx, '');

		if (isNaN(+purifiedValue)) return;

		void setFieldValue(id, purifiedValue);
	};

	const InputFields = getInputFields(studioNetworkDomain, subjects).map(
		({ id, label, supLabel, placeholder }) => {
			const estimatedNumberField = id === 'estimatedNumberOfSubjects';

			const handleChangeParam = estimatedNumberField
				? handleChangeEstimatedNumber
				: handleChange;

			const valueParam = estimatedNumberField
				? values[id] &&
				  normalizeDigit({ value: +values[id].replace(commaRegEx, '') })
				: values[id];

			return (
				<InputGrid
					id={id}
					key={id}
					label={label}
					error={errors[id]}
					supLabel={supLabel}
					touched={touched[id]}
					placeholder={placeholder}
					value={valueParam as string}
					handleChange={handleChangeParam}
				/>
			);
		}
	);

	return (
		<form className="preset-project-form">
			<div className="preset-project-wrapper">{InputFields}</div>
			<span className="preset-title">Project Journey Dates</span>
			<PresetDates />
			<Button
				value="Create"
				className="btn-primary"
				handleClick={handleSubmit}
			/>
			<Button value="Back" className="btn-secondary" handleClick={handleBack} />
		</form>
	);
};
