import { ISelectOption } from 'types/ui/select';

export const GENERAL_SELECT_OPTION = 'All';

export const generalFilterSelectOption: ISelectOption<
	typeof GENERAL_SELECT_OPTION
> = {
	label: GENERAL_SELECT_OPTION,
	value: GENERAL_SELECT_OPTION,
};
