import { createSelector } from 'reselect';

import { RootState } from 'store/store';

export const broadcastsStateSelector = (state: RootState) => state.broadcasts;

export const campaignKeySelector = createSelector(
	broadcastsStateSelector,
	({ campaignKey }) => campaignKey
);

export const isCreateBroadcastSelector = createSelector(
	broadcastsStateSelector,
	({ isCreateBroadcast }) => isCreateBroadcast
);

export const copiedBroadcastKeySelector = createSelector(
	broadcastsStateSelector,
	({ copiedBroadcastKey }) => copiedBroadcastKey
);
