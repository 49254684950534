import { FC } from 'react';

import { IJourneyDate } from 'constants/projects/timeline/journeyTimelines';
import { ReactDatePicker } from 'components/ReactDatePicker';

interface IDateItemProps extends IJourneyDate {
	error: string;
	touched: boolean;
	value: Date | null;
	disabled?: boolean;
	wrapperClassName: string;
	handleChange: (date: Date) => void;
}

export const DateItem: FC<IDateItemProps> = ({
	id,
	icon,
	label,
	error,
	value,
	touched,
	disabled,
	handleChange,
	wrapperClassName,
}) => (
	<div className={wrapperClassName}>
		<i className={`project-section-icon ${icon}`} />
		<span className="label-grid">
			{label}:<i className="grid-required">*</i>
		</span>
		<ReactDatePicker
			id={id}
			selected={value}
			disabled={disabled}
			onChange={handleChange}
		/>
		{error && touched && <span className="grid-error">{error}</span>}
	</div>
);
