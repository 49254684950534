import { IGeneralPriceListOption } from '../../generalPriceListOption';

export enum PoseOptionTypes {
	None = 'None',
	Pose = 'Pose',
	Style = 'Style',
	ColorFlavor = 'ColorFlavor',
}

export interface IPoseOption extends IGeneralPriceListOption {
	retailPrice: number;
	referenceCode: string;
	previewImageUrl: string | null;
	poseOptionType: PoseOptionTypes;
	priceListStudioFulfilledKey: number;
	priceListStudioFulfilledPoseOptionKey: number;
}
