import { BackgroundOptionVisibilityTypes } from 'api/models/responses/priceLists/studio/priceListStudio';

export const backgroundOptionVisibilityTypesUI: Record<
	BackgroundOptionVisibilityTypes,
	string
> = {
	[BackgroundOptionVisibilityTypes.OnlyIfImageIsExtracted]:
		'Only if image is extracted',
	[BackgroundOptionVisibilityTypes.EvenIfImageIsNotExtracted]:
		'Even if image is not extracted',
};
