import { IPaginationResponse } from '../general/paginationResponse';
import { UnitTypes } from '../general/unitTypes';

export enum PromotionTypes {
	Coupon = 'Coupon',
	Offer = 'Offer',
}

export interface IPromotion {
	name: string;
	value: number;
	unitType: UnitTypes;
	type: PromotionTypes;
	numberOfUses: number;
	promotionKey: number;
	expirationDate: string;
}

export type PromotionsResponse = IPaginationResponse<IPromotion>;
