import { useNavigate } from 'react-router-dom';
import { FC } from 'react';

import { IBackgroundOptionsVisibilityProps } from 'pages/PriceLists/types/studio/priceListOptions/backgroundOptionsVisibilityProps';
import { PriceListControls } from 'pages/PriceLists/components/PriceListControls';

import { StudioPriceListTabs } from 'constants/priceLists/tabs/studioPriceListTabs';
import { ROUTES } from 'constants/ROUTES';

import { IPriceListStudio } from 'api/models/responses/priceLists/studio/priceListStudio';

import { OwnPackageOptionsContainer } from './components/OwnPackageOptionsContainer';

import { OrderOptionsContainer } from './components/OrderOptionsContainer';
import { PoseOptionsContainer } from './components/PoseOptionsContainer';

export interface IPriceListOptionsProps
	extends IBackgroundOptionsVisibilityProps {
	isPriceListPending: boolean;
	priceListStudio: IPriceListStudio | null;
	setActiveTab: (tab: StudioPriceListTabs) => void;
}

export const PriceListOptions: FC<IPriceListOptionsProps> = ({
	setActiveTab,
	priceListStudio,
	isPriceListPending,
	updatePriceListStudio,
}) => {
	const navigate = useNavigate();

	const priceListKey = priceListStudio?.priceListStudioFulfilledKey;
	const className = 'price-search-container price-sub-search';

	const handleBack = () => {
		navigate(ROUTES.PRICE_LISTS.PRICE_LISTS);
	};

	const handleNext = () => {
		setActiveTab(StudioPriceListTabs.Packages);
	};

	return (
		<>
			<PoseOptionsContainer
				priceListKey={priceListKey}
				isPriceListPending={isPriceListPending}
				updatePriceListStudio={updatePriceListStudio}
				backgroundOptionVisibilityType={
					priceListStudio?.backgroundOptionVisibilityType
				}
			/>
			<OwnPackageOptionsContainer
				className={className}
				priceListKey={priceListKey}
				isPriceListPending={isPriceListPending}
			/>
			<OrderOptionsContainer
				className={className}
				priceListStudio={priceListStudio}
				isPriceListPending={isPriceListPending}
				updatePriceListStudio={updatePriceListStudio}
			/>
			<PriceListControls handleBack={handleBack} handleNext={handleNext} />
		</>
	);
};
