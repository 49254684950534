import { SingleValue } from 'react-select';
import { FC } from 'react';

import { SelectComponent } from 'components/FormControls/Select';
import { ISelectOption } from 'types/ui/select';

interface SalePriceListProps {
	id: string;
	icon: string;
	value: string;
	label: string;
	error: string;
	touched: boolean;
	placeholder: string;
	selectOptions: ISelectOption<string>[];
	handleChange: (value: SingleValue<ISelectOption<string>>) => void;
}

export const SalePriceList: FC<SalePriceListProps> = ({
	id,
	icon,
	value,
	label,
	error,
	touched,
	placeholder,
	handleChange,
	selectOptions,
}) => (
	<div className="project-section-wrapper project-section-wrapper-sm">
		<i className={`project-journey-symbol ${icon}`} />
		<SelectComponent
			id={id}
			value={value}
			label={label}
			error={error}
			touched={touched}
			onChange={handleChange}
			selectOptions={selectOptions}
			selectPlaceholder={placeholder}
		/>
	</div>
);
