import { useCallback, useEffect, useState } from 'react';

import { getInitialPaginationResponse } from 'constants/general/pagination/getInitialPaginationResponse';
import { photosBlobStorageLowResUrl } from 'constants/images/blobUrls';
import { SKIP, TAKE } from 'constants/general/generalGetDataParams';
import { PhotoSortLabels } from 'constants/photos/photosSortLabels';

import { CloudPhotoType } from 'api/models/responses/photos/cloudPhotoType';
import PhotoService from 'api/services/PhotoService';
import {
	ISearchPhotosParams,
	ISearchPhotosRequest,
} from 'api/models/requests/photos/searchPhotos';

import { removeDomainFromUrl } from 'utils/general/removeDomainFromUrl';
import { prepareSasUrl } from 'utils/photos/prepareSasUrl';

import { SortParamsResult, useSortParams } from 'hooks/useSortParams';

import imagePlaceholder from 'images/imagePlaceholder.jpg';

import { IPhotoUI, PhotosUIPaginated } from '../components/Photos/types';

interface ISearchPhotosResult extends SortParamsResult<PhotoSortLabels> {
	isPending: boolean;
	isDeletedPhotos: boolean;
	photosUI: PhotosUIPaginated;
	isPaginationPending: boolean;
	getPaginatedPhotos: () => Promise<void>;
	setUpdatePhotos: (updatePhotos: boolean) => void;
	setPhotosUI: (photosUI: PhotosUIPaginated) => void;
	setIsDeletedPhotos: (isDeletedPhotos: boolean) => void;
}

interface IUseSearchPhotosParams {
	isCall: boolean;
	projectKey: number;
	searchPhotosParams: ISearchPhotosParams;
}

export const usePhotos = ({
	isCall,
	projectKey,
	searchPhotosParams,
}: IUseSearchPhotosParams): ISearchPhotosResult => {
	const initialPhotosUI = getInitialPaginationResponse<IPhotoUI>();

	const [photosUI, setPhotosUI] = useState<PhotosUIPaginated>(initialPhotosUI);

	const [isPaginationPending, setIsPaginationPending] = useState(false);
	const [isDeletedPhotos, setIsDeletedPhotos] = useState(false);
	const [updatePhotos, setUpdatePhotos] = useState(true);
	const [isPending, setIsPending] = useState(false);

	const { orderBy, setSortParams, orderDirection } =
		useSortParams<PhotoSortLabels>(PhotoSortLabels.FileName);

	const getPhotosUI = async (requestParams: ISearchPhotosRequest) => {
		try {
			const photos = await PhotoService.searchPhoto(requestParams);

			if (photos) {
				const smallThumbnailSasUrlsPromises = photos.results.map(
					({ cloudImageId }) =>
						PhotoService.getImageSasUrl(
							cloudImageId,
							CloudPhotoType.SmallThumbnail
						)
				);

				const smallThumbnailSasUrls = await Promise.all(
					smallThumbnailSasUrlsPromises
				);

				const photosUIMapped = photos.results.map((photo, index) => {
					const preparedSasUrl = prepareSasUrl(
						CloudPhotoType.SmallThumbnail,
						smallThumbnailSasUrls[index]
					);

					const smallThumbnailUrl = preparedSasUrl
						? `${photosBlobStorageLowResUrl}${removeDomainFromUrl(
								preparedSasUrl
						  )}`
						: imagePlaceholder;

					return {
						...photo,
						smallThumbnailUrl,
					};
				});

				return {
					results: photosUIMapped,
					totalCount: photos.totalCount,
				};
			}
		} catch (error) {
			console.log(error);

			return null;
		}
	};

	const getPaginatedPhotosUI = async () => {
		const isPaginationLimit =
			photosUI.totalCount === photosUI.results.length && photosUI.totalCount;

		const isLengthLessThanTake = photosUI.totalCount <= TAKE;

		if (
			!projectKey ||
			isPaginationLimit ||
			isPaginationPending ||
			isLengthLessThanTake
		)
			return;

		const requestParams: ISearchPhotosRequest = {
			orderBy,
			take: TAKE,
			projectKey,
			orderDirection,
			searchByAlbumIds: [],
			skip: photosUI.results.length,
			searchValue: searchPhotosParams.searchValue,
			searchParameter: searchPhotosParams.searchParameter,
		};

		setIsPaginationPending(true);

		const paginatedPhotos = await getPhotosUI(requestParams);

		setIsPaginationPending(false);

		if (!paginatedPhotos) return;

		setPhotosUI({
			totalCount: paginatedPhotos.totalCount,
			results: [...photosUI.results, ...paginatedPhotos.results],
		});
	};

	const getInitialPhotosUI = useCallback(async () => {
		if (!projectKey || !isCall || !updatePhotos) return;

		const requestParams: ISearchPhotosRequest = {
			orderBy,
			skip: SKIP,
			take: TAKE,
			projectKey,
			orderDirection,
			searchByAlbumIds: [],
			searchValue: searchPhotosParams.searchValue,
			searchParameter: searchPhotosParams.searchParameter,
		};

		setIsPending(true);

		const paginatedPhotos = await getPhotosUI(requestParams);

		setIsPending(false);
		setUpdatePhotos(false);

		if (!paginatedPhotos) return;

		setPhotosUI({
			results: paginatedPhotos.results,
			totalCount: paginatedPhotos.totalCount,
		});
	}, [
		isCall,
		orderBy,
		projectKey,
		updatePhotos,
		orderDirection,
		searchPhotosParams.searchValue,
		searchPhotosParams.searchParameter,
	]);

	useEffect(() => {
		setUpdatePhotos(true);
	}, [orderBy, orderDirection]);

	useEffect(() => {
		void getInitialPhotosUI();
	}, [getInitialPhotosUI]);

	return {
		orderBy,
		photosUI,
		isPending,
		setPhotosUI,
		setSortParams,
		orderDirection,
		setUpdatePhotos,
		isDeletedPhotos,
		setIsDeletedPhotos,
		isPaginationPending,
		getPaginatedPhotos: getPaginatedPhotosUI,
	};
};
