import cn from 'classnames';
import { FC } from 'react';

interface ITimeLineItemProps {
	icon?: string;
	year?: string;
	iconHelper?: string;
	datePreview?: string;
	title?: string | null;
	value?: string | null;
	saleLineColor?: string;
	dateIsEstimate?: boolean;
	dateIsEstimateComment?: string | null;
}

export const TimelineItem: FC<ITimeLineItemProps> = ({
	year,
	icon,
	title,
	value,
	iconHelper,
	datePreview,
	saleLineColor,
	dateIsEstimate,
	dateIsEstimateComment,
}) => {
	const showTitle = title && !value;
	const showValue = title && value;

	return (
		<li className={`timeline-item ${saleLineColor || ''}`}>
			<span
				className={cn('timeline-date', {
					'timeline-date-estimate': !!dateIsEstimate,
				})}
			>
				{datePreview}
			</span>
			<div className={`timeline-road ${iconHelper || ''}`}>
				{icon && <i className={`timeline-indent-icon ${icon}`} />}
				{!icon && year && <span>&#8217;</span>}
				{year}
			</div>
			{showTitle && <span className="timeline-name">{title}</span>}
			{showValue && (
				<span className="timeline-name">
					<b>{title}</b> {value}
				</span>
			)}
			{dateIsEstimateComment && (
				<span className="timeline-roadmap-subtitle">
					{dateIsEstimateComment}
				</span>
			)}
		</li>
	);
};
