import { UnitTypes } from 'api/models/responses/general/unitTypes';
import { ISelectOption } from 'types/ui/select';

import { promoUnitTypesUI } from '../promoUnitTypesUI';

export const promoUnitTypesSelectOptions: ISelectOption<UnitTypes>[] =
	Object.values(UnitTypes).map((unitType) => ({
		value: unitType,
		label: promoUnitTypesUI[unitType],
	}));
