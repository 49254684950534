import { FC, ReactNode } from 'react';

interface IProps {
	startMonth: string;
	endMonth: string;
	children: ReactNode;
}

export const ChartList: FC<IProps> = ({ children, startMonth, endMonth }) => (
	<ul className="chart-list">
		<li className="chart-item start-date">{startMonth}</li>
		{children}
		<li className="chart-item end-date">{endMonth}</li>
	</ul>
);
