import { ISearchPriceLists } from 'api/models/requests/priceLists/searchPriceLists';
import { PaginatedPriceLists } from 'api/models/responses/priceLists/priceList';

import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';

import { BaseService } from '../BaseService';

class PriceListsService extends BaseService {
	async searchPriceLists({
		skip,
		take,
		orderBy,
		isActive,
		searchValue,
		orderDirection,
	}: ISearchPriceLists): Promise<PaginatedPriceLists | null> {
		return this.get(priceListsUrls.searchPriceLists, {
			params: {
				Skip: skip,
				Take: take,
				OrderBy: orderBy,
				IsActive: isActive,
				SearchValue: searchValue,
				OrderDirection: orderDirection,
			},
		});
	}
}

export default new PriceListsService();
