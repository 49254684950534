import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';

import { validateRetailValue } from 'utils/validations/priceLists/validateRetailValue';
import { normalizeDigit } from 'utils/ui/normalizeDigit';

import { EnterCode } from 'constants/general/keyboardCodes';

interface ILazyDollarInputParams {
	initialValue: number;
	clearError: () => void;
	handleUpdate: (
		value: number,
		validationMessage: string
	) => Promise<void> | void;
}

interface IDollarLazyInputResult {
	value: string;
	handleBlur: () => void;
	handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
	handleKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
}

export const useLazyDollarInput = ({
	clearError,
	initialValue,
	handleUpdate,
}: ILazyDollarInputParams): IDollarLazyInputResult => {
	const [value, setValue] = useState('$0.00');

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value: inputValue } = e.target;

		if (inputValue === '') {
			setValue('');
			return;
		}

		const valueWithoutDollarSignAndCommas = inputValue.replace(/[$,]/g, '');

		if (
			isNaN(Number(valueWithoutDollarSignAndCommas)) ||
			Number(valueWithoutDollarSignAndCommas) < 0
		) {
			return;
		}

		setValue(inputValue);
		clearError();
	};

	const handleBlur = () => {
		const valueWithoutDollarSignAndCommas = value.replace(/[$,]/g, '');
		const valueNumber = Number(valueWithoutDollarSignAndCommas);

		if (valueNumber !== initialValue) {
			const validationMessage = validateRetailValue(valueNumber);

			void handleUpdate(valueNumber, validationMessage);
		}

		setValue(
			normalizeDigit({
				isPrice: true,
				value: valueNumber,
				minimumFractionDigits: 2,
			})
		);
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		const { code } = e;

		if (e.code === 'Comma' || (e.code === 'Digit4' && e.shiftKey)) {
			e.preventDefault();
		}

		if (code !== EnterCode) return;

		handleBlur();
	};

	useEffect(() => {
		setValue(
			normalizeDigit({
				isPrice: true,
				value: initialValue,
				minimumFractionDigits: 2,
			})
		);
	}, [initialValue]);

	return {
		value,
		handleBlur,
		handleChange,
		handleKeyDown,
	};
};
