export const validateDaysAmount = (daysAmount: number): string => {
	if (isNaN(daysAmount)) {
		return 'The days amount must be a number.';
	}

	if (!Number.isInteger(daysAmount)) {
		return 'The days amount must be an integer.';
	}

	if (daysAmount < 0 || daysAmount > 1000) {
		return 'The days amount must be between 0 and 1000.';
	}

	return '';
};
