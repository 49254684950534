import { CreateProjectFromPreset } from 'pages/Projects/pages/CreateProjectFromPreset';
import { ProjectDetailsPage } from 'pages/Projects/pages/ProjectDetailsPage';
import { CreateBlankProject } from 'pages/Projects/pages/CreateBlankProject';
import { CreateProject } from 'pages/Projects/pages/CreateProject';

import { ROUTES } from 'constants/ROUTES';

export const projectRoutesList = [
	{
		path: ROUTES.PROJECTS.CREATE_PROJECT,
		element: <CreateProject />,
	},
	{
		path: ROUTES.PROJECTS.CREATE_BLANK_PROJECT,
		element: <CreateBlankProject />,
	},
	{
		path: ROUTES.PROJECTS.CREATE_PROJECT_FROM_PRESET,
		element: <CreateProjectFromPreset />,
	},
	{
		path: ROUTES.PROJECTS.PROJECT_DETAILS,
		element: <ProjectDetailsPage />,
	},
];
