import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	projectNameValidationRegEx,
	projectNameValidationMessage,
} from 'constants/projects/validation/generalProjectFields';

export const validatePresetName = (presetName: string): string => {
	if (!presetName) {
		return requiredFieldMessage;
	}

	if (projectNameValidationRegEx.test(presetName)) {
		return projectNameValidationMessage;
	}

	return '';
};
