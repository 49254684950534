import { DragEvent, FC, useState } from 'react';

import { PriceListFormQuestions } from 'pages/PriceLists/components/PriceListFormQuestions';
import { DeleteControlColumn } from 'pages/PriceLists/components/DeleteControlColumn';
import { ReferenceCodeColumn } from 'pages/PriceLists/components/ReferenceCodeColumn';
import { RetailValueColumn } from 'pages/PriceLists/components/RetailValueColumn';
import { ToggleArrowColumn } from 'pages/PriceLists/components/ToggleArrowColumn';
import { NameColumn } from 'pages/PriceLists/components/NameColumn';
import {
	CreatePriceListFormQuestion,
	DeletePriceListFormQuestion,
	SetPriceListFormQuestionsUI,
	UpdatePriceListFormQuestion,
	ReorderPriceListFormQuestions,
} from 'pages/PriceLists/types/studio/priceListFormQuestionUI';
import {
	IPackageUI,
	IUpdatePackage,
	IDeletePackage,
	IClearPackageErrors,
	IUploadPackageImage,
} from 'pages/PriceLists/types/studio/priceListPackageUI';

import { PoseAvailableFor } from 'constants/priceLists/poseAvailableFor/poseAvailableFor';
import { IChangeSequencePayload, useDragAndDrop } from 'hooks/useDragAndDrop';

import { AvailableForColumn } from './components/AvailableForColumn';
import { PackageContent } from './components/PackageContent';

type DeletePackageHandler = (deletePackage: IDeletePackage) => Promise<void>;
type ChangePackagesSequence = (payload: IChangeSequencePayload) => void;

export type ClearPackageErrorHandler = (
	clearPackageErrors: IClearPackageErrors
) => void;

export type UpdatePackageHandler = (
	updatePackage: IUpdatePackage
) => Promise<void>;

export type UploadPackageImage = (
	payload: IUploadPackageImage
) => Promise<void>;

export type DeletePackageImage = (packageKey?: number) => Promise<void>;

interface IPriceListPackageProps {
	packageUI: IPackageUI;
	previewImageFile: File | null;
	deletePackage: DeletePackageHandler;
	updatePackage: UpdatePackageHandler;
	deletePackageImage: DeletePackageImage;
	uploadPackageImage: UploadPackageImage;
	clearPackageError: ClearPackageErrorHandler;
	changePackagesSequence: ChangePackagesSequence;
	setFormQuestionsUI: SetPriceListFormQuestionsUI;
	createFormQuestion: CreatePriceListFormQuestion;
	updateFormQuestion: UpdatePriceListFormQuestion;
	deleteFormQuestion: DeletePriceListFormQuestion;
	reorderFormQuestions: ReorderPriceListFormQuestions;
}

export const PriceListPackage: FC<IPriceListPackageProps> = ({
	packageUI,
	deletePackage,
	updatePackage,
	previewImageFile,
	clearPackageError,
	deletePackageImage,
	uploadPackageImage,
	setFormQuestionsUI,
	createFormQuestion,
	deleteFormQuestion,
	updateFormQuestion,
	reorderFormQuestions,
	changePackagesSequence,
}) => {
	const [showContent, setShowContent] = useState(false);

	const toggleShowContent = () => {
		setShowContent(!showContent);
	};

	const {
		name,
		errors,
		sequence,
		retailPrice,
		availableFor,
		referenceCode,
		priceListStudioFulfilledPackageFormQuestions,
		priceListStudioFulfilledPackageKey: packageKey,
	} = packageUI;

	const {
		name: nameError,
		retailPrice: retailPriceError,
		referenceCode: referenceCodeError,
	} = errors;

	const packageKeyString = packageKey?.toString() || '';

	const {
		draggable,
		handleDrop,
		handleDragEnd,
		handleDragOver,
		handleDragStart,
		handleMouseDown,
	} = useDragAndDrop([], changePackagesSequence);

	const onDragStart = (e: DragEvent<HTMLTableSectionElement>) => {
		if (!packageKeyString) return;

		handleDragStart(e, packageKeyString);
	};

	const handleDelete = () => {
		void deletePackage({
			sequence,
			packageKey,
		});
	};

	const updateName = (value: string, validationMessage: string) => {
		void updatePackage({
			value,
			sequence,
			packageKey,
			fieldKey: 'name',
			validationMessage,
		});
	};

	const clearNameError = () => {
		if (!nameError) return;

		clearPackageError({
			sequence,
			fieldKey: 'name',
		});
	};

	const updatePriceValue = (value: number, validationMessage: string) => {
		void updatePackage({
			value,
			sequence,
			packageKey,
			validationMessage,
			fieldKey: 'retailPrice',
		});
	};

	const clearPriceValueError = () => {
		if (!retailPriceError) return;

		clearPackageError({
			sequence,
			fieldKey: 'retailPrice',
		});
	};

	const updateReferenceCode = (value: string, validationMessage: string) => {
		void updatePackage({
			value,
			sequence,
			packageKey,
			validationMessage,
			fieldKey: 'referenceCode',
		});
	};

	const clearReferenceCodeError = () => {
		if (!referenceCodeError) return;

		clearPackageError({
			sequence,
			fieldKey: 'referenceCode',
		});
	};

	const updateAvailableFor = async (value: PoseAvailableFor) => {
		await updatePackage({
			value,
			sequence,
			packageKey,
			validationMessage: '',
			fieldKey: 'availableFor',
		});
	};

	const unsavedPackageOption = !packageKey;
	const showBurgerBtn = !showContent && !unsavedPackageOption;
	const showContentBurgerBtn = showContent && !unsavedPackageOption;

	const uniqPreviewImageFile = unsavedPackageOption ? previewImageFile : null;

	return (
		<tbody
			onDrop={handleDrop}
			id={packageKeyString}
			draggable={draggable}
			onDragStart={onDragStart}
			onDragEnd={handleDragEnd}
			onDragOver={handleDragOver}
			data-drag-sequence={sequence}
			className="price-option-tbody"
		>
			<tr>
				<ToggleArrowColumn
					showContent={showContent}
					showBurgerBtn={showBurgerBtn}
					handleMouseDown={handleMouseDown}
					handleToggleShowContent={toggleShowContent}
				/>
				<NameColumn
					name={name}
					nameError={nameError}
					updateName={updateName}
					clearError={clearNameError}
				/>
				<RetailValueColumn
					retailValue={retailPrice}
					clearError={clearPriceValueError}
					retailValueError={retailPriceError}
					updateRetailValue={updatePriceValue}
				/>
				<ReferenceCodeColumn
					referenceCode={referenceCode}
					clearError={clearReferenceCodeError}
					referenceCodeError={referenceCodeError}
					updateReferenceCode={updateReferenceCode}
				/>
				<AvailableForColumn
					availableFor={availableFor}
					updateAvailableFor={updateAvailableFor}
				/>
				<DeleteControlColumn showDeleteBtn handleDelete={handleDelete} />
			</tr>
			{showContent && (
				<>
					<PackageContent
						packageUI={packageUI}
						updatePackage={updatePackage}
						handleMouseDown={handleMouseDown}
						showBurger={showContentBurgerBtn}
						clearPackageError={clearPackageError}
						previewImageFile={uniqPreviewImageFile}
						deletePackageImage={deletePackageImage}
						uploadPackageImage={uploadPackageImage}
					/>
					<PriceListFormQuestions
						entityKey={packageKey}
						deleteFormQuestion={deleteFormQuestion}
						updateFormQuestion={updateFormQuestion}
						setFormQuestionsUI={setFormQuestionsUI}
						createFormQuestion={createFormQuestion}
						reorderFormQuestions={reorderFormQuestions}
						formQuestionsUI={priceListStudioFulfilledPackageFormQuestions}
					/>
				</>
			)}
		</tbody>
	);
};
