import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import { FC } from 'react';

import { Button } from 'components/FormControls/Button';
import { Table } from 'components/Table';

import { IActiveCampaignItem } from 'api/models/responses/campaigns/campaignDeleteReject';
import { usedInPresetsSelector, usedInProjectsSelector } from 'store/campaigns';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { ROUTES } from 'constants/ROUTES';

interface ICampaignDeleteRejectProps {
	hideModal: () => void;
}

export const CampaignDeleteReject: FC<ICampaignDeleteRejectProps> = ({
	hideModal,
}) => {
	const usedInProjects = useAppSelector(usedInProjectsSelector);
	const usedInPresets = useAppSelector(usedInPresetsSelector);

	const projectsColumns: Column<IActiveCampaignItem>[] = [
		{
			Header: () => (
				<div className="tr-arrow">Projects using this campaign</div>
			),
			accessor: 'name',
			Cell: ({ row }: CellProps<IActiveCampaignItem>) => {
				const { name, key } = row.original;

				return (
					<Link
						title={name}
						className="card-table-link"
						to={`${ROUTES.PROJECTS.PROJECTS}/${key}`}
					>
						{name}
					</Link>
				);
			},
		},
	];

	const presetsColumns: Column<IActiveCampaignItem>[] = [
		{
			Header: () => <div className="tr-arrow">Presets using this campaign</div>,
			accessor: 'name',
			Cell: ({ row }: CellProps<IActiveCampaignItem>) => {
				const { name, key } = row.original;

				return (
					<Link
						title={name}
						className="card-table-link"
						to={`${ROUTES.PRESETS.PRESETS}/${key}`}
					>
						{name}
					</Link>
				);
			},
		},
	];

	return (
		<div className="modal-body campaign-modal-tables">
			<div className="campaign-modal-table">
				<Table<IActiveCampaignItem>
					columns={projectsColumns}
					data={usedInProjects}
				/>
			</div>
			<div className="campaign-modal-table">
				<Table<IActiveCampaignItem>
					columns={presetsColumns}
					data={usedInPresets}
				/>
			</div>
			<Button
				value="OK"
				handleClick={hideModal}
				className="btn-primary campaign-table-btn"
			/>
		</div>
	);
};
