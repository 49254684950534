import { Dispatch } from '@reduxjs/toolkit';

import { ICampaignDeleteReject } from 'api/models/responses/campaigns/campaignDeleteReject';
import { IErrorResponse } from 'api/models/responses/errors/errorResponse';
import { ICopyCampaignBody } from 'api/models/requests/campaigns';
import CampaignsService from 'api/services/CampaignsService';

import {
	setCampaigns,
	setUsedInItems,
	deleteCampaign,
	setIsCopySuccess,
	setCopiedCampaign,
	setIsCampaignsPending,
	setIsDeleteCampaignReject,
	setIsDeleteCampaignSuccess,
} from './campaignsSlice';

export const getCampaignsAsync = () => async (dispatch: Dispatch) => {
	dispatch(setIsCampaignsPending(true));

	try {
		const data = await CampaignsService.getCampaigns();

		if (!data) return;

		dispatch(setCampaigns(data));
	} catch (error) {
		console.log(error);
	}

	dispatch(setIsCampaignsPending(false));
};

export const copyCampaignAsync =
	(campaignKey: number, body: ICopyCampaignBody) =>
	async (dispatch: Dispatch) => {
		try {
			const data = await CampaignsService.copyCampaign(campaignKey, body);

			if (!data) return;

			dispatch(setCopiedCampaign(data));
			dispatch(setIsCopySuccess(true));
		} catch (error) {
			console.log(error);
		}
	};

export const deleteCampaignAsync =
	(campaignKey: number) => async (dispatch: Dispatch) => {
		try {
			await CampaignsService.deleteCampaign(campaignKey);

			dispatch(deleteCampaign(campaignKey));
			dispatch(setIsDeleteCampaignSuccess(true));
		} catch (error) {
			const { type } = error as IErrorResponse;

			if (!type) {
				const { isDeleted, usedInPresets, usedInProjects } =
					error as ICampaignDeleteReject;

				if (!isDeleted) {
					dispatch(setUsedInItems({ usedInPresets, usedInProjects }));
					dispatch(setIsDeleteCampaignReject(true));
				}
			}
		}
	};
