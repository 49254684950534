import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
	IGalleryImage,
	IUploadedGalleryImages,
} from 'api/models/responses/imageGallery';

import { IImageGalleryState } from '.';

const initialState: IImageGalleryState = {
	galleryImages: [],
	addedImagesCount: 0,
	skippedImagesCount: 0,
	isImageGalleryPending: false,
	isUploadImagesPending: false,
};

const imageGallerySlice = createSlice({
	name: 'imageGallery',
	initialState,
	reducers: {
		setGalleryImages: (state, { payload }: PayloadAction<IGalleryImage[]>) => {
			state.galleryImages = payload;
		},

		deleteGalleryImage: (state, { payload }: PayloadAction<number>) => {
			state.galleryImages = state.galleryImages.filter(
				({ galleryImageKey }) => galleryImageKey !== payload
			);
		},

		setUploadedMultipleImages: (
			state,
			{ payload }: PayloadAction<IUploadedGalleryImages>
		) => {
			state.galleryImages = [...state.galleryImages, ...payload.uploadedImages];
			state.addedImagesCount = payload.addedImagesCount;
			state.skippedImagesCount = payload.skippedImagesCount;
		},

		setIsImageGalleryPending: (state, { payload }: PayloadAction<boolean>) => {
			state.isImageGalleryPending = payload;
		},

		setIsUploadImagesPending: (state, { payload }: PayloadAction<boolean>) => {
			state.isUploadImagesPending = payload;
		},
	},
});

export const imageGalleryReducer = imageGallerySlice.reducer;
export const {
	setGalleryImages,
	deleteGalleryImage,
	setIsImageGalleryPending,
	setIsUploadImagesPending,
	setUploadedMultipleImages,
} = imageGallerySlice.actions;
