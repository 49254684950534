// TODO: check and make correct logic for all enums

type Enum = { [s: string]: string | number };

function isEnumKey<T extends Enum>(enumSrc: T, key: unknown): key is keyof T {
	return Number.isInteger(enumSrc[key as keyof T]);
}

function enumToKeys<T extends Enum>(enumSrc: T): (keyof T)[] {
	return Object.keys(enumSrc).filter((key: keyof T | any) =>
		isEnumKey(enumSrc, key)
	) as (keyof T)[];
}

export const parseEnumToSelectValues = <T extends Enum>(
	enumParam: T,
	parseEntries = false
) =>
	parseEntries
		? enumToKeys(enumParam).map((key) => ({
				value: enumParam[key],
				label: key as string,
		  }))
		: Object.values(enumParam).map((value) => ({
				value,
				label: value as string,
		  }));
