import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IStudioPhotoSettings } from 'api/models/responses/studio/studioPhotoSettings';
import { IStudioMarketing } from 'api/models/responses/studio/studioMarketing';
import { IStudio } from 'api/models/responses/studio/studio';

import { IStudioState } from './types';

const initialState: IStudioState = {
	studio: null,
	studioMarketing: null,
	studioPhotoSettings: null,
};

const studioSlice = createSlice({
	name: 'studio',
	initialState,
	reducers: {
		setStudio: (state, { payload }: PayloadAction<IStudio>) => {
			state.studio = payload;
		},

		setStudioMarketing: (
			state,
			{ payload }: PayloadAction<IStudioMarketing>
		) => {
			state.studioMarketing = payload;
		},

		setStudioPhotoSettings: (
			state,
			{ payload }: PayloadAction<IStudioPhotoSettings>
		) => {
			state.studioPhotoSettings = payload;
		},

		clearStudio: (state) => {
			state.studio = null;
		},
	},
});

export const studioReducer = studioSlice.reducer;
export const {
	setStudio,
	clearStudio,
	setStudioMarketing,
	setStudioPhotoSettings,
} = studioSlice.actions;
