export const colorsCollection = [
	{ color: 'rgb(0,0,0)' },
	{ color: 'rgb(26,26,26)' },
	{ color: 'rgb(51,51,51)' },
	{ color: 'rgb(77,77,77)' },
	{ color: 'rgb(102,102,102)' },
	{ color: 'rgb(128,128,128)' },
	{ color: 'rgb(153,153,153)' },
	{ color: 'rgb(179,179,179)' },
	{ color: 'rgb(255,255,255)' },
	{ color: 'rgb(51,0,0)' },
	{ color: 'rgb(102,0,0)' },
	{ color: 'rgb(153,0,0)' },
	{ color: 'rgb(204,0,0)' },
	{ color: 'rgb(255,0,0)' },
	{ color: 'rgb(255,51,51)' },
	{ color: 'rgb(255,102,102)' },
	{ color: 'rgb(255,153,153)' },
	{ color: 'rgb(255,204,204)' },
	{ color: 'rgb(51,17,0)' },
	{ color: 'rgb(102,34,0)' },
	{ color: 'rgb(153,51,0)' },
	{ color: 'rgb(204,68,0)' },
	{ color: 'rgb(255,85,0)' },
	{ color: 'rgb(255,119,51)' },
	{ color: 'rgb(255,153,102)' },
	{ color: 'rgb(255,187,153)' },
	{ color: 'rgb(255,221,204)' },
	{ color: 'rgb(51,34,0)' },
	{ color: 'rgb(102,68,0)' },
	{ color: 'rgb(153,102,0)' },
	{ color: 'rgb(204,136,0)' },
	{ color: 'rgb(255,170,0)' },
	{ color: 'rgb(255,187,51)' },
	{ color: 'rgb(255,204,102)' },
	{ color: 'rgb(255,221,153)' },
	{ color: 'rgb(255,238,204)' },
	{ color: 'rgb(51,51,0)' },
	{ color: 'rgb(102,102,0)' },
	{ color: 'rgb(153,153,0)' },
	{ color: 'rgb(204,204,0)' },
	{ color: 'rgb(255,255,0)' },
	{ color: 'rgb(255,255,51)' },
	{ color: 'rgb(255,255,102)' },
	{ color: 'rgb(255,255,153)' },
	{ color: 'rgb(255,255,204)' },
	{ color: 'rgb(34,51,0)' },
	{ color: 'rgb(68,102,0)' },
	{ color: 'rgb(102,153,0)' },
	{ color: 'rgb(136,204,0)' },
	{ color: 'rgb(170,255,0)' },
	{ color: 'rgb(187,255,51)' },
	{ color: 'rgb(204,255,102)' },
	{ color: 'rgb(221,255,153)' },
	{ color: 'rgb(238,255,204)' },
	{ color: 'rgb(0,51,0)' },
	{ color: 'rgb(0,102,0)' },
	{ color: 'rgb(0,153,0)' },
	{ color: 'rgb(0,204,0)' },
	{ color: 'rgb(0,255,0)' },
	{ color: 'rgb(51,255,51)' },
	{ color: 'rgb(102,255,102)' },
	{ color: 'rgb(153,255,153)' },
	{ color: 'rgb(204,255,204)' },
	{ color: 'rgb(0,51,34)' },
	{ color: 'rgb(0,102,68)' },
	{ color: 'rgb(0,153,102)' },
	{ color: 'rgb(0,204,136)' },
	{ color: 'rgb(0,255,170)' },
	{ color: 'rgb(51,255,187)' },
	{ color: 'rgb(102,255,204)' },
	{ color: 'rgb(153,255,221)' },
	{ color: 'rgb(204,255,238)' },
	{ color: 'rgb(0,34,51)' },
	{ color: 'rgb(0,68,102)' },
	{ color: 'rgb(0,102,153)' },
	{ color: 'rgb(0,136,204)' },
	{ color: 'rgb(0,170,255)' },
	{ color: 'rgb(51,187,255)' },
	{ color: 'rgb(102,204,255)' },
	{ color: 'rgb(153,221,255)' },
	{ color: 'rgb(204,238,255)' },
	{ color: 'rgb(0,0,51)' },
	{ color: 'rgb(0,0,102)' },
	{ color: 'rgb(0,0,153)' },
	{ color: 'rgb(0,0,204)' },
	{ color: 'rgb(0,0,255)' },
	{ color: 'rgb(51,51,255)' },
	{ color: 'rgb(102,102,255)' },
	{ color: 'rgb(153,153,255)' },
	{ color: 'rgb(204,204,255)' },
	{ color: 'rgb(34,0,51)' },
	{ color: 'rgb(68,0,102)' },
	{ color: 'rgb(102,0,153)' },
	{ color: 'rgb(136,0,204)' },
	{ color: 'rgb(170,0,255)' },
	{ color: 'rgb(187,51,255)' },
	{ color: 'rgb(204,102,255)' },
	{ color: 'rgb(221,153,255)' },
	{ color: 'rgb(238,204,255)' },
	{ color: 'rgb(51,0,42)' },
	{ color: 'rgb(102,0,85)' },
	{ color: 'rgb(153,0,127)' },
	{ color: 'rgb(204,0,170)' },
	{ color: 'rgb(255,0,212)' },
	{ color: 'rgb(255,51,221)' },
	{ color: 'rgb(255,102,229)' },
	{ color: 'rgb(255,153,238)' },
	{ color: 'rgb(255,204,246)' },
	{ color: 'rgb(51,0,26)' },
	{ color: 'rgb(102,0,51)' },
	{ color: 'rgb(153,0,77)' },
	{ color: 'rgb(204,0,102)' },
	{ color: 'rgb(255,0,128)' },
	{ color: 'rgb(255,51,153)' },
	{ color: 'rgb(255,102,179)' },
	{ color: 'rgb(255,153,204)' },
	{ color: 'rgb(255,204,230)' },
];
