import { useEffect, useState } from 'react';
import { SingleValue } from 'react-select';

import { SelectComponent } from 'components/FormControls/Select';
import { Button } from 'components/FormControls/Button';

import { ISelectOption } from 'types/ui/select';

interface IAddOptionTypeModalBodyProps<T> {
	btnValue: string;
	selectLabel: string;
	selectPlaceholder: string;
	selectOptions: ISelectOption<T>[];
	addOptionType: (value: T) => void;
}

export const AddOptionTypeModalBody = <T extends string>({
	btnValue,
	selectLabel,
	selectOptions,
	addOptionType,
	selectPlaceholder,
}: IAddOptionTypeModalBodyProps<T>) => {
	const [value, setValue] = useState<T | null>(null);

	const handleChange = (option: SingleValue<ISelectOption<T>>) => {
		if (!option) return;

		setValue(option.value);
	};

	const handleAddOptionType = () => {
		if (!value) return;

		addOptionType(value);
		setValue(null);
	};

	useEffect(() => {
		if (!selectOptions.length) return;

		setValue(selectOptions[0].value);
	}, [selectOptions]);

	return (
		<div className="org-prospects-modal">
			<div className="org-select-form flex">
				<SelectComponent
					value={value}
					label={selectLabel}
					onChange={handleChange}
					selectOptions={selectOptions}
					selectPlaceholder={selectPlaceholder}
				/>
			</div>
			<Button
				value={btnValue}
				handleClick={handleAddOptionType}
				className="org-modal-btn long btn-primary"
			/>
		</div>
	);
};
