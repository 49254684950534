export enum BillingEntityType {
	IndividualSoleProprietor = 'IndividualSoleProprietor',
	CCorporation = 'CCorporation',
	SCorporation = 'SCorporation',
	Partenership = 'Partenership',
	LLCSingleMember = 'LLCSingleMember',
	LLCCCorporation = 'LLCCCorporation',
	LLCPartnership = 'LLCPartnership',
	LLCSCorporation = 'LLCSCorporation',
	Other = 'Other',
}
