import { useCallback, useEffect, useState } from 'react';
import { SingleValue } from 'react-select';

import { ISelectOption } from 'types/ui/select';

interface IAsyncOptimizedSelectResult<T> {
	value: T | null;
	isPending: boolean;
	handleChange: (option: SingleValue<ISelectOption<T>>) => void;
}

interface IAsyncOptimizedSelectProps<T> {
	initialValue?: T | null;
	clearError?: () => void;
	updateSelectValue: (value: T) => Promise<void>;
}

export const useAsyncOptimizedSelect = <T,>({
	clearError,
	initialValue,
	updateSelectValue,
}: IAsyncOptimizedSelectProps<T>): IAsyncOptimizedSelectResult<T> => {
	const [value, setValue] = useState<T | null>(null);
	const [isPending, setIsPending] = useState(false);

	const isEqual = value === initialValue;

	const handleChange = useCallback((option: SingleValue<ISelectOption<T>>) => {
		if (!option) return;

		if (clearError) {
			clearError();
		}

		setValue(option.value);
	}, []);

	const updateValue = useCallback(async () => {
		if (isEqual || !value) return;

		setIsPending(true);

		await updateSelectValue(value);

		setIsPending(false);
	}, [value]);

	useEffect(() => {
		if (isEqual || !initialValue) return;

		setValue(initialValue);
	}, [initialValue]);

	useEffect(() => {
		void updateValue();
	}, [updateValue]);

	return {
		value,
		isPending,
		handleChange,
	};
};
