import { PriceListStudio } from 'pages/PriceLists/pages/PriceListStudio';
import { PriceListImago } from 'pages/PriceLists/pages/PriceListImago';
import { PriceLists } from 'pages/PriceLists/pages/PriceLists';

import { ROUTES } from 'constants/ROUTES';

export const priceListRoutesList = [
	{
		path: ROUTES.PRICE_LISTS.PRICE_LISTS,
		element: <PriceLists />,
	},
	{
		path: ROUTES.PRICE_LISTS.PRICE_LISTS_STUDIO_DETAILS,
		element: <PriceListStudio />,
	},
	{
		path: ROUTES.PRICE_LISTS.PRICE_LISTS_IMAGO_DETAILS,
		element: <PriceListImago />,
	},
];
