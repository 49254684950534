import { IOwnPackageOption } from 'api/models/responses/priceLists/studio/priceListOptions/ownPackageOption';
import { ICreateOwnPackageOption } from 'api/models/requests/priceLists/studio/priceListOptions/createOwnPackageOption';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { BaseService } from 'api/services/BaseService';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';

class OwnPackageOptions extends BaseService {
	async getOwnPackageOptions(
		priceListKey: number
	): Promise<IOwnPackageOption[] | null> {
		return this.get<IOwnPackageOption[]>(
			priceListsUrls.studioFulfilled.ownPackageOptions.getUrlByPriceListKey(
				priceListKey
			)
		);
	}

	async createOwnPackageOption(
		priceListKey: number,
		{
			name,
			price,
			points,
			sequence,
			description,
			referenceCode,
			previewImageFile,
		}: ICreateOwnPackageOption
	): Promise<IOwnPackageOption | null> {
		const formData = new FormData();

		formData.set('Name', name);
		formData.set('Price', price.toString());
		formData.set('Description', description);
		formData.set('Points', points.toString());
		formData.set('ReferenceCode', referenceCode);
		formData.set('Sequence', sequence.toString());
		formData.set('PreviewImage', previewImageFile ?? '');

		return this.post(
			priceListsUrls.studioFulfilled.ownPackageOptions.getUrlByPriceListKey(
				priceListKey
			),
			formData
		);
	}

	async updateOwnPackageOption(
		ownPackageOptionKey: number,
		body: IPatchBody[]
	): Promise<IOwnPackageOption | null> {
		return this.patch(
			priceListsUrls.studioFulfilled.ownPackageOptions.getUrlByOwnPackageOptionKey(
				ownPackageOptionKey
			),
			body
		);
	}

	async deleteOwnPackageOption(
		ownPackageOptionKey: number
	): Promise<string | null> {
		return this.delete(
			priceListsUrls.studioFulfilled.ownPackageOptions.getUrlByOwnPackageOptionKey(
				ownPackageOptionKey
			)
		);
	}

	async reorderOwnPackageOptions(
		priceListKey: number,
		body: Record<number, number>
	): Promise<string | null> {
		return this.put(
			priceListsUrls.studioFulfilled.ownPackageOptions.reorderOwnPackageOptions(
				priceListKey
			),
			body
		);
	}

	async uploadOwnPackageOptionPreviewImage(
		ownPackageOptionKey: number,
		imageFile: File
	): Promise<string | null> {
		const formData = new FormData();

		formData.set('imageFile', imageFile);

		return this.post(
			priceListsUrls.studioFulfilled.ownPackageOptions.previewImageOwnPackageOption(
				ownPackageOptionKey
			),
			formData,
			{
				...multipartHeaders,
			}
		);
	}

	async deleteOwnPackageOptionPreviewImage(ownPackageOptionKey: number) {
		return this.delete(
			priceListsUrls.studioFulfilled.ownPackageOptions.previewImageOwnPackageOption(
				ownPackageOptionKey
			)
		);
	}
}

export default new OwnPackageOptions();
