import { FC, useEffect } from 'react';

import { PoseOptionsSubTitle } from 'pages/PriceLists/components/BackgroundOptions/components/PoseOptionsSubTitle';
import { BackgroundOptions } from 'pages/PriceLists/components/BackgroundOptions';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToggleSection } from 'hooks/useToggleSection';

import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Loader } from 'components/Loader';

import { PriceListFulfillment } from 'constants/priceLists/priceListFulfillment';
import { getImagoBackgroundsAsync } from 'store/priceLists/priceListBackgrounds';

interface IPoseOptionsContainerProps {
	priceListKey?: number;
	isPriceListPending: boolean;
}

export const PoseOptionsContainer: FC<IPoseOptionsContainerProps> = ({
	priceListKey,
	isPriceListPending,
}) => {
	const { isOpen, handleToggle } = useToggleSection(true);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!priceListKey) return;

		void dispatch(getImagoBackgroundsAsync(priceListKey));
	}, [priceListKey]);

	const showLoader = isPriceListPending;

	return (
		<ToggleSection
			isOpen={isOpen}
			title="Pose Options"
			handleToggle={handleToggle}
			className="price-search-container"
			header={<PoseOptionsSubTitle title="(prompted when pose is selected)" />}
		>
			{showLoader ? (
				<Loader />
			) : (
				<div className="price-container">
					<span className="price-container-subtitle">Background Options</span>
					<BackgroundOptions
						priceListKey={priceListKey}
						fulfillment={PriceListFulfillment.Imago}
					/>
					<div className="price-prompt">
						<span className="price-prompt-label">
							Yearbook Pose Options <i>(coming soon)</i>
						</span>
						<span className="price-prompt-info">
							Prompt parents to confirm the selected yearbook pose
						</span>
					</div>
					<Button
						disabled
						className="price-add-type btn-secondary"
						value="+ Add Another Type of Pose Option"
					/>
				</div>
			)}
		</ToggleSection>
	);
};
