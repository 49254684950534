import { useEffect } from 'react';
import moment from 'moment';

import { ArchivedOrgSortLabels } from 'constants/organizations/archivedOrgSortLabels';
import { DataOrder } from 'constants/general/dataOrder';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useSortParams } from 'hooks/useSortParams';

import {
	archivedOrganizationsSelector,
	setArchivedOrganizations,
} from 'store/organizations';

interface ISortArchivedOrganizationsResult {
	orderDirection: DataOrder;
	orderBy: ArchivedOrgSortLabels;
	setSortParams: (sortLabel: ArchivedOrgSortLabels) => void;
}

export const useSortArchivedOrganizations =
	(): ISortArchivedOrganizationsResult => {
		const { orderBy, setSortParams, orderDirection } =
			useSortParams<ArchivedOrgSortLabels>(ArchivedOrgSortLabels.ArchivedDate);

		const archivedOrganizations = useAppSelector(archivedOrganizationsSelector);

		const dispatch = useAppDispatch();

		useEffect(() => {
			if (!archivedOrganizations.length) return;

			switch (orderBy) {
				case ArchivedOrgSortLabels.OrganizationName: {
					const sortedArchivedOrganizations = [...archivedOrganizations].sort(
						(a, b) =>
							orderDirection === DataOrder.ASC
								? a.organizationName.localeCompare(b.organizationName)
								: b.organizationName.localeCompare(a.organizationName)
					);

					dispatch(setArchivedOrganizations(sortedArchivedOrganizations));
					break;
				}

				case ArchivedOrgSortLabels.ArchivedProjects: {
					const sortedArchivedOrganizations = [...archivedOrganizations].sort(
						(a, b) =>
							orderDirection === DataOrder.ASC
								? a.archivedProjectsCount - b.archivedProjectsCount
								: b.archivedProjectsCount - a.archivedProjectsCount
					);

					dispatch(setArchivedOrganizations(sortedArchivedOrganizations));
					break;
				}

				case ArchivedOrgSortLabels.CreatedDate: {
					const sortedArchivedOrganizations = [...archivedOrganizations].sort(
						(a, b) => {
							const firstDate = moment(a.createDate);
							const secondDate = moment(b.createDate);

							if (orderDirection === DataOrder.ASC) {
								return secondDate.isAfter(firstDate) ? 1 : -1;
							}

							return secondDate.isAfter(firstDate) ? -1 : 1;
						}
					);

					dispatch(setArchivedOrganizations(sortedArchivedOrganizations));
					break;
				}

				case ArchivedOrgSortLabels.ArchivedDate: {
					const sortedArchivedOrganizations = [...archivedOrganizations].sort(
						(a, b) => {
							const firstDate = moment(a.archivedDate);
							const secondDate = moment(b.archivedDate);

							if (orderDirection === DataOrder.ASC) {
								return secondDate.isAfter(firstDate) ? 1 : -1;
							}

							return secondDate.isAfter(firstDate) ? -1 : 1;
						}
					);

					dispatch(setArchivedOrganizations(sortedArchivedOrganizations));
					break;
				}

				default:
					break;
			}
		}, [orderBy, orderDirection]);

		return { orderBy, setSortParams, orderDirection };
	};
