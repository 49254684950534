import { PriceListFulfillment } from './priceListFulfillment';

export const priceListFulfillmentDescriptions: Record<
	PriceListFulfillment,
	string
> = {
	[PriceListFulfillment.NA]: '',
	[PriceListFulfillment.Imago]: '(orders produced and shipped by studio)',
	[PriceListFulfillment.Studio]: '(orders fulfilled by studio)',
};
