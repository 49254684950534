export enum ProjectTypes {
	LeagueSports = 'LeagueSports',
	SchoolSports = 'SchoolSports',
	DanceStudio = 'DanceStudio',
	PreSchoolDaycare = 'PreSchoolDaycare',
	ElementarySchool = 'ElementarySchool',
	JrHighMiddleSchool = 'JrHighMiddleSchool',
	HighSchool = 'HighSchool',
	K12School = 'K12School',
	Seniors = 'Seniors',
	Graduation = 'Graduation',
	PromFormalDance = 'PromFormalDance',
	YearbookSales = 'YearbookSales',
	SummerCamp = 'SummerCamp',
	Event = 'Event',
}
