import { ProjectTypes } from 'api/models/requests/projects/projectTypes';

interface IProjectTypeSubject {
	value: ISubject;
	projectTypes: ProjectTypes[];
}

interface ISubject {
	subject: string;
	subjects: string;
}

export const projectTypeSubjects: IProjectTypeSubject[] = [
	{
		value: { subjects: 'Students', subject: 'Student' },
		projectTypes: [
			ProjectTypes.ElementarySchool,
			ProjectTypes.JrHighMiddleSchool,
			ProjectTypes.HighSchool,
			ProjectTypes.K12School,
			ProjectTypes.Seniors,
			ProjectTypes.Graduation,
			ProjectTypes.YearbookSales,
		],
	},
	{
		value: { subjects: 'Athletes', subject: 'Athlete' },
		projectTypes: [ProjectTypes.LeagueSports, ProjectTypes.SchoolSports],
	},
	{
		value: { subjects: 'Dancers', subject: 'Dancer' },
		projectTypes: [ProjectTypes.DanceStudio],
	},
	{
		value: { subjects: 'Attendees', subject: 'Attendee' },
		projectTypes: [ProjectTypes.PromFormalDance, ProjectTypes.Event],
	},
	{
		value: { subjects: 'Children', subject: 'Child' },
		projectTypes: [ProjectTypes.PreSchoolDaycare, ProjectTypes.SummerCamp],
	},
];
