import { FC, useEffect, useState } from 'react';
import cn from 'classnames';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { setIsOpen as setIsOpenAside } from 'store/aside';
import { isOpenSelector, setIsOpen as setIsOpenSidebar } from 'store/sidebar';

import { HeaderBreadcrumbs } from './HeaderBreadcrumbs';
import { HeaderControl } from './HeaderControl';
import { HeaderMobile } from './HeaderMobile';

export const Header: FC = () => {
	const isOpen = useAppSelector(isOpenSelector);

	const dispatch = useAppDispatch();

	const showAside = () => {
		dispatch(setIsOpenAside(true));
	};

	const showSidebar = () => {
		dispatch(setIsOpenSidebar(true));
	};

	const [showComponent, setShowComponent] = useState(window.innerWidth < 768);

	useEffect(() => {
		const handleResize = () => {
			setShowComponent(window.innerWidth < 768);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<>
			<header className="header">
				<div className={`header-content ${isOpen ? 'header-thumb-open' : ''}`}>
					<div
						className={cn('header-thumb', {
							'is-show': !isOpen,
						})}
					>
						{!showComponent && (
							<button className="header-burger" onClick={showSidebar}></button>
						)}
						<div className="heade-logo-mini"></div>
					</div>
					{!showComponent && <HeaderBreadcrumbs />}
				</div>
				<HeaderControl showAside={showAside} />
			</header>
			{showComponent && <HeaderMobile showSidebar={showSidebar} />}
		</>
	);
};
