import { FC, useCallback, useEffect, useState } from 'react';
import { SingleValue } from 'react-select';

import { changeCampaignsPaths } from 'api/models/requests/projects/changeCampaignsPaths';
import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';
import { ISelectCampaign } from 'api/models/responses/projects/marketing';
import { CampaignsTarget } from 'api/models/responses/campaigns/campaign';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import ProjectsService from 'api/services/ProjectsService';

import { SelectComponent } from 'components/FormControls/Select';
import { Button } from 'components/FormControls/Button';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import { IUpdateTimeline } from 'pages/Projects/types';
import { ISelectOption } from 'types/ui/select';

interface ISelectCampaignProps extends IUpdateTimeline {
	projectKey: number;
	journey?: ProjectJourneys;
	hideChangeCampaignModal: () => void;
	campaignTarget: CampaignsTarget | null;
}

export const SelectCampaign: FC<ISelectCampaignProps> = ({
	journey,
	projectKey,
	campaignTarget,
	setIsUpdateTimeline,
	hideChangeCampaignModal,
}) => {
	const [campaigns, setCampaigns] = useState<ISelectCampaign[]>([]);
	const [selectedCampaignKey, setSelectedCampaignKey] = useState(0);
	const [errorMessage, setErrorMessage] = useState('');

	const campaignsSelectOptions: ISelectOption[] = campaigns.map(
		({ name, campaignKey }) => ({ label: name, value: campaignKey })
	);

	const handleSelectCampaign = (option: SingleValue<ISelectOption>) => {
		if (!option) return;

		setSelectedCampaignKey(option.value);
	};

	const handleSelectCampaignFocus = () => {
		setErrorMessage('');
	};

	const handleChangeCampaign = async () => {
		try {
			if (!campaignTarget) return;

			if (!selectedCampaignKey) {
				return setErrorMessage(requiredFieldMessage);
			}

			const updatedProjectFields: IPatchBody = {
				op: 'replace',
				value: selectedCampaignKey,
				path: changeCampaignsPaths[campaignTarget],
			};

			const projectUpdateBody: IPatchBody[] = [updatedProjectFields];

			await ProjectsService.partialProjectUpdate(projectKey, projectUpdateBody);

			hideChangeCampaignModal();
			setIsUpdateTimeline(true);
		} catch (error) {
			console.log(error);
		}
	};

	const getMarketingCampaigns = useCallback(async () => {
		try {
			if (!campaignTarget || !journey) return;

			const data = await ProjectsService.getMarketingCampaigns(
				campaignTarget,
				journey
			);

			if (!data) return;

			setCampaigns(data);
		} catch (error) {
			console.log(error);
		}
	}, [campaignTarget, journey]);

	useEffect(() => {
		void getMarketingCampaigns();
	}, [getMarketingCampaigns]);

	return (
		<div className="org-prospects-modal">
			<div className="org-select-form flex">
				<SelectComponent
					touched
					id="campaigns"
					label="Campaigns"
					error={errorMessage}
					value={selectedCampaignKey}
					onChange={handleSelectCampaign}
					selectPlaceholder="Select Campaign"
					selectOptions={campaignsSelectOptions}
					handleFocus={handleSelectCampaignFocus}
				/>
			</div>
			<Button
				value="Change Campaign"
				handleClick={handleChangeCampaign}
				className="org-modal-btn long btn-primary"
			/>
		</div>
	);
};
