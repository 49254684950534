import { IW9BillingInfoResponse } from 'api/models/responses/studioBilling/w9BillingInfo';
import { IBankAccountResponse } from 'api/models/responses/studioBilling/bankAccount';
import { IW9BillingInfoBody } from 'api/models/requests/studioBilling/w9BillingInfo';
import { IBankAccountBody } from 'api/models/requests/studioBilling/bankAccount';

import { studioBillingUrls } from 'constants/apiUrls/studioBillingUrls';

import { BaseService } from '../BaseService';

class StudioBillingService extends BaseService {
	async createBankACHInformation(
		bankACHBody: IBankAccountBody
	): Promise<IBankAccountResponse | null> {
		return this.post<IBankAccountResponse, IBankAccountBody>(
			studioBillingUrls.createBankACHInformation,
			bankACHBody
		);
	}

	async updateW9BillingInfo(
		w9BillingBody: IW9BillingInfoBody
	): Promise<IW9BillingInfoResponse | null> {
		return this.put<IW9BillingInfoResponse, IW9BillingInfoBody>(
			studioBillingUrls.updateW9BillingInfo,
			w9BillingBody
		);
	}
}

export default new StudioBillingService();
