import { IStudioPhotoSettings } from 'api/models/responses/studio/studioPhotoSettings';
import { IUpdateStudioPhotoSettings } from 'api/models/requests/studioPhotoSettings';

import { studioPhotoSettingsUrls } from 'constants/apiUrls/studioPhotoSettingsUrls';
import { multipartHeaders } from 'constants/images/multipartHeaders';

import { BaseService } from '../BaseService';

class StudioPhotoSettingsService extends BaseService {
	async getStudioPhotoSettings(): Promise<IStudioPhotoSettings | null> {
		return this.get(studioPhotoSettingsUrls.studioPhotoSettingsUrl);
	}

	async updateStudioPhotoSettings({
		imageFile,
		watermarkType,
		isCustomWatermarkDeleted,
	}: IUpdateStudioPhotoSettings): Promise<IStudioPhotoSettings | null> {
		const formData = new FormData();

		formData.set('Image', imageFile ?? '');
		formData.set(
			'IsCustomWatermarkDeleted',
			isCustomWatermarkDeleted.toString()
		);
		formData.set('WatermarkType', watermarkType);

		return this.put(studioPhotoSettingsUrls.studioPhotoSettingsUrl, formData, {
			...multipartHeaders,
		});
	}
}

export default new StudioPhotoSettingsService();
