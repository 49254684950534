import { ICampaignDetails } from 'api/models/responses/campaigns/campaignDetails';
import { ICampaign } from 'api/models/responses/campaigns/campaign';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { ICopyCampaignBody } from 'api/models/requests/campaigns';

import { campaignsUrls } from 'constants/apiUrls/campaigns';

import { BaseService } from '../BaseService';

class CampaignsService extends BaseService {
	async getCampaigns(): Promise<ICampaign[] | null> {
		return this.get(campaignsUrls.getCampaigns);
	}

	async getCampaign(campaignKey: number): Promise<ICampaignDetails | null> {
		return this.get(campaignsUrls.getCampaignUrl(campaignKey));
	}

	async updateCampaign(
		campaignKey: number,
		body: IPatchBody[]
	): Promise<ICampaignDetails | null> {
		return this.patch(campaignsUrls.getCampaignUrl(campaignKey), body);
	}

	async deleteCampaign(campaignKey: number): Promise<string | null> {
		return this.delete(campaignsUrls.getCampaignUrl(campaignKey));
	}

	async copyCampaign(
		campaignKey: number,
		body: ICopyCampaignBody
	): Promise<ICampaign | null> {
		return this.post(campaignsUrls.copyCampaign(campaignKey), body);
	}
}

export default new CampaignsService();
