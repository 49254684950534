import { FC, useEffect } from 'react';

import { ISubjectFormQuestionUI } from 'components/SubjectFormQuestions/types';
import { SubjectFormQuestions } from 'components/SubjectFormQuestions';
import { Loader } from 'components/Loader';

import { useSubjectFormQuestionCRUD } from 'hooks/subjectFormQuestions/useSubjectFormQuestionCRUD';
import { getSubjectFormQuestionsUI } from 'utils/subjectFormQuestions/getSubjectFormQuestionsUI';
import SubjectFormQuestionsService from 'api/services/SubjectFormQuestionsService';
import { projectsUrls } from 'constants/apiUrls/projectsUrls';
import { IHandleUpdateParams } from 'pages/Projects/types';

import { ToggleFormSectionContainerProps } from '..';

interface IFormSectionContainerProps extends ToggleFormSectionContainerProps {
	isPending: boolean;
	setIsPending: (isPending: boolean) => void;
	subjectFormQuestions: ISubjectFormQuestionUI[];
	setSubjectFormQuestions: (
		subjectFormQuestions: ISubjectFormQuestionUI[]
	) => void;
}

export const ProjectFormSection: FC<IFormSectionContainerProps> = ({
	isPending,
	projectKey,
	setIsPending,
	subjectFormQuestions,
	setSubjectFormQuestions,
}) => {
	const {
		isReordered,
		disabledFieldId,
		reorderSubjectFormQuestions,
		handleSaveSubjectFormQuestion,
		handleCreateSubjectFormQuestion,
		handleUpdateSubjectFromQuestion,
		handleDeleteSubjectFormQuestion,
		clearSubjectFormQuestionFieldError,
		handleChangeSubjectFormQuestionsSequence,
	} = useSubjectFormQuestionCRUD(subjectFormQuestions, setSubjectFormQuestions);

	const handleSave = () => {
		if (!projectKey) return;

		void handleSaveSubjectFormQuestion(
			projectsUrls.createProjectFormQuestion(projectKey)
		);
	};

	const handleUpdate = (updateParams: IHandleUpdateParams) => {
		void handleUpdateSubjectFromQuestion(
			projectsUrls.getProjectFormQuestionUrl,
			updateParams
		);
	};

	const handleDelete = (localKey: string, subjectFormQuestionKey?: number) => {
		void handleDeleteSubjectFormQuestion(
			projectsUrls.getProjectFormQuestionUrl,
			localKey,
			subjectFormQuestionKey
		);
	};

	useEffect(() => {
		if (subjectFormQuestions.length || !projectKey) return;

		setIsPending(true);

		SubjectFormQuestionsService.getProjectFormQuestions(
			projectsUrls.getProjectFormQuestions(projectKey)
		)
			.then((data) => {
				if (!data) return;

				const subjectFormQuestionsUI = getSubjectFormQuestionsUI(data);

				setSubjectFormQuestions(subjectFormQuestionsUI);
				setIsPending(false);
			})
			.catch((error) => {
				setIsPending(false);

				console.log(error);
			});
	}, [projectKey]);

	useEffect(() => {
		if (!isReordered || !projectKey) return;

		void reorderSubjectFormQuestions(
			projectsUrls.reorderProjectFormQuestions(projectKey)
		);
	}, [projectKey, isReordered]);

	if (isPending) return <Loader />;

	return (
		<SubjectFormQuestions
			hideBurger
			handleSave={handleSave}
			disableMultipleCreation
			handleUpdate={handleUpdate}
			deleteFormRow={handleDelete}
			disabledFieldId={disabledFieldId}
			subjectFormQuestions={subjectFormQuestions}
			handleCreate={handleCreateSubjectFormQuestion}
			clearError={clearSubjectFormQuestionFieldError}
			changeSequence={handleChangeSubjectFormQuestionsSequence}
		/>
	);
};
