import { useFormikContext } from 'formik';
import { FC } from 'react';

import {
	BroadcastEmail,
	IEmailSectionProps,
} from 'components/Broadcast/BroadcastEmail';

import { IBroadcastValues } from '../../..';

interface IBroadcastEmailContainerProps
	extends Pick<
		IEmailSectionProps,
		'replyTo' | 'editorRef' | 'emailFrom' | 'senderName' | 'initialEmailBody'
	> {
	disabledEdit: boolean;
}

export const BroadcastEmailContainer: FC<IBroadcastEmailContainerProps> = ({
	replyTo,
	emailFrom,
	editorRef,
	senderName,
	disabledEdit,
	initialEmailBody,
}) => {
	const { values, errors, touched, setErrors, handleChange } =
		useFormikContext<IBroadcastValues>();

	const clearEmailBodyError = () => {
		setErrors({
			...errors,
			emailMessage: '',
		});
	};

	return (
		<BroadcastEmail
			replyTo={replyTo}
			editorRef={editorRef}
			emailFrom={emailFrom}
			senderName={senderName}
			disabledEdit={disabledEdit}
			handleChange={handleChange}
			emailSubject={values.emailSubject}
			initialEmailBody={initialEmailBody}
			emailBodyError={errors.emailMessage}
			emailSubjectError={errors.emailSubject}
			clearEmailBodyError={clearEmailBodyError}
			emailSubjectTouched={touched.emailSubject}
		/>
	);
};
