import { IGetImageUploadSasUrl } from 'api/models/requests/albums/imageUploadSasUrl';
import { ICreateAlbumBody } from 'api/models/requests/albums/createAlbum';
import { IImageUpload } from 'api/models/responses/albums/imageUpload';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { IAlbum } from 'api/models/responses/albums/album';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { getBlobHeaders } from 'constants/images/getBlobHeaders';
import { albumsUrls } from 'constants/apiUrls/albumsUrls';

import { BaseService } from '../BaseService';

class AlbumService extends BaseService {
	async getAlbums(projectKey: number): Promise<IAlbum[] | null> {
		return this.get(albumsUrls.getAlbums(projectKey));
	}

	async updateAlbum(
		albumKey: string,
		projectKey: number,
		body: IPatchBody[]
	): Promise<IAlbum | null> {
		return this.patch(albumsUrls.getAlbumUrl(albumKey, projectKey), body);
	}

	async getAlbum(albumKey: string, projectKey: number): Promise<IAlbum | null> {
		return this.get(albumsUrls.getAlbumUrl(albumKey, projectKey));
	}

	async createAlbum(body: ICreateAlbumBody): Promise<IAlbum | null> {
		return this.post(albumsUrls.createAlbum, body);
	}

	async deleteAlbum(
		albumKey: string,
		projectKey: number
	): Promise<string | null> {
		return this.delete(albumsUrls.getAlbumUrl(albumKey, projectKey));
	}

	async getImageUploadSasUrl({
		albumGuid,
		studioKey,
		projectKey,
		hiResDownload,
		autoThumbnails,
		watermarkMethod,
		watermarkImageId,
		originalFileName,
		colorCorrectLevel,
		hasTransparentAlphaLayer,
	}: IGetImageUploadSasUrl): Promise<IImageUpload | null> {
		const formData = new FormData();

		formData.set('AlbumGuid', albumGuid);
		formData.set('EventKey', projectKey.toString());
		formData.set('WatermarkMethod', watermarkMethod);
		formData.set('WatermarkImageId', watermarkImageId);
		formData.set('OriginalFileName', originalFileName);
		formData.set('PhotographerKey', studioKey.toString());
		formData.set('HiResDownload', hiResDownload.toString());
		formData.set('AutoThumbnails', autoThumbnails.toString());
		formData.set('ColorCorrectLevel', colorCorrectLevel.toString());
		formData.set(
			'HasTransparentAlphaLaver',
			hasTransparentAlphaLayer.toString()
		);

		return this.post(albumsUrls.getImageUploadSasUrl, formData, {
			...multipartHeaders,
		});
	}

	async uploadImageToSasUrl(
		url: string,
		contentType: string,
		arrayBuffer: ArrayBuffer | string | null
	) {
		return this.put(url, arrayBuffer, { ...getBlobHeaders(contentType) });
	}
}

export default new AlbumService();
