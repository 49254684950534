interface IValidationParams {
	width: number;
	height: number;
	minWidth?: number;
	minHeight?: number;
	maxWidth?: number;
	maxHeight?: number;
}

export const validateFloatingImageDimensions = ({
	width,
	height,
	maxWidth,
	minWidth,
	minHeight,
	maxHeight,
}: IValidationParams) => {
	if (minWidth && minHeight && (width < minWidth || height < minHeight)) {
		return `Image dimensions should be at least ${minWidth}x${minHeight}.`;
	}

	if (maxWidth && maxHeight && (width > maxWidth || height > maxHeight)) {
		return `Image dimensions should be at most ${maxWidth}x${maxHeight}.`;
	}

	return '';
};
