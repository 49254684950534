import { useEffect, useState } from 'react';

export type ActionMenuId = string | number | null;

interface Result {
	actionMenuId: ActionMenuId;
	setActionMenuId: (id: ActionMenuId) => void;
}

export const useActionMenu = (): Result => {
	const [actionMenuId, setActionMenuId] = useState<ActionMenuId>(null);

	useEffect(() => {
		if (!actionMenuId) return;

		const hideActionMenu = () => {
			setActionMenuId(null);
		};

		document.addEventListener('click', hideActionMenu);

		return () => {
			return document.removeEventListener('click', hideActionMenu);
		};
	}, [actionMenuId]);

	return { actionMenuId, setActionMenuId };
};
