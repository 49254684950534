import { ActionMeta, SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { FC } from 'react';

import { priceListFulfillmentDescriptions } from 'constants/priceLists/priceListFulfillmentDescriptions';
import { timeDatePickerConfig } from 'constants/general/datePickerConfigs';
import {
	salesNotAvailableParam,
	salesNotAvailableSelectOption,
} from 'constants/priceLists/selectOptions/salesNotAvailableSelectOption';

import { SelectComponent } from 'components/FormControls/Select';
import { ToggleSection } from 'components/ToggleSection';

import { getPriceListSelectOptionKey } from 'utils/priceLists/getPriceListSelectOptionKey';
import { IPriceListSale } from 'api/models/responses/priceLists/priceListSale';
import { useToggleSection } from 'hooks/useToggleSection';
import { ISelectOption } from 'types/ui/select';

import { IEditSaleValues } from '../../';

interface ISalePriceListProps {
	disabled: boolean;
	allowedPriceLists: IPriceListSale[];
}

export const SalePriceList: FC<ISalePriceListProps> = ({
	disabled,
	allowedPriceLists,
}) => {
	const { values, touched, errors, setFieldValue } =
		useFormikContext<IEditSaleValues>();

	const { saleEndDate, saleStartDate } = values;

	const { isOpen, handleToggle } = useToggleSection(true);

	const handleChangeStartDate = (date: Date) => {
		void setFieldValue('saleStartDate', date);
	};

	const handleChangeEndDate = (date: Date) => {
		void setFieldValue('saleEndDate', date);
	};

	const handleChangePriceList = (
		option: SingleValue<ISelectOption<string>>,
		actionMeta?: ActionMeta<ISelectOption<string>>
	) => {
		if (!option || !actionMeta?.name) return;

		void setFieldValue(actionMeta.name, option.value);
	};

	const priceListSelectOptions: ISelectOption<string>[] = [
		salesNotAvailableSelectOption,
		...allowedPriceLists.map(
			({ priceListKey, priceListName, fulfillmentType }) => ({
				label: priceListName,
				value: getPriceListSelectOptionKey(priceListKey, fulfillmentType),
			})
		),
	];

	const searchedPriceList =
		(values.priceListKey === salesNotAvailableSelectOption.value &&
			salesNotAvailableParam) ||
		allowedPriceLists.find((priceList) => {
			const [key, fulfillment] = values.priceListKey.split('-');

			return (
				priceList.priceListKey === +key &&
				priceList.fulfillmentType === fulfillment
			);
		});

	const label = searchedPriceList
		? `${searchedPriceList.fulfillmentType} ${
				priceListFulfillmentDescriptions[searchedPriceList.fulfillmentType]
		  }`
		: '';

	const orderFulfillmentSelectOptions: ISelectOption<string>[] = [
		{
			label,
			value: values.priceListKey,
		},
	];

	const disabledSaleStartDate =
		!!saleStartDate &&
		!!timeDatePickerConfig.minDate &&
		saleStartDate < timeDatePickerConfig.minDate;

	const disabledSaleEndDate =
		!!saleEndDate &&
		!!timeDatePickerConfig.minDate &&
		saleEndDate < timeDatePickerConfig.minDate;

	const showSaleStartDateError =
		errors.saleStartDate && !!touched.saleStartDate;
	const showSaleEndDateError = errors.saleEndDate && !!touched.saleEndDate;

	return (
		<ToggleSection
			isOpen={isOpen}
			title="Sale Price List"
			handleToggle={handleToggle}
			className="presale-section"
		>
			<div className="presale-form-container">
				<div className="presale-form">
					<SelectComponent
						disabled
						label="Order Fulfillment:"
						selectPlaceholder="Studio"
						value={values.priceListKey}
						selectOptions={orderFulfillmentSelectOptions}
					/>
				</div>
				<div className="presale-form">
					<SelectComponent
						id="priceListKey"
						label="Price List"
						name="priceListKey"
						disabled={disabled}
						value={values.priceListKey}
						onChange={handleChangePriceList}
						selectOptions={priceListSelectOptions}
						selectPlaceholder="2023 Blue Mod Prepay"
					/>
				</div>
				<div className="presale-form presale-form-datepicker">
					<label htmlFor="saleStartDate" className="label-grid">
						Start Sale
					</label>
					<DatePicker
						{...timeDatePickerConfig}
						id="saleStartDate"
						selected={saleStartDate}
						onChange={handleChangeStartDate}
						disabled={disabled || disabledSaleStartDate}
					/>
					<button className="presale-form-btn">Central</button>
					{showSaleStartDateError && (
						<span className="datepicker-error">{errors.saleStartDate}</span>
					)}
				</div>
				<div className="presale-form presale-form-datepicker">
					<label htmlFor="saleEndDate" className="label-grid">
						End Sale
					</label>
					<DatePicker
						{...timeDatePickerConfig}
						id="saleEndDate"
						selected={saleEndDate}
						onChange={handleChangeEndDate}
						disabled={disabled || disabledSaleEndDate}
					/>
					<button className="presale-form-btn">Central</button>
					{showSaleEndDateError && (
						<span className="datepicker-error">{errors.saleEndDate}</span>
					)}
				</div>
			</div>
		</ToggleSection>
	);
};
