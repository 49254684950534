import { FC, SyntheticEvent, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { IErrorResponse } from 'api/models/responses/errors/errorResponse';
import OrganizationService from 'api/services/OrganizationService';

import { imagesBlobStorageUrl } from 'constants/images/blobUrls';
import {
	imageUploadTypes,
	imageUploadTypesErrorMessage,
} from 'constants/images/validation/imageUploadTypes';
import {
	BYTES_IN_ONE_MB,
	IMAGE_UPLOAD_LIMIT_BYTES,
} from 'constants/images/validation/imageUploadLimit';

import { ISearchResultImageProps } from '..';

export const SearchResultImage: FC<ISearchResultImageProps> = ({
	organizationKey,
	searchResultsImageRelativePath,
	setSearchResultsImageRelativePath,
}) => {
	const [errorMessage, setErrorMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const uploadImage = async (acceptedFiles: File[]) => {
		if (!organizationKey) return;

		const [file] = acceptedFiles;

		if (!acceptedFiles.length) {
			return setErrorMessage(imageUploadTypesErrorMessage);
		}

		if (file.size > IMAGE_UPLOAD_LIMIT_BYTES) {
			return setErrorMessage(
				`Image size more than available limit ${
					IMAGE_UPLOAD_LIMIT_BYTES * BYTES_IN_ONE_MB
				}mb`
			);
		}

		setIsLoading(true);

		try {
			const data = await OrganizationService.setSearchResultImage(
				organizationKey,
				file
			);

			if (!data) return;

			setSearchResultsImageRelativePath(data);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const message = errors.imageFile;

			if (!message) return;

			setErrorMessage((message as string[]).join(''));
		}

		setIsLoading(false);
	};

	const onDrop = (acceptedFiles: File[]) => {
		void uploadImage(acceptedFiles);
	};

	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop,
		multiple: false,
		accept: imageUploadTypes,
	});

	const deleteImage = async () => {
		try {
			if (!organizationKey) return;

			await OrganizationService.deleteSearchResultImage(organizationKey);

			setSearchResultsImageRelativePath('');
		} catch (error) {
			console.log(error);
		}
	};

	const handleDeleteImage = (e: SyntheticEvent<HTMLButtonElement>) => {
		e.stopPropagation();

		void deleteImage();
	};

	if (!searchResultsImageRelativePath) {
		return isLoading ? (
			<div className="loader-container qr">
				<div className="loader" />
			</div>
		) : (
			<>
				<span className="org-qr-mock" {...getRootProps()} onClick={open}>
					<input style={{ display: 'none' }} {...getInputProps()} />
				</span>
				{errorMessage && <span className="org-qr-error">{errorMessage}</span>}
			</>
		);
	}

	return (
		<>
			<img
				alt="search-img"
				className="org-qr-img"
				src={`${imagesBlobStorageUrl}${searchResultsImageRelativePath}`}
			/>
			<button className="org-cover-btn" onClick={handleDeleteImage}>
				<i className="org-cover-icon icon-delete" />
			</button>
		</>
	);
};
