import cn from 'classnames';
import { FC } from 'react';

import { BurgerBtn } from 'components/Controls/BurgerBtn';

interface IToggleArrowColumn {
	showContent: boolean;
	showBurgerBtn: boolean;
	handleMouseDown?: () => void;
	handleToggleShowContent: () => void;
}

export const ToggleArrowColumn: FC<IToggleArrowColumn> = ({
	showContent,
	showBurgerBtn,
	handleMouseDown,
	handleToggleShowContent,
}) => (
	<td className="price-option-td price-td-arrow">
		<div className="price-arrow-container">
			{showBurgerBtn && <BurgerBtn handleMouseDown={handleMouseDown} />}
			<i
				onClick={handleToggleShowContent}
				className={cn('price-table-arrow icon-arrow', {
					'is-active': showContent,
				})}
			/>
		</div>
	</td>
);
