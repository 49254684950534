import { createSelector } from 'reselect';

import { RootState } from 'store/store';

const breadcrumbsStateSelector = (state: RootState) => state.breadcrumbs;

export const breadcrumbsSelector = createSelector(
	breadcrumbsStateSelector,
	({ breadcrumbs }) => breadcrumbs
);
