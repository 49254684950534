import { ChangeEvent, FC } from 'react';

import { useToggleSection } from 'hooks/useToggleSection';
import { ToggleSection } from 'components/ToggleSection';

interface ITextMessageSectionProps {
	showError: boolean;
	textMessage: string;
	phoneNumber?: string;
	disabledEdit?: boolean;
	charactersLeft: number;
	textMessageError?: string;
	disableTextMessage?: boolean;
	handleChangeTextMessage: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const BroadcastTextMessage: FC<ITextMessageSectionProps> = ({
	showError,
	textMessage,
	phoneNumber,
	disabledEdit,
	charactersLeft,
	textMessageError,
	disableTextMessage,
	handleChangeTextMessage,
}) => {
	const { isOpen, handleToggle } = useToggleSection(true);

	return (
		<ToggleSection
			isOpen={isOpen}
			subtitle="(SMS)"
			title="Text Message"
			handleToggle={handleToggle}
			className="org-details-header"
		>
			<div className="broadcast-section broadcast-section-message">
				<div className="broadcast-message">
					<div className="broadcast-message-header">
						<span className="broadcast-message-title">From:</span>
						<span className="broadcast-message-subtitle">
							skoolhouse Photography
							<b>{phoneNumber}</b>
						</span>
					</div>
					<div className="broadcast-message-content">
						<span className="broadcast-content-title">
							Message: <b>{charactersLeft} Characters Left</b>
						</span>
						<textarea
							id="textMessage"
							value={textMessage}
							onChange={handleChangeTextMessage}
							disabled={disableTextMessage || disabledEdit}
							className="input-grid broadcast-content-input"
						/>
						{showError && (
							<span className="broadcast-message-error">
								{textMessageError}
							</span>
						)}
						<span className="broadcast-content-subtitle">
							“reply STOP to unsubscribe”
							<b>automatically added to the end of the message.</b>
						</span>
					</div>
				</div>
			</div>
		</ToggleSection>
	);
};
