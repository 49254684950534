import { ROUTES } from 'constants/ROUTES';

import { PriceListFulfillment } from './priceListFulfillment';

export const priceListRouteByFulfillment: Record<PriceListFulfillment, string> =
	{
		[PriceListFulfillment.NA]: '',
		[PriceListFulfillment.Imago]: ROUTES.PRICE_LISTS.PRICE_LISTS_IMAGO,
		[PriceListFulfillment.Studio]: ROUTES.PRICE_LISTS.PRICE_LISTS_STUDIO,
	};
