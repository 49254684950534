import { FC } from 'react';

import { IAlbumThumbnailUrls } from '../../types';

export const AlbumPreviews: FC<IAlbumThumbnailUrls> = ({
	firstSmallThumbnailUrl,
	secondSmallThumbnailUrl,
	thirdSmallThumbnailUrl,
}) => (
	<div className="project-album">
		{firstSmallThumbnailUrl && <img src={firstSmallThumbnailUrl} alt="" />}
		{secondSmallThumbnailUrl && <img src={secondSmallThumbnailUrl} alt="" />}
		{thirdSmallThumbnailUrl && <img src={thirdSmallThumbnailUrl} alt="" />}
	</div>
);
