import { FC, SyntheticEvent } from 'react';
import { useFormikContext } from 'formik';

import { getMaxLengthMessage } from 'utils/validations/general/getMaxLengthMessage';
import { inHourTimeline } from 'utils/dates/inHourTimeline';

import { maxTextMessageLength } from 'constants/broadcasts/validation';
import {
	endDayHour,
	startDayHour,
} from 'constants/general/dates/dayHoursLimit';

import { BroadcastTextMessage } from 'components/Broadcast/BroadcastTextMessage';

import { IGeneralBroadcastProps, ISendBroadcastValues } from '../../types';

export const TextMessageSection: FC<IGeneralBroadcastProps> = ({
	broadcastInfo,
}) => {
	const { values, touched, errors, setFieldValue, setErrors } =
		useFormikContext<ISendBroadcastValues>();

	const handleChangeTextMessage = (e: SyntheticEvent<HTMLTextAreaElement>) => {
		const { value } = e.currentTarget;

		if (value.length > maxTextMessageLength) {
			return setErrors({
				...errors,
				textMessage: getMaxLengthMessage(maxTextMessageLength),
			});
		}

		void setFieldValue('textMessage', value);
	};

	const showError = !!(touched.textMessage && errors.textMessage);

	const charactersLeft = maxTextMessageLength - values.textMessage.length;

	const disableTextMessage =
		!values.sendByPhone ||
		!broadcastInfo?.hasVerifiedPhoneNumber ||
		!inHourTimeline(startDayHour, endDayHour);

	return (
		<BroadcastTextMessage
			showError={showError}
			charactersLeft={charactersLeft}
			textMessage={values.textMessage}
			textMessageError={errors.textMessage}
			disableTextMessage={disableTextMessage}
			phoneNumber={broadcastInfo?.phoneNumber}
			handleChangeTextMessage={handleChangeTextMessage}
		/>
	);
};
