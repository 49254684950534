import { ICreatePoseOption } from 'api/models/requests/priceLists/studio/priceListOptions/createPoseOption';
import {
	IPoseOption,
	PoseOptionTypes,
} from 'api/models/responses/priceLists/studio/priceListOptions/poseOption';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { BaseService } from 'api/services/BaseService';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';

class PoseOptionsService extends BaseService {
	async createPoseOption(
		priceListKey: number,
		{
			name,
			sequence,
			retailPrice,
			referenceCode,
			poseOptionType,
			previewImageFile,
		}: ICreatePoseOption
	): Promise<IPoseOption | null> {
		const formData = new FormData();

		formData.set('Name', name);
		formData.set('ReferenceCode', referenceCode);
		formData.set('Sequence', sequence.toString());
		formData.set('PoseOptionType', poseOptionType);
		formData.set('RetailPrice', retailPrice.toString());
		formData.set('PreviewImage', previewImageFile ?? '');

		return this.post(
			priceListsUrls.studioFulfilled.poseOptions.createPoseOption(priceListKey),
			formData
		);
	}

	async updatePoseOption(
		poseOptionKey: number,
		body: IPatchBody[]
	): Promise<IPoseOption | null> {
		return this.patch(
			priceListsUrls.studioFulfilled.poseOptions.updatePoseOption(
				poseOptionKey
			),
			body
		);
	}

	async deletePoseOptions(
		priceListKey: number,
		poseOptionType: PoseOptionTypes
	): Promise<string | null> {
		return this.delete(
			priceListsUrls.studioFulfilled.poseOptions.deletePoseOptions(
				priceListKey,
				poseOptionType
			)
		);
	}

	async deletePoseOption(
		priceListKey: number,
		poseOptionKey: number
	): Promise<string | null> {
		return this.delete(
			priceListsUrls.studioFulfilled.poseOptions.deletePoseOption(
				priceListKey,
				poseOptionKey
			)
		);
	}

	async reorderPoseOptions(
		priceListKey: number,
		body: Record<number, number>
	): Promise<string | null> {
		return this.put(
			priceListsUrls.studioFulfilled.poseOptions.reorderPoseOptions(
				priceListKey
			),
			body
		);
	}

	async uploadPoseOptionPreviewImage(
		poseOptionKey: number,
		imageFile: File
	): Promise<string | null> {
		const formData = new FormData();

		formData.append('imageFile', imageFile);

		return this.post(
			priceListsUrls.studioFulfilled.poseOptions.poseOptionPreviewImageUrl(
				poseOptionKey
			),
			formData,
			{
				...multipartHeaders,
			}
		);
	}

	async deletePoseOptionPreviewImage(
		poseOptionKey: number
	): Promise<string | null> {
		return this.delete(
			priceListsUrls.studioFulfilled.poseOptions.poseOptionPreviewImageUrl(
				poseOptionKey
			)
		);
	}
}

export default new PoseOptionsService();
