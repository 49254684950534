import { FC } from 'react';

interface IProps {
	stepCount: string;
	title: string;
}

export const StepHeader: FC<IProps> = ({ title, stepCount }) => (
	<>
		<span className="steps">{stepCount}</span>
		<h1 className="heading">{title}</h1>
	</>
);
