import cn from 'classnames';
import { FC } from 'react';

interface IColumnLabelArrowProps {
	label: string;
	isActive: boolean;
	showArrowIcon: boolean;
	handleSort: () => void;
}

export const ColumnLabel: FC<IColumnLabelArrowProps> = ({
	label,
	isActive,
	handleSort,
	showArrowIcon,
}) => (
	<div className="tr-arrow" onClick={handleSort} title={label}>
		{showArrowIcon && (
			<i
				className={cn('tr-arrow-icon', {
					'is-active': isActive,
					'icon-arrow': showArrowIcon,
				})}
			/>
		)}
		<span>{label}</span>
	</div>
);
