import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IBreadcrumbs, IBreadcrumbsState } from './types';

const initialState: IBreadcrumbsState = {
	breadcrumbs: [],
};

const breadcrumbsSlice = createSlice({
	name: 'breadcrumbs',
	initialState,
	reducers: {
		setBreadcrumbs: (state, { payload }: PayloadAction<IBreadcrumbs[]>) => {
			state.breadcrumbs = payload;
		},
	},
});

export const breadcrumbsReducer = breadcrumbsSlice.reducer;
export const { setBreadcrumbs } = breadcrumbsSlice.actions;
