import { DragEvent, FC, SyntheticEvent, useState } from 'react';

import { ReferenceCodeColumn } from 'pages/PriceLists/components/ReferenceCodeColumn';
import { DeleteControlColumn } from 'pages/PriceLists/components/DeleteControlColumn';
import { ToggleArrowColumn } from 'pages/PriceLists/components/ToggleArrowColumn';
import { RetailValueColumn } from 'pages/PriceLists/components/RetailValueColumn';
import { NameColumn } from 'pages/PriceLists/components/NameColumn';
import {
	IOwnPackageOptionUI,
	IUpdateOwnPackageOption,
	IDeleteOwnPackageOption,
	IClearOwnPackageOptionError,
	IUploadOwnPackageOptionPreview,
} from 'pages/PriceLists/types/studio/priceListOptions/ownPackageOptions';

import { validatePoints } from 'utils/validations/priceLists/studio/validatePoints';
import { validateImageFile } from 'utils/validations/general/validateImageFile';

import { IChangeSequencePayload, useDragAndDrop } from 'hooks/useDragAndDrop';
import { LazyInput } from 'components/FormControls/LazyInput';

import { OwnPackageOptionContent } from './OwnPackageOptionContent';

export interface IOwnPackageOptionProps {
	previewImageFile: File | null;
	ownPackageOptionUI: IOwnPackageOptionUI;
	deleteImage: (ownPackageKey?: number) => Promise<void>;
	uploadImage: (payload: IUploadOwnPackageOptionPreview) => Promise<void>;
	changeOwnPackageOptionSequence: (payload: IChangeSequencePayload) => void;
	clearOwnPackageOptionError: (params: IClearOwnPackageOptionError) => void;
	deleteOwnPackageOption: (params: IDeleteOwnPackageOption) => Promise<void>;
	updateOwnPackageOption: (params: IUpdateOwnPackageOption) => Promise<void>;
}

export const OwnPackageOption: FC<IOwnPackageOptionProps> = ({
	uploadImage,
	deleteImage,
	previewImageFile,
	ownPackageOptionUI,
	deleteOwnPackageOption,
	updateOwnPackageOption,
	clearOwnPackageOptionError,
	changeOwnPackageOptionSequence,
}) => {
	const [showContent, setShowContent] = useState(false);
	const [isImagePending, setIsImagePending] = useState(false);

	const {
		name,
		price,
		errors,
		points,
		sequence,
		description,
		referenceCode,
		previewImageUrl,
		priceListStudioFulfilledOwnPackageOptionKey: ownPackageOptionKey,
	} = ownPackageOptionUI;

	const {
		name: nameError,
		price: priceError,
		points: pointsError,
		preview: previewError,
		description: descriptionError,
		referenceCode: referenceCodeError,
	} = errors;

	const {
		draggable,
		handleDrop,
		handleDragEnd,
		handleDragOver,
		handleMouseDown,
		handleDragStart,
	} = useDragAndDrop([], changeOwnPackageOptionSequence);

	const ownPackageOptionKeyString = ownPackageOptionKey?.toString() || '';

	const onDragStart = (e: DragEvent<HTMLTableSectionElement>) => {
		handleDragStart(e, ownPackageOptionKeyString);
	};

	const updatePriceValue = (value: number, validationMessage: string) => {
		void updateOwnPackageOption({
			value,
			sequence,
			validationMessage,
			fieldKey: 'price',
			ownPackageOptionKey,
		});
	};

	const clearPriceError = () => {
		if (!priceError) return;

		clearOwnPackageOptionError({ fieldKey: 'price', sequence });
	};

	const handleToggleShowContent = () => {
		setShowContent(!showContent);
	};

	const updateName = (value: string, validationMessage: string) => {
		void updateOwnPackageOption({
			value,
			sequence,
			fieldKey: 'name',
			validationMessage,
			ownPackageOptionKey,
		});
	};

	const clearNameError = () => {
		if (!nameError) return;

		clearOwnPackageOptionError({ fieldKey: 'name', sequence });
	};

	const updateReferenceCode = (value: string, validationMessage: string) => {
		void updateOwnPackageOption({
			value,
			sequence,
			validationMessage,
			ownPackageOptionKey,
			fieldKey: 'referenceCode',
		});
	};

	const clearReferenceCodeError = () => {
		if (!referenceCodeError) return;

		clearOwnPackageOptionError({ fieldKey: 'referenceCode', sequence });
	};

	const handleChangePointValue = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		const valueNumber = +value;

		const validationMessage = validatePoints(valueNumber);

		void updateOwnPackageOption({
			sequence,
			validationMessage,
			fieldKey: 'points',
			value: valueNumber,
			ownPackageOptionKey,
		});
	};

	const clearPointValueError = () => {
		if (!pointsError) return;

		clearOwnPackageOptionError({ fieldKey: 'points', sequence });
	};

	const updateDescription = (value: string, validationMessage: string) => {
		void updateOwnPackageOption({
			value,
			sequence,
			validationMessage,
			ownPackageOptionKey,
			fieldKey: 'description',
		});
	};

	const clearDescriptionError = () => {
		if (!descriptionError) return;

		clearOwnPackageOptionError({ fieldKey: 'description', sequence });
	};

	const handleDeleteOwnPackageOption = () => {
		void deleteOwnPackageOption({ sequence, ownPackageOptionKey });
	};

	const handleUploadImage = async (imageFile: File) => {
		setIsImagePending(true);

		const validationMessage = validateImageFile({
			type: imageFile.type,
			validateJpg: true,
			validatePng: true,
		});

		await uploadImage({
			sequence,
			imageFile,
			validationMessage,
			ownPackageOptionKey,
		});

		setIsImagePending(false);
	};

	const handleDeleteImage = async () => {
		setIsImagePending(true);

		await deleteImage(ownPackageOptionKey);

		setIsImagePending(false);
	};

	const unsavedOwnPackageOption = !ownPackageOptionKey;
	const showBurgerBtn = !showContent && !unsavedOwnPackageOption;
	const showContentBurgerBtn = showContent && !unsavedOwnPackageOption;

	const uniqPreviewImageFile = unsavedOwnPackageOption
		? previewImageFile
		: null;

	return (
		<tbody
			onDrop={handleDrop}
			draggable={draggable}
			onDragStart={onDragStart}
			onDragEnd={handleDragEnd}
			onDragOver={handleDragOver}
			data-drag-sequence={sequence}
			className="price-option-tbody"
			id={ownPackageOptionKeyString}
		>
			<tr>
				<ToggleArrowColumn
					showContent={showContent}
					showBurgerBtn={showBurgerBtn}
					handleMouseDown={handleMouseDown}
					handleToggleShowContent={handleToggleShowContent}
				/>
				<NameColumn
					name={name}
					nameError={nameError}
					updateName={updateName}
					clearError={clearNameError}
				/>
				<ReferenceCodeColumn
					referenceCode={referenceCode}
					clearError={clearReferenceCodeError}
					referenceCodeError={referenceCodeError}
					updateReferenceCode={updateReferenceCode}
				/>
				<td className="price-option-td">
					<LazyInput
						error={pointsError}
						placeholder="Points"
						className="input exp-wrapper"
						defaultValue={points.toString()}
						clearError={clearPointValueError}
						handleChange={handleChangePointValue}
					/>
				</td>
				<RetailValueColumn
					retailValue={price}
					clearError={clearPriceError}
					retailValueError={priceError}
					updateRetailValue={updatePriceValue}
				/>
				<DeleteControlColumn handleDelete={handleDeleteOwnPackageOption} />
			</tr>
			{showContent && (
				<OwnPackageOptionContent
					description={description}
					previewError={previewError}
					isImagePending={isImagePending}
					handleMouseDown={handleMouseDown}
					previewImageUrl={previewImageUrl}
					descriptionError={descriptionError}
					showBurgerBtn={showContentBurgerBtn}
					handleUploadImage={handleUploadImage}
					updateDescription={updateDescription}
					handleDeleteImage={handleDeleteImage}
					previewImageFile={uniqPreviewImageFile}
					clearDescriptionError={clearDescriptionError}
				/>
			)}
		</tbody>
	);
};
