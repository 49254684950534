import { FC } from 'react';

interface IPresetDetailsHeaderProps {
	presetName: string;
	presetInfo: string;
}

export const PresetDetailsHeader: FC<IPresetDetailsHeaderProps> = ({
	presetName,
	presetInfo,
}) => (
	<>
		<h2 className="preset-title">{presetName}</h2>
		<p className="preset-info">
			{presetInfo}
			<a
				target="_blank"
				rel="noreferrer"
				className="project-journey-link"
				href="https://get.theimago.io/journeys/"
			>
				learn more
			</a>
		</p>
	</>
);
