import { FC } from 'react';

interface ICreateProjectHeaderProps {
	title: string;
	step?: string;
}

export const StepSubjectHeader: FC<ICreateProjectHeaderProps> = ({
	step,
	title,
}) => (
	<div className="project-blank-header">
		{step && <h4 className="project-steps">{step}</h4>}
		<h1 className="project-blank-title">{title}</h1>
	</div>
);
