import { InteractionType } from '@azure/msal-browser';
import { BrowserRouter } from 'react-router-dom';
import { FC, useEffect } from 'react';
import {
	useMsal,
	useIsAuthenticated,
	useMsalAuthentication,
} from '@azure/msal-react';

import { getSubscriptionAsync } from 'store/subscriptions/actions';
import {
	getStudioAsync,
	studioKeySelector,
	getStudioMarketingAsync,
	getStudioPhotoSettingsAsync,
} from 'store/studio';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { FINAL_REGISTRATION_STEP } from 'constants/auth/registrationSteps';
import { AxiosInterceptors } from 'AxiosInterceptors';
import { loginRequest } from 'config/authConfig';
import { Router } from 'router';

export const App: FC = () => {
	const studioKey = useAppSelector(studioKeySelector);
	const isAuthenticated = useIsAuthenticated();
	const { accounts } = useMsal();

	useMsalAuthentication(InteractionType.Redirect, loginRequest);

	const dispatch = useAppDispatch();

	useEffect(() => {
		void dispatch(getSubscriptionAsync());
		void dispatch(getStudioMarketingAsync());
		void dispatch(getStudioPhotoSettingsAsync());
	}, []);

	useEffect(() => {
		if (!isAuthenticated || !accounts.length) return;

		const registrationStepCompleted =
			accounts[0].idTokenClaims?.extension_RegistrationStepCompleted;

		if (registrationStepCompleted !== FINAL_REGISTRATION_STEP) return;

		const extensionStudioKey = accounts[0].idTokenClaims?.extension_StudioKey;

		if (extensionStudioKey === studioKey) return;

		void dispatch(getStudioAsync());
	}, [isAuthenticated, accounts, studioKey, dispatch]);

	return (
		<div className="app">
			<BrowserRouter>
				<AxiosInterceptors>{isAuthenticated && <Router />}</AxiosInterceptors>
			</BrowserRouter>
		</div>
	);
};
