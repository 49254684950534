import { GiftAndAccessoriesProductCategoryTypes } from 'api/models/responses/catalogProducts/giftAndAccessoriesCatalogProduct';

export const giftAndAccessoriesCatalogProductTypesUI: Record<
	GiftAndAccessoriesProductCategoryTypes,
	string
> = {
	[GiftAndAccessoriesProductCategoryTypes.BagTags]: 'Bag Tags',
	[GiftAndAccessoriesProductCategoryTypes.Calendars]: 'Calendars',
	[GiftAndAccessoriesProductCategoryTypes.Drinkware]: 'Drinkware',
	[GiftAndAccessoriesProductCategoryTypes.GraphicPrints]: 'Graphic Prints',
	[GiftAndAccessoriesProductCategoryTypes.Jewelry]: 'Jewelry',
	[GiftAndAccessoriesProductCategoryTypes.Keychains]: 'Keychains',
	[GiftAndAccessoriesProductCategoryTypes.Magnets]: 'Magnets',
	[GiftAndAccessoriesProductCategoryTypes.Notepads]: 'Notepads',
	[GiftAndAccessoriesProductCategoryTypes.Office]: 'Office',
	[GiftAndAccessoriesProductCategoryTypes.Ornaments]: 'Ornaments',
	[GiftAndAccessoriesProductCategoryTypes.TraderCards]: 'Trader Cards',
};
