import { IOwnPackageOptionErrors } from 'pages/PriceLists/types/studio/priceListOptions/ownPackageOptions';

export const initialOwnPackageOptionErrors: IOwnPackageOptionErrors = {
	name: '',
	price: '',
	points: '',
	preview: '',
	description: '',
	referenceCode: '',
};
