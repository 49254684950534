import { useEffect, useState } from 'react';

import { PriceListsSortLabels } from 'constants/priceLists/priceListsSortLabels';
import { SKIP, TAKE } from 'constants/general/generalGetDataParams';

import { ISearchPriceLists } from 'api/models/requests/priceLists/searchPriceLists';
import { IPriceList } from 'api/models/responses/priceLists/priceList';

import { priceListsSelector, searchPriceListsAsync } from 'store/priceLists';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useSortParams } from 'hooks/useSortParams';

import { ISearchResult } from 'types/searchParams';

interface ISearchParams {
	isActive: boolean;
}

export const useSearchPriceLists = ({
	isActive,
}: ISearchParams): ISearchResult<PriceListsSortLabels, IPriceList[]> => {
	const [searchValue, setSearchValue] = useState('');

	const priceLists = useAppSelector(priceListsSelector);

	const { orderBy, setSortParams, orderDirection } =
		useSortParams<PriceListsSortLabels>(PriceListsSortLabels.Name);

	const dispatch = useAppDispatch();

	const handleSearch = (projectName: string) => {
		setSearchValue(projectName);
	};

	const setPaginatedPriceLists = () => {
		const isPaginationLimit =
			priceLists.totalCount === priceLists.results.length &&
			priceLists.totalCount > 0;

		if (isPaginationLimit) return;

		const searchParams: ISearchPriceLists = {
			orderBy,
			isActive,
			take: TAKE,
			orderDirection,
			searchValue: searchValue || null,
			skip: priceLists.results.length,
		};

		void dispatch(searchPriceListsAsync(searchParams, true));
	};

	useEffect(() => {
		const searchParams: ISearchPriceLists = {
			orderBy,
			isActive,
			take: TAKE,
			skip: SKIP,
			orderDirection,
			searchValue: searchValue || null,
		};

		void dispatch(searchPriceListsAsync(searchParams));
	}, [orderBy, isActive, searchValue, orderDirection]);

	return {
		orderBy,
		handleSearch,
		setSortParams,
		orderDirection,
		isPending: false,
		results: priceLists.results,
		setPaginatedEntity: setPaginatedPriceLists,
	};
};
