export enum ImageTypes {
	Group = 'Group',
	Individual = 'Individual',
}

export interface IPhoto {
	id: string;
	fileName: string;
	albumKey: string;
	orders: string[];
	cloudImageId: string;
	imageType: ImageTypes;
	hiResDownload: boolean;
	password: string | null;
	countOfDownloads: number;
	cellNumber: string | null;
	emailAddress: string | null;
	participantName: string | null;
	additionalPhoneNumbers: string[];
	orderedByUsersWithEmails: string[];
	additionalEmailAddresses: string[];
	orderedByUsersWithPhoneNumbers: string[];
}
