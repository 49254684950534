import { Link } from 'react-router-dom';
import cn from 'classnames';
import { FC } from 'react';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { breadcrumbsSelector } from 'store/breadcrumbs';

interface IHeaderMobileProps {
	showSidebar: () => void;
}

export const HeaderMobile: FC<IHeaderMobileProps> = ({ showSidebar }) => {
	const breadcrumbs = useAppSelector(breadcrumbsSelector);

	const [parentBreadcrumb, ...restBreadcrumbs] = breadcrumbs;

	const SubBreadcrumbsList = restBreadcrumbs.map(
		({ title, path, isActive }) => (
			<h4
				key={path}
				className={cn('breadcrumb-mob breadcrumb-list-item', {
					'header-active': isActive,
				})}
			>
				<Link to={path} title={title} className="breadcrumb-item">
					{title}
				</Link>
			</h4>
		)
	);

	return (
		<>
			<div className="header-mob-title">
				<button className="header-burger" onClick={showSidebar}></button>
				<h1
					className={cn('header-title', {
						'header-active': parentBreadcrumb?.isActive,
					})}
				>
					{parentBreadcrumb?.title}
				</h1>
			</div>
			{!!restBreadcrumbs.length && (
				<div className="breadcrumb-list-mobile">{SubBreadcrumbsList}</div>
			)}
		</>
	);
};
