import { FC } from 'react';

import { BurgerBtn } from 'components/Controls/BurgerBtn';

interface IBurgerColumnProps {
	colSpan?: number;
	showBurger: boolean;
	handleMouseDown?: () => void;
}

export const BurgerColumn: FC<IBurgerColumnProps> = ({
	colSpan,
	showBurger,
	handleMouseDown,
}) => (
	<td className="price-option-td" colSpan={colSpan}>
		{showBurger && <BurgerBtn handleMouseDown={handleMouseDown} />}
	</td>
);
