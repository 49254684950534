import { FC } from 'react';

import { normalizeDigit } from 'utils/ui/normalizeDigit';

interface ISimpleColumnProps {
	value: number;
}

export const SimpleColumn: FC<ISimpleColumnProps> = ({ value }) => (
	<td className="price-option-td">
		<span>
			{normalizeDigit({ value, isPrice: true, minimumFractionDigits: 2 })}
		</span>
	</td>
);
