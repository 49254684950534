import { object, string } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	priceListNameRegEx,
	priceListNameMinLength,
	priceListNameMaxLength,
	priceListNameErrorMessage,
	priceListNameMinLengthError,
	priceListNameMaxLengthError,
} from 'constants/priceLists/validation/priceListNameRegEx';

export const createPriceListValidationScheme = object().shape({
	name: string()
		.required(requiredFieldMessage)
		.matches(priceListNameRegEx, priceListNameErrorMessage)
		.min(priceListNameMinLength, priceListNameMinLengthError)
		.max(priceListNameMaxLength, priceListNameMaxLengthError),
	fulfillment: string().required(requiredFieldMessage),
});
