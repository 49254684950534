import { FC, ReactNode } from 'react';

interface CampaignsFieldsListProps {
	children: ReactNode[];
}

export const CampaignsFieldsList: FC<CampaignsFieldsListProps> = ({
	children,
}) => (
	<div className="project-section-container">
		<div className="project-section-form">{children}</div>
	</div>
);
