import {
	maxOrderConfirmationMessageLength,
	orderConfirmationMessageErrorMessage,
} from 'constants/projects/validation/generalProjectFields';

export const validateOrderNotificationEmail = (
	orderNotificationEmail: string
): string => {
	if (orderNotificationEmail.length > maxOrderConfirmationMessageLength) {
		return orderConfirmationMessageErrorMessage;
	}

	return '';
};
