import { FC } from 'react';

import { Article } from 'components/CardContent/Article';
import { Card } from 'components/Card';

export const DidYouKnow: FC = () => (
	<Card title="Did You Know..." subtitle="">
		<Article />
	</Card>
);
