import { FC, useEffect } from 'react';

import { ISubjectFormQuestionUI } from 'components/SubjectFormQuestions/types';
import { SubjectFormQuestions } from 'components/SubjectFormQuestions';

import { useSubjectFormQuestionCRUD } from 'hooks/subjectFormQuestions/useSubjectFormQuestionCRUD';
import { projectsUrls } from 'constants/apiUrls/projectsUrls';
import { presetsUrls } from 'constants/apiUrls/presetsUrls';
import { IHandleUpdateParams } from 'pages/Projects/types';

interface IJourneyPresetFormQuestionsProps {
	presetKey: number;
	subjectFormQuestions: ISubjectFormQuestionUI[];
	setSubjectFormQuestions: (params: ISubjectFormQuestionUI[]) => void;
}

export const JourneyPresetFormQuestions: FC<
	IJourneyPresetFormQuestionsProps
> = ({ presetKey, subjectFormQuestions, setSubjectFormQuestions }) => {
	const {
		isReordered,
		disabledFieldId,
		reorderSubjectFormQuestions,
		handleSaveSubjectFormQuestion,
		handleCreateSubjectFormQuestion,
		handleUpdateSubjectFromQuestion,
		handleDeleteSubjectFormQuestion,
		clearSubjectFormQuestionFieldError,
		handleChangeSubjectFormQuestionsSequence,
	} = useSubjectFormQuestionCRUD(subjectFormQuestions, setSubjectFormQuestions);

	const handleSave = () => {
		void handleSaveSubjectFormQuestion(
			presetsUrls.createPresetsFormQuestion(presetKey)
		);
	};

	const handleUpdate = (updateParams: IHandleUpdateParams) => {
		void handleUpdateSubjectFromQuestion(
			presetsUrls.getPresetsFormQuestionUrl,
			updateParams
		);
	};

	const handleDelete = (localKey: string, subjectFormQuestionKey?: number) => {
		void handleDeleteSubjectFormQuestion(
			presetsUrls.getPresetsFormQuestionUrl,
			localKey,
			subjectFormQuestionKey
		);
	};

	useEffect(() => {
		if (!isReordered) return;

		void reorderSubjectFormQuestions(
			projectsUrls.reorderProjectFormQuestions(presetKey)
		);
	}, [isReordered]);

	return (
		<SubjectFormQuestions
			hideBurger
			title="Form"
			disableMultipleCreation
			handleSave={handleSave}
			handleUpdate={handleUpdate}
			deleteFormRow={handleDelete}
			disabledFieldId={disabledFieldId}
			subjectFormQuestions={subjectFormQuestions}
			handleCreate={handleCreateSubjectFormQuestion}
			clearError={clearSubjectFormQuestionFieldError}
			changeSequence={handleChangeSubjectFormQuestionsSequence}
		/>
	);
};
