import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';

import { ICreateProspects } from 'api/models/responses/projects/createProspects';
import { CampaignsTarget } from 'api/models/responses/campaigns/campaign';
import ProjectsService from 'api/services/ProjectsService';
import {
	ICampaignDetails,
	ICampaignStatistic,
} from 'api/models/responses/projects/marketing';

import { Agreement } from 'components/Modal/components/Agreement';
import { UploadButton } from 'components/UploadButton';
import { Modal, ModalRef } from 'components/Modal';
import { Loader } from 'components/Loader';
import { Table } from 'components/Table';

import { SCV_FORMAT } from 'constants/general/csvFormat';
import { IUpdateTimeline } from 'pages/Projects/types';

import { AddProspectsConfirmation } from './components/AddProspectsConfirmation';
import { AddProspectsReport } from './components/AddProspectsReport';
import { ProjectMessage } from './components/ProjectMessage';
import { SelectCampaign } from './components/SelectCampaign';
import { AddProspects } from './components/AddProspects';
import { CampaignEdit } from './components/CampaignEdit';
import { Campaign } from './components/Campaign';
import { IMarketingContainerProps } from '..';

export interface IMarketingProps
	extends IUpdateTimeline,
		Omit<IMarketingContainerProps, 'hasAnySentBroadcast'> {
	isPending: boolean;
	campaignDetails: ICampaignDetails[];
	campaignStatistics: ICampaignStatistic[];
	setIsStatisticsPending: (isPending: boolean) => void;
	setIsCampaignDetailsPending: (isPending: boolean) => void;
	setCampaignDetails: (campaignDetails: ICampaignDetails[]) => void;
	setCampaignStatistics: (campaignStatistics: ICampaignStatistic[]) => void;
}

export const Marketing: FC<IMarketingProps> = ({
	journey,
	isPending,
	projectKey,
	campaignDetails,
	accessCodeMessage,
	smsSpecialMessage,
	setProjectDetails,
	campaignStatistics,
	setCampaignDetails,
	isMarketingEnabled,
	emailSpecialMessage,
	setIsUpdateTimeline,
	setCampaignStatistics,
	setIsStatisticsPending,
	setIsCampaignDetailsPending,
}) => {
	const addProspectsConfirmationModalRef = useRef<ModalRef>(null);
	const addProspectsReportModalRef = useRef<ModalRef>(null);
	const changeCampaignModalRef = useRef<ModalRef>(null);
	const addProspectsModalRef = useRef<ModalRef>(null);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const fileErrorModalRef = useRef<ModalRef>(null);

	const [uploadedFile, setUploadedFile] = useState<File | null>(null);
	const [confirmationData, setConfirmationData] =
		useState<ICreateProspects | null>(null);

	const [changeableCampaignTarget, setChangeableCampaignTarget] =
		useState<CampaignsTarget | null>(null);

	const columns: Column<ICampaignStatistic>[] = [
		{
			Header: <span className="thead-empty">_</span>,
			accessor: 'campaignTarget',
			Cell: ({ row }: CellProps<ICampaignStatistic>) => {
				const { campaignKey, campaignTarget } = row.original;

				return (
					// TODO: add correct route to campaign
					<Link
						to={`${campaignKey}`}
						title={`${campaignTarget} Campaign`}
					>{`${campaignTarget} Campaign`}</Link>
				);
			},
		},
		{
			Header: 'Campaign',
			accessor: 'campaignName',
		},
		{
			Header: '# of Broadcasts',
			accessor: 'broadcasts',
		},
		{
			Header: 'People',
			accessor: 'people',
		},
		{
			Header: 'Sends',
			accessor: 'sends',
		},
		{
			Header: 'Opens',
			accessor: 'opens',
		},
		{
			Header: 'Clicks',
			accessor: 'clicks',
		},
		{
			Header: 'Converted',
			accessor: 'converted',
		},
		{
			Header: 'Unsubscribe',
			accessor: 'unsubscribes',
		},
	];

	const showChangeCampaignModal = (campaignTarget: CampaignsTarget) => {
		setChangeableCampaignTarget(campaignTarget);

		changeCampaignModalRef?.current?.open();
	};

	const hideChangeCampaignModal = () => {
		changeCampaignModalRef?.current?.close();
	};

	const hideAddProspectsModal = () => {
		addProspectsModalRef?.current?.close();
	};

	const showAddProspectsConfirmationModal = () => {
		addProspectsConfirmationModalRef?.current?.open();
	};

	const hideAddProspectsConfirmationModal = () => {
		addProspectsConfirmationModalRef?.current?.close();
	};

	const showAddProspectsReportModal = () => {
		addProspectsReportModalRef?.current?.open();
	};

	const hideAddProspectsReportModal = () => {
		addProspectsReportModalRef?.current?.close();
	};

	const hideFileErrorModal = () => {
		fileErrorModalRef?.current?.close();
	};

	const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		const fileInput = fileInputRef.current;

		if (!file || !fileInput) return;

		fileInput.value = '';

		if (file.type !== SCV_FORMAT) {
			fileErrorModalRef?.current?.open();
			return;
		}

		setUploadedFile(file);
		addProspectsModalRef?.current?.open();
	};

	const totalNumberOfImportedRecords =
		confirmationData?.totalNumberOfImportedRecords || 0;
	const totalNumberOfRowsWithData =
		confirmationData?.totalNumberOfRowsWithData || 0;

	const errorRecordsCount =
		totalNumberOfRowsWithData - totalNumberOfImportedRecords;

	const isPlural = errorRecordsCount > 1;

	const stringConditionPart = isPlural
		? `are ${errorRecordsCount} people`
		: `is ${errorRecordsCount} person`;

	const erredRecordsMessage = errorRecordsCount
		? `There
			${stringConditionPart}
			in this list we are not able to add`
		: '';

	const BroadcastsList = campaignDetails.map(
		({ campaignKey, campaignName, campaignTarget, broadcasts }, index) => {
			const handleEdit = () => showChangeCampaignModal(campaignTarget);

			return (
				<Campaign
					key={campaignKey}
					broadcasts={broadcasts}
					campaignTarget={campaignTarget}
					header={
						!index ? (
							<CampaignEdit
								handleEdit={handleEdit}
								campaignName={campaignName}
								disabled={!isMarketingEnabled}
							>
								<UploadButton
									accept=".csv"
									text="+ Add Prospects"
									handleUpload={handleUpload}
									fileInputRef={fileInputRef}
									disabled={!isMarketingEnabled}
								/>
							</CampaignEdit>
						) : (
							<CampaignEdit
								handleEdit={handleEdit}
								campaignName={campaignName}
								disabled={!isMarketingEnabled}
							/>
						)
					}
				/>
			);
		}
	);

	useEffect(() => {
		if (!projectKey || campaignStatistics.length) return;

		setIsStatisticsPending(true);

		ProjectsService.getMarketingCampaignStatistics(projectKey)
			.then((data) => {
				if (!data) return;

				setCampaignStatistics(data);
				setIsStatisticsPending(false);
			})
			.catch((error) => {
				console.log(error);

				setIsStatisticsPending(false);
			});
	}, [projectKey, campaignStatistics.length]);

	useEffect(() => {
		if (!projectKey || campaignDetails.length) return;

		setIsCampaignDetailsPending(true);

		ProjectsService.getMarketingCampaignDetails(projectKey)
			.then((data) => {
				if (!data) return;

				setCampaignDetails(data);
				setIsCampaignDetailsPending(false);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [projectKey, campaignDetails.length]);

	if (isPending) {
		return <Loader />;
	}

	return (
		<>
			<Table
				columns={columns}
				data={campaignStatistics}
				className="org-table project-details-table"
			/>
			{BroadcastsList}
			<ProjectMessage
				projectKey={projectKey}
				setProjectDetails={setProjectDetails}
				accessCodeMessage={accessCodeMessage}
				smsSpecialMessage={smsSpecialMessage}
				isMarketingEnabled={isMarketingEnabled}
				emailSpecialMessage={emailSpecialMessage}
			/>
			<Modal
				title="Select Campaign"
				ref={changeCampaignModalRef}
				subtitle="Select the new campaign you would like to use for this project."
			>
				<SelectCampaign
					journey={journey}
					projectKey={projectKey}
					campaignTarget={changeableCampaignTarget}
					setIsUpdateTimeline={setIsUpdateTimeline}
					hideChangeCampaignModal={hideChangeCampaignModal}
				/>
			</Modal>
			<Modal
				title="Add Prospects"
				ref={addProspectsModalRef}
				subtitle="Select the columns with email addresses and phone numbers."
				className="modal-fixed"
			>
				<AddProspects
					projectKey={projectKey}
					uploadedFile={uploadedFile}
					setConfirmationData={setConfirmationData}
					hideAddProspectsModal={hideAddProspectsModal}
					showAddProspectsConfirmationModal={showAddProspectsConfirmationModal}
				/>
			</Modal>
			<Modal
				title={
					totalNumberOfImportedRecords
						? 'Adding your Prospects'
						: 'No Prospects Found'
				}
				ref={addProspectsConfirmationModalRef}
				subtitle={
					totalNumberOfImportedRecords
						? 'The list of prospects is being added to this project.'
						: ''
				}
			>
				<AddProspectsConfirmation
					confirmationData={confirmationData}
					erredRecordsMessage={erredRecordsMessage}
					showAddProspectsReportModal={showAddProspectsReportModal}
					hideAddProspectsConfirmationModal={hideAddProspectsConfirmationModal}
				/>
			</Modal>
			<Modal ref={addProspectsReportModalRef} title={`${erredRecordsMessage}:`}>
				<AddProspectsReport
					confirmationData={confirmationData}
					hideAddProspectsReportModal={hideAddProspectsReportModal}
				/>
			</Modal>
			<Modal
				ref={fileErrorModalRef}
				title="Wrong Type of File"
				subtitle="Please select a valid .csv file to import your prospects."
			>
				<Agreement handleAgreement={hideFileErrorModal} />
			</Modal>
		</>
	);
};
