import cn from 'classnames';
import { FC } from 'react';

import { ISubscription } from 'api/models/responses/subscriptions/subscription';

import { splitDecimalPricePart } from 'utils/general/splitDecimalPricePart';
import { normalizeDigit } from 'utils/ui/normalizeDigit';

interface TableColumnProps extends ISubscription {
	activeSubscriptionLevelKey: number;
	selectSubscription: (subscriptionLevelKey: number) => void;
}

export const TableColumn: FC<TableColumnProps> = ({
	name,
	maxImageCount,
	monthlyPayment,
	customCampaigns,
	additionalUsers,
	freeSmsMarketing,
	maxPriceListCount,
	selectSubscription,
	subscriptionLevelKey,
	exceededMaxStorageFee,
	activeSubscriptionLevelKey,
	autoFulfillServiceFeePercent,
	studioFulfillServiceFeePercent,
}) => {
	const isActive = activeSubscriptionLevelKey === subscriptionLevelKey;

	const { intString, decimalString } = splitDecimalPricePart(
		`${monthlyPayment}`
	);

	const handleClick = () => selectSubscription(subscriptionLevelKey);

	return (
		<ul
			onClick={handleClick}
			className={cn('panel-plan', {
				'is-active': isActive,
			})}
		>
			<li className="panel-plan-name">{name}</li>
			<li className="panel-plan-price">
				<sup>$</sup>
				{intString}
				<sup>{decimalString}</sup>
			</li>
			<li>
				<b>{studioFulfillServiceFeePercent}%</b>
			</li>
			<li>
				<b>{autoFulfillServiceFeePercent}%</b>
			</li>
			<li>
				<b>
					{normalizeDigit({ value: maxImageCount })}
					<span> images</span>
				</b>
			</li>
			<li>
				{exceededMaxStorageFee}¢ <span>/ extra image</span>
			</li>
			<li>{additionalUsers}</li>
			<li>{!freeSmsMarketing ? 'Email only' : '✓'}</li>
			<li>{maxPriceListCount}</li>
			<li>✓</li>
			<li>{!customCampaigns ? '-' : '✓'}</li>
			<li>✓</li>
			<li>✓</li>
			<li>✓</li>
		</ul>
	);
};
