import { FC } from 'react';

import { INavigation } from 'types/navigation';

import { SubNavItems } from './SubNavItems';

interface INavPagesProps {
	navigationItems: INavigation[];
}

export const NavItems: FC<INavPagesProps> = ({ navigationItems }) => {
	const NavItemsList = navigationItems.map(
		({ title, path, subNavigationItems }) => (
			<SubNavItems
				key={title}
				path={path}
				title={title}
				subNavigationItems={subNavigationItems}
			/>
		)
	);

	return <ul className="nav-pages">{NavItemsList}</ul>;
};
