import {
	maxPrice,
	minPrice,
	getMaxPriceMessage,
	getMinPriceMessage,
} from 'constants/general/validation/priceValidation';

export const validateRetailValue = (retailPrice: number): string => {
	if (retailPrice < minPrice) {
		return getMinPriceMessage('Retail value');
	}

	if (retailPrice > maxPrice) {
		return getMaxPriceMessage('Retail value');
	}

	return '';
};
