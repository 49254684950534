import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { FC, useEffect, useRef } from 'react';
import { useMsal } from '@azure/msal-react';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { IW9BillingInfoBody } from 'api/models/requests/studioBilling/w9BillingInfo';
import { IBankAccountBody } from 'api/models/requests/studioBilling/bankAccount';
import StudioBillingService from 'api/services/StudioBillingService';
import { IErrorResponse } from 'api/models/responses/errors/errorResponse';

import { clearSelectedSubscriptionLevelKey } from 'store/subscriptions';
import { clearStudio, studioKeySelector } from 'store/studio';
import {
	setBankACH,
	setStudioBilling,
	isBankACHFulfilledSelector,
	isStudioBillingFulfilledSelector,
} from 'store/studioBilling';

import { parseEnumToSelectValues } from 'utils/ui/parseEnumToSelectValues';
import { States } from 'types/ui/states';

import { SideWrapper } from '../components/SideWrapper';

import { getPaidFormValidationScheme } from './validations';
import { SetupAccountForm } from './SetupAccountForm';
import {
	ITinField,
	IPartialSsnField,
	IPartialEinField,
	IPartialPhoneField,
	IGetPaidFormValues,
} from './types';

export const SetupAccount: FC = () => {
	const isBankACHFulfilled = useAppSelector(isBankACHFulfilledSelector);
	const studioKey = useAppSelector(studioKeySelector);
	const isStudioBillingFulfilled = useAppSelector(
		isStudioBillingFulfilledSelector
	);

	const formRef = useRef<FormikProps<IGetPaidFormValues> | null>(null);

	const { instance } = useMsal();

	const dispatch = useAppDispatch();

	const billingPhoneInitial: IPartialPhoneField = {
		firstPartPhone: '',
		secondPartPhone: '',
		thirdPartPhone: '',
	};

	const ssnInitial: IPartialSsnField = {
		firstPartSsn: '',
		secondPartSsn: '',
		thirdPartSsn: '',
	};

	const einInitial: IPartialEinField = {
		firstPartEin: '',
		secondPartEin: '',
	};

	const tinInitial: ITinField = {
		ssn: ssnInitial,
		ein: einInitial,
	};

	const getPaidFormInitial: IGetPaidFormValues = {
		bankAccountName: '',
		bankAccountNumber: '',
		confirmBankAccountNumber: '',
		bankRoutingNumber: '',
		confirmBankRoutingNumber: '',
		billingFirstName: '',
		billingLastName: '',
		billingBusinessName: '',
		billingStreet: '',
		billingState: '',
		billingPostalCode: '',
		billingPhone: billingPhoneInitial,
		billingFax: '',
		billingEmail: '',
		billingPhoneExt: '',
		billingEntityType: '',
		tin: tinInitial,
		billingCity: '',
		billingW9Initials: '',
	};

	const submitBankACH = async (
		bankACHBody: IBankAccountBody,
		setErrors: FormikHelpers<IGetPaidFormValues>['setErrors']
	) => {
		try {
			const data = await StudioBillingService.createBankACHInformation(
				bankACHBody
			);

			if (!data) return;

			dispatch(setBankACH(data));
			setErrors({});
		} catch (error) {
			const typedError = error as IErrorResponse;

			const isValidationErrors = !!typedError.traceId;

			if (isValidationErrors) {
				const updatedErrorMessages = Object.entries(typedError.errors).reduce<
					FormikErrors<IGetPaidFormValues>
				>((acc, [key, message]) => {
					const splittedKey = key.split('.');

					const parsedKey = splittedKey[splittedKey.length - 1];

					const parsedMessage = isValidationErrors
						? (message as string[]).join(' ')
						: (message as string);

					return {
						...acc,
						[parsedKey]: parsedMessage,
					};
				}, {});

				setErrors(updatedErrorMessages);
			}
		}
	};

	const submitW9Info = async (
		w9BillingBody: IW9BillingInfoBody,
		setErrors: FormikHelpers<IGetPaidFormValues>['setErrors']
	) => {
		try {
			const data = await StudioBillingService.updateW9BillingInfo(
				w9BillingBody
			);

			if (!data) return;

			dispatch(setStudioBilling(data));
		} catch (error) {
			const typedError = error as IErrorResponse;

			const form = formRef.current;

			if (!form) return;

			const isValidationErrors = !!typedError.traceId;

			const updatedErrorMessages = Object.entries(typedError.errors).reduce<
				FormikErrors<IGetPaidFormValues>
			>((acc, [key, message]) => {
				const splittedKey = key.split('.');

				const parsedKey = splittedKey[splittedKey.length - 1];

				const parsedMessage = isValidationErrors
					? (message as string[]).join(' ')
					: (message as string);

				if (parsedKey === 'billingPhone') {
					acc.billingPhone = {};
					acc.billingPhone.firstPartPhone = parsedMessage;

					return acc;
				}

				if (parsedKey === 'ssn') {
					acc.tin = {};
					acc.tin.ssn = {};
					acc.tin.ssn.firstPartSsn = parsedMessage;

					return acc;
				}

				if (parsedKey === 'ein') {
					acc.tin = {};
					acc.tin.ssn = {};
					acc.tin.ssn.firstPartSsn = parsedMessage;

					return acc;
				}

				return {
					...acc,
					[parsedKey]: parsedMessage,
				};
			}, {});

			const errors = form.errors;

			setErrors({
				...getPaidFormInitial,
				bankAccountName: errors.bankAccountName,
				bankAccountNumber: errors.bankAccountNumber,
				bankRoutingNumber: errors.bankRoutingNumber,
				...updatedErrorMessages,
			} as FormikErrors<IGetPaidFormValues>);
		}
	};

	const onSubmit = async (
		values: IGetPaidFormValues,
		{ setErrors }: FormikHelpers<IGetPaidFormValues>
	) => {
		const {
			tin,
			billingState,
			billingPhone,
			bankAccountName,
			bankRoutingNumber,
			bankAccountNumber,
			confirmBankAccountNumber,
			confirmBankRoutingNumber,
			...w9BillingRest
		} = values;

		const stateIndex = parseEnumToSelectValues(States).findIndex(
			({ value }) => value === billingState
		);

		const fullBillingPhone = `${billingPhone.firstPartPhone}${billingPhone.secondPartPhone}${billingPhone.thirdPartPhone}`;
		const fullSsn = `${tin.ssn.firstPartSsn}${tin.ssn.secondPartSsn}${tin.ssn.thirdPartSsn}`;
		const fullEin = `${tin.ein.firstPartEin}${tin.ein.secondPartEin}`;

		const bankACHBody = {
			studioKey,
			bankAccountName,
			bankAccountNumber,
			bankRoutingNumber,
		};

		const w9BillingBody = {
			...w9BillingRest,
			studioKey,
			ssn: fullSsn,
			ein: fullEin,
			billingState: `${stateIndex}`,
			billingPhone: fullBillingPhone,
		};

		if (!isBankACHFulfilled) {
			await submitBankACH(bankACHBody, setErrors);
		}

		await submitW9Info(w9BillingBody, setErrors);
	};

	useEffect(() => {
		if (!isBankACHFulfilled || !isStudioBillingFulfilled) return;

		instance
			.logoutRedirect()
			.then(() => {
				dispatch(clearStudio());
				dispatch(clearSelectedSubscriptionLevelKey());
			})
			.catch((e) => console.log(e));
	}, [instance, isBankACHFulfilled, isStudioBillingFulfilled]);

	return (
		<div className="paid-container">
			<div className="panel">
				<Formik
					innerRef={formRef}
					onSubmit={onSubmit}
					initialValues={getPaidFormInitial}
					validationSchema={getPaidFormValidationScheme}
				>
					<SetupAccountForm />
				</Formik>
			</div>
			<SideWrapper />
		</div>
	);
};
