import { ChangeEvent, FC, useState } from 'react';

import { PreviewImageColumn } from 'pages/PriceLists/components/PreviewImageColumn';
import { DescriptionColumn } from 'pages/PriceLists/components/DescriptionColumn';
import { IAlaCarteUI } from 'pages/PriceLists/types/studio/priceListAlaCarteUI';
import { BurgerColumn } from 'pages/PriceLists/components/BurgerColumn';

import { validateImageFile } from 'utils/validations/general/validateImageFile';
import { validateFileSize } from 'utils/validations/general/validateFileSize';

import { MAX_IMAGE_SIZE_MB } from 'constants/priceLists/validation/maxImageSize';

import { AlaCarteOptions } from '../AlaCarteOptions';
import {
	DeleteAlaCarteImage,
	UploadAlaCarteImage,
	UpdateAlaCarteHandler,
	ClearAlaCarteErrorHandler,
} from '../..';

interface IAlaCarteContentProps {
	showBurger: boolean;
	alaCarteUI: IAlaCarteUI;
	handleMouseDown: () => void;
	previewImageFile: File | null;
	updateAlaCarte: UpdateAlaCarteHandler;
	uploadAlaCarteImage: UploadAlaCarteImage;
	deleteAlaCarteImage: DeleteAlaCarteImage;
	clearAlaCarteError: ClearAlaCarteErrorHandler;
}

export const AlaCarteContent: FC<IAlaCarteContentProps> = ({
	alaCarteUI,
	showBurger,
	updateAlaCarte,
	handleMouseDown,
	previewImageFile,
	clearAlaCarteError,
	uploadAlaCarteImage,
	deleteAlaCarteImage,
}) => {
	const [isImagePending, setIsImagePending] = useState(false);

	const {
		errors,
		sequence,
		description,
		previewImageUrl,
		requiredPoseOptions,
		isWaiveShippingCharge,
		isIncludeImagesDownload,
		isBackgroundOptionRequired,
		priceListStudioFulfilledALaCarteKey: alaCarteKey,
	} = alaCarteUI;

	const {
		description: descriptionError,
		previewImageUrl: previewImageUrlError,
	} = errors;

	const updateDescription = (value: string, validationMessage: string) => {
		void updateAlaCarte({
			value,
			sequence,
			alaCarteKey,
			validationMessage,
			fieldKey: 'description',
		});
	};

	const clearDescriptionError = () => {
		if (!descriptionError) return;

		clearAlaCarteError({
			sequence,
			fieldKey: 'description',
		});
	};

	const uploadImageAsync = async (imageFile: File) => {
		setIsImagePending(true);

		const fileTypeValidationMessage = validateImageFile({
			type: imageFile.type,
			validateJpg: true,
			validatePng: true,
		});

		const imageFileSizeValidationMessage = validateFileSize(
			imageFile.size,
			MAX_IMAGE_SIZE_MB
		);

		await uploadAlaCarteImage({
			sequence,
			imageFile,
			alaCarteKey,
			validationMessage:
				fileTypeValidationMessage || imageFileSizeValidationMessage,
		});

		setIsImagePending(false);
	};

	const deleteImageAsync = async () => {
		setIsImagePending(true);

		await deleteAlaCarteImage(alaCarteKey);

		setIsImagePending(false);
	};

	const handleUploadImage = (e: ChangeEvent<HTMLInputElement>) => {
		const imageFile = e.target.files?.[0];

		if (!imageFile) return;

		void uploadImageAsync(imageFile);
	};

	const handleDeleteImage = () => {
		void deleteImageAsync();
	};

	return (
		<tr className="price-option-tr">
			<BurgerColumn
				colSpan={1}
				showBurger={showBurger}
				handleMouseDown={handleMouseDown}
			/>
			<PreviewImageColumn
				colSpan={1}
				dimensions="300x450 recommended"
				error={previewImageUrlError}
				isImagePending={isImagePending}
				previewImageUrl={previewImageUrl}
				previewImageFile={previewImageFile}
				handleDeleteImage={handleDeleteImage}
				handleUploadImage={handleUploadImage}
			/>
			<td className="price-option-td" colSpan={4}>
				<div className="price-td-container">
					<DescriptionColumn
						error={descriptionError}
						description={description}
						updateDescription={updateDescription}
						clearDescriptionError={clearDescriptionError}
					/>
					<AlaCarteOptions
						sequence={sequence}
						alaCarteKey={alaCarteKey}
						updateAlaCarte={updateAlaCarte}
						requiredPoseOptions={requiredPoseOptions}
						isWaiveShippingCharge={isWaiveShippingCharge}
						isIncludeImagesDownload={isIncludeImagesDownload}
						isBackgroundOptionRequired={isBackgroundOptionRequired}
					/>
				</div>
			</td>
		</tr>
	);
};
