import { createSelector } from 'reselect';

import { GENERAL_SELECT_OPTION } from 'constants/projects/selectOptions/generalFilterSelectOption';
import { RootState } from 'store/store';

const journeyPresetsStateSelector = (state: RootState) => state.journeyPresets;

export const journeyPresetsSelector = createSelector(
	journeyPresetsStateSelector,
	({ journeyPresets }) => journeyPresets
);

export const isJourneyPresetsPendingSelector = createSelector(
	journeyPresetsStateSelector,
	({ isJourneyPresetsPending }) => isJourneyPresetsPending
);

export const filterByJourneySelector = createSelector(
	journeyPresetsStateSelector,
	({ filterByJourney }) => filterByJourney
);

export const filterByProjectTypeSelector = createSelector(
	journeyPresetsStateSelector,
	({ filterByProjectType }) => filterByProjectType
);

export const filteredJourneyPresetsSelector = createSelector(
	journeyPresetsSelector,
	filterByJourneySelector,
	filterByProjectTypeSelector,
	(presets, filterByJourney, filterByProjectType) =>
		presets
			.filter(
				(preset) =>
					filterByProjectType === GENERAL_SELECT_OPTION ||
					filterByProjectType === preset.projectType
			)
			.filter(
				(preset) =>
					filterByJourney === GENERAL_SELECT_OPTION ||
					filterByJourney === preset.journey
			)
);

export const copiedJourneyPresetKeySelector = createSelector(
	journeyPresetsStateSelector,
	({ copiedJourneyPresetKey }) => copiedJourneyPresetKey
);

export const createJourneyPresetValuesSelector = createSelector(
	journeyPresetsStateSelector,
	({ createJourneyPresetValues }) => createJourneyPresetValues
);
