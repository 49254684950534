import { DataOrder } from 'constants/general/dataOrder';

export enum SortPromotionsLabels {
	Name = 'Name',
	Type = 'Type',
	Value = 'Value',
	NumberOfUses = 'NumberOfUses',
	ExpirationDate = 'ExpirationDate',
}

export interface IPaginatePromotionsParams {
	skip: number;
	take: number;
	orderDirection: DataOrder;
	orderBy: SortPromotionsLabels;
}
