import { Outlet } from 'react-router-dom';
import { FC } from 'react';

import { Header } from '../Header';

export const Content: FC = () => (
	<main className="content">
		<Header />
		<div className="content-thumb">
			<Outlet />
		</div>
	</main>
);
