import { IDefaultBackground } from 'api/models/responses/priceLists/defaultBackground';
import { BaseService } from 'api/services/BaseService';

import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';

class DefaultBackgroundsService extends BaseService {
	async getDefaultBackgrounds(): Promise<IDefaultBackground[] | null> {
		return this.get(priceListsUrls.defaultBackgroundsUrl);
	}
}

export default new DefaultBackgroundsService();
