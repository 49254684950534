import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';

import { IOrganizationProject } from 'api/models/responses/projects/organizationProjects';
import {
	GeneralProjectSortLabels,
	OrgDetailsProjectSortLabels,
	UnitedOrgDetailsProjectSortLabels,
} from 'api/models/requests/projects/projectsParams';

import { ColumnLabelContainer } from 'components/Table/ColumnLabelContainer';
import { Table } from 'components/Table';

import { projectTypesUI } from 'constants/projects/projectTypesUI';
import { IProjectsListProps } from 'pages/Organizations/types';
import { normalizeDate } from 'utils/ui/normalizeDate';
import { usePagination } from 'hooks/usePagination';

export const ProjectList = ({
	orderBy,
	projects,
	className,
	setSortParams,
	orderDirection,
	setPaginatedProjects,
}: IProjectsListProps<
	IOrganizationProject,
	UnitedOrgDetailsProjectSortLabels
>) => {
	const ref = usePagination(setPaginatedProjects);

	const columns: Column<IOrganizationProject>[] = [
		{
			Header: (
				<ColumnLabelContainer<UnitedOrgDetailsProjectSortLabels>
					label="Name"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={GeneralProjectSortLabels.ProjectName}
				/>
			),
			accessor: 'projectName',
			Cell: ({ row }: CellProps<IOrganizationProject>) => {
				const { projectKey, projectName } = row.original;

				return (
					<Link
						to={`/projects/${projectKey}`}
						className="card-table-link"
						title={projectName}
					>
						{projectName}
					</Link>
				);
			},
		},
		{
			Header: (
				<ColumnLabelContainer<UnitedOrgDetailsProjectSortLabels>
					label="Project Type"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={GeneralProjectSortLabels.ProjectType}
				/>
			),
			accessor: 'projectType',
			Cell: ({
				value,
			}: CellProps<
				IOrganizationProject,
				IOrganizationProject['projectType']
			>) => <>{projectTypesUI[value]}</>,
		},
		{
			Header: <>Sale</>,
			accessor: 'sale',
		},
		{
			Header: <>Images</>,
			accessor: 'imagesCount',
		},
		{
			Header: (
				<ColumnLabelContainer<UnitedOrgDetailsProjectSortLabels>
					label="Publish Date"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={GeneralProjectSortLabels.PublishDate}
				/>
			),
			accessor: 'publishDate',
			Cell: ({
				value,
			}: CellProps<
				IOrganizationProject,
				IOrganizationProject['publishDate']
			>) => <>{normalizeDate(value)}</>,
		},
		{
			Header: (
				<ColumnLabelContainer<UnitedOrgDetailsProjectSortLabels>
					label="Expiration Date"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={GeneralProjectSortLabels.ExpireDate}
				/>
			),
			accessor: 'expireDate',
			Cell: ({
				value,
			}: CellProps<
				IOrganizationProject,
				IOrganizationProject['expireDate']
			>) => <>{normalizeDate(value)}</>,
		},
		{
			Header: (
				<ColumnLabelContainer<UnitedOrgDetailsProjectSortLabels>
					label="Last Modified"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={OrgDetailsProjectSortLabels.LastModifyDate}
				/>
			),
			accessor: 'lastModifyDate',
			Cell: ({
				value,
			}: CellProps<
				IOrganizationProject,
				IOrganizationProject['lastModifyDate']
			>) => <>{normalizeDate(value)}</>,
		},
	];

	return (
		<div className="org-project-search-container">
			<Table<IOrganizationProject>
				columns={columns}
				className={className}
				data={projects.results}
			/>
			<div ref={ref} style={{ height: '1px' }} />
		</div>
	);
};
