import { ProjectDateTypes } from 'api/models/responses/broadcasts/broadcast';
import { CampaignsTarget } from 'api/models/responses/campaigns/campaign';

import { ISelectOption } from 'types/ui/select';

import { projectDateTypesUI } from '../projectDateTypesUI';

export const getProjectDateTypesSelectOptions = (
	target: CampaignsTarget
): ISelectOption<ProjectDateTypes>[] => {
	const {
		LoginDate: LoginDate1,
		PurchaseDate: PurchaseDate1,
		...prospectProjectDateTypes
	} = ProjectDateTypes;

	const {
		PurchaseDate,
		DatePersonAdded: DatePersonAdded1,
		...leadProjectDateTypes
	} = ProjectDateTypes;

	const { LoginDate, DatePersonAdded, ...buyerProjectDateTypes } =
		ProjectDateTypes;

	const options: Record<CampaignsTarget, ProjectDateTypes[]> = {
		[CampaignsTarget.Lead]: Object.values(leadProjectDateTypes),
		[CampaignsTarget.Buyer]: Object.values(buyerProjectDateTypes),
		[CampaignsTarget.Prospect]: Object.values(prospectProjectDateTypes),
	};

	return options[target].map((type) => ({
		value: type,
		label: projectDateTypesUI[type],
	}));
};
