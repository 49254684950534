import { FC } from 'react';

import { SearchResultImage } from './SearchResultImage';

export interface ISearchResultImageProps {
	organizationKey?: number;
	searchResultsImageRelativePath?: string;
	setSearchResultsImageRelativePath: (
		searchResultsImageRelativePath: string
	) => void;
}

export const SearchResultImageContainer: FC<ISearchResultImageProps> = (
	props
) => (
	<div className="org-img-container">
		<span className="org-img-label">
			Search Results Image
			<span>300px x 300px</span>
		</span>
		<span className="org-cover-img-container">
			<SearchResultImage {...props} />
		</span>
	</div>
);
