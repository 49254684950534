import { FC, useRef, useState } from 'react';

import { galleryImageActionMenuList } from 'constants/imageGallery/galleryImageActionMenuList';
import { GalleryImageActionMenu } from 'constants/imageGallery/galleryImageActionMenu';
import { imagesBlobStorageUrl } from 'constants/images/blobUrls';

import { IGalleryImage } from 'api/models/responses/imageGallery';
import { CellActionMenu } from 'components/Table/CellActionMenu';
import { deleteGalleryImageAsync } from 'store/imageGallery';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

export const MediaItem: FC<IGalleryImage> = ({
	imageFileName,
	galleryImageKey,
	imageRelativePath,
	imagePreviewRelativePath,
}) => {
	const [showActionMenu, setShowActionMenu] = useState(false);

	const downloadLinkRef = useRef<HTMLAnchorElement>(null);

	const dispatch = useAppDispatch();

	const setActiveActionMenu = () => setShowActionMenu(true);
	const handleMouseLeave = () => setShowActionMenu(false);

	const handleMenuItemClick = (menuItem: GalleryImageActionMenu) => {
		switch (menuItem) {
			case GalleryImageActionMenu.DownloadFile: {
				const downloadLink = downloadLinkRef.current;

				if (!downloadLink) return;

				downloadLink.href = `${imagesBlobStorageUrl}${imageRelativePath}`;
				downloadLink.download = imageFileName;
				downloadLink.click();
				break;
			}

			case GalleryImageActionMenu.DeletePermanently:
				void dispatch(deleteGalleryImageAsync(galleryImageKey));
				break;

			default:
				break;
		}
	};

	return (
		<div className="media-item" onMouseLeave={handleMouseLeave}>
			<CellActionMenu
				className="media-item-btn"
				showActionMenu={showActionMenu}
				menuList={galleryImageActionMenuList}
				handleMenuItemClick={handleMenuItemClick}
				setActiveActionMenu={setActiveActionMenu}
			/>
			<img
				alt="media-image"
				className="media-img"
				src={`${imagesBlobStorageUrl}${imagePreviewRelativePath}`}
			/>
			<span className="media-img-name" title={imageFileName}>
				{imageFileName}
			</span>
			<a download ref={downloadLinkRef} />
		</div>
	);
};
