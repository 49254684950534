import { Days, ISelectOption } from 'types/ui/select';

export const dateRangeSelectOptions: ISelectOption<Days>[] = [
	{
		value: Days.WEEK,
		label: Days.WEEK,
	},
	{
		value: Days.MONTH,
		label: Days.MONTH,
	},
	{
		value: Days.YEAR,
		label: Days.YEAR,
	},
];
