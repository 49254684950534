export const subscriptionRowLabels = [
	{ label: 'Monthly price', isBold: true },
	{ label: 'Studio fulfill service fee*', isBold: true },
	{ label: 'Imago fulfill service fee*', isBold: true },
	{ label: 'Monthly storage', isBold: true },
	{ label: 'Exceeded max storage' },
	{ label: 'Additional users' },
	{ label: 'Free email & text (sms) marketing' },
	{ label: 'Price lists' },
	{ label: 'Automated marketing funnel' },
	{ label: 'Custom campaigns' },
	{ label: 'Image download delivery' },
	{ label: 'Upload via web, H&H Color Lab, hhschools' },
	{ label: 'H&H Color Lab integration' },
];
