import { FC } from 'react';

import presetHeaderImage from 'images/projects/preset-header.png';

export const JourneyPresetPlaceholder: FC = () => (
	<>
		<img alt="preset" src={presetHeaderImage} className="preset-header-img" />
		<span className="preset-placeholder" />
	</>
);
