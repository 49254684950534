export enum GeneralProjectSortLabels {
	ExpireDate = 'ExpireDate',
	ProjectName = 'ProjectName',
	ProjectType = 'ProjectType',
	PublishDate = 'PublishDate',
}

export enum SearchedProjectSortLabels {
	OrganizationName = 'OrganizationName',
}

export enum OrgDetailsProjectSortLabels {
	LastModifyDate = 'LastModifyDate',
}

export type UnitedSearchedProjectSortLabels =
	| GeneralProjectSortLabels
	| SearchedProjectSortLabels;

export type UnitedOrgDetailsProjectSortLabels =
	| GeneralProjectSortLabels
	| OrgDetailsProjectSortLabels;
