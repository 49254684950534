export enum BackgroundOptionVisibilityTypes {
	OnlyIfImageIsExtracted = 'OnlyIfImageIsExtracted',
	EvenIfImageIsNotExtracted = 'EvenIfImageIsNotExtracted',
}

export interface IPriceListStudio {
	name: string;
	studioKey: number;
	createDate: string;
	lastModifyDate: string;
	salesTaxPercent: number;
	isChargeSalesTax: boolean;
	isAddBuddyPoseAtPresale: boolean;
	isOwnPackageBuilderEnabled: boolean;
	priceListStudioFulfilledKey: number;
	isPackageSelectionRequired: boolean;
	isBackgroundSelectionRequired: boolean;
	backgroundOptionVisibilityType: BackgroundOptionVisibilityTypes;
}
