interface IMakeEntityAsDefault<T> {
	entities: T[];
	entityKey: number;
	fieldKey: keyof T;
}

export const makeEntityAsDefault = <T>({
	fieldKey,
	entities,
	entityKey,
}: IMakeEntityAsDefault<T>): T[] => {
	const entitiesCopy = [...entities];

	const processedEntityIndex = entitiesCopy.findIndex(
		(entity) => entity[fieldKey] === entityKey
	);

	const removedEntity = entitiesCopy.splice(processedEntityIndex, 1);
	const removedDefaultEntity = entitiesCopy.splice(0, 1);

	entitiesCopy.splice(0, 0, removedEntity[0]);
	entitiesCopy.splice(processedEntityIndex, 0, removedDefaultEntity[0]);

	const reorderedEntities = entitiesCopy.map((entity, index) => ({
		...entity,
		sequence: index,
	}));

	return reorderedEntities;
};
