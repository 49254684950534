import { FC } from 'react';

export const Article: FC = () => (
	<div className="card-content-article">
		<h4 className="card-content-title">Article Header</h4>
		<p className="paragraph">
			Rovid et excest odis net eos ut faccullam fuga. Nequo vendae. Nem harunt
			estia cusam quidus, sollanda vel mos ere volorro tem aut aut quia vellita
			iunto totatquam as pel idit minto corem cupta veliquo qui do lorep
			erciamus diam, autem verferepedit quis et el illuptates dis abori bus ea
			nam venis anda sunt volorit faciti rem abo. Itatempel imagnihil moluptio
			temped et, aut odit faccusa dolupta tiberchicit expel minim ex eatur?
		</p>
		<h4 className="card-content-title">Article Header</h4>
		<p className="paragraph">
			Rovid et excest odis net eos ut faccullam fuga. Nequo vendae. Nem harunt
			estia cusam quidus, sollanda vel mos ere volorro tem aut aut quia vellita
			iunto totatquam as pel idit minto corem cupta veliquo qui do lorep
			erciamus diam, autem verferepedit quis et el illuptates dis abori
		</p>
	</div>
);
