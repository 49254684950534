import { FC, ReactNode } from 'react';
import { useToggleSection } from 'hooks/useToggleSection';
import { ToggleSection } from '../../ToggleSection/index';

interface IProjectSpecialMessageProps {
	SmsSpecialMessageField: ReactNode;
	EmailSpecialMessageField: ReactNode;
	toggled?: boolean;
}

export const ProjectSpecialMessage: FC<IProjectSpecialMessageProps> = ({
	SmsSpecialMessageField,
	EmailSpecialMessageField,
	toggled,
}) => {
	const { isOpen, handleToggle } = useToggleSection(false);

	const MessageContent = () => (
		<div className="project-textarea-section">
			<div className="project-message">
				{SmsSpecialMessageField}
				<span className="project-textarea-info t-right">
					120 Characters Max
				</span>
			</div>
			{EmailSpecialMessageField}
		</div>
	);

	return (
		<div className="project-message-section">
			{!toggled ? (
				<>
					<span className="project-message-title">
						Project Special Message
						<b>
							Will be inserted in marketing broadcasts when using keyword
							[SpecialMessage]
						</b>
					</span>
					<MessageContent />
				</>
			) : (
				<ToggleSection
					title="Project Special Message"
					subtitle="Will be inserted in marketing broadcasts when using keyword [SpecialMessage]"
					isOpen={isOpen}
					handleToggle={handleToggle}
					className="project-message-toggled"
				>
					<MessageContent />
				</ToggleSection>
			)}
		</div>
	);
};
