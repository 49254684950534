import { createSelector } from 'reselect';

import { RootState } from 'store/store';

export const imageGalleryStateSelector = (state: RootState) =>
	state.imageGallery;

export const galleryImagesSelector = createSelector(
	imageGalleryStateSelector,
	({ galleryImages }) => galleryImages
);

export const addedImagesCountSelector = createSelector(
	imageGalleryStateSelector,
	({ addedImagesCount }) => addedImagesCount
);

export const skippedImagesCountSelector = createSelector(
	imageGalleryStateSelector,
	({ skippedImagesCount }) => skippedImagesCount
);

export const isImageGalleryPendingSelector = createSelector(
	imageGalleryStateSelector,
	({ isImageGalleryPending }) => isImageGalleryPending
);

export const isUploadImagesPendingSelector = createSelector(
	imageGalleryStateSelector,
	({ isUploadImagesPending }) => isUploadImagesPending
);
