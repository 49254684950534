import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';
import { SaleTypes } from 'api/models/responses/projects/sales';

export interface IProjectJourneyInfoSale {
	icon: string;
	text: string;
	title: string;
	saleExpiresLabel: string;
	enableKioskMode: boolean;
}
import { ISaleUI, salesUI } from './salesUI';

export interface IProjectJourneyInfo {
	sales: ISaleUI[];
	description: string;
	salesTypes: SaleTypes[];
	validatePriceListSale1: boolean;
	validatePriceListSale2: boolean;
	validatePriceListSale3: boolean;
}

export const projectJourneyInfo: Record<ProjectJourneys, IProjectJourneyInfo> =
	{
		[ProjectJourneys.A]: {
			validatePriceListSale1: true,
			validatePriceListSale2: true,
			validatePriceListSale3: true,
			sales: [
				salesUI.JourneyAPresale,
				salesUI.JourneyALatePresale,
				salesUI.JourneyAGallerySale,
			],
			salesTypes: [
				SaleTypes.JourneyAPresale,
				SaleTypes.JourneyALatePresale,
				SaleTypes.JourneyAGallerySale,
			],
			description:
				'This journey is intended for you to offer a presale to maximize initial participation while offering an opportunity for consumers to continue ordering after picture day. This journey is recommended for Fall school photos, youth sports, and other presale projects. If there is an absentee day, the late presale orders can be ordered up to the day prior. Otherwise, if there is no absentee day, late presale orders can be ordered until the images are uploaded.',
		},
		[ProjectJourneys.B]: {
			validatePriceListSale1: true,
			validatePriceListSale2: true,
			validatePriceListSale3: false,
			sales: [salesUI.JourneyBProofSale, salesUI.JourneyBGallerySale],
			salesTypes: [SaleTypes.JourneyBProofSale, SaleTypes.JourneyBGallerySale],
			description:
				'This journey is intended for you to offer a proof sale to maximize initial participation while offering an opportunity for consumers to continue ordering after the initial sale. This journey is recommended for spring school photos, preschools, volume seniors, and other proof projects.',
		},
		[ProjectJourneys.C]: {
			validatePriceListSale1: true,
			validatePriceListSale2: false,
			validatePriceListSale3: false,
			sales: [salesUI.JourneyCGallerySale],
			salesTypes: [SaleTypes.JourneyCGallerySale],
			description:
				'This journey is intended for automatic flow fulfillment and shipping of orders directly to home immediately after the order is placed. This journey is recommended for proms, graduations, and other event-based projects.',
		},
		[ProjectJourneys.D]: {
			validatePriceListSale1: true,
			validatePriceListSale2: true,
			validatePriceListSale3: false,
			sales: [salesUI.JourneyDPresale, salesUI.JourneyDGallerySale],
			salesTypes: [SaleTypes.JourneyDPresale, SaleTypes.JourneyDGallerySale],
			description:
				'This journey is intended for you to offer a presale opportunity over multiple weeks to accomodate difficult shoot schedules while offering an opportunity for consumers to continue ordering after picture day. This journey is recommended for Jr/High sports, Dance, Travel Ball, and other presale projects where all participants will be photographed. Do not choose this journey if you plan on photographing the presale buyers only.',
		},
		[ProjectJourneys.E]: {
			validatePriceListSale1: true,
			validatePriceListSale2: true,
			validatePriceListSale3: true,
			sales: [
				salesUI.JourneyEPresale,
				salesUI.JourneyELatePresale,
				salesUI.JourneyEGallerySale,
			],
			salesTypes: [
				SaleTypes.JourneyEPresale,
				SaleTypes.JourneyELatePresale,
				SaleTypes.JourneyEGallerySale,
			],
			description:
				'This journey is intended for you to offer a presale to maximize initial participation while offering an opportunity for consumers to continue ordering after picture day. This journey is recommended for Fall school photos, youth sports, and other presale projects. Late presale orders can be ordered from when the presale ends up to the day prior to absentee day.',
		},
	};
