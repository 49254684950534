import { ICreateStudioBody } from 'api/models/requests/studio/createStudio';
import { IStudio } from 'api/models/responses/studio/studio';

import { studioUrls } from 'constants/apiUrls/studioUrls';

import { BaseService } from '../BaseService';

class StudioService extends BaseService {
	async createStudio(
		createStudioBody: ICreateStudioBody
	): Promise<IStudio | null> {
		return this.post<IStudio, ICreateStudioBody>(
			studioUrls.studio,
			createStudioBody
		);
	}

	async getStudio(): Promise<IStudio | null> {
		return this.get(studioUrls.studio);
	}
}

export default new StudioService();
