export const lineColors: Record<string, string> = {
	opens: '#f15635',
	sends: '#9e9faf',
	clicks: '#2c2e45',
	logins: '#FEC52E',
	orders: '#009bed',
};

export const chartList = [
	'sends',
	'opens',
	'clicks',
	'logins',
	'orders',
	'sales',
];
