import { createSelector } from 'reselect';

import { RootState } from 'store/store';

export const priceListsStateSelector = (state: RootState) => state.priceLists;

export const priceListsSelector = createSelector(
	priceListsStateSelector,
	({ priceLists }) => priceLists
);

export const isPriceListsPendingSelector = createSelector(
	priceListsStateSelector,
	({ isPriceListsPending }) => isPriceListsPending
);
