import { FC } from 'react';

interface Props {
	title: string;
	paragraph: string;
	iconType: string;
}

export const Info: FC<Props> = ({ title, paragraph, iconType }) => (
	<li className="card-info-item">
		<div className={`card-info-icon ${iconType}`}></div>
		<div className="card-info-content">
			<h4 className="card-info-title">{title}</h4>
			<p>{paragraph}</p>
		</div>
	</li>
);
