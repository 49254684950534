import { SearchPhotoByParameters } from 'api/models/requests/photos/searchByParameters';
import { ISelectOption } from 'types/ui/select';

import { searchPhotoByParametersUI } from '../searchPhotoParametersUI';

export const searchBySelectOptions: ISelectOption<SearchPhotoByParameters>[] =
	Object.values(SearchPhotoByParameters).map((value) => ({
		value,
		label: searchPhotoByParametersUI[value],
	}));
