import { FC } from 'react';

import { useAsyncOptimizedCheckbox } from 'hooks/formControls/useAsyncOptimizedCheckbox';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { Checkbox } from 'components/FormControls/Checkbox';

import { IAdditionalChargeOptionsProps } from '../../..';

type SaleTaxColumnProps = Pick<
	IAdditionalChargeOptionsProps,
	'isChargeSalesTax' | 'updatePriceListStudio'
>;

export const SaleTaxColumn: FC<SaleTaxColumnProps> = ({
	isChargeSalesTax,
	updatePriceListStudio,
}) => {
	const updateIsChargeSalesTax = async (value: boolean) => {
		if (!updatePriceListStudio) return;

		try {
			const updatedField: IPatchBody = {
				value,
				op: 'replace',
				path: 'isChargeSalesTax',
			};

			const body: IPatchBody[] = [updatedField];

			await updatePriceListStudio(body);
		} catch (error) {
			console.log(error);
		}
	};

	const { checked, isPending, handleChange } = useAsyncOptimizedCheckbox(
		isChargeSalesTax,
		updateIsChargeSalesTax
	);

	return (
		<>
			<td className="price-option-td" />
			<td className="price-option-td">
				<Checkbox
					label="Sales Tax"
					checked={checked}
					disabled={isPending}
					id="isChargeSalesTax"
					handleChange={handleChange}
				/>
			</td>
		</>
	);
};
