import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import { emailBodyMaxLength } from 'constants/broadcasts/validation';

import { getMaxLengthMessage } from '../general/getMaxLengthMessage';

export const validateEmailBody = (emailBody: string) => {
	if (!emailBody) {
		return requiredFieldMessage;
	}

	if (emailBody.length > emailBodyMaxLength) {
		return getMaxLengthMessage(emailBodyMaxLength);
	}

	return '';
};
