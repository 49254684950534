import { SingleValue } from 'react-select';
import { FC, DragEvent } from 'react';

import { CUSTOM_BACKGROUND_OPTION_KEY } from 'constants/priceLists/selectOptions/backgroundFileCustomSelectOption';
import { disabledBgOptionsDragSequence } from 'constants/priceLists/studio/disabledBgOptionsDragSequence';
import { CommonOptionActionMenu } from 'constants/priceLists/commonOptionActionMenu';

import { ActionMenuControlColumn } from 'pages/PriceLists/components/ActionMenuControlColumn';
import { ReferenceCodeColumn } from 'pages/PriceLists/components/ReferenceCodeColumn';
import { RetailValueColumn } from 'pages/PriceLists/components/RetailValueColumn';
import { BurgerColumn } from 'pages/PriceLists/components/BurgerColumn';
import { NameColumn } from 'pages/PriceLists/components/NameColumn';

import { IChangeSequencePayload, useDragAndDrop } from 'hooks/useDragAndDrop';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { ActionMenuId } from 'hooks/useActionMenu';

import { SelectComponent } from 'components/FormControls/Select';
import { ISelectOption } from 'types/ui/select';
import {
	IBgActionMenuParams,
	IClearBgOptionError,
	IStudioBackgroundOptionUI,
	IUpdateBackgroundOption,
	IImagoBackgroundOptionUI,
	setBackgroundFileKey,
} from 'store/priceLists/priceListBackgrounds';

import { ImagePreviewColumn } from './ImagePreviewColumn';

export interface IBackgroundOptionProps {
	backgroundFileKey: number;
	actionMenuId: ActionMenuId;
	backgroundOptionKey?: number;
	handleUpdateBackgroundOption: (
		params: IUpdateBackgroundOption
	) => Promise<void>;
	customBackgroundFile: File | null;
	backgroundFileSelectOptions: ISelectOption[];
	defaultBackgroundThumbnailUrl: string | null;
	clearError: (params: IClearBgOptionError) => void;
	setActionMenuId: (actionMenuId: ActionMenuId) => void;
	handleActionMenuItemClick: (params: IBgActionMenuParams) => void;
	backgroundOptionUI: IStudioBackgroundOptionUI | IImagoBackgroundOptionUI;
	changeBackgroundOptionsSequence: (params: IChangeSequencePayload) => void;
}

export const BackgroundOption: FC<IBackgroundOptionProps> = ({
	clearError,
	actionMenuId,
	setActionMenuId,
	backgroundFileKey,
	backgroundOptionUI,
	backgroundOptionKey,
	customBackgroundFile,
	handleActionMenuItemClick,
	backgroundFileSelectOptions,
	handleUpdateBackgroundOption,
	defaultBackgroundThumbnailUrl,
	changeBackgroundOptionsSequence,
}) => {
	const dispatch = useAppDispatch();

	const {
		name,
		errors,
		sequence,
		retailPrice,
		referenceCode,
		thumbnailImageUrl,
		defaultPriceListBackground,
	} = backgroundOptionUI;

	const {
		name: nameError,
		retailPrice: retailPriceError,
		referenceCode: referenceCodeError,
		thumbnailImageUrl: thumbnailImageUrlError,
	} = errors;

	const backgroundOptionKeyString = backgroundOptionKey?.toString() || '';

	const {
		draggable,
		handleDrop,
		handleDragEnd,
		handleDragOver,
		handleDragStart,
		handleMouseDown,
	} = useDragAndDrop(
		disabledBgOptionsDragSequence,
		changeBackgroundOptionsSequence
	);

	const onDragStart = (e: DragEvent<HTMLTableSectionElement>) => {
		handleDragStart(e, backgroundOptionKeyString);
	};

	const setActiveActionMenuId = () => {
		setActionMenuId(sequence);
	};

	const actionMenuItemClick = (menuItem: CommonOptionActionMenu) => {
		handleActionMenuItemClick({
			menuItem,
			sequence,
			backgroundOptionKey,
		});
	};

	const updateRetailPrice = (value: number, validationMessage: string) => {
		void handleUpdateBackgroundOption({
			value,
			sequence,
			validationMessage,
			backgroundOptionKey,
			fieldKey: 'retailPrice',
		});
	};

	const clearRetailPriceError = () => {
		if (!retailPriceError) return;

		clearError({ fieldKey: 'retailPrice', sequence });
	};

	const updateName = (value: string, validationMessage: string) => {
		void handleUpdateBackgroundOption({
			value,
			sequence,
			fieldKey: 'name',
			validationMessage,
			backgroundOptionKey,
		});
	};

	const clearNameError = () => {
		if (!nameError) return;

		clearError({ fieldKey: 'name', sequence });
	};

	const updateReferenceCode = (value: string, validationMessage: string) => {
		void handleUpdateBackgroundOption({
			value,
			sequence,
			validationMessage,
			backgroundOptionKey,
			fieldKey: 'referenceCode',
		});
	};

	const clearReferenceCodeError = () => {
		if (!referenceCodeError) return;

		clearError({ fieldKey: 'referenceCode', sequence });
	};

	const clearPreviewError = () => {
		if (!thumbnailImageUrlError) return;

		clearError({ fieldKey: 'thumbnailImageUrl', sequence });
	};

	const handleChangeBackgroundFile = (option: SingleValue<ISelectOption>) => {
		if (!option) return;

		dispatch(setBackgroundFileKey(option.value));
		clearPreviewError();
	};

	const updateImageError = (validationMessage: string) => {
		void handleUpdateBackgroundOption({
			sequence,
			value: '',
			validationMessage,
			backgroundOptionKey,
			fieldKey: 'thumbnailImageUrl',
		});
	};

	const unsavedBackgroundOption = !backgroundOptionKey;
	const isDefaultBackgroundOption = !sequence;

	const showBurger = !isDefaultBackgroundOption && !unsavedBackgroundOption;

	const backgroundFileValue = !unsavedBackgroundOption
		? defaultPriceListBackground?.defaultPriceListBackgroundKey ||
		  CUSTOM_BACKGROUND_OPTION_KEY
		: backgroundFileKey;

	const imagePreviewUrl =
		defaultPriceListBackground?.thumbnailImageCdnUrl ||
		thumbnailImageUrl ||
		defaultBackgroundThumbnailUrl;

	const menuList = Object.values(CommonOptionActionMenu);

	const processedMenuList = unsavedBackgroundOption
		? menuList.slice(1)
		: menuList;

	const showReferenceCodeColumn = referenceCode !== undefined;
	const showRetailValueColumn = retailPrice !== undefined;

	const uniqCustomBackgroundFile = unsavedBackgroundOption
		? customBackgroundFile
		: null;

	return (
		<tbody
			onDrop={handleDrop}
			onDragStart={onDragStart}
			onDragEnd={handleDragEnd}
			onDragOver={handleDragOver}
			data-drag-sequence={sequence}
			id={backgroundOptionKeyString}
			className="price-option-tbody"
			draggable={draggable && !isDefaultBackgroundOption}
		>
			<tr className="price-option-tr">
				<BurgerColumn
					showBurger={showBurger}
					handleMouseDown={handleMouseDown}
				/>
				<ImagePreviewColumn
					clearError={clearPreviewError}
					imagePreviewUrl={imagePreviewUrl}
					updateImageError={updateImageError}
					previewError={thumbnailImageUrlError}
					customBackgroundFile={uniqCustomBackgroundFile}
				/>
				<NameColumn
					name={name}
					nameError={nameError}
					updateName={updateName}
					clearError={clearNameError}
				/>
				<td className="price-option-td">
					<SelectComponent
						value={backgroundFileValue}
						disabled={!unsavedBackgroundOption}
						selectPlaceholder="Select background"
						onChange={handleChangeBackgroundFile}
						selectOptions={backgroundFileSelectOptions}
					/>
				</td>
				{showReferenceCodeColumn && (
					<ReferenceCodeColumn
						referenceCode={referenceCode}
						clearError={clearReferenceCodeError}
						referenceCodeError={referenceCodeError}
						updateReferenceCode={updateReferenceCode}
					/>
				)}
				{showRetailValueColumn && (
					<RetailValueColumn
						retailValue={retailPrice}
						clearError={clearRetailPriceError}
						retailValueError={retailPriceError}
						updateRetailValue={updateRetailPrice}
					/>
				)}
				<ActionMenuControlColumn
					menuList={processedMenuList}
					isDefault={isDefaultBackgroundOption}
					handleMenuItemClick={actionMenuItemClick}
					showActionMenu={actionMenuId === sequence}
					setActiveActionMenu={setActiveActionMenuId}
				/>
			</tr>
		</tbody>
	);
};
