export const minMaxUses = 1;
export const minMaxUsesMessage = 'Max Uses must be at least 1';

export const maxMaxUses = 2147483647;
export const maxMaxUsesMessage = 'Max Uses must be at most 2147483647';

export const minOrderValueTitle = 'Min order value';

export const priceValueMessage = 'Price must be between 0 and 9999.99';

export const promoCodeMaxLength = 25;
export const promoCodesMessage =
	'Each promo code must be at most 25 characters and contain only alphanumeric characters without spaces.';
