import { FC, useState } from 'react';

import { UnitTypes } from 'api/models/responses/general/unitTypes';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { SelectComponent } from 'components/FormControls/Select';
import { InputField } from 'components/InputField';

import { chargeUnitTypesSelectOptions } from 'constants/priceLists/studio/selectOptions/chargeUnitTypesSelectOptions';
import { useLazyPercentInput } from 'hooks/formControls/useLazyPercentInput';

import { IAdditionalChargeOptionsProps } from '../../..';

interface IAmountColumnProps
	extends Pick<IAdditionalChargeOptionsProps, 'updatePriceListStudio'> {
	salesTaxPercent?: number;
}

export const AmountColumn: FC<IAmountColumnProps> = ({
	salesTaxPercent,
	updatePriceListStudio,
}) => {
	const [amountError, setAmountError] = useState('');

	const clearError = () => setAmountError('');

	const handleUpdate = async (value: number, validationMessage: string) => {
		if (!updatePriceListStudio) return;

		if (validationMessage) {
			return setAmountError(validationMessage);
		}

		try {
			const updatedField: IPatchBody = {
				value,
				op: 'replace',
				path: 'salesTaxPercent',
			};

			const body: IPatchBody[] = [updatedField];

			await updatePriceListStudio(body);
		} catch (error) {
			console.log(error);
		}
	};

	const { value, handleBlur, handleChange, handleKeyDown } =
		useLazyPercentInput({
			clearError,
			handleUpdate,
			initialValue: salesTaxPercent,
		});

	return (
		<td className="price-option-td">
			<div className="price-table-container">
				<SelectComponent
					disabled
					readonly
					value={UnitTypes.Percent}
					selectOptions={chargeUnitTypesSelectOptions}
				/>
				<InputField
					touched
					value={value}
					error={amountError}
					handleBlur={handleBlur}
					placeholder="Amount value"
					handleChange={handleChange}
					handleKeyDown={handleKeyDown}
				/>
			</div>
		</td>
	);
};
