import { FC } from 'react';

import { ActionMenuControlColumn } from 'pages/PriceLists/components/ActionMenuControlColumn';
import { RetailValueColumn } from 'pages/PriceLists/components/RetailValueColumn';
import {
	IShippingOptionUI,
	IUpdateShippingOption,
	IShippingActionMenuParams,
	IClearShippingOptionErrors,
} from 'pages/PriceLists/types/studio/priceListOptions/shippingOptions';

import { CommonOptionActionMenu } from 'constants/priceLists/commonOptionActionMenu';
import { ActionMenuId } from 'hooks/useActionMenu';

import { ReferenceCodeColumn } from './components/ReferenceCodeColumn';
import { NameColumn } from './components/NameColumn';

type UpdateShippingOptionHandler = (
	params: IUpdateShippingOption
) => Promise<void>;

type ClearShippingOptionErrorsHandler = (
	params: IClearShippingOptionErrors
) => void;

export interface IGeneralColumnProps {
	sequence: number;
	shippingOptionKey?: number;
	updateShippingOption: UpdateShippingOptionHandler;
	clearShippingOptionErrors: ClearShippingOptionErrorsHandler;
}

interface IShippingOptionProps {
	actionMenuId: ActionMenuId;
	unsavedShippingOptions: boolean;
	shippingOptionUI: IShippingOptionUI;
	disableShippingTypeSelection: boolean;
	updateShippingOption: UpdateShippingOptionHandler;
	setActionMenuId: (actionMenuId: ActionMenuId) => void;
	clearShippingOptionErrors: ClearShippingOptionErrorsHandler;
	handleActionMenuClick: (params: IShippingActionMenuParams) => void;
}

export const ShippingOption: FC<IShippingOptionProps> = ({
	actionMenuId,
	setActionMenuId,
	shippingOptionUI,
	updateShippingOption,
	handleActionMenuClick,
	unsavedShippingOptions,
	clearShippingOptionErrors,
	disableShippingTypeSelection,
}) => {
	const {
		errors,
		sequence,
		isDefault,
		referenceCode,
		shippingPrice,
		orderShippingType,
		isCollectingMailingAddress,
		priceListStudioFulfilledShippingOptionKey: shippingOptionKey,
	} = shippingOptionUI;

	const { shippingPrice: shippingPriceError } = errors;

	const updateShippingPrice = (value: number, validationMessage: string) => {
		void updateShippingOption({
			value,
			sequence,
			shippingOptionKey,
			validationMessage,
			fieldKey: 'shippingPrice',
		});
	};

	const clearShippingPriceError = () => {
		if (!shippingPriceError) return;

		clearShippingOptionErrors({ sequence, fieldKey: 'shippingPrice' });
	};

	const setActiveActionMenuId = () => {
		setActionMenuId(sequence);
	};

	const actionMenuItemClick = (menuItem: CommonOptionActionMenu) => {
		handleActionMenuClick({
			menuItem,
			sequence,
			shippingOptionKey,
		});
	};

	const menuList = Object.values(CommonOptionActionMenu);

	const processedMenuList = unsavedShippingOptions
		? menuList.slice(1)
		: menuList;

	const generalColumnProps: IGeneralColumnProps = {
		sequence,
		shippingOptionKey,
		updateShippingOption,
		clearShippingOptionErrors,
	};

	return (
		<tbody className="price-option-tbody">
			<tr>
				<NameColumn
					{...generalColumnProps}
					orderShippingType={orderShippingType}
					orderShippingTypeError={errors.orderShippingType}
					disableShippingTypeSelection={disableShippingTypeSelection}
				/>
				<RetailValueColumn
					retailValue={shippingPrice}
					clearError={clearShippingPriceError}
					retailValueError={shippingPriceError}
					updateRetailValue={updateShippingPrice}
				/>
				<ReferenceCodeColumn
					{...generalColumnProps}
					referenceCode={referenceCode}
					referenceCodeError={errors.referenceCode}
					isCollectingMailingAddress={isCollectingMailingAddress}
				/>
				<ActionMenuControlColumn
					isDefault={isDefault}
					menuList={processedMenuList}
					handleMenuItemClick={actionMenuItemClick}
					showActionMenu={actionMenuId === sequence}
					setActiveActionMenu={setActiveActionMenuId}
				/>
			</tr>
		</tbody>
	);
};
