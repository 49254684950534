import { date, number, object, string } from 'yup';

import { projectNameValidationMessage } from 'constants/projects/validation/generalProjectFields';
import { percentErrorMessage } from 'constants/general/validation/percentValidation';
import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	maxPrice,
	minPrice,
	getMaxPriceMessage,
	getMinPriceMessage,
} from 'constants/general/validation/priceValidation';
import {
	validBaseNameRegex,
	alphaNumericWithoutSpacesRegex,
	numberInDecimalRangeRegex,
	numberInIntegerRangeRegex,
} from 'constants/general/validation/generalRegEx';
import {
	maxMaxUses,
	minMaxUses,
	maxMaxUsesMessage,
	promoCodesMessage,
	priceValueMessage,
	minMaxUsesMessage,
	minOrderValueTitle,
	promoCodeMaxLength,
} from 'constants/promotions/validation';

import { UnitTypes } from 'api/models/responses/general/unitTypes';

export const promotionValidationSchema = object().shape({
	name: string()
		.required(requiredFieldMessage)
		.matches(validBaseNameRegex, projectNameValidationMessage),

	applyTo: string().required(requiredFieldMessage),

	maxUses: number()
		.notRequired()
		.min(minMaxUses, minMaxUsesMessage)
		.max(maxMaxUses, maxMaxUsesMessage),

	priceValue: string().when('unitType', (unitType: UnitTypes[]) =>
		unitType[0] === UnitTypes.Dollar
			? string().matches(numberInDecimalRangeRegex, priceValueMessage)
			: string().notRequired()
	),

	percentValue: string().when('unitType', (unitType: UnitTypes[]) =>
		unitType[0] === UnitTypes.Percent
			? string().matches(numberInIntegerRangeRegex, percentErrorMessage)
			: string().notRequired()
	),

	promoCodes: string()
		.required(requiredFieldMessage)
		.test('promoCodes', promoCodesMessage, (value) => {
			if (typeof value !== 'string') return false;

			const codes = value.split(',');

			return codes.every(
				(code) =>
					code.length <= promoCodeMaxLength &&
					alphaNumericWithoutSpacesRegex.test(code)
			);
		}),

	minOrderValue: number()
		.min(minPrice, getMinPriceMessage(minOrderValueTitle))
		.max(maxPrice, getMaxPriceMessage(minOrderValueTitle)),

	expirationDate: date().required(requiredFieldMessage),
});
