import { FC } from 'react';

import { CoverImage } from './CoverImage';

export interface ICoverImageProps {
	label: string;
	coverImagePreviewRelativePath: string;
	handleDeleteImage: () => Promise<void>;
	handleUploadImage: (file: File) => Promise<void>;
}

export const CoverImageContainer: FC<ICoverImageProps> = ({
	label,
	handleUploadImage,
	handleDeleteImage,
	coverImagePreviewRelativePath,
}) => {
	return (
		<div className="org-cover">
			<span className="org-cover-title">
				{label}
				<span className="org-cover-size">1920px x 1000px</span>
			</span>
			<span className="org-cover-img-container">
				<CoverImage
					handleUploadImage={handleUploadImage}
					handleDeleteImage={handleDeleteImage}
					coverImagePreviewRelativePath={coverImagePreviewRelativePath}
				/>
			</span>
		</div>
	);
};
