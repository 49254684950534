export const dollarDecimalRegex = /^\$\d+\.?[0-9]{0,2}$/;

export const decimalDigitsRegex = /^\d+\.?[0-9]{0,2}$/;

export const alphaNumericRegex = /^[A-Za-z0-9 ]+$/;

export const alphaNumericWithoutSpacesRegex = /^[A-Za-z0-9]+$/;

export const alphaNumericWithDashRegex = /^[a-zA-Z0-9-]+$/;

export const validBaseNameRegex =
	/^(?![ '‘’`.&-])(?!.* $)[a-zA-Z0-9 '‘’`.&-]+$/;

// range from 0 to 100 exclude 0
export const numberInIntegerRangeRegex = /^(100|[1-9][0-9]?)$/;
export const numberInDecimalRangeRegex = /^(100|[1-9][0-9]?(\.\d{1,2})?)$/;
