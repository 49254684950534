import { ICatalogProduct } from 'api/models/responses/catalogProducts/catalogProducts';
import { ISelectOption } from 'types/ui/select';

export const getProductSelectOptions = (
	catalogProducts: ICatalogProduct[]
): ISelectOption[] =>
	catalogProducts.map(({ name, catalogProductKey }) => ({
		value: catalogProductKey,
		label: name,
	}));
