import { object, string } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	orgUrlLengthLimit,
	orgNameLengthLimit,
	orgUrlRegExMessage,
	orgNameRegExMessage,
	organizationUrlRegEx,
	organizationNameRegEx,
	orgUrlMaxLengthMessage,
	orgNameMaxLengthMessage,
} from 'constants/organizations/validation';

import { validateUrlByReservedWords } from 'utils/validations/general/validateUrlByReservedWords';

export const createNewOrganizationScheme = object().shape({
	organizationName: string()
		.required(requiredFieldMessage)
		.max(orgNameLengthLimit, orgNameMaxLengthMessage)
		.matches(organizationNameRegEx, orgNameRegExMessage),

	organizationUrl: string()
		.required(requiredFieldMessage)
		.max(orgUrlLengthLimit, orgUrlMaxLengthMessage)
		.matches(organizationUrlRegEx, orgUrlRegExMessage)
		.test('', '', (value, context) => {
			const message = validateUrlByReservedWords(value);

			if (!message) return true;

			return context.createError({
				message,
			});
		}),
});
