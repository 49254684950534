import { WatermarkTypes } from 'api/models/responses/studio/studioPhotoSettings';

import noWatermarkImagePreview from 'images/watermarkImagePreviews/no-watermark-image-preview.png';
import singleWatermarkImagePreview from 'images/watermarkImagePreviews/single-watermark-image-preview.png';
import repeatWatermarkImagePreview from 'images/watermarkImagePreviews/repeat-watermark-image-preview.png';

export const watermarkImagePreviews: Record<WatermarkTypes, string> = {
	[WatermarkTypes.No]: noWatermarkImagePreview,
	[WatermarkTypes.Single]: singleWatermarkImagePreview,
	[WatermarkTypes.Repeat]: repeatWatermarkImagePreview,
};
