import { FC, SyntheticEvent } from 'react';

import { validateDescription } from 'utils/validations/priceLists/validateDescription';
import { LazyTextArea } from 'components/FormControls/LazyTextArea';

interface IDescriptionColumnProps {
	error?: string;
	colSpan?: number;
	description: string;
	clearDescriptionError: () => void;
	updateDescription: (value: string, validationMessage: string) => void;
}

export const DescriptionColumn: FC<IDescriptionColumnProps> = ({
	error,
	colSpan,
	description,
	updateDescription,
	clearDescriptionError,
}) => {
	const handleChangeDescription = (e: SyntheticEvent<HTMLTextAreaElement>) => {
		const { value } = e.currentTarget;

		const validationMessage = validateDescription(value);

		updateDescription(value, validationMessage);
	};

	return (
		<td className="price-option-td" colSpan={colSpan}>
			<label htmlFor="description" className="price-textarea-label">
				Description
				<LazyTextArea
					name="description"
					defaultValue={description}
					className="price-textarea"
					placeholder="Enter description..."
					clearError={clearDescriptionError}
					handleChange={handleChangeDescription}
				/>
				{error && <span className="input-error">{error}</span>}
			</label>
		</td>
	);
};
