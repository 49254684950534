interface IValidationParams {
	width: number;
	height: number;
	requiredWidth: number;
	requiredHeight: number;
}

export const validateFixedImageDimensions = ({
	width,
	height,
	requiredWidth,
	requiredHeight,
}: IValidationParams) => {
	if (width !== requiredWidth || height !== requiredHeight) {
		return `Image dimensions should be exactly ${requiredWidth}x${requiredHeight}.`;
	}

	return '';
};
