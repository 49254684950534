import { navigation } from 'constants/navigation';
import { INavigation } from 'types/navigation';

class NavigationService {
	private navigation: INavigation[];

	constructor() {
		this.navigation = navigation;
	}

	get navigationList() {
		return this.navigation;
	}

	getParentNavigation(title: string) {
		const parentNavigation = this.navigationList.find(
			(nav) => nav.title === title
		);

		return parentNavigation;
	}

	getSubNavigation(title: string, path: string) {
		const parentNavigation = this.getParentNavigation(title);

		const subNavigation = parentNavigation?.subNavigationItems.find(
			(subNav) => subNav.path === path
		);

		return subNavigation;
	}
}

export default new NavigationService();
