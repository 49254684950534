import { FC, useState } from 'react';

import { ISubjectFormQuestionUI } from 'components/SubjectFormQuestions/types';
import { ToggleSection } from 'components/ToggleSection';

import { useToggleSection } from 'hooks/useToggleSection';

import { ProjectFormSection } from './ProjectFormSection';

export interface ToggleFormSectionContainerProps {
	projectKey?: number;
}

export const ProjectFormSectionContainer: FC<
	ToggleFormSectionContainerProps
> = ({ projectKey }) => {
	const [subjectFormQuestions, setSubjectFormQuestions] = useState<
		ISubjectFormQuestionUI[]
	>([]);

	const [isPending, setIsPending] = useState(false);

	const { isOpen, handleToggle } = useToggleSection(false);

	return (
		<ToggleSection
			title="Form"
			isOpen={isOpen}
			handleToggle={handleToggle}
			className="preset-details-header"
		>
			<ProjectFormSection
				isPending={isPending}
				projectKey={projectKey}
				setIsPending={setIsPending}
				subjectFormQuestions={subjectFormQuestions}
				setSubjectFormQuestions={setSubjectFormQuestions}
			/>
		</ToggleSection>
	);
};
