import { FC } from 'react';

import { poseAvailableForSelectOptions } from 'constants/priceLists/poseAvailableFor/poseAvailableForSelectOptions';
import { PoseAvailableFor } from 'constants/priceLists/poseAvailableFor/poseAvailableFor';

import { useAsyncOptimizedSelect } from 'hooks/formControls/useAsyncOptimizedSelect';
import { SelectComponent } from 'components/FormControls/Select';

interface IAvailableForColumnProps {
	availableFor: PoseAvailableFor;
	updateAvailableFor: (availableFor: PoseAvailableFor) => Promise<void>;
}

export const AvailableForColumn: FC<IAvailableForColumnProps> = ({
	availableFor,
	updateAvailableFor,
}) => {
	const { value, isPending, handleChange } =
		useAsyncOptimizedSelect<PoseAvailableFor>({
			initialValue: availableFor,
			updateSelectValue: updateAvailableFor,
		});

	return (
		<td className="price-option-td">
			<SelectComponent
				value={value}
				disabled={isPending}
				onChange={handleChange}
				selectOptions={poseAvailableForSelectOptions}
			/>
		</td>
	);
};
