import { IPoseOptionErrors } from 'pages/PriceLists/types/studio/priceListOptions/poseOptions';

import { initialBackgroundOptionErrors } from './initialBackgroundOptionErrors';

const { thumbnailImageUrl, ...rest } = initialBackgroundOptionErrors;

export const initialPoseOptionErrors: IPoseOptionErrors = {
	...rest,
	previewImageUrl: '',
};
