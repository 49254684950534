import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import { Button } from 'components/FormControls/Button';
import { ROUTES } from 'constants/ROUTES';

import { IJourneyPresetValues } from '../../../types';

export const JourneyPresetControls: FC = () => {
	const { handleSubmit } = useFormikContext<IJourneyPresetValues>();

	const navigate = useNavigate();

	const handleCreatePreset = () => {
		handleSubmit();
	};

	const handleBack = () => {
		navigate(ROUTES.PRESETS.CREATE_PRESET);
	};

	return (
		<div className="project-section-btn">
			<Button value="Back" className="btn-secondary" handleClick={handleBack} />
			<Button
				value="Create"
				className="btn-primary"
				handleClick={handleCreatePreset}
			/>
		</div>
	);
};
