import { FC } from 'react';

import { StepSubjectHeader } from 'components/StepSubjectHeader';
import { IStepControl } from 'pages/Projects/types';

import { PresetDetails } from './components/PresetDetails';
import { Form } from './components/Form';

export const CreateProjectFromPresetStep1: FC<IStepControl> = ({ setStep }) => (
	<div className="project-thumb project-preset-step">
		<StepSubjectHeader step="Step 1 of 2" title="Select a Preset" />
		<div className="project-blank-main">
			<Form setStep={setStep} />
			<PresetDetails />
		</div>
	</div>
);
