import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';

import journeyBlankProjectTimelineA from 'images/projects/journeyBlankProjectTimelines/journey-timeline-A.svg';
import journeyBlankProjectTimelineB from 'images/projects/journeyBlankProjectTimelines/journey-timeline-B.svg';
import journeyBlankProjectTimelineC from 'images/projects/journeyBlankProjectTimelines/journey-timeline-C.svg';
import journeyBlankProjectTimelineD from 'images/projects/journeyBlankProjectTimelines/journey-timeline-D.svg';
import journeyBlankProjectTimelineE from 'images/projects/journeyBlankProjectTimelines/journey-timeline-E.svg';

import journeyProjectPresetImageA from 'images/projects/journeyProjectPresetTimelines/journey-timeline-A.svg';
import journeyProjectPresetImageB from 'images/projects/journeyProjectPresetTimelines/journey-timeline-B.svg';
import journeyProjectPresetImageC from 'images/projects/journeyProjectPresetTimelines/journey-timeline-C.svg';
import journeyProjectPresetImageD from 'images/projects/journeyProjectPresetTimelines/journey-timeline-D.svg';
import journeyProjectPresetImageE from 'images/projects/journeyProjectPresetTimelines/journey-timeline-E.svg';

export interface IDatesLabels {
	expireDateLabel: DatesLabels;
	publishDateLabel: DatesLabels;
	lastPictureDateLabel?: DatesLabels;
	firstPictureDateLabel: DatesLabels;
}

export interface IJourneyTimeline {
	journeyText: string;
	journeyTitle: string;
	journeySubText: string;
	journeyDate: IProjectJourneyDate;
	journeyBlankProjectImage: string;
	journeyProjectPresetImage: string;
}

interface IProjectJourneyDate {
	datesLabels: IDatesLabels;
	journeyBlankProjectDates: IJourneyDate[];
	journeyProjectPresetDates: IJourneyDate[];
	validateBlankProjectLastPictureDate: boolean;
	validateBlankProjectFirstPictureDate: boolean;
	validateProjectPresetLastPictureDate: boolean;
}

export interface IJourneyDate {
	id: string;
	icon: string;
	label: string;
}

enum DatesLabels {
	AbsenteeDay = 'Absentee Day',
	ExpireDate = 'Expire Project',
	PublishDate = 'Publish Project',
	FirstPictureDate = 'Picture Day',
	ProofSaleEnds = 'Proof Sale Ends',
	LastPictureDate = 'Last Picture Day',
}

export const publishDate: IJourneyDate = {
	id: 'publishDate',
	icon: 'icon-finish',
	label: DatesLabels.PublishDate,
};

export const firstPictureDate: IJourneyDate = {
	id: 'firstPictureDate',
	icon: 'icon-camera is-active',
	label: DatesLabels.FirstPictureDate,
};

export const expireDate: IJourneyDate = {
	id: 'expireDate',
	icon: 'icon-mounting',
	label: DatesLabels.ExpireDate,
};

const proofSaleEnds: IJourneyDate = {
	id: 'lastPictureDate',
	icon: 'icon-shaping',
	label: DatesLabels.ProofSaleEnds,
};

export const lastPictureDate: IJourneyDate = {
	id: 'lastPictureDate',
	icon: 'icon-camera',
	label: DatesLabels.LastPictureDate,
};

const absenteeDay: IJourneyDate = {
	id: 'lastPictureDate',
	icon: 'icon-camera',
	label: DatesLabels.AbsenteeDay,
};

const datesLabels: IDatesLabels = {
	expireDateLabel: DatesLabels.ExpireDate,
	publishDateLabel: DatesLabels.PublishDate,
	lastPictureDateLabel: DatesLabels.LastPictureDate,
	firstPictureDateLabel: DatesLabels.FirstPictureDate,
};

export const journeyTimelines: Record<ProjectJourneys, IJourneyTimeline> = {
	[ProjectJourneys.A]: {
		journeyDate: {
			datesLabels,
			validateBlankProjectLastPictureDate: false,
			validateBlankProjectFirstPictureDate: true,
			validateProjectPresetLastPictureDate: false,
			journeyProjectPresetDates: [publishDate, firstPictureDate],
			journeyBlankProjectDates: [publishDate, firstPictureDate, expireDate],
		},
		journeyTitle: 'Journey A',
		journeyText: 'Presale + Picture Day Gallery',
		journeySubText: '(Ex. Fall schools, youth sports...)',
		journeyProjectPresetImage: journeyProjectPresetImageA,
		journeyBlankProjectImage: journeyBlankProjectTimelineA,
	},
	[ProjectJourneys.B]: {
		journeyTitle: 'Journey B',
		journeyText: 'Proof sale + Picture Day Gallery',
		journeyProjectPresetImage: journeyProjectPresetImageB,
		journeyBlankProjectImage: journeyBlankProjectTimelineB,
		journeySubText: '(Ex. Spring schools, Preschools, Seniors...)',
		journeyDate: {
			journeyBlankProjectDates: [
				publishDate,
				firstPictureDate,
				proofSaleEnds,
				expireDate,
			],
			datesLabels: {
				...datesLabels,
				lastPictureDateLabel: DatesLabels.ProofSaleEnds,
			},
			validateBlankProjectLastPictureDate: true,
			validateProjectPresetLastPictureDate: false,
			validateBlankProjectFirstPictureDate: false,
			journeyProjectPresetDates: [publishDate, firstPictureDate],
		},
	},
	[ProjectJourneys.C]: {
		journeyDate: {
			datesLabels,
			validateBlankProjectLastPictureDate: false,
			validateProjectPresetLastPictureDate: false,
			validateBlankProjectFirstPictureDate: false,
			journeyProjectPresetDates: [publishDate, firstPictureDate],
			journeyBlankProjectDates: [publishDate, firstPictureDate, expireDate],
		},
		journeyTitle: 'Journey C',
		journeyText: 'Picture Day Gallery',
		journeyProjectPresetImage: journeyProjectPresetImageC,
		journeyBlankProjectImage: journeyBlankProjectTimelineC,
		journeySubText: '(Ex. Prom, Graduations, Mini Sessions...)',
	},
	[ProjectJourneys.D]: {
		journeyTitle: 'Journey D',
		journeyProjectPresetImage: journeyProjectPresetImageD,
		journeyBlankProjectImage: journeyBlankProjectTimelineD,
		journeyText: 'Presale (multi-day) + Picture Day Gallery',
		journeySubText: '(Ex. JR/High sports, Dance, Travel Ball...)',
		journeyDate: {
			journeyBlankProjectDates: [
				publishDate,
				firstPictureDate,
				lastPictureDate,
				expireDate,
			],
			journeyProjectPresetDates: [
				publishDate,
				firstPictureDate,
				lastPictureDate,
			],
			datesLabels: {
				...datesLabels,
				lastPictureDateLabel: DatesLabels.LastPictureDate,
			},
			validateBlankProjectLastPictureDate: true,
			validateBlankProjectFirstPictureDate: true,
			validateProjectPresetLastPictureDate: true,
		},
	},
	[ProjectJourneys.E]: {
		journeyTitle: 'Journey E',
		journeySubText: '(Ex. Fall schools...)',
		journeyProjectPresetImage: journeyProjectPresetImageE,
		journeyBlankProjectImage: journeyBlankProjectTimelineE,
		journeyText: 'Presale (w/ Absentee) + Picture Day Gallery',
		journeyDate: {
			journeyBlankProjectDates: [
				publishDate,
				firstPictureDate,
				absenteeDay,
				expireDate,
			],
			datesLabels: {
				...datesLabels,
				lastPictureDateLabel: DatesLabels.AbsenteeDay,
			},
			journeyProjectPresetDates: [publishDate, firstPictureDate, absenteeDay],
			validateBlankProjectLastPictureDate: true,
			validateBlankProjectFirstPictureDate: true,
			validateProjectPresetLastPictureDate: true,
		},
	},
};
