import { FormikErrors } from 'formik';

export const getProcessedErrors = <T extends object>(
	values: T,
	errors: Record<string, string | string[]>
) => {
	const processedErrors = Object.entries(errors).reduce<FormikErrors<T>>(
		(acc, [key, message]) => {
			const doesKeyExist = key in values;

			if (!doesKeyExist) return acc;

			const parsedMessage =
				typeof message === 'string' ? message : message.join('');

			return {
				...acc,
				[key]: parsedMessage,
			};
		},
		{}
	);

	return processedErrors;
};
