import { createSelector } from 'reselect';

import { RootState } from 'store/store';

const promotionsStateSelector = (state: RootState) => state.promotions;

export const promotionsSelector = createSelector(
	promotionsStateSelector,
	(promotions) => promotions.promotions
);

export const copiedPromotionKeySelector = createSelector(
	promotionsStateSelector,
	(promotions) => promotions.copiedPromotionKey
);

export const isPromotionsPendingSelector = createSelector(
	promotionsStateSelector,
	(promotions) => promotions.isPromotionsPending
);

export const isPromotionsPaginationPendingSelector = createSelector(
	promotionsStateSelector,
	(promotions) => promotions.isPromotionsPaginationPending
);
