import { Dispatch } from '@reduxjs/toolkit';

import ImageGalleryService from 'api/services/ImageGalleryService';

import {
	setGalleryImages,
	deleteGalleryImage,
	setUploadedMultipleImages,
	setIsImageGalleryPending,
	setIsUploadImagesPending,
} from './imageGallerySlice';

export const getGalleryImagesAsync = () => async (dispatch: Dispatch) => {
	dispatch(setIsImageGalleryPending(true));

	try {
		const data = await ImageGalleryService.getGalleryImages();

		if (data) {
			dispatch(setGalleryImages(data));
		}
	} catch (error) {
		console.log(error);
	}

	dispatch(setIsImageGalleryPending(false));
};

export const uploadMultipleImagesAsync =
	(imageFiles: File[]) => async (dispatch: Dispatch) => {
		dispatch(setIsUploadImagesPending(true));

		try {
			const data = await ImageGalleryService.uploadMultipleImages(imageFiles);

			if (data) {
				dispatch(setUploadedMultipleImages(data));
			}
		} catch (error) {
			console.log(error);
		}

		dispatch(setIsUploadImagesPending(false));
	};

export const deleteGalleryImageAsync =
	(galleryImageKey: number) => async (dispatch: Dispatch) => {
		dispatch(deleteGalleryImage(galleryImageKey));

		try {
			await ImageGalleryService.deleteGalleryImage(galleryImageKey);
		} catch (error) {
			console.log(error);
		}
	};
