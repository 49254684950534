import { ICreateStudioDefaultBackgroundOption } from 'api/models/requests/priceLists/studio/priceListOptions/createStudioDefaultBackgroundOption';
import { ICreateStudioBackgroundOption } from 'api/models/requests/priceLists/studio/priceListOptions/createStudioBackgroundOption';
import { IStudioBackgroundOption } from 'api/models/responses/priceLists/studio/priceListOptions/backgroundOptions';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { BaseService } from 'api/services/BaseService';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';

class StudioBackgroundOptionsService extends BaseService {
	async createStudioBackgroundOption(
		priceListKey: number,
		{
			name,
			sequence,
			retailPrice,
			referenceCode,
			backgroundImage,
		}: ICreateStudioBackgroundOption
	): Promise<IStudioBackgroundOption | null> {
		const formData = new FormData();

		formData.set('Name', name);
		formData.set('ReferenceCode', referenceCode);
		formData.set('Sequence', sequence.toString());
		formData.set('BackgroundImage', backgroundImage);
		formData.set('RetailPrice', retailPrice.toString());

		return this.post(
			priceListsUrls.studioFulfilled.backgroundOptions.createBackgroundOption(
				priceListKey
			),
			formData,
			{ ...multipartHeaders }
		);
	}

	async createDefaultBackgroundOption(
		priceListKey: number,
		body: ICreateStudioDefaultBackgroundOption
	): Promise<IStudioBackgroundOption | null> {
		return this.post(
			priceListsUrls.studioFulfilled.backgroundOptions.createDefaultBackgroundOption(
				priceListKey
			),
			body
		);
	}

	async updateBackgroundOption(
		backgroundOptionKey: number,
		body: IPatchBody[]
	): Promise<IStudioBackgroundOption | null> {
		return this.patch(
			priceListsUrls.studioFulfilled.backgroundOptions.getBackgroundOptionUrl(
				backgroundOptionKey
			),
			body
		);
	}

	async deleteBackgroundOption(
		backgroundOptionKey: number
	): Promise<string | null> {
		return this.delete(
			priceListsUrls.studioFulfilled.backgroundOptions.getBackgroundOptionUrl(
				backgroundOptionKey
			)
		);
	}

	async deleteBackgroundOptions(priceListKey: number): Promise<string | null> {
		return this.delete(
			priceListsUrls.studioFulfilled.backgroundOptions.deleteBackgroundOptions(
				priceListKey
			)
		);
	}

	async reorderBackgroundOptions(
		priceListKey: number,
		body: Record<number, number>
	): Promise<string | null> {
		return this.put(
			priceListsUrls.studioFulfilled.backgroundOptions.reorderBackgroundOptions(
				priceListKey
			),
			body
		);
	}
}

export default new StudioBackgroundOptionsService();
