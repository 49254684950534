import { FC, SyntheticEvent } from 'react';

import { useAsyncOptimizedCheckbox } from 'hooks/formControls/useAsyncOptimizedCheckbox';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { ProjectSpecialMessage } from 'components/Marketing/ProjectSpecialMessage';
import { CampaignsFieldsList } from 'components/Marketing/CampaignsFieldsList';
import { AccessCodeMessage } from 'components/Marketing/AccessCodeMessage';
import { MarketingHeader } from 'components/Marketing/MarketingHeader';
import { LazyTextArea } from 'components/FormControls/LazyTextArea';
import { InputGrid } from 'components/FormControls/InputGrid';
import { TextArea } from 'components/FormControls/TextArea';

import { CampaignsTarget } from 'api/models/responses/campaigns/campaign';
import { IPreset } from 'api/models/responses/presets/presets';

import { campaignsSelectOptionsSelector } from 'store/projects/selectors';

import { validateEmailSpecialMessage } from '../../helpers/validateEmailSpecialMessage';
import { validateSmsSpecialMessage } from '../../helpers/validateSmsSpecialMessage';
import { validateAccessCodeMessage } from '../../helpers/validateAccessCodeMessage';
import { JourneyPresetCampaign } from './JourneyPresetCampaign';
import {
	PresetErrors,
	UpdatePresetHandler,
	UpdateCampaignHandler,
} from '../../types';

interface IJourneyPresetMarketingProps {
	errors: PresetErrors;
	presetDetails: IPreset;
	updatePreset: UpdatePresetHandler;
	updateCampaign: UpdateCampaignHandler;
	clearPresetError: (key: keyof PresetErrors) => void;
}

export const JourneyPresetMarketing: FC<IJourneyPresetMarketingProps> = ({
	errors,
	updatePreset,
	presetDetails,
	updateCampaign,
	clearPresetError,
}) => {
	const campaignsSelectOptions = useAppSelector(campaignsSelectOptionsSelector);

	const {
		leadCampaign,
		buyerCampaign,
		prospectCampaign,
		smsSpecialMessage,
		accessCodeMessage,
		isMarketingEnabled,
		emailSpecialMessage,
	} = presetDetails;

	const leadCampaignsKey = leadCampaign.campaignKey;
	const buyerCampaignsKey = buyerCampaign.campaignKey;
	const prospectCampaignsKey = prospectCampaign.campaignKey;

	const disabledMarketing = !isMarketingEnabled;

	const updateSmsSpecialMessage = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		const validationMessage = validateSmsSpecialMessage(value);

		void updatePreset({
			value,
			validationMessage,
			key: 'smsSpecialMessage',
		});
	};

	const clearSmsSpecialMessageError = () => {
		clearPresetError('smsSpecialMessage');
	};

	const updateEmailSpecialMessage = (
		e: SyntheticEvent<HTMLTextAreaElement>
	) => {
		const { value } = e.currentTarget;

		const validationMessage = validateEmailSpecialMessage(value);

		void updatePreset({
			value,
			validationMessage,
			key: 'emailSpecialMessage',
		});
	};

	const clearEmailSpecialMessageError = () => {
		clearPresetError('emailSpecialMessage');
	};

	const updateAccessCodeMessage = (e: SyntheticEvent<HTMLTextAreaElement>) => {
		const { value } = e.currentTarget;

		const validationMessage = isMarketingEnabled
			? validateAccessCodeMessage(value)
			: '';

		void updatePreset({
			value,
			validationMessage,
			key: 'accessCodeMessage',
		});
	};

	const clearAccessCodeMessageError = () => {
		clearPresetError('accessCodeMessage');
	};

	const updateIsMarketingEnabled = async (value: boolean) => {
		await updatePreset({
			value,
			key: 'isMarketingEnabled',
		});
	};

	const { checked, isPending, handleChange } = useAsyncOptimizedCheckbox(
		isMarketingEnabled,
		updateIsMarketingEnabled
	);

	const CampaignsSelectList = campaignsSelectOptions.map(
		({ id, label, target, selectOptions }) => {
			const campaignsKeyValues: Record<CampaignsTarget, number> = {
				[CampaignsTarget.Lead]: leadCampaignsKey,
				[CampaignsTarget.Buyer]: buyerCampaignsKey,
				[CampaignsTarget.Prospect]: prospectCampaignsKey,
			};

			return (
				<JourneyPresetCampaign
					id={id}
					key={id}
					label={label}
					target={target}
					error={errors[id]}
					placeholder="Select Campaign"
					selectOptions={selectOptions}
					updateCampaign={updateCampaign}
					value={campaignsKeyValues[target]}
					clearPresetError={clearPresetError}
					disabledMarketing={disabledMarketing}
				/>
			);
		}
	);

	const SmsSpecialMessageField = (
		<InputGrid
			isLazy
			touched
			required={false}
			className="autorow"
			label="SMS Special Message"
			defaultValue={smsSpecialMessage}
			error={errors.smsSpecialMessage}
			placeholder="SMS Special Message"
			clearError={clearSmsSpecialMessageError}
			handleLazyChange={updateSmsSpecialMessage}
		/>
	);

	const EmailSpecialMessageField = (
		<TextArea
			isLazy
			touched
			subTextClassName="t-right"
			subText="500 Characters Max"
			label="Email Special Message"
			error={errors.emailSpecialMessage}
			defaultValue={emailSpecialMessage}
			placeholder="Email Special Message"
			clearError={clearEmailSpecialMessageError}
			handleLazyChange={updateEmailSpecialMessage}
		/>
	);

	return (
		<div className="project-section">
			<MarketingHeader
				disabled={isPending}
				handleChange={handleChange}
				isMarketingEnabled={checked}
			/>
			<CampaignsFieldsList>{CampaignsSelectList}</CampaignsFieldsList>
			<AccessCodeMessage touched error={errors.accessCodeMessage}>
				<LazyTextArea
					className="project-textarea"
					disabled={disabledMarketing}
					placeholder="Access Code Message"
					defaultValue={accessCodeMessage || ''}
					handleChange={updateAccessCodeMessage}
					clearError={clearAccessCodeMessageError}
				/>
			</AccessCodeMessage>
			<ProjectSpecialMessage
				SmsSpecialMessageField={SmsSpecialMessageField}
				EmailSpecialMessageField={EmailSpecialMessageField}
				toggled={true}
			/>
		</div>
	);
};
