import { createSelector } from 'reselect';

import { salesNotAvailableSelectOption } from 'constants/priceLists/selectOptions/salesNotAvailableSelectOption';
import { CampaignKeys } from 'constants/campaigns/campaignKeys';

import { isValidSubjectFormQuestions } from 'utils/subjectFormQuestions/isValidSubjectFormQuestions';
import { getPriceListSelectOptionKey } from 'utils/priceLists/getPriceListSelectOptionKey';

import { ISubjectFormQuestion } from 'api/models/responses/projects/subjectFormQuestion';
import { IUpdateCampaign } from 'api/models/requests/marketing/updateCampaign';
import { CampaignsTarget } from 'api/models/responses/campaigns/campaign';

import { ISelectOption } from 'types/ui/select';
import { RootState } from 'store';

const projectsStateSelector = (state: RootState) => state.projects;

export const organizationKeySelector = createSelector(
	projectsStateSelector,
	({ organizationKey }) => organizationKey
);

export const createdProjectSelector = createSelector(
	projectsStateSelector,
	({ createdProject }) => createdProject
);

export const projectCreationFormSelector = createSelector(
	projectsStateSelector,
	({ projectCreationForm }) => projectCreationForm
);

export const priceListsSale1Selector = createSelector(
	projectCreationFormSelector,
	({ priceListsSale1 }) => priceListsSale1
);

export const priceListsSale2Selector = createSelector(
	projectCreationFormSelector,
	({ priceListsSale2 }) => priceListsSale2
);

export const priceListsSale3Selector = createSelector(
	projectCreationFormSelector,
	({ priceListsSale3 }) => priceListsSale3
);

export const salesPriceListsSelector = createSelector(
	[priceListsSale1Selector, priceListsSale2Selector, priceListsSale3Selector],
	(priceListsSale1, priceListsSale2, priceListsSale3) => [
		priceListsSale1,
		priceListsSale2,
		priceListsSale3,
	]
);

export const priceListsSale1SelectOptionsSelector = createSelector(
	priceListsSale1Selector,
	(priceListsSale1) => [
		salesNotAvailableSelectOption,
		...priceListsSale1.map(
			({ priceListKey, priceListName, fulfillmentType }) => ({
				label: priceListName,
				value: getPriceListSelectOptionKey(priceListKey, fulfillmentType),
			})
		),
	]
);

export const priceListsSale2SelectOptionsSelector = createSelector(
	priceListsSale2Selector,
	(priceListsSale2) => [
		salesNotAvailableSelectOption,
		...priceListsSale2.map(
			({ priceListKey, priceListName, fulfillmentType }) => ({
				label: priceListName,
				value: getPriceListSelectOptionKey(priceListKey, fulfillmentType),
			})
		),
	]
);

export const priceListsSale3SelectOptionsSelector = createSelector(
	priceListsSale3Selector,
	(priceListsSale3) => [
		salesNotAvailableSelectOption,
		...priceListsSale3.map(
			({ priceListKey, priceListName, fulfillmentType }) => ({
				label: priceListName,
				value: getPriceListSelectOptionKey(priceListKey, fulfillmentType),
			})
		),
	]
);

export const salesPriceListsSelectOptionsSelector = createSelector(
	[
		priceListsSale1SelectOptionsSelector,
		priceListsSale2SelectOptionsSelector,
		priceListsSale3SelectOptionsSelector,
	],
	(
		priceListsSale1SelectOptions,
		priceListsSale2SelectOptions,
		priceListsSale3SelectOptions
	) => [
		priceListsSale1SelectOptions,
		priceListsSale2SelectOptions,
		priceListsSale3SelectOptions,
	]
);

export const prospectCampaignsListSelector = createSelector(
	projectCreationFormSelector,
	({ prospectCampaignsList }) => prospectCampaignsList
);

export const leadCampaignsListSelector = createSelector(
	projectCreationFormSelector,
	({ leadCampaignsList }) => leadCampaignsList
);

export const buyerCampaignsListSelector = createSelector(
	projectCreationFormSelector,
	({ buyerCampaignsList }) => buyerCampaignsList
);

export const targetProspectCampaignsListSelector = createSelector(
	prospectCampaignsListSelector,
	(prospectCampaignsList): IUpdateCampaign[] =>
		prospectCampaignsList.map(({ campaignKey }) => ({
			campaignKey,
			campaignTarget: CampaignsTarget.Prospect,
		}))
);

export const targetLeadCampaignListSelector = createSelector(
	leadCampaignsListSelector,
	(leadCampaignsList): IUpdateCampaign[] =>
		leadCampaignsList.map(({ campaignKey }) => ({
			campaignKey,
			campaignTarget: CampaignsTarget.Lead,
		}))
);

export const targetBuyerCampaignListSelector = createSelector(
	buyerCampaignsListSelector,
	(buyerCampaignsList): IUpdateCampaign[] =>
		buyerCampaignsList.map(({ campaignKey }) => ({
			campaignKey,
			campaignTarget: CampaignsTarget.Buyer,
		}))
);

export const prospectCampaignsListSelectOptionsSelector = createSelector(
	prospectCampaignsListSelector,
	(prospectCampaignsList): ISelectOption[] =>
		prospectCampaignsList.map(({ campaignKey, name }) => ({
			value: campaignKey,
			label: name,
		}))
);

export const buyerCampaignsListSelectOptionsSelector = createSelector(
	buyerCampaignsListSelector,
	(buyerCampaignsList): ISelectOption[] =>
		buyerCampaignsList.map(({ campaignKey, name }) => ({
			value: campaignKey,
			label: name,
		}))
);

export const leadCampaignsListSelectOptionsSelector = createSelector(
	leadCampaignsListSelector,
	(leadCampaignsList): ISelectOption[] =>
		leadCampaignsList.map(({ campaignKey, name }) => ({
			value: campaignKey,
			label: name,
		}))
);

export const campaignsSelectOptionsSelector = createSelector(
	[
		prospectCampaignsListSelectOptionsSelector,
		buyerCampaignsListSelectOptionsSelector,
		leadCampaignsListSelectOptionsSelector,
	],
	(
		prospectCampaignsListSelectOptions,
		buyerCampaignsListSelectOptions,
		leadCampaignsListSelectOptions
	) => [
		{
			label: 'Prospect Campaign',
			target: CampaignsTarget.Prospect,
			id: CampaignKeys.ProspectCampaignKey,
			selectOptions: prospectCampaignsListSelectOptions,
		},
		{
			label: 'Lead Campaign',
			target: CampaignsTarget.Lead,
			id: CampaignKeys.LeadCampaignKey,
			selectOptions: leadCampaignsListSelectOptions,
		},
		{
			label: 'Buyer Campaign',
			target: CampaignsTarget.Buyer,
			id: CampaignKeys.BuyerCampaignKey,
			selectOptions: buyerCampaignsListSelectOptions,
		},
	]
);

export const subjectFormQuestionsSelector = createSelector(
	projectCreationFormSelector,
	({ subjectFormQuestions }) => subjectFormQuestions
);

export const subjectFormQuestionsParamsSelector = createSelector(
	projectCreationFormSelector,
	({ subjectFormQuestions }): ISubjectFormQuestion[] =>
		subjectFormQuestions.map((subjectFormQuestion) => {
			const { localKey, error, ...rest } = subjectFormQuestion;

			return rest;
		}) as ISubjectFormQuestion[]
);

export const isValidSubjectFormQuestionsSelector = createSelector(
	projectCreationFormSelector,
	({ subjectFormQuestions }) =>
		isValidSubjectFormQuestions(subjectFormQuestions)
);

export const saleTypesSelector = createSelector(
	projectCreationFormSelector,
	({ saleType1, saleType2, saleType3 }) => [saleType1, saleType2, saleType3]
);
