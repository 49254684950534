import { useEffect, useState } from 'react';
import { toDataURL } from 'qrcode';

export const useQrCode = (url: string) => {
	const [qr, setQr] = useState('');

	useEffect(() => {
		toDataURL(url, {}, (error, qrUrl) => {
			if (error) return console.log(error);

			setQr(qrUrl);
		});
	}, [url]);

	return qr;
};
