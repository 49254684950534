import { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';

import { watermarkTypesSelectOptions } from 'constants/studioPhotoSettings/selectOptions/watermarkTypesSelectOptions';
import { watermarkImagePreviews } from 'constants/studioPhotoSettings/watermarkImagePreviews';
import {
	minWatermarkWidth,
	minWatermarkHeight,
} from 'constants/studioPhotoSettings/validation/watermarkDimensions';

import { validateFloatingImageDimensions } from 'utils/images/validateFloatingImageDimensions';
import { validateImageFile } from 'utils/validations/general/validateImageFile';

import { SelectComponent } from 'components/FormControls/Select';
import { Checkbox } from 'components/FormControls/Checkbox';
import { Button } from 'components/FormControls/Button';

import { WatermarkTypes } from 'api/models/responses/studio/studioPhotoSettings';
import { ISelectOption } from 'types/ui/select';

import { IEditWatermarkProps, IEditWatermarkValues } from '..';

export const EditWatermarkForm: FC<
	Pick<IEditWatermarkProps, 'customWatermarkUrl' | 'defaultWatermarkUrl'>
> = ({ customWatermarkUrl, defaultWatermarkUrl }) => {
	const [localCustomWatermarkUrl, setLocalCustomWatermarkUrl] = useState('');
	const [uploadErrorMessage, setUploadErrorMessage] = useState('');

	const { values, handleSubmit, setFieldValue } =
		useFormikContext<IEditWatermarkValues>();

	const { imageFile, watermarkType, availableWatermark } = values;

	const handleChangeImageFile = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];

		if (!file) return;

		const fileTypeErrorMessage = validateImageFile({
			type: file.type,
			validatePng: true,
		});

		if (fileTypeErrorMessage) {
			setUploadErrorMessage(fileTypeErrorMessage);
			return;
		}

		setUploadErrorMessage('');
		void setFieldValue('imageFile', file);
	};

	const handleChangeWatermarkType = (
		option: SingleValue<ISelectOption<WatermarkTypes>>
	) => {
		if (!option) return;

		setUploadErrorMessage('');
		void setFieldValue('watermarkType', option.value);
	};

	const handleChangeAvailableWatermark = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target;

		void setFieldValue('availableWatermark', checked);

		if (!checked) {
			setUploadErrorMessage('');
			void setFieldValue('watermarkType', WatermarkTypes.No);
			return;
		}

		void setFieldValue('watermarkType', WatermarkTypes.Single);
	};

	const handleDeleteCustomWatermark = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		e.preventDefault();

		setUploadErrorMessage('');
		setLocalCustomWatermarkUrl('');
		void setFieldValue('imageFile', null);

		if (customWatermarkUrl) {
			void setFieldValue('isCustomWatermarkDeleted', true);
		}
	};

	useEffect(() => {
		if (!imageFile) return;

		const reader = new FileReader();

		reader.onload = () => {
			const img = new Image();

			img.onload = () => {
				const { width, height } = img;

				const imageDimensionsErrorMessage = validateFloatingImageDimensions({
					width,
					height,
					minWidth: minWatermarkWidth,
					minHeight: minWatermarkHeight,
				});

				if (imageDimensionsErrorMessage) {
					setUploadErrorMessage(imageDimensionsErrorMessage);
					return;
				}

				setLocalCustomWatermarkUrl(reader.result as string);
			};

			img.src = reader.result as string;
		};

		reader.onerror = () => {
			console.log('There was an error loading the image.');
		};

		reader.readAsDataURL(imageFile);
	}, [imageFile]);

	useEffect(() => {
		if (localCustomWatermarkUrl) return;

		setLocalCustomWatermarkUrl(customWatermarkUrl);
	}, [customWatermarkUrl]);

	const url = localCustomWatermarkUrl || defaultWatermarkUrl;

	return (
		<div className="media-watermark-modal">
			<div className="media-watermark-container">
				<img
					alt="watermarked image"
					className="media-watermark-img"
					src={watermarkImagePreviews[watermarkType]}
				/>
				<div className="media-watermark-info">
					<Checkbox
						id="availableWatermark"
						label="Watermark Images:"
						checked={availableWatermark}
						handleChange={handleChangeAvailableWatermark}
					/>
					{availableWatermark && (
						<>
							<div className="media-watermark">
								<span className="media-watermark-size">
									<b>Watermark:</b>(500x500px)
								</span>
								<label htmlFor="watermark" className="media-watermark-drop">
									{/* TODO: add styles for seeing watermark image */}
									<div style={{ background: '#C0C0C0' }}>
										<img
											src={url}
											alt="watermark"
											className="media-watermark-mark"
										/>
									</div>
									{!localCustomWatermarkUrl && (
										<input
											type="file"
											accept=".png"
											id="watermark"
											onChange={handleChangeImageFile}
										/>
									)}
									{localCustomWatermarkUrl && (
										<button
											className="org-cover-btn"
											onClick={handleDeleteCustomWatermark}
										>
											<i className="org-cover-icon icon-delete" />
										</button>
									)}
								</label>
							</div>
							{/* TODO: add styles */}
							<span style={{ color: 'red' }}>{uploadErrorMessage}</span>
							<div className="media-watermark-select">
								<SelectComponent
									value={watermarkType}
									label="Show Watermark:"
									onChange={handleChangeWatermarkType}
									selectOptions={watermarkTypesSelectOptions}
								/>
							</div>
						</>
					)}
				</div>
			</div>
			<Button
				handleClick={handleSubmit}
				value="Apply to All Images"
				className="btn-primary media-watermark-btn"
			/>
		</div>
	);
};
