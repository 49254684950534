import { IHeaderConfig } from 'pages/PriceLists/components/PriceTable';

export const imagoTableHeaders = (isPackage?: boolean): IHeaderConfig[] => [
	{
		left: true,
		name: 'Name',
		required: true,
	},
	{
		left: true,
		required: true,
		name: 'Retail Value',
	},
	{
		left: true,
		required: true,
		name: isPackage ? 'Package' : 'Product',
	},
	{
		name: 'Fulfillment',
		suptitle: 'product costs',
	},
	{
		name: 'Service Fee',
		suptitle: 'order processing (incl. credit card)',
	},
	{
		name: 'Profit',
	},
];
