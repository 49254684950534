import { useCallback, useState } from 'react';

import { DataOrder } from 'constants/general/dataOrder';
import { ISortParams } from 'types/sortParams';

export interface SortParamsResult<T>
	extends Omit<ISortParams<T>, 'skip' | 'take'> {
	setSortParams: (sortLabel: T) => void;
}

export const useSortParams = <T extends string>(
	defaultOrderBy: T
): SortParamsResult<T> => {
	const [orderDirection, setOrderDirection] = useState(DataOrder.ASC);
	const [orderBy, setOrderBy] = useState<T>(defaultOrderBy);

	const setSortParams = useCallback(
		(sortLabel: T) => {
			if (sortLabel === orderBy) {
				const updatedOrderDirection =
					orderDirection === DataOrder.ASC ? DataOrder.DESC : DataOrder.ASC;

				return setOrderDirection(updatedOrderDirection);
			}

			setOrderBy(sortLabel);
			setOrderDirection(DataOrder.ASC);
		},
		[orderBy, orderDirection]
	);

	return { orderBy, setSortParams, orderDirection };
};
