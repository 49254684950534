import { useState, useCallback, useLayoutEffect } from 'react';

import { WindowSizes } from 'constants/general/windowSizes';

interface IDefineDeviceResult {
	isDesktop: boolean;
	isTablet: boolean;
	isMobile: boolean;
}

interface ISize {
	width: number;
	height: number;
}

const initialSize: ISize = {
	width: window.innerWidth,
	height: window.innerHeight,
};

export const useDefineDevice = (): IDefineDeviceResult => {
	const [size, setSize] = useState<ISize>(initialSize);

	const updateSize = useCallback(
		() =>
			setSize({
				width: window.innerWidth,
				height: window.innerHeight,
			}),
		[]
	);

	const isDesktop = size.width >= WindowSizes.TABLET;

	const isTablet =
		size.width < WindowSizes.TABLET && size.width >= WindowSizes.MOBILE;

	const isMobile = size.width < WindowSizes.MOBILE;

	useLayoutEffect(() => {
		updateSize();

		window.addEventListener('resize', updateSize);

		return () => window.removeEventListener('resize', updateSize);
	}, [updateSize]);

	return {
		isDesktop,
		isTablet,
		isMobile,
	};
};
