import { ISelectOption } from 'types/ui/select';

import { PriceListFulfillment } from '../priceListFulfillment';

export const salesNotAvailableSelectOption: ISelectOption<string> = {
	label: 'Sales not available',
	value: `${0}-${PriceListFulfillment.NA}`,
};

export const salesNotAvailableParam = {
	priceListKey: null,
	fulfillmentType: PriceListFulfillment.NA,
};
