import { createSelector } from 'reselect';

import { RootState } from 'store/store';
import { IAsideState } from './types';

export const asideSelector = (state: RootState): IAsideState => state.aside;

export const isOpenSelector = createSelector(
	asideSelector,
	({ isOpen }) => isOpen
);
