import { FC } from 'react';

interface ILabel {
	label: string;
	isRequired: boolean;
}

const labels: ILabel[] = [
	{ label: '', isRequired: false },
	{ label: 'question', isRequired: true },
	{ label: 'data field', isRequired: true },
	{ label: 'response options', isRequired: false },
];

export const SubjectFormLabels: FC = () => {
	const LabelsList = labels.map(({ label, isRequired }) => (
		<span className="project-form-title" key={label}>
			{label} {isRequired && <i>*</i>}
		</span>
	));

	return <div className="project-form-container">{LabelsList}</div>;
};
