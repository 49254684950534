import { ProjectTypes } from 'api/models/requests/projects/projectTypes';

export const projectTypesUI = {
	[ProjectTypes.LeagueSports]: 'League Sports',
	[ProjectTypes.SchoolSports]: 'School Sports',
	[ProjectTypes.DanceStudio]: 'Dance Studio',
	[ProjectTypes.PreSchoolDaycare]: 'Pre-School + Daycare',
	[ProjectTypes.ElementarySchool]: 'Elementary School',
	[ProjectTypes.JrHighMiddleSchool]: 'Jr. High/Middle School',
	[ProjectTypes.HighSchool]: 'High School',
	[ProjectTypes.K12School]: 'K-12 School',
	[ProjectTypes.Seniors]: 'Seniors',
	[ProjectTypes.Graduation]: 'Graduation',
	[ProjectTypes.PromFormalDance]: 'Prom / Formal Dance',
	[ProjectTypes.YearbookSales]: 'Yearbook Sales',
	[ProjectTypes.SummerCamp]: 'Summer Camp',
	[ProjectTypes.Event]: 'Event',
};
