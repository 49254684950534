import { FC } from 'react';

interface IDeleteBtnProps {
	handleClick?: () => void;
}

export const DeleteBtn: FC<IDeleteBtnProps> = ({ handleClick }) => (
	<button className="price-btn-delete" onClick={handleClick}>
		<i className="price-icon-table icon-delete" />
	</button>
);
