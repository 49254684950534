import { ReactDatePickerProps } from 'react-datepicker';
import moment from 'moment';

type DatePickerConfig = Partial<ReactDatePickerProps>;

const minDate = moment().startOf('day').toDate();

const getMinTime = (minTimeH: number) =>
	moment()
		.set({
			hour: minTimeH,
			minute: 0,
			seconds: 0,
		})
		.toDate();

const getMaxTime = (maxTimeH: number) =>
	moment()
		.set({
			hour: maxTimeH,
			minute: 0,
			seconds: 0,
		})
		.toDate();

export const classicDatePickerConfig: DatePickerConfig = {
	minDate,
	dateFormat: 'MM/dd/yy',
	className: 'datepicker',
	placeholderText: 'mm/dd/yy',
};

export const timeDatePickerConfig: DatePickerConfig = {
	minDate,
	timeIntervals: 15,
	showTimeSelect: true,
	className: 'datepicker',
	dateFormat: 'MM/dd/yy   h:mm aa',
	placeholderText: 'mm/dd/yy h:mm aa',
};

export const getOnlyTimeDatePickerConfig = (
	minTimeH?: number,
	maxTimeH?: number
): DatePickerConfig => ({
	timeIntervals: 15,
	timeCaption: 'Time',
	showTimeSelect: true,
	dateFormat: 'h:mm aa',
	className: 'datepicker',
	showTimeSelectOnly: true,
	minTime: minTimeH ? getMinTime(minTimeH) : undefined,
	maxTime: maxTimeH ? getMaxTime(maxTimeH) : undefined,
});
