import {
	emailRegEx,
	emailErrorMessage,
} from 'constants/general/validation/emailValidation';

export const validateSendToEmails = (emails: string) => {
	const isValidEmails = emails
		.trim()
		.split(',')
		.filter((email) => email)
		.map((email) => {
			const trimEmail = email.trim();

			const isValidEmail = emailRegEx.test(trimEmail);

			return isValidEmail;
		})
		.every((email) => email);

	return isValidEmails ? '' : emailErrorMessage;
};
