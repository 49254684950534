import { Dispatch } from '@reduxjs/toolkit';

import SubscriptionsService from 'api/services/SubscriptionsService';

import { setSubscription } from './subscriptionsSlice';

export const getSubscriptionAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await SubscriptionsService.getSubscription();

		if (!data) return;

		dispatch(setSubscription(data));
	} catch (error) {
		console.log(error);
	}
};
