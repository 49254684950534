import { SearchPhotoByParameters } from 'api/models/requests/photos/searchByParameters';

export const searchPhotoByParametersUI: Record<
	SearchPhotoByParameters,
	string
> = {
	[SearchPhotoByParameters.ParticipantName]: 'Name',
	[SearchPhotoByParameters.AccessCode]: 'Access Code',
	[SearchPhotoByParameters.EmailAddress]: 'Email Address',
	[SearchPhotoByParameters.PhoneNumber]: 'Phone Number',
	[SearchPhotoByParameters.ImageName]: 'Image Name',
	[SearchPhotoByParameters.GroupName]: 'Group Name',
	[SearchPhotoByParameters.DownloadOrdered]: 'Download Ordered',
	[SearchPhotoByParameters.ImageDownloaded]: 'Image Downloaded',
};
