import { SingleValue } from 'react-select';
import { FC } from 'react';

import { projectJourneyFilterSelectOptions } from 'constants/projects/selectOptions/projectJourneysSelectOptions';
import { targetFilterSelectOptions } from 'constants/campaigns/targetSelectOptions';

import { JourneyFilterSelectOption } from 'types/projects/selectOptions/journeyFilterSelectOption';
import { ISelectOption } from 'types/ui/select';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { SelectComponent } from 'components/FormControls/Select';
import {
	TargetFilterSelectOption,
	setTargetFilterSelectOption,
	setJourneyFilterSelectOption,
	targetFilterSelectOptionSelector,
	journeyFilterSelectOptionSelector,
} from 'store/campaigns';

export const CampaignsHeader: FC = () => {
	const journeyFilterSelectOption = useAppSelector(
		journeyFilterSelectOptionSelector
	);
	const targetFilterSelectOption = useAppSelector(
		targetFilterSelectOptionSelector
	);

	const dispatch = useAppDispatch();

	const handleChangeTarget = (
		option: SingleValue<ISelectOption<TargetFilterSelectOption>>
	) => {
		if (!option) return;

		dispatch(setTargetFilterSelectOption(option.value));
	};

	const handleChangeJourney = (
		option: SingleValue<ISelectOption<JourneyFilterSelectOption>>
	) => {
		if (!option) return;

		dispatch(setJourneyFilterSelectOption(option.value));
	};

	return (
		<div className="campaign-header">
			<div className="campaign-select">
				<SelectComponent
					label="Campaign Target"
					onChange={handleChangeTarget}
					value={targetFilterSelectOption}
					selectOptions={targetFilterSelectOptions}
				/>
			</div>
			<div className="campaign-select">
				<SelectComponent
					label="Project Journey"
					onChange={handleChangeJourney}
					value={journeyFilterSelectOption}
					selectOptions={projectJourneyFilterSelectOptions}
				/>
			</div>
		</div>
	);
};
