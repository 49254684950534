import { FC, SyntheticEvent } from 'react';

import { validateOptionName } from 'utils/validations/priceLists/validateOptionName';
import { LazyInput } from 'components/FormControls/LazyInput';

interface INameColumnProps {
	name: string;
	nameError: string;
	clearError: () => void;
	updateName: (name: string, validationMessage: string) => void;
}

export const NameColumn: FC<INameColumnProps> = ({
	name,
	nameError,
	updateName,
	clearError,
}) => {
	const handleChangeName = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		const trimmedValue = value.trim();

		const validationMessage = validateOptionName(trimmedValue);

		updateName(trimmedValue, validationMessage);
	};

	return (
		<td className="price-option-td">
			<LazyInput
				error={nameError}
				placeholder="Name"
				defaultValue={name}
				clearError={clearError}
				className="input exp-wrapper"
				handleChange={handleChangeName}
			/>
		</td>
	);
};
