import { createSelector } from 'reselect';

import { RootState } from 'store/store';

const studioBillingSelector = (state: RootState) => state.studioBilling;

export const isBankACHFulfilledSelector = createSelector(
	studioBillingSelector,
	({ isBankACHFulfilled }) => isBankACHFulfilled
);

export const isStudioBillingFulfilledSelector = createSelector(
	studioBillingSelector,
	({ iStudioBillingFulfilled }) => iStudioBillingFulfilled
);
