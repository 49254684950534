import { FC } from 'react';

import { useAsyncOptimizedSelect } from 'hooks/formControls/useAsyncOptimizedSelect';
import { SelectComponent } from 'components/FormControls/Select';
import { ISelectOption } from 'types/ui/select';

interface IProductSelectColumnProps {
	error: string;
	placeholder: string;
	catalogProductKey: number;
	clearProductError: () => void;
	selectOptions: ISelectOption[];
	updateSelectValue: (value: number) => Promise<void>;
}

export const ProductSelectColumn: FC<IProductSelectColumnProps> = ({
	error,
	placeholder,
	selectOptions,
	catalogProductKey,
	updateSelectValue,
	clearProductError,
}) => {
	const { value, isPending, handleChange } = useAsyncOptimizedSelect({
		updateSelectValue,
		clearError: clearProductError,
		initialValue: catalogProductKey,
	});

	return (
		<td className="price-option-td">
			<SelectComponent
				touched
				value={value}
				error={error}
				disabled={isPending}
				onChange={handleChange}
				selectOptions={selectOptions}
				selectPlaceholder={placeholder}
			/>
		</td>
	);
};
