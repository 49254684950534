import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Formik, FormikHelpers } from 'formik';
import { utc } from 'moment';

import { getPriceListSelectOptionKey } from 'utils/priceLists/getPriceListSelectOptionKey';
import { getProcessedErrors } from 'utils/errors/getProcessedErrors';
import { getInitialDate } from 'utils/dates/getInitialDate';

import { IUpdateProjectSale } from 'api/models/requests/projects/updateProjectSale';
import { IPriceListRequestDto } from 'api/models/requests/priceLists/priceListDto';
import { ISale } from 'api/models/responses/projects/sales';
import ProjectsService from 'api/services/ProjectsService';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/errors/errorResponse';

import { PriceListFulfillment } from 'constants/priceLists/priceListFulfillment';

import { SaleDetailsForm } from './components/SaleDetailsForm';
import { editSaleScheme } from './validation';

export interface IEditSaleValues {
	priceListKey: string;
	saleEndDate: Date | null;
	saleStartDate: Date | null;
	isKioskModeEnabled: boolean;
	orderNotificationEmail: string;
	orderConfirmationMessage: string;
}

interface IEditSaleModalProps {
	saleKey: number;
	hideEditSale: () => void;
	setIsUpdateProject: (isUpdate: boolean) => void;
	setIsUpdateTimeline: (isUpdated: boolean) => void;
}

export const SaleDetails: FC<IEditSaleModalProps> = ({
	saleKey,
	hideEditSale,
	setIsUpdateProject,
	setIsUpdateTimeline,
}) => {
	const [sale, setSale] = useState<ISale | null>(null);

	const [isPending, setIsPending] = useState(false);

	const editorRef = useRef<CKEditor<Editor>>(null);

	const getProjectSaleAsync = useCallback(async () => {
		setIsPending(true);

		try {
			if (!saleKey) return;

			const data = await ProjectsService.getProjectSale(saleKey);

			setSale(data);
		} catch (error) {
			console.log(error);
		}

		setIsPending(false);
	}, [saleKey]);

	useEffect(() => {
		void getProjectSaleAsync();
	}, [getProjectSaleAsync]);

	const saleStartDateInitial = getInitialDate(sale?.saleStartDate);
	const saleEndDateInitial = getInitialDate(sale?.saleEndDate);

	const allowedPriceListsInitial = sale?.allowedPriceLists || [];
	const projectGreetingInitial = sale?.projectGreeting || '';
	const priceListKeyInitial =
		(sale &&
			getPriceListSelectOptionKey(
				sale.priceListResponse.priceListKey,
				sale.priceListResponse.fulfillmentType
			)) ||
		'';

	const initialValues: IEditSaleValues = {
		saleEndDate: saleEndDateInitial,
		priceListKey: priceListKeyInitial,
		saleStartDate: saleStartDateInitial,
		isKioskModeEnabled: !!sale?.isKioskModeEnabled,
		orderNotificationEmail: sale?.orderNotificationEmail || '',
		orderConfirmationMessage: sale?.orderConfirmationMessage || '',
	};

	const onSubmit = async (
		values: IEditSaleValues,
		{ setErrors }: FormikHelpers<IEditSaleValues>
	) => {
		if (!sale) return;

		const {
			saleEndDate,
			priceListKey,
			saleStartDate,
			isKioskModeEnabled,
			orderNotificationEmail,
			orderConfirmationMessage,
		} = values;

		const saleEndDateUtc = saleEndDate && utc(saleEndDate).toISOString();
		const saleStartDateUtc = saleStartDate && utc(saleStartDate).toISOString();

		const projectGreetingParam = editorRef.current?.editor?.data.get() || '';

		const searchedPriceList = sale.allowedPriceLists.find((priceList) => {
			const [key, fulfillment] = priceListKey.split('-');

			return (
				priceList.priceListKey === +key &&
				priceList.fulfillmentType === fulfillment
			);
		});

		const priceListRequestDto: IPriceListRequestDto = {
			priceListKey: searchedPriceList?.priceListKey || null,
			fulfillmentType:
				searchedPriceList?.fulfillmentType || PriceListFulfillment.NA,
		};

		const requestBody: IUpdateProjectSale = {
			isKioskModeEnabled,
			priceListRequestDto,
			orderNotificationEmail,
			orderConfirmationMessage,
			saleEndDate: saleEndDateUtc,
			saleStartDate: saleStartDateUtc,
			projectGreeting: projectGreetingParam,
		};

		try {
			await ProjectsService.updateProjectSale(saleKey, requestBody);

			hideEditSale();
			setIsUpdateProject(true);
			setIsUpdateTimeline(true);
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (type === ErrorTypes.BusinessError) {
				const processedErrors = getProcessedErrors(values, errors);

				setErrors(processedErrors);
			}
		}
	};

	return (
		<Formik
			enableReinitialize
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={editSaleScheme}
		>
			<SaleDetailsForm
				isPending={isPending}
				editorRef={editorRef}
				projectGreetingInitial={projectGreetingInitial}
				allowedPriceListsInitial={allowedPriceListsInitial}
			/>
		</Formik>
	);
};
