import { FC, ReactNode } from 'react';
import cn from 'classnames';

interface IToggleProps {
	title: string;
	isOpen: boolean;
	subtitle?: string;
	className: string;
	header?: ReactNode;
	children: ReactNode;
	handleToggle: () => void;
}

export const ToggleSection: FC<IToggleProps> = ({
	title,
	isOpen,
	header,
	subtitle,
	children,
	className,
	handleToggle,
}) => (
	<>
		<div className={className}>
			<h1
				className={cn('org-search-title', {
					'is-active': isOpen,
				})}
				onClick={handleToggle}
			>
				{title}
				{subtitle && <span className="org-subtitle">{subtitle}</span>}
			</h1>
			{header}
		</div>
		{isOpen && children}
	</>
);
