import { ChargeOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/additionalChargeOption';
import { ISelectOption } from 'types/ui/select';

import { chargeOptionTypesUI } from '../chargeOptionTypesUI';

export const chargeOptionTypesSelectOptions: ISelectOption<ChargeOptionTypes>[] =
	Object.values(ChargeOptionTypes).map((type) => ({
		value: type,
		label: chargeOptionTypesUI[type],
	}));
