export const validateExpirationDate = (
	prevValue: string,
	currentValue: string
) => {
	const isRepeatedSlash =
		currentValue
			.split('')
			.reduce((acc, char) => (char === '/' ? (acc += 1) : acc), 0) > 1;

	const excludeSlashValue =
		currentValue.length >= 3
			? currentValue
					.split('')
					.filter((char) => char !== '/')
					.join('')
			: currentValue;

	const isNumber = /^[0-9]+$/.test(excludeSlashValue);

	if (currentValue.length === 6) {
		return prevValue;
	}

	if (currentValue.length >= 3 && isRepeatedSlash) {
		return prevValue;
	}

	if (!isNumber && currentValue) {
		return prevValue;
	}

	if (prevValue.length === 3 && currentValue.length === 2) {
		return currentValue.slice(0, 1);
	}

	if (currentValue.length === 2) {
		if (+currentValue > 12) {
			return '12/';
		}

		if (+currentValue < 1) {
			return '01/';
		}

		return `${currentValue}/`;
	}

	return currentValue;
};
