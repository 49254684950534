import { FC } from 'react';

interface IMultiCellProps {
	values: string[];
}

export const MultiCell: FC<IMultiCellProps> = ({ values }) => {
	const List = values.map((value) => (
		<span key={value} className="project-table-name" title={value}>
			{value}
		</span>
	));

	return <div>{List}</div>;
};
