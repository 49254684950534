import { FC } from 'react';

import { IProjectJourneyInfo } from 'constants/projects/projectJourneyInfo';

export const JourneyInfo: FC<
	Omit<
		IProjectJourneyInfo,
		| 'validatePriceListSale1'
		| 'validatePriceListSale2'
		| 'validatePriceListSale3'
		| 'salesTypes'
	>
> = ({ sales, description }) => {
	const ProjectJourneyItemsList = sales.map(({ icon, title, text }) => (
		<li key={`${title}-${icon}`} className="project-journey-item">
			<i className={`project-journey-symbol ${icon}`} />
			<b>{title}</b> - {text}
		</li>
	));

	return (
		<div className="project-journey-info">
			<ul className="project-journey-list">{ProjectJourneyItemsList}</ul>
			<p className="project-journey-par">{description}</p>
			<a
				target="_blank"
				rel="noreferrer"
				className="project-journey-link"
				href="https://get.theimago.io/journeys/"
			>
				learn more
			</a>
		</div>
	);
};
