import { BroadcastDetails } from 'pages/Campaigns/pages/BroadcastDetails';
import { CampaignDetails } from 'pages/Campaigns/pages/CampaignDetails';
import { Campaigns } from 'pages/Campaigns/pages/Campaigns';

import { ROUTES } from 'constants/ROUTES';

export const campaignsRoutesList = [
	{
		path: ROUTES.CAMPAIGNS.CAMPAIGNS,
		element: <Campaigns />,
	},
	{
		path: ROUTES.CAMPAIGNS.CAMPAIGN_DETAILS,
		element: <CampaignDetails />,
	},
	{
		path: ROUTES.CAMPAIGNS.BROADCAST_DETAILS,
		element: <BroadcastDetails />,
	},
	{
		path: ROUTES.CAMPAIGNS.CREATE_BROADCAST,
		element: <BroadcastDetails />,
	},
];
