import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';

import { validatePercentValue } from 'utils/validations/priceLists/validatePercentValue';
import { decimalDigitsRegex } from 'constants/general/validation/generalRegEx';
import { EnterCode } from 'constants/general/keyboardCodes';

interface ILazyPercentInputParams {
	initialValue?: number;
	clearError: () => void;
	handleUpdate: (
		value: number,
		validationMessage: string
	) => Promise<void> | void;
}

interface ILazyPercentInputResult {
	value: string;
	handleBlur: () => void;
	handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
	handleKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
}

export const useLazyPercentInput = ({
	clearError,
	initialValue,
	handleUpdate,
}: ILazyPercentInputParams): ILazyPercentInputResult => {
	const [value, setValue] = useState('0%');
	const [isEditing, setIsEditing] = useState(false);

	const valueNumber = +value.replace('%', '');

	const isEqual = valueNumber === initialValue;
	const isUndefined = initialValue === undefined;

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		let { value: inputValue } = e.target;

		if (!isEditing) {
			inputValue = inputValue.replace('%', '');
			setIsEditing(true);
		}

		if (decimalDigitsRegex.test(inputValue) || inputValue === '') {
			setValue(inputValue);
			clearError();
		}
	};

	const handleBlur = () => {
		if (!isEqual) {
			const validationMessage = validatePercentValue(valueNumber);

			void handleUpdate(valueNumber, validationMessage);
		}

		setValue(`${valueNumber}%`);
		setIsEditing(false);
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		const { code } = e;

		if (code !== EnterCode) return;

		handleBlur();
	};

	useEffect(() => {
		if (isEqual || isUndefined) return;

		setValue(`${initialValue}%`);
	}, [initialValue]);

	return {
		value,
		handleBlur,
		handleChange,
		handleKeyDown,
	};
};
