import {
	CellActionMenu,
	ICellActionMenuProps,
} from 'components/Table/CellActionMenu';

interface IActionMenuControlColumnProps<T> extends ICellActionMenuProps<T> {
	isDefault?: boolean;
}

export const ActionMenuControlColumn = <T extends string>({
	menuList,
	isDefault,
	showActionMenu,
	setActiveActionMenu,
	handleMenuItemClick,
}: IActionMenuControlColumnProps<T>) => (
	<td className="price-option-td">
		{isDefault ? (
			<span className="price-btn-default">Default</span>
		) : (
			<CellActionMenu
				menuList={menuList}
				showActionMenu={showActionMenu}
				handleMenuItemClick={handleMenuItemClick}
				setActiveActionMenu={setActiveActionMenu}
			/>
		)}
	</td>
);
