import { FC } from 'react';

export const W9Header: FC = () => (
	<>
		<tr className="acc-tr">
			<td className="acc-td-header">
				<p className="acc-main-title">
					From <span>W-9</span>
					<br />
					<b>(Rev. December 2014)</b>
					Department of the
					<br />
					Treasury
					<br />
					Internal Revenue
					<br />
					Service
				</p>
			</td>
			<td className="acc-td-header" colSpan={3}>
				<span className="acc-sub-title">Request for Taxpayer</span>
				<span className="acc-sub-title">
					Identification Number and Certification
				</span>
			</td>
			<td className="acc-td-header" colSpan={2}>
				<span className="acc-sup-title">
					Give Form to the
					<br />
					Requester. Do not
					<br />
					send to the IRS
				</span>
			</td>
		</tr>
		<tr className="acc-tr">
			<th className="acc-th" colSpan={6}>
				<span className="acc-notif">
					Please check over all the information and correct any values that are
					incorrect before submitting.
				</span>
			</th>
		</tr>
	</>
);
