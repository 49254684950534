import { useFormikContext } from 'formik';
import { FC } from 'react';

import { ICreateBlankProjectValues } from 'pages/Projects/pages/CreateBlankProject';
import { DateItem } from 'pages/Projects/components/DateItem';

import { journeyTimelines } from 'constants/projects/timeline/journeyTimelines';
import { JourneyTimeline } from 'components/Journey/JourneyTimeline';

export const ProjectJourneyDates: FC = () => {
	const { values, errors, touched, setFieldValue } =
		useFormikContext<ICreateBlankProjectValues>();

	const {
		journeyText,
		journeyDate,
		journeyTitle,
		journeySubText,
		journeyBlankProjectImage,
	} = journeyTimelines[values.journey];

	const handleChange = (id: string, date: Date) => {
		void setFieldValue(id, date);
	};

	const JourneyDatesFieldList = journeyDate.journeyBlankProjectDates.map(
		({ id, icon, label }) => {
			const typedId = id as keyof ICreateBlankProjectValues;

			const value = values[typedId] as Date | null;
			const error = errors[typedId] || '';
			const isTouched = !!touched[typedId];

			return (
				<DateItem
					key={id}
					id={id}
					icon={icon}
					label={label}
					value={value}
					error={error}
					touched={isTouched}
					handleChange={(date: Date) => handleChange(id, date)}
					wrapperClassName="project-section-wrapper project-section-datepicker"
				/>
			);
		}
	);

	return (
		<div className="project-section journey">
			<h2 className="project-section-title">project journey dates</h2>
			<div className="project-section-container">
				<JourneyTimeline
					journeyText={journeyText}
					journeyTitle={journeyTitle}
					journeySubText={journeySubText}
					journeyImage={journeyBlankProjectImage}
				/>
				<div className="project-section-form">{JourneyDatesFieldList}</div>
			</div>
		</div>
	);
};
