export const removeDomainFromUrl = (url: string) => {
	try {
		const { pathname, search } = new URL(url);

		return `${pathname}${search}`;
	} catch (error) {
		if (error instanceof TypeError) {
			return url;
		}
	}

	return url;
};
