import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IAsideState } from './types';

const initialState: IAsideState = {
	isOpen: false,
};

const asideSlice = createSlice({
	name: 'aside',
	initialState,
	reducers: {
		setIsOpen: (state, { payload }: PayloadAction<boolean>) => {
			state.isOpen = payload;
		},
	},
});

export const asideReducer = asideSlice.reducer;
export const { setIsOpen } = asideSlice.actions;
