import { createSelector } from 'reselect';

import { poseOptionTypesSelectOptions } from 'constants/priceLists/studio/selectOptions/poseOptionTypesSelectOptions';
import { PoseOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/poseOption';
import { RootState } from 'store/store';

export const poseOptionsStateSelector = (state: RootState) =>
	state.priceListPoseOptions;

export const groupedPoseOptionsSelector = createSelector(
	poseOptionsStateSelector,
	({ groupedPoseOptions }) => groupedPoseOptions
);

export const addedPoseOptionTypesSelector = createSelector(
	poseOptionsStateSelector,
	({ addedPoseOptionTypes }) => addedPoseOptionTypes
);

export const showStyleOptionsSelector = createSelector(
	[groupedPoseOptionsSelector, addedPoseOptionTypesSelector],
	(groupedPoseOptions, addedPoseOptionTypes) =>
		!!groupedPoseOptions.Style.length || addedPoseOptionTypes.Style
);

export const showPoseOptionsSelector = createSelector(
	[groupedPoseOptionsSelector, addedPoseOptionTypesSelector],
	(groupedPoseOptions, addedPoseOptionTypes) =>
		!!groupedPoseOptions.Pose.length || addedPoseOptionTypes.Pose
);

export const showColorFavorOptionsSelector = createSelector(
	[groupedPoseOptionsSelector, addedPoseOptionTypesSelector],
	(groupedPoseOptions, addedPoseOptionTypes) =>
		!!groupedPoseOptions.ColorFlavor.length || addedPoseOptionTypes.ColorFlavor
);

export const filteredPoseOptionTypesSelectOptionsSelector = createSelector(
	[
		showStyleOptionsSelector,
		showPoseOptionsSelector,
		showColorFavorOptionsSelector,
	],
	(showStyleOptions, showPoseOptions, showColorFavorOptions) =>
		poseOptionTypesSelectOptions.filter(({ value }) => {
			const poseOptionsExist =
				value === PoseOptionTypes.Pose && showPoseOptions;

			const styleOptionsExist =
				value === PoseOptionTypes.Style && showStyleOptions;

			const colorFavorOptionsExist =
				value === PoseOptionTypes.ColorFlavor && showColorFavorOptions;

			if (poseOptionsExist || styleOptionsExist || colorFavorOptionsExist)
				return false;

			return true;
		})
);

export const showPoseCheckboxSelector = createSelector(
	groupedPoseOptionsSelector,
	(groupedPoseOptions) => !!groupedPoseOptions[PoseOptionTypes.Pose].length
);

export const showStyleCheckboxSelector = createSelector(
	groupedPoseOptionsSelector,
	(groupedPoseOptions) => !!groupedPoseOptions[PoseOptionTypes.Style].length
);

export const showColorFlavorCheckboxSelector = createSelector(
	groupedPoseOptionsSelector,
	(groupedPoseOptions) =>
		!!groupedPoseOptions[PoseOptionTypes.ColorFlavor].length
);

export const initialRequiredPoseOptionsSelector = createSelector(
	[
		showPoseCheckboxSelector,
		showStyleCheckboxSelector,
		showColorFlavorCheckboxSelector,
	],
	(showPoseCheckbox, showStyleCheckbox, showColorFlavorCheckbox) => {
		if (!showPoseCheckbox && !showStyleCheckbox && !showColorFlavorCheckbox) {
			return `${PoseOptionTypes.None}`;
		}

		const requiredPoseOptionsArr = [];

		if (showPoseCheckbox) {
			requiredPoseOptionsArr.push(PoseOptionTypes.Pose);
		}

		if (showStyleCheckbox) {
			requiredPoseOptionsArr.push(PoseOptionTypes.Style);
		}

		if (showColorFlavorCheckbox) {
			requiredPoseOptionsArr.push(PoseOptionTypes.ColorFlavor);
		}

		if (requiredPoseOptionsArr.length === 1) {
			return requiredPoseOptionsArr[0];
		}

		return requiredPoseOptionsArr.join(', ');
	}
);
