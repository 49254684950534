import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';

import { ISearchedProject } from 'api/models/responses/projects/organizationProjects';
import {
	GeneralProjectSortLabels,
	SearchedProjectSortLabels,
	UnitedSearchedProjectSortLabels,
} from 'api/models/requests/projects/projectsParams';

import { ColumnLabelContainer } from 'components/Table/ColumnLabelContainer';
import { Table } from 'components/Table';

import { projectTypesUI } from 'constants/projects/projectTypesUI';
import { normalizeDate } from 'utils/ui/normalizeDate';
import { usePagination } from 'hooks/usePagination';
import { ISearchResult } from 'types/searchParams';
import { ROUTES } from 'constants/ROUTES';

interface ISearchProjectsResult
	extends Omit<
		ISearchResult<UnitedSearchedProjectSortLabels, ISearchedProject[]>,
		'isPending' | 'handleSearch'
	> {
	className: string;
}

export const SearchedProjectList = ({
	orderBy,
	results,
	className,
	setSortParams,
	orderDirection,
	setPaginatedEntity,
}: ISearchProjectsResult) => {
	const ref = usePagination(setPaginatedEntity);

	const columns: Column<ISearchedProject>[] = [
		{
			Header: (
				<ColumnLabelContainer<UnitedSearchedProjectSortLabels>
					label="Name"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={GeneralProjectSortLabels.ProjectName}
				/>
			),
			accessor: 'projectName',
			Cell: ({ row }: CellProps<ISearchedProject>) => {
				const { projectKey, projectName } = row.original;

				return (
					<Link
						title={projectName}
						className="card-table-link"
						to={`${ROUTES.PROJECTS.PROJECTS}/${projectKey}`}
					>
						{projectName}
					</Link>
				);
			},
		},
		{
			Header: (
				<ColumnLabelContainer<UnitedSearchedProjectSortLabels>
					orderBy={orderBy}
					label="Organization Name"
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={SearchedProjectSortLabels.OrganizationName}
				/>
			),
			accessor: 'organizationName',
			Cell: ({ row }: CellProps<ISearchedProject>) => {
				const { organizationKey, organizationName } = row.original;

				return (
					<Link
						title={organizationName}
						className="card-table-link"
						to={`${ROUTES.ORGANIZATIONS.ORGANIZATIONS}/${organizationKey}`}
					>
						{organizationName}
					</Link>
				);
			},
		},
		{
			Header: (
				<ColumnLabelContainer<UnitedSearchedProjectSortLabels>
					label="Project Type"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={GeneralProjectSortLabels.ProjectType}
				/>
			),
			accessor: 'projectType',
			Cell: ({
				value,
			}: CellProps<ISearchedProject, ISearchedProject['projectType']>) => (
				<>{projectTypesUI[value]}</>
			),
		},
		{
			Header: <>Sale</>,
			accessor: 'sale',
		},
		{
			Header: <>Images</>,
			accessor: 'imagesCount',
		},
		{
			Header: (
				<ColumnLabelContainer<UnitedSearchedProjectSortLabels>
					label="Publish Date"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={GeneralProjectSortLabels.PublishDate}
				/>
			),
			accessor: 'publishDate',
			Cell: ({
				value,
			}: CellProps<ISearchedProject, ISearchedProject['publishDate']>) => (
				<>{normalizeDate(value)}</>
			),
		},
		{
			Header: (
				<ColumnLabelContainer<UnitedSearchedProjectSortLabels>
					label="Expiration Date"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={GeneralProjectSortLabels.ExpireDate}
				/>
			),
			accessor: 'expireDate',
			Cell: ({
				value,
			}: CellProps<ISearchedProject, ISearchedProject['expireDate']>) => (
				<>{normalizeDate(value)}</>
			),
		},
	];

	return (
		<div className="org-project-search-container">
			<Table<ISearchedProject>
				data={results}
				columns={columns}
				className={className}
			/>
			<div ref={ref} style={{ height: '1px' }} />
		</div>
	);
};
