import { GiftAndAccessoriesProductCategoryTypes } from 'api/models/responses/catalogProducts/giftAndAccessoriesCatalogProduct';
import { IProduct } from 'api/models/responses/priceLists/imago/priceListProducts';

import { initialProductErrors } from 'constants/priceLists/imago/initialProductErrors';
import { IProductUI } from 'pages/PriceLists/types/imago/productUI';

export const getProductsUI = (
	products: IProduct[],
	key?: GiftAndAccessoriesProductCategoryTypes
): IProductUI[] =>
	products.map((product) => ({
		...product,
		key,
		errors: initialProductErrors,
	}));
