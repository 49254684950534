import { BackgroundOptionVisibilityTypes } from 'api/models/responses/priceLists/studio/priceListStudio';
import { ISelectOption } from 'types/ui/select';

import { backgroundOptionVisibilityTypesUI } from '../backgroundOptionVisibilityTypesUI';

export const backgroundOptionVisibilityTypesSelectOptions: ISelectOption<BackgroundOptionVisibilityTypes>[] =
	Object.values(BackgroundOptionVisibilityTypes).map((value) => ({
		value,
		label: backgroundOptionVisibilityTypesUI[value],
	}));
