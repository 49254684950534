import { IChangeSequencePayload } from 'hooks/useDragAndDrop';

interface IChangeEntitiesSequence<T> extends IChangeSequencePayload {
	entities: T[];
	fieldKey: keyof T;
}

export const changeEntitiesSequence = <T>({
	fieldKey,
	toBottom,
	entities,
	dragElemId,
	dropElemId,
}: IChangeEntitiesSequence<T>): T[] => {
	const entitiesCopy = [...entities];

	const dragElemIndex = entitiesCopy.findIndex(
		(entity) => +dragElemId === entity[fieldKey]
	);

	const dropElemIndex = entitiesCopy.findIndex(
		(entity) => +dropElemId === entity[fieldKey]
	);

	const removedDragElem = entitiesCopy.splice(dragElemIndex, 1);

	const start = toBottom ? dropElemIndex + 1 : dropElemIndex;

	entitiesCopy.splice(start, 0, removedDragElem[0]);

	const reorderedEntities = entitiesCopy.map((entity, index) => ({
		...entity,
		sequence: index,
	}));

	return reorderedEntities;
};
