import { Column } from 'react-table';
import { FC } from 'react';

import { NoItemsFound } from 'components/NoItemsFound';
import { Loader } from 'components/Loader';
import { Table } from 'components/Table';

import { IPreset } from 'api/models/responses/presets/presets';

interface IJourneyPresetsListProps {
	isPresetsPending: boolean;
	columns: Column<IPreset>[];
	sortedJourneyPresets: IPreset[];
}

export const JourneyPresetsList: FC<IJourneyPresetsListProps> = ({
	columns,
	isPresetsPending,
	sortedJourneyPresets,
}) => {
	const showPresetsTable = !isPresetsPending && !!sortedJourneyPresets.length;
	const showNoItemsFound = !isPresetsPending && !sortedJourneyPresets.length;

	if (isPresetsPending) {
		return <Loader />;
	}

	if (showNoItemsFound) {
		return (
			<div className="page-loader-container is-section">
				<NoItemsFound title="journey presets" />
			</div>
		);
	}

	if (showPresetsTable) {
		return (
			<div className="org-project-search-container">
				<Table
					columns={columns}
					data={sortedJourneyPresets}
					className="org-table table-action-menu"
				/>
			</div>
		);
	}

	return null;
};
