import { useFormikContext } from 'formik';
import { FC } from 'react';

import { ICreateProjectFromPresetValues } from 'pages/Projects/pages/CreateProjectFromPreset';
import { DateItem } from 'pages/Projects/components/DateItem';

import { journeyTimelines } from 'constants/projects/timeline/journeyTimelines';

export const PresetDates: FC = () => {
	const { values, errors, touched, setFieldValue } =
		useFormikContext<ICreateProjectFromPresetValues>();

	if (!values.journeyPreset) return null;

	const { journeyDate } = journeyTimelines[values.journeyPreset.journey];

	const handleChange = (id: string, date: Date) => {
		void setFieldValue(id, date);
	};

	const PresetDatesList = journeyDate.journeyProjectPresetDates.map(
		({ id, label, icon }) => {
			const value = values[id] as Date | null;
			const error = errors[id] || '';
			const isTouched = !!touched[id];

			return (
				<DateItem
					id={id}
					key={id}
					icon={icon}
					label={label}
					error={error}
					value={value}
					touched={isTouched}
					wrapperClassName="preset-date"
					handleChange={(date: Date) => handleChange(id, date)}
				/>
			);
		}
	);

	return <div className="preset-date-wrapper">{PresetDatesList}</div>;
};
