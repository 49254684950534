import { ChangeEvent, FC, useState } from 'react';

import { PoseAvailableFor } from 'constants/priceLists/poseAvailableFor/poseAvailableFor';
import { MAX_IMAGE_SIZE_MB } from 'constants/priceLists/validation/maxImageSize';

import { PreviewImageColumn } from 'pages/PriceLists/components/PreviewImageColumn';
import { DescriptionColumn } from 'pages/PriceLists/components/DescriptionColumn';
import { BurgerColumn } from 'pages/PriceLists/components/BurgerColumn';
import {
	UpdateProductHandler,
	ClearProductErrorHandler,
	DeleteProductImageHandler,
	UploadProductImageHandler,
} from 'pages/PriceLists/pages/PriceListImago/hooks/useImagoProducts';
import { IProductUI } from 'pages/PriceLists/types/imago/productUI';

import { validateImageFile } from 'utils/validations/general/validateImageFile';
import { validateFileSize } from 'utils/validations/general/validateFileSize';

import { ProductOptions } from '../ProductOptions';

interface IProductContentProps {
	showBurger: boolean;
	productUI: IProductUI;
	handleMouseDown: () => void;
	previewImageFile: File | null;
	showMaxNumberOfPoses?: boolean;
	disabledAvailableFor?: boolean;
	updateProduct: UpdateProductHandler;
	clearProductError: ClearProductErrorHandler;
	uploadProductImage: UploadProductImageHandler;
	deleteProductImage: DeleteProductImageHandler;
}

export const ProductContent: FC<IProductContentProps> = ({
	productUI,
	showBurger,
	updateProduct,
	handleMouseDown,
	previewImageFile,
	clearProductError,
	uploadProductImage,
	deleteProductImage,
	showMaxNumberOfPoses,
}) => {
	const [isImagePending, setIsImagePending] = useState(false);

	const {
		errors,
		sequence,
		availableFor,
		previewImageUrl,
		previewDescription,
		priceListImagoFulfilledProductKey: productKey,
	} = productUI;

	const {
		previewDescription: descriptionError,
		previewImageUrl: previewImageUrlError,
	} = errors;

	const uploadImageAsync = async (imageFile: File) => {
		setIsImagePending(true);

		const fileTypeValidationMessage = validateImageFile({
			type: imageFile.type,
			validateJpg: true,
			validatePng: true,
		});

		const imageSizeValidationMessage = validateFileSize(
			imageFile.size,
			MAX_IMAGE_SIZE_MB
		);

		await uploadProductImage({
			sequence,
			imageFile,
			productKey,
			validationMessage:
				fileTypeValidationMessage || imageSizeValidationMessage,
		});

		setIsImagePending(false);
	};

	const deleteImageAsync = async () => {
		setIsImagePending(true);

		await deleteProductImage(productKey);

		setIsImagePending(false);
	};

	const handleUploadImage = (e: ChangeEvent<HTMLInputElement>) => {
		const imageFile = e.target.files?.[0];

		if (!imageFile) return;

		void uploadImageAsync(imageFile);
	};

	const handleDeleteImage = () => {
		void deleteImageAsync();
	};

	const updateDescription = (value: string, validationMessage: string) => {
		void updateProduct({
			value,
			sequence,
			productKey,
			validationMessage,
			fieldKey: 'previewDescription',
		});
	};

	const clearDescriptionError = () => {
		if (!descriptionError) return;

		clearProductError({
			sequence,
			fieldKey: 'previewDescription',
		});
	};

	const updateAvailableFor = async (value: PoseAvailableFor) => {
		await updateProduct({
			value,
			sequence,
			productKey,
			validationMessage: '',
			fieldKey: 'availableFor',
		});
	};

	return (
		<tr className="price-option-tr price-imago-body">
			<BurgerColumn
				colSpan={1}
				showBurger={showBurger}
				handleMouseDown={handleMouseDown}
			/>
			<PreviewImageColumn
				dimensions="300x450 recommended"
				error={previewImageUrlError}
				isImagePending={isImagePending}
				previewImageUrl={previewImageUrl}
				previewImageFile={previewImageFile}
				handleUploadImage={handleUploadImage}
				handleDeleteImage={handleDeleteImage}
			/>
			<td className="price-option-td" colSpan={5}>
				<div className="price-td-container">
					<DescriptionColumn
						error={descriptionError}
						description={previewDescription}
						updateDescription={updateDescription}
						clearDescriptionError={clearDescriptionError}
					/>
					<ProductOptions
						maxNumberOfPoses={0}
						availableFor={availableFor}
						updateAvailableFor={updateAvailableFor}
						showMaxNumberOfPoses={showMaxNumberOfPoses}
					/>
				</div>
			</td>
		</tr>
	);
};
