import { FC } from 'react';

import { InputGrid } from 'components/FormControls/InputGrid';
import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useToggleSection } from 'hooks/useToggleSection';

import { createPhoneNumberAsync, studioMarketingSelector } from 'store/studio';

export const TextMessageSettings: FC = () => {
	const studioMarketing = useAppSelector(studioMarketingSelector);

	const dispatch = useAppDispatch();

	const { isOpen, handleToggle } = useToggleSection(true);

	const isPhoneNumberVerified = !!studioMarketing?.isPhoneNumberVerified;

	const handleCreatePhoneNumber = () => {
		void dispatch(createPhoneNumberAsync());
	};

	return (
		<ToggleSection
			isOpen={isOpen}
			title="Text Message Settings"
			handleToggle={handleToggle}
			className="acc-user-header"
		>
			<div className="marketing-wrapper">
				{isPhoneNumberVerified ? (
					<p className="marketing-text">
						Your customers will receive your sms text marketing from this
						number. They can reply &#39;Stop&#39; at any time to opt out.
					</p>
				) : (
					<p className="marketing-text">
						Click the button below to get a phone number and activate text
						marketing for your account.
					</p>
				)}
				{!isPhoneNumberVerified ? (
					<Button
						handleClick={handleCreatePhoneNumber}
						className="btn-primary marketing-btn"
						value="Activate Text Message Marketing"
					/>
				) : (
					<div className="marketing-form">
						<InputGrid
							disabled
							required={false}
							label="Phone Number:"
							placeholder={studioMarketing?.twillioPhoneNumber || ''}
						/>
					</div>
				)}
			</div>
		</ToggleSection>
	);
};
