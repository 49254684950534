import { reservedOrgUrlWords } from 'constants/general/reservedUrlWords';

export const validateUrlByReservedWords = (url: string) => {
	const urlLowerCase = url.toLocaleLowerCase();

	const reservedWordIndex = reservedOrgUrlWords.findIndex((reservedWord) =>
		urlLowerCase.includes(reservedWord)
	);

	const reservedWord = reservedOrgUrlWords[reservedWordIndex];

	if (reservedWordIndex === -1) return '';

	const reservedWordIndexInValue = urlLowerCase.indexOf(reservedWord);

	const reservedWordUi = url.slice(
		reservedWordIndexInValue,
		reservedWord.length + reservedWordIndexInValue
	);

	return `Invalid url. Word '${reservedWordUi}' is disabled.`;
};
