import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDefaultBackground } from 'api/models/responses/priceLists/defaultBackground';

import { CUSTOM_BACKGROUND_OPTION_KEY } from 'constants/priceLists/selectOptions/backgroundFileCustomSelectOption';
import { initialBackgroundOptionErrors } from 'constants/priceLists/studio/initialBackgroundOptionErrors';

import { changeEntitiesSequence } from 'utils/dragAndDrop/changeEntitiesSequence';
import { makeEntityAsDefault } from 'utils/dragAndDrop/makeEntityAsDefault';

import { IChangeSequencePayload } from 'hooks/useDragAndDrop';

import {
	IClearBgOptionError,
	IStudioBackgroundOptionUI,
	IPriceListBackgroundsState,
	IImagoBackgroundOptionUI,
} from './types';

const initialState: IPriceListBackgroundsState = {
	imagoBackgrounds: [],
	studioBackgrounds: [],
	defaultBackgrounds: [],
	customBackgroundFile: null,
	isReorderedBackgrounds: false,
	backgroundFileKey: CUSTOM_BACKGROUND_OPTION_KEY,
};

const priceListBackgroundSlice = createSlice({
	name: 'priceListBackgrounds',
	initialState,
	reducers: {
		setDefaultBackgrounds: (
			state,
			{ payload }: PayloadAction<IDefaultBackground[]>
		) => {
			state.defaultBackgrounds = payload;
		},

		setStudioBackgrounds: (
			state,
			{ payload }: PayloadAction<IStudioBackgroundOptionUI[]>
		) => {
			state.studioBackgrounds = payload;
		},

		setImagoBackgrounds: (
			state,
			{ payload }: PayloadAction<IImagoBackgroundOptionUI[]>
		) => {
			state.imagoBackgrounds = payload;
		},

		setIsReorderedBackgrounds: (state, { payload }: PayloadAction<boolean>) => {
			state.isReorderedBackgrounds = payload;
		},

		setBackgroundFileKey: (state, { payload }: PayloadAction<number>) => {
			state.backgroundFileKey = payload;
		},

		setCustomBackgroundFile: (
			state,
			{ payload }: PayloadAction<File | null>
		) => {
			state.customBackgroundFile = payload;
		},

		addStudioBackground: (state, { payload }: PayloadAction<number>) => {
			const backgrounds = state.studioBackgrounds;

			const initialSequence =
				backgrounds.length && backgrounds[backgrounds.length - 1].sequence + 1;

			const newStudioBackground: IStudioBackgroundOptionUI = {
				name: '',
				retailPrice: 0,
				referenceCode: '',
				thumbnailImageUrl: '',
				sequence: initialSequence,
				defaultPriceListBackground: null,
				priceListStudioFulfilledKey: payload,
				errors: initialBackgroundOptionErrors,
			};

			state.studioBackgrounds.push(newStudioBackground);
		},

		addImagoBackground: (state, { payload }: PayloadAction<number>) => {
			const backgrounds = state.imagoBackgrounds;

			const initialSequence =
				backgrounds.length && backgrounds[backgrounds.length - 1].sequence + 1;

			const newImagoBackground: IImagoBackgroundOptionUI = {
				name: '',
				retailPrice: 0,
				thumbnailImageUrl: '',
				sequence: initialSequence,
				defaultPriceListBackground: null,
				priceListImagoFulfilledKey: payload,
				errors: initialBackgroundOptionErrors,
			};

			state.imagoBackgrounds.push(newImagoBackground);
		},

		changeStudioBackgroundsSequence: (
			state,
			{ payload }: PayloadAction<IChangeSequencePayload>
		) => {
			const reorderedBackgroundOptionsUI =
				changeEntitiesSequence<IStudioBackgroundOptionUI>({
					...payload,
					entities: state.studioBackgrounds,
					fieldKey: 'priceListStudioFulfilledBackgroundOptionKey',
				});

			state.studioBackgrounds = reorderedBackgroundOptionsUI;
			state.isReorderedBackgrounds = true;
		},

		changeImagoBackgroundsSequence: (
			state,
			{ payload }: PayloadAction<IChangeSequencePayload>
		) => {
			const reorderedBackgroundOptionsUI =
				changeEntitiesSequence<IImagoBackgroundOptionUI>({
					...payload,
					entities: state.imagoBackgrounds,
					fieldKey: 'priceListImagoFulfilledBackgroundKey',
				});

			state.imagoBackgrounds = reorderedBackgroundOptionsUI;
			state.isReorderedBackgrounds = true;
		},

		makeStudioBackgroundDefault: (
			state,
			{ payload }: PayloadAction<number>
		) => {
			const reorderedBackgroundOptionsUI =
				makeEntityAsDefault<IStudioBackgroundOptionUI>({
					entityKey: payload,
					entities: state.studioBackgrounds,
					fieldKey: 'priceListStudioFulfilledBackgroundOptionKey',
				});

			state.studioBackgrounds = reorderedBackgroundOptionsUI;
			state.isReorderedBackgrounds = true;
		},

		makeImagoBackgroundDefault: (state, { payload }: PayloadAction<number>) => {
			const reorderedBackgroundOptionsUI =
				makeEntityAsDefault<IImagoBackgroundOptionUI>({
					entityKey: payload,
					entities: state.imagoBackgrounds,
					fieldKey: 'priceListImagoFulfilledBackgroundKey',
				});

			state.imagoBackgrounds = reorderedBackgroundOptionsUI;
			state.isReorderedBackgrounds = true;
		},

		clearStudioBackgroundError: (
			state,
			{ payload }: PayloadAction<IClearBgOptionError>
		) => {
			const { fieldKey, sequence } = payload;

			const searchedStudioBackground = state.studioBackgrounds.find(
				(bg) => bg.sequence === sequence
			);

			if (!searchedStudioBackground) return;

			searchedStudioBackground.errors[fieldKey] = '';
		},

		clearImagoBackgroundError: (
			state,
			{ payload }: PayloadAction<IClearBgOptionError>
		) => {
			const { fieldKey, sequence } = payload;

			const searchedImagoBackground = state.imagoBackgrounds.find(
				(bg) => bg.sequence === sequence
			);

			if (!searchedImagoBackground) return;

			searchedImagoBackground.errors[fieldKey] = '';
		},
	},
});

export const priceListBackgroundReducer = priceListBackgroundSlice.reducer;
export const {
	addImagoBackground,
	setImagoBackgrounds,
	addStudioBackground,
	setStudioBackgrounds,
	setBackgroundFileKey,
	setDefaultBackgrounds,
	setCustomBackgroundFile,
	setIsReorderedBackgrounds,
	clearImagoBackgroundError,
	clearStudioBackgroundError,
	makeImagoBackgroundDefault,
	makeStudioBackgroundDefault,
	changeImagoBackgroundsSequence,
	changeStudioBackgroundsSequence,
} = priceListBackgroundSlice.actions;
