export interface ISelectOption<T = number> {
	value: T;
	label: string;
}

export enum Days {
	WEEK = 'Last 7 days',
	MONTH = 'Last 30 days',
	YEAR = 'Last 365 days',
}
