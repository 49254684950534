import { useMsal } from '@azure/msal-react';
import cn from 'classnames';
import { FC } from 'react';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { SelectComponent } from 'components/FormControls/Select';
import { Checkbox } from 'components/FormControls/Checkbox';
import { Button } from 'components/FormControls/Button';
import { Input } from 'components/FormControls/Input';

import { clearSelectedSubscriptionLevelKey } from 'store/subscriptions';
import { isOpenSelector, setIsOpen } from 'store/aside';
import { clearStudio } from 'store/studio';

export const Aside: FC = () => {
	const isOpen = useAppSelector(isOpenSelector);

	const dispatch = useAppDispatch();
	const { instance } = useMsal();

	const hideAside = () => dispatch(setIsOpen(false));

	const handleLogoutRedirect = () => {
		instance
			.logoutRedirect()
			.then(() => {
				dispatch(clearStudio());
				dispatch(clearSelectedSubscriptionLevelKey());
			})
			.catch((e) => console.log(e));
	};

	return (
		<aside
			className={cn('aside', {
				'is-open': isOpen,
			})}
		>
			<button onClick={hideAside} className="asside-collapse">
				Collapse Menu
			</button>
			<div className="aside-section">
				<h3 className="aside-section-title">ACCOUNTS</h3>
				<div className="aside-content">
					<ul className="aside-menu">
						<li className="aside-menu-item">
							<a href="" className="aside-menu-link bold">
								Skoolhouse Photography
							</a>
						</li>
						<li className="aside-menu-item">
							<a href="" className="aside-menu-link">
								Cantrell Creations
							</a>
						</li>
						<li className="aside-menu-item">
							<a href="" className="aside-menu-link">
								Create new account
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div className="aside-section aside-mob-section">
				<h3 className="aside-section-title">FIND AN ORDER</h3>
				<div className="aside-content">
					<Input
						value=""
						type="text"
						id="order"
						placeholder="Participant Name"
						handleChange={() => {}}
					/>
					<span className="aside-menu-link">Find by other info</span>
					<Button className="btn-secondary" value="Search" />
				</div>
			</div>
			<div className="aside-section aside-mob-section">
				<h3 className="aside-section-title">SEND A LINK</h3>
				<div className="aside-content">
					<SelectComponent
						id=""
						value=""
						onChange={() => {}}
						selectOptions={[]}
						selectPlaceholder="Select Project*"
					/>
					<Input
						value=""
						type="text"
						id="passcode"
						placeholder="Passcode (if not already known)"
						handleChange={() => {}}
					/>
					<Input
						value=""
						type="email"
						id="email"
						placeholder="Email Address"
						handleChange={() => {}}
					/>
					<Input
						value=""
						type="phone"
						id="phone"
						placeholder="Mobile Phone Number"
						handleChange={() => {}}
					/>
					<Checkbox
						id=""
						checked
						handleChange={() => {}}
						disabled={false}
						label="Add to Prospect Campaign"
					/>
					<Button className="btn-secondary" value="Share" />
				</div>
			</div>
			<div className="aside-section">
				<div className="aside-content">
					<ul className="aside-menu">
						<li className="aside-menu-item">
							<a href="" className="aside-menu-link">
								My Profile
							</a>
						</li>
						<li className="aside-menu-item">
							<a href="" className="aside-menu-link">
								Help
							</a>
						</li>
						<li className="aside-menu-item">
							<button
								className="aside-menu-link"
								onClick={handleLogoutRedirect}
							>
								Log Out
							</button>
						</li>
					</ul>
				</div>
			</div>
		</aside>
	);
};
