import { useEffect } from 'react';

import { SETTINGS } from 'constants/navigation';
import { ROUTES } from 'constants/ROUTES';

import { IBreadcrumbs, setBreadcrumbs } from 'store/breadcrumbs';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import NavigationService from 'utils/NavigationService';

export const useWatermarkMediaBreadcrumbs = (isMediaLibrary?: boolean) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		const settingsNavigation = NavigationService.getParentNavigation(SETTINGS);

		const watermarkMediaNavigation = NavigationService.getSubNavigation(
			SETTINGS,
			ROUTES.WATERMARK_MEDIA.WATERMARK_MEDIA
		);

		if (!settingsNavigation || !watermarkMediaNavigation) return;

		const settingsBreadcrumbs: IBreadcrumbs = {
			isActive: false,
			path: settingsNavigation.path,
			title: settingsNavigation.title,
		};

		const watermarkMediaBreadcrumbs: IBreadcrumbs = {
			isActive: !isMediaLibrary,
			path: watermarkMediaNavigation.path,
			title: watermarkMediaNavigation.title,
		};

		const mediaLibraryBreadcrumbs: IBreadcrumbs = {
			isActive: true,
			title: 'Media Library',
			path: ROUTES.WATERMARK_MEDIA.MEDIA_LIBRARY,
		};

		const breadcrumbs: IBreadcrumbs[] = [
			settingsBreadcrumbs,
			watermarkMediaBreadcrumbs,
		];

		if (isMediaLibrary) {
			breadcrumbs.push(mediaLibraryBreadcrumbs);
		}

		dispatch(setBreadcrumbs(breadcrumbs));
	}, []);
};
