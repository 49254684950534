import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

import { persistedRootReducer } from './rootReducer';

export const store = configureStore({
	reducer: persistedRootReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof persistedRootReducer>;
export type AppStore = typeof store;
export type AppDispatch = AppStore['dispatch'];
