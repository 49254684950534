import { IPaginatePromotionsParams } from 'api/models/requests/promotions/paginatePromotionsParams';
import { IPromotionDetails } from 'api/models/responses/promotions/promotionDetails';
import { ICreatePromotion } from 'api/models/requests/promotions/createPromotion';
import { PromotionsResponse } from 'api/models/responses/promotions/promotion';

import { promotionsUrls } from 'constants/apiUrls/promotionsUrls';

import { BaseService } from '../BaseService';

class PromotionsService extends BaseService {
	async getPromotions({
		skip,
		take,
		orderBy,
		orderDirection,
	}: IPaginatePromotionsParams): Promise<PromotionsResponse | null> {
		return this.get(promotionsUrls.promotionsUrl, {
			params: {
				Skip: skip,
				Take: take,
				OrderBy: orderBy,
				OrderDirection: orderDirection,
			},
		});
	}

	async getPromotionDetails(
		promotionKey: number
	): Promise<IPromotionDetails | null> {
		return this.get(promotionsUrls.promotionsUrlByKey(promotionKey));
	}

	async createPromotion(
		body: ICreatePromotion
	): Promise<IPromotionDetails | null> {
		return this.post(promotionsUrls.promotionsUrl, body);
	}

	async updatePromotion(
		promotionKey: number,
		body: ICreatePromotion
	): Promise<IPromotionDetails | null> {
		return this.put(promotionsUrls.promotionsUrlByKey(promotionKey), body);
	}

	async deletePromotion(promotionKey: number): Promise<string | null> {
		return this.delete(promotionsUrls.promotionsUrlByKey(promotionKey));
	}
}

export default new PromotionsService();
