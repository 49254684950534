import { useEffect } from 'react';

import { MARKET_SELL } from 'constants/navigation';
import { ROUTES } from 'constants/ROUTES';

import { IBreadcrumbs, setBreadcrumbs } from 'store/breadcrumbs';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import NavigationService from 'utils/NavigationService';

export const useJourneyPresetBreadcrumbs = (
	breadcrumbsParam?: IBreadcrumbs
) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		const marketSellNavigation =
			NavigationService.getParentNavigation(MARKET_SELL);

		const journeyPresetsNavigation = NavigationService.getSubNavigation(
			MARKET_SELL,
			ROUTES.PRESETS.PRESETS
		);

		if (!marketSellNavigation || !journeyPresetsNavigation) return;

		const marketSellBreadcrumbs: IBreadcrumbs = {
			isActive: false,
			path: marketSellNavigation.path,
			title: marketSellNavigation.title,
		};

		const journeyPresetsBreadcrumbs: IBreadcrumbs = {
			isActive: !breadcrumbsParam,
			path: journeyPresetsNavigation.path,
			title: journeyPresetsNavigation.title,
		};

		const breadcrumbs: IBreadcrumbs[] = [
			marketSellBreadcrumbs,
			journeyPresetsBreadcrumbs,
		];

		if (breadcrumbsParam) {
			breadcrumbs.push(breadcrumbsParam);
		}

		dispatch(setBreadcrumbs(breadcrumbs));
	}, [breadcrumbsParam?.title]);
};
