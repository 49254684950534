interface IPriceParts {
	decimalString: string;
	intString: string;
}

export const splitDecimalPricePart = (price: string): IPriceParts => {
	const decimalPointIndex = price.indexOf('.');

	const decimal = price.slice(decimalPointIndex + 1, price.length);

	return {
		decimalString: decimal,
		intString: price.slice(0, decimalPointIndex),
	};
};
