import { FC, SyntheticEvent } from 'react';

import { InputGrid } from 'components/FormControls/InputGrid';
import { TextArea } from 'components/FormControls/TextArea';

import { validatePresetName } from '../../helpers/validatePresetName';
import {
	PresetErrors,
	ClearPresetError,
	UpdatePresetHandler,
} from '../../types';

interface IJourneyPresetHeaderProps {
	presetName: string;
	description: string;
	errors: PresetErrors;
	updatePreset: UpdatePresetHandler;
	clearPresetError: ClearPresetError;
}

export const JourneyPresetHeader: FC<IJourneyPresetHeaderProps> = ({
	errors,
	presetName,
	description,
	updatePreset,
	clearPresetError,
}) => {
	const updatePresetName = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		const validationMessage = validatePresetName(value);

		void updatePreset({
			value,
			key: 'presetName',
			validationMessage,
		});
	};

	const clearPresetNameError = () => {
		clearPresetError('presetName');
	};

	const updateDescription = (e: SyntheticEvent<HTMLTextAreaElement>) => {
		const { value } = e.currentTarget;

		void updatePreset({
			value,
			key: 'description',
			validationMessage: '',
		});
	};

	const clearDescriptionError = () => {
		clearPresetError('description');
	};

	return (
		<div className="preset-header-form">
			<InputGrid
				isLazy
				touched
				required
				label="Preset Name"
				defaultValue={presetName}
				error={errors.presetName}
				placeholder="Preset name"
				clearError={clearPresetNameError}
				handleLazyChange={updatePresetName}
				className="autorow preset-header-input"
			/>
			{/* TODO: Add className for text area */}
			<TextArea
				isLazy
				touched
				required
				label="Description"
				placeholder="Description"
				defaultValue={description}
				error={errors.description}
				clearError={clearDescriptionError}
				handleLazyChange={updateDescription}
			/>
		</div>
	);
};
