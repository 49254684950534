import { PoseOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/poseOption';
import { ISelectOption } from 'types/ui/select';

import { poseOptionTypesUI } from '../poseOptionTypesUI';

export const poseOptionTypesSelectOptions: ISelectOption<PoseOptionTypes>[] =
	Object.values(PoseOptionTypes)
		.filter((value) => value !== PoseOptionTypes.None)
		.map((value) => {
			const { singleLabel } = poseOptionTypesUI[value];

			return {
				value,
				label: singleLabel,
			};
		});
