import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';

import projectTimelineA from 'images/projects/projectTimelines/project-timeline-A.png';
import projectTimelineB from 'images/projects/projectTimelines/project-timeline-B.png';
import projectTimelineC from 'images/projects/projectTimelines/project-timeline-C.png';
import projectTimelineD from 'images/projects/projectTimelines/project-timeline-D.png';
import projectTimelineE from 'images/projects/projectTimelines/project-timeline-E.png';

export const projectTimelineImages: Record<ProjectJourneys, string> = {
	[ProjectJourneys.A]: projectTimelineA,
	[ProjectJourneys.B]: projectTimelineB,
	[ProjectJourneys.C]: projectTimelineC,
	[ProjectJourneys.D]: projectTimelineD,
	[ProjectJourneys.E]: projectTimelineE,
};
