import { WatermarkTypes } from 'api/models/responses/studio/studioPhotoSettings';
import { ISelectOption } from 'types/ui/select';

import { watermarkTypesUI } from '../watermarkTypesUI';

const { No, ...availableTypes } = WatermarkTypes;

export const watermarkTypesSelectOptions: ISelectOption<WatermarkTypes>[] =
	Object.values(availableTypes).map((type) => ({
		value: type,
		label: watermarkTypesUI[type],
	}));
