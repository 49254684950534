import { ChangeEvent, FC, useEffect, useState } from 'react';
import cn from 'classnames';

import { validateFixedImageDimensions } from 'utils/images/validateFixedImageDimensions';
import { validateImageFile } from 'utils/validations/general/validateImageFile';
import {
	requiredWidth,
	requiredHeight,
} from 'utils/validations/priceLists/studio/backgroundOptions/previewImage';
import { getImageBlobUrl } from 'utils/images/getImageBlobUrl';

import { setCustomBackgroundFile } from 'store/priceLists/priceListBackgrounds';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { DeleteBtn } from 'components/Controls/DeleteBtn';

import { ImageUploadArea } from '../../../../ImageUploadArea';
import { IBackgroundOptionProps } from '..';
import { validateFileSize } from 'utils/validations/general/validateFileSize';
import { MAX_IMAGE_SIZE_MB } from 'constants/priceLists/validation/maxImageSize';

interface IImagePreviewProps
	extends Pick<IBackgroundOptionProps, 'customBackgroundFile'> {
	previewError: string;
	clearError: () => void;
	imagePreviewUrl: string | null;
	updateImageError: (validationMessage: string) => void;
}

export const ImagePreviewColumn: FC<IImagePreviewProps> = ({
	clearError,
	previewError,
	imagePreviewUrl,
	updateImageError,
	customBackgroundFile,
}) => {
	const [customBackgroundImage, setCustomBackgroundImage] = useState<
		string | null
	>(null);

	const dispatch = useAppDispatch();

	const handleUploadImage = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];

		if (!file) return;

		const fileTypeValidationMessage = validateImageFile({
			type: file.type,
			validateJpg: true,
			validatePng: true,
		});

		const imageFileSizeValidationMessage = validateFileSize(
			file.size,
			MAX_IMAGE_SIZE_MB
		);

		const imageFileValidationMessage =
			fileTypeValidationMessage || imageFileSizeValidationMessage;

		if (imageFileValidationMessage) {
			updateImageError(imageFileValidationMessage);
			return;
		}

		dispatch(setCustomBackgroundFile(file));
		clearError();
	};

	const handleDeleteCustomImagePreview = () => {
		setCustomBackgroundImage(null);
		dispatch(setCustomBackgroundFile(null));
	};

	const url = getImageBlobUrl(imagePreviewUrl);

	const showCustomBackgroundPreview = !url && customBackgroundImage;
	const showBackgroundUpload = !url && !customBackgroundImage;

	useEffect(() => {
		if (!customBackgroundFile || customBackgroundImage) return;

		const reader = new FileReader();

		reader.onloadend = (event) => {
			const img = new Image();

			img.onload = () => {
				const { width, height } = img;

				const dimensionsValidationMessage = validateFixedImageDimensions({
					width,
					height,
					requiredWidth,
					requiredHeight,
				});

				if (dimensionsValidationMessage) {
					updateImageError(dimensionsValidationMessage);
					return;
				}

				setCustomBackgroundImage((event.target?.result as string) || null);
			};

			img.onerror = () => {
				console.log('There was an error loading the image.');
			};

			if (typeof event.target?.result === 'string') {
				img.src = event.target.result;
			}
		};

		reader.onerror = () => {
			console.log(reader.error);
		};

		reader.readAsDataURL(customBackgroundFile);
	}, [customBackgroundFile]);

	return (
		<td
			className={cn('price-option-td', {
				'price-td-preview': showCustomBackgroundPreview,
			})}
		>
			{showCustomBackgroundPreview && (
				<DeleteBtn handleClick={handleDeleteCustomImagePreview} />
			)}
			<div className="price-preview">
				{url && (
					<img
						src={url}
						alt="background preview"
						className="price-preview-img"
					/>
				)}
				{showCustomBackgroundPreview && (
					<img
						alt="background preview"
						src={customBackgroundImage}
						className="price-preview-img"
					/>
				)}
				{showBackgroundUpload && (
					<ImageUploadArea handleUploadImage={handleUploadImage} />
				)}
				{previewError && <span className="input-error">{previewError}</span>}
			</div>
		</td>
	);
};
