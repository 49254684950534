import { IGiftAndAccessoriesCatalogProduct } from 'api/models/responses/catalogProducts/giftAndAccessoriesCatalogProduct';
import {
	ICatalogProduct,
	CatalogProductCategoryTypes,
} from 'api/models/responses/catalogProducts/catalogProducts';

import { catalogProductsUrls } from 'constants/apiUrls/catalogProductsUrls';

import { BaseService } from '../BaseService';

class CatalogProductsService extends BaseService {
	async getCatalogProducts(
		catalogCategoryType: CatalogProductCategoryTypes
	): Promise<ICatalogProduct[] | null> {
		return this.get(
			catalogProductsUrls.getCatalogProducts(catalogCategoryType)
		);
	}

	async getSpecificCatalogProducts(): Promise<
		IGiftAndAccessoriesCatalogProduct[] | null
	> {
		return this.get(catalogProductsUrls.getSpecificCatalogProducts);
	}
}

export default new CatalogProductsService();
