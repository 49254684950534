import { FC } from 'react';

import { IMarketingItem } from '..';

export const MarketingItem: FC<IMarketingItem> = ({ label, value }) => (
	<>
		<span className="label">{label}</span>
		<input
			disabled
			type="text"
			value={value}
			onChange={() => {}}
			className="input-grid"
		/>
	</>
);
