import { useIntersectionObserver } from 'usehooks-ts';
import { RefObject, useEffect, useRef } from 'react';

export const usePagination = (
	setPaginatedEntity: () => void | Promise<void>
): RefObject<HTMLDivElement> => {
	const ref = useRef<HTMLDivElement | null>(null);

	const entry = useIntersectionObserver(ref, { threshold: 0 });

	const isVisible = !!entry?.isIntersecting;

	useEffect(() => {
		if (!isVisible) return;

		void setPaginatedEntity();
	}, [isVisible]);

	return ref;
};
