import { object, string } from 'yup';

import { albumNameRegEx, maxPasswordLength } from 'constants/albums/validation';
import { requiredFieldMessage } from 'constants/general/validation/generalMessages';

export const updateAlbumScheme = object().shape({
	name: string()
		.trim()
		.matches(
			albumNameRegEx,
			'Group Name must be 3 - 100 letters, numbers, and spaces, and must begin with a letter or number.'
		)
		.min(3, 'Group name must be at least 3 characters.')
		.max(maxPasswordLength, 'Group name must be max 100 characters.')
		.required(requiredFieldMessage),
	password: string()
		.notRequired()
		.max(maxPasswordLength, 'Access code must be max 100 characters.'),
});
