import { useNavigate } from 'react-router-dom';
import { FC } from 'react';

import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';

import { useToggleSection } from 'hooks/useToggleSection';
import { ROUTES } from 'constants/ROUTES';

export const MediaLibrarySection: FC = () => {
	const { isOpen, handleToggle } = useToggleSection(true);

	const navigate = useNavigate();

	const handleManageMediaLibrary = () => {
		navigate(ROUTES.WATERMARK_MEDIA.MEDIA_LIBRARY);
	};

	return (
		<ToggleSection
			isOpen={isOpen}
			title="Media Library"
			handleToggle={handleToggle}
			className="watermark-media-section"
		>
			<div className="watermark-media">
				<p className="watermark-media-info">
					This collection of images can be used for marketing email and
					organization/project page purposes. These images are not inteded to be
					used for selling, but instead to help you
				</p>
				<Button
					value="Manage Your Media Library"
					handleClick={handleManageMediaLibrary}
					className="btn-primary watermark-media-btn"
				/>
			</div>
		</ToggleSection>
	);
};
