import { IStatisticRenderHelper } from 'types/statistic/statisticRenderHelper';

export const organizationStatisticPriceKeys: IStatisticRenderHelper[] = [
	{
		key: 'revenue',
		title: 'Revenue',
	},
	{
		key: 'averageOrder',
		title: 'Avg. Order',
	},
	{
		key: 'revenuePerPerson',
		title: 'Rev. per Person',
	},
];
