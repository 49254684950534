import { useFormikContext } from 'formik';
import { FC } from 'react';

import { TextEditorRef } from 'components/TextEditor/types';
import { TextEditor } from 'components/TextEditor';

import { ICreateBlankProjectValues } from '../../..';

interface IGreetingProps {
	editorRef: TextEditorRef;
}

export const Greeting: FC<IGreetingProps> = ({ editorRef }) => {
	const { values } = useFormikContext<ICreateBlankProjectValues>();

	return (
		<div className="project-section">
			<h2 className="project-section-title">greeting</h2>
			<div className="project-section-container">
				<TextEditor
					editorRef={editorRef}
					initialContent={values.projectGreeting}
				/>
			</div>
		</div>
	);
};
