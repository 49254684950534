export const organizationsUrls = {
	createNewOrganization: 'api/organizations',
	getActiveOrganizations: 'api/organizations/active',
	getArchivedOrganizations: 'api/organizations/archived',

	getOrganizationStatistic: (orgKey: number) =>
		`api/dashboard/organization/${orgKey}`,

	organizationUrlIncludingKey: (orgKey: number) =>
		`api/organizations/${orgKey}`,

	coverImageUrl: (orgKey: number) => `api/organizations/${orgKey}/cover-image`,

	searchResultsImageUrl: (orgKey: number) =>
		`api/organizations/${orgKey}/search-results-image`,
};
