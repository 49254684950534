import { ChangeEvent, FC } from 'react';

import { Checkbox } from 'components/FormControls/Checkbox';

interface IMarketingHeaderProps {
	disabled?: boolean;
	isMarketingEnabled: boolean;
	handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const MarketingHeader: FC<IMarketingHeaderProps> = ({
	disabled,
	handleChange,
	isMarketingEnabled,
}) => {
	return (
		<h2 className="project-section-title">
			marketing funnel
			<Checkbox
				disabled={disabled}
				id="isMarketingEnabled"
				handleChange={handleChange}
				checked={isMarketingEnabled}
				label="Enable Marketing For This Project"
			/>
		</h2>
	);
};

MarketingHeader.defaultProps = {
	disabled: false,
};
