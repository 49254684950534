import { CreateStudio } from 'pages/RegistrationSteps/CreateStudio';
import { SetupAccount } from 'pages/RegistrationSteps/SetupAccount';
import { SelectPlan } from 'pages/RegistrationSteps/SelectPlan';

import { ROUTES } from 'constants/ROUTES';

export const registrationStepsRoutesList = [
	{
		path: ROUTES.STUDIO_STEPS.CREATE_STUDIO,
		element: <CreateStudio />,
	},
	{
		path: ROUTES.STUDIO_STEPS.SELECT_PLAN,
		element: <SelectPlan />,
	},
	{
		path: ROUTES.STUDIO_STEPS.GET_PAID,
		element: <SetupAccount />,
	},
];
