import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';

import { ICreateNewOrganizationBody } from 'api/models/requests/organizations/createNewOrganization';
import OrganizationService from 'api/services/OrganizationService';
import { IErrorResponse } from 'api/models/responses/errors/errorResponse';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { setCreatedOrganization } from 'store/organizations';
import { studioNetworkDomainSelector } from 'store/studio';

import { ButtonTypes } from 'types/ui/buttonTypes';
import { ROUTES } from 'constants/ROUTES';

import { createNewOrganizationScheme } from './validations';

interface IField {
	id: string;
	label: string;
	supLabel?: string;
	placeholder: string;
}

const getCreateOrganizationFields = (networkDomain: string): IField[] => [
	{
		id: 'organizationName',
		label: 'Organization Name',
		placeholder: 'Organization Name',
	},
	{
		id: 'organizationUrl',
		label: 'Organization URL',
		placeholder: 'Organization URL',
		supLabel: `${networkDomain}.accessmyevent.com/`,
	},
];

export interface ICreateNewOrganizationValues
	extends ICreateNewOrganizationBody {
	[key: string]: string;
}

export const CreateNewOrganization: FC = () => {
	const studioNetworkDomain = useAppSelector(studioNetworkDomainSelector);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const initialValues: ICreateNewOrganizationValues = {
		organizationName: '',
		organizationUrl: '',
	};

	const onSubmit = (
		values: ICreateNewOrganizationValues,
		{ setErrors }: FormikHelpers<ICreateNewOrganizationValues>
	) => {
		OrganizationService.createNewOrganization(values)
			.then((data) => {
				if (!data) return;

				dispatch(setCreatedOrganization(data));

				navigate(
					`${ROUTES.ORGANIZATIONS.ORGANIZATIONS}/${data.organizationKey}`
				);
			})
			.catch((error) => {
				const typedError = error as IErrorResponse;

				const isValidationErrors = typedError.traceId;

				const updatedErrorMessages = Object.entries(typedError.errors).reduce<
					FormikErrors<ICreateNewOrganizationValues>
				>((acc, [key, message]) => {
					const splittedKey = key.split('.');

					const parsedKey = splittedKey[splittedKey.length - 1];

					const doesKeyExist = parsedKey in values;

					const parsedMessage = isValidationErrors
						? (message as string[]).join(' ')
						: (message as string);

					if (!doesKeyExist) return acc;

					return {
						...acc,
						[parsedKey]: parsedMessage,
					};
				}, {});

				void setErrors(updatedErrorMessages);
			});
	};

	const createOrganizationFields =
		getCreateOrganizationFields(studioNetworkDomain);

	return (
		<Formik
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={createNewOrganizationScheme}
		>
			{({ values, errors, touched, handleChange, handleSubmit }) => (
				<div className="modal-body">
					<div className="org-modal">
						{createOrganizationFields.map(
							({ id, label, supLabel, placeholder }) => (
								<InputGrid
									row
									id={id}
									key={id}
									required
									label={label}
									className="row"
									error={errors[id]}
									value={values[id]}
									supLabel={supLabel}
									touched={touched[id]}
									placeholder={placeholder}
									handleChange={handleChange}
								/>
							)
						)}
					</div>
					<Button
						value="Create"
						type={ButtonTypes.SUBMIT}
						handleClick={handleSubmit}
						className="org-modal-btn btn-primary"
					/>
				</div>
			)}
		</Formik>
	);
};
