import { FC } from 'react';

import { ButtonTypes } from 'types/ui/buttonTypes';

interface ButtonProps {
	value: string;
	className: string;
	disabled?: boolean;
	type?: ButtonTypes;
	handleClick?: () => void | Promise<void>;
}

export const Button: FC<ButtonProps> = ({
	type,
	value,
	disabled,
	className,
	handleClick,
}) => (
	<button
		type={type}
		className={className}
		onClick={handleClick}
		disabled={disabled}
	>
		{value}
	</button>
);

Button.defaultProps = {
	type: ButtonTypes.BUTTON,
	disabled: false,
};
