import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { organizationKeySelector } from 'store/projects';
import { ROUTES } from 'constants/ROUTES';

export const useOrganizationKeyExist = () => {
	const organizationKey = useAppSelector(organizationKeySelector);

	const navigate = useNavigate();

	useEffect(() => {
		if (organizationKey) return;

		navigate(ROUTES.ORGANIZATIONS.ACTIVE_ORGANIZATIONS);
	}, [organizationKey]);
};
