import { IPriceListSale } from '../priceLists/priceListSale';
import { ISale } from './sales';

export enum ProjectTimelineEventType {
	Sale = 'Sale',
	ProjectDateEvent = 'ProjectDateEvent',
	MarketingBroadcast = 'MarketingBroadcast',
	ProjectLifeCycleEvent = 'ProjectLifeCycleEvent',
}

export interface ITimelineSaleModel
	extends Omit<
		ISale,
		'saleEndDate' | 'saleStartDate' | 'priceListResponse' | 'allowedPriceLists'
	> {
	saleEnds: string;
	saleBegins: string;
	dateIsEstimate: boolean;
	isEndDateEstimated: boolean;
	dateIsEstimateComment: string;
	isStartDateEstimated: boolean;
	priceList: IPriceListSale | null;
}

export interface ITimelineItem {
	date: string;
	value: string | null;
	title: string | null;
	dateIsEstimate: boolean;
	saleModel: ITimelineSaleModel | null;
	dateIsEstimateComment: string | null;
	projectEventType: ProjectTimelineEventType;
}

export interface IProjectTimeline {
	error: null;
	isEmpty: boolean;
	isSuccessful: boolean;
	value: ITimelineItem[];
}
