import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import { FC, useEffect } from 'react';

import { ArchivedOrgSortLabels } from 'constants/organizations/archivedOrgSortLabels';
import { imagesBlobStorageUrl } from 'constants/images/blobUrls';
import { ROUTES } from 'constants/ROUTES';

import { ColumnLabelContainer } from 'components/Table/ColumnLabelContainer';
import { ToggleSection } from 'components/ToggleSection';
import { Loader } from 'components/Loader';
import { Search } from 'components/Search';
import { Table } from 'components/Table';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToggleSection } from 'hooks/useToggleSection';
import { useDefineDevice } from 'hooks/useDefineDevice';

import { useOrganizationsBreadcrumbs } from 'pages/Organizations/hooks/useOrganizationsBreadcrumbs';
import { IArchivedOrganization } from 'api/models/responses/organizations/archivedOrganization';
import { normalizeDate } from 'utils/ui/normalizeDate';
import {
	isOrgPendingSelector,
	archivedOrganizationsSelector,
	getArchivedOrganizationsAsync,
} from 'store/organizations';

import { useSortArchivedOrganizations } from '../../hooks/useSortArchivedOrganizations';
import { SearchedProjectList } from '../../components/SearchedProjectList';
import { useSearchProjects } from '../../hooks/useSearchProjects';
import { NoItemsFound } from 'components/NoItemsFound';

const mobileRenderColumnsId = ['organizationName', 'archivedDate'];

export const ArchivedOrganizations: FC = () => {
	const archivedOrganizations = useAppSelector(archivedOrganizationsSelector);
	const isOrgPending = useAppSelector(isOrgPendingSelector);

	const dispatch = useAppDispatch();

	const { isMobile } = useDefineDevice();

	const { isOpen, setIsOpen, handleToggle } = useToggleSection(false);

	useOrganizationsBreadcrumbs(false);

	const showSearch = () => {
		setIsOpen(true);
	};

	const {
		results,
		isPending,
		handleSearch,
		setPaginatedEntity,
		orderBy: projectOrderBy,
		setSortParams: setProjectSortParams,
		orderDirection: projectOrderDirection,
	} = useSearchProjects(showSearch);

	const {
		orderBy: orgOrderBy,
		orderDirection: orgOrderDirection,
		setSortParams: setOrgSortParams,
	} = useSortArchivedOrganizations();

	const columns: Column<IArchivedOrganization>[] = [
		{
			Header: (
				<ColumnLabelContainer<ArchivedOrgSortLabels>
					label="Name"
					orderBy={orgOrderBy}
					setSortParams={setOrgSortParams}
					orderDirection={orgOrderDirection}
					sortLabel={ArchivedOrgSortLabels.OrganizationName}
				/>
			),
			accessor: 'organizationName',
			Cell: ({ row }: CellProps<IArchivedOrganization>) => {
				const {
					organizationKey,
					organizationName,
					searchResultsImageRelativePath,
				} = row.original;

				const backgroundImage = `url(${imagesBlobStorageUrl}${searchResultsImageRelativePath})`;

				return (
					<span className="card-link-icon" title={organizationName}>
						<i
							className="card-table-icon"
							style={{
								backgroundImage,
							}}
						/>
						<Link
							title={organizationName}
							className="card-table-link"
							to={`${ROUTES.ORGANIZATIONS.ORGANIZATIONS}/${organizationKey}`}
						>
							{organizationName}
						</Link>
					</span>
				);
			},
		},
		{
			Header: (
				<ColumnLabelContainer<ArchivedOrgSortLabels>
					orderBy={orgOrderBy}
					label="Archived Projects"
					setSortParams={setOrgSortParams}
					orderDirection={orgOrderDirection}
					sortLabel={ArchivedOrgSortLabels.ArchivedProjects}
				/>
			),
			accessor: 'archivedProjectsCount',
		},
		{
			Header: (
				<ColumnLabelContainer<ArchivedOrgSortLabels>
					label="Created"
					orderBy={orgOrderBy}
					setSortParams={setOrgSortParams}
					orderDirection={orgOrderDirection}
					sortLabel={ArchivedOrgSortLabels.CreatedDate}
				/>
			),
			accessor: 'createDate',
			Cell: ({
				value,
			}: CellProps<
				IArchivedOrganization,
				IArchivedOrganization['createDate']
			>) => <>{normalizeDate(value)}</>,
		},
		{
			Header: (
				<ColumnLabelContainer<ArchivedOrgSortLabels>
					label="Archived"
					orderBy={orgOrderBy}
					setSortParams={setOrgSortParams}
					orderDirection={orgOrderDirection}
					sortLabel={ArchivedOrgSortLabels.ArchivedDate}
				/>
			),
			accessor: 'archivedDate',
			Cell: ({
				value,
			}: CellProps<
				IArchivedOrganization,
				IArchivedOrganization['archivedDate']
			>) => <>{normalizeDate(value)}</>,
		},
	];

	const filteredColumns = isMobile
		? columns.filter(({ accessor }) =>
				mobileRenderColumnsId.includes(accessor as string)
		  )
		: columns;

	useEffect(() => {
		void dispatch(getArchivedOrganizationsAsync());
	}, [dispatch]);

	return (
		<div className="org">
			<ToggleSection
				isOpen={isOpen}
				title="Find a Project:"
				handleToggle={handleToggle}
				header={
					<Search placeholder="Find a Project" handleSearch={handleSearch} />
				}
				className="org-search-container"
			>
				<div className="org-active-container">
					{!isPending && !!results.length && (
						<SearchedProjectList
							results={results}
							className="org-table"
							orderBy={projectOrderBy}
							setSortParams={setProjectSortParams}
							orderDirection={projectOrderDirection}
							setPaginatedEntity={setPaginatedEntity}
						/>
					)}
					{isPending && <Loader />}
					{!isPending && !results.length && (
						<div className="page-loader-container is-section">
							<NoItemsFound title="projects" />
						</div>
					)}
				</div>
			</ToggleSection>
			<div className="org-active-container">
				<div className="org-header">
					<span className="org-title">Archived Organizations</span>
				</div>
				{isOrgPending ? (
					<Loader isFullHeight />
				) : (
					<div className="org-project-search-container">
						<Table<IArchivedOrganization>
							className="org-table"
							columns={filteredColumns}
							data={archivedOrganizations}
						/>
					</div>
				)}
			</div>
		</div>
	);
};
