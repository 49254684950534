import { createSelector } from 'reselect';

import { RootState } from 'store/store';
import { ISidebarState } from './types';

export const sidebarSelector = (state: RootState): ISidebarState =>
	state.sidebar;

export const isOpenSelector = createSelector(
	sidebarSelector,
	({ isOpen }) => isOpen
);
