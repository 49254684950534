import { useFormikContext } from 'formik';
import { FC } from 'react';

import { InputGrid } from 'components/FormControls/InputGrid';
import { TextArea } from 'components/FormControls/TextArea';

import { ICreateBlankProjectValues } from 'pages/Projects/pages/CreateBlankProject';

export const OrderMessages: FC = () => {
	const { values, errors, touched, handleChange } =
		useFormikContext<ICreateBlankProjectValues>();

	return (
		<div className="project-section">
			<div className="project-message-section order-section">
				<span className="project-message-title">Order Messages</span>
				<div className="project-textarea-section order">
					<div className="project-message">
						<InputGrid
							required={false}
							className="autorow"
							id="orderNotificationEmail"
							handleChange={handleChange}
							label="Order Notification Email"
							value={values.orderNotificationEmail}
							error={errors.orderNotificationEmail}
							placeholder="Order Notification Email"
							touched={touched.orderNotificationEmail}
						/>
						<span className="project-textarea-info">
							Will send an email to this address each time an order is placed
						</span>
					</div>
					<TextArea
						handleChange={handleChange}
						id="orderConfirmationMessage"
						label="Order Confirmation Message"
						value={values.orderConfirmationMessage}
						error={errors.orderConfirmationMessage}
						touched={touched.orderConfirmationMessage}
						subText="Will be shown on order confirmation page and on order invoice."
						placeholder="Print a copy of your receipt and bring it with you on picture day."
					/>
				</div>
			</div>
		</div>
	);
};
