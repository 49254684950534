import { IPatchBody } from 'api/models/requests/general/patchBody';
import {
	ISubjectFormQuestion,
	ISubjectFormQuestionUniq,
} from 'api/models/responses/projects/subjectFormQuestion';

import { BaseService } from '../BaseService';

class SubjectFormQuestionsService extends BaseService {
	async getProjectFormQuestions(
		url: string
	): Promise<ISubjectFormQuestionUniq[] | null> {
		return this.get(url);
	}

	async createProjectFormQuestion(
		url: string,
		body: ISubjectFormQuestion
	): Promise<ISubjectFormQuestionUniq | null> {
		return this.post(url, body);
	}

	async updateProjectFormQuestion(
		url: string,
		body: IPatchBody[]
	): Promise<ISubjectFormQuestionUniq | null> {
		return this.patch(url, body);
	}

	async deleteProjectFormQuestion(url: string): Promise<boolean | null> {
		return this.delete(url);
	}

	async reorderSubjectFormQuestions(
		url: string,
		body: Record<number, number>
	): Promise<string | null> {
		return this.put(url, body);
	}
}

export default new SubjectFormQuestionsService();
