import { ProjectTimelineEventType } from 'api/models/responses/projects/timeline';

interface IIcons {
	icon: string;
	iconHelper: string;
}

enum ProjectDateEventTitles {
	PictureDay = 'Picture Day',
}

enum ProjectLifeCycleEventTitles {
	ProjectPublished = 'Project Published',
	ProjectExpired = 'Project Expired',
}

export const projectTimelineEventTypeIcons: Record<
	ProjectTimelineEventType,
	(title?: string | null) => IIcons
> = {
	[ProjectTimelineEventType.Sale]: () => ({
		icon: 'icon-cart',
		iconHelper: '',
	}),
	[ProjectTimelineEventType.MarketingBroadcast]: () => ({
		icon: 'icon-mail',
		iconHelper: '',
	}),
	[ProjectTimelineEventType.ProjectDateEvent]: (title?: string | null) => {
		if (ProjectDateEventTitles.PictureDay === title) {
			return {
				icon: 'icon-timeline-camera',
				iconHelper: 'picture',
			};
		}

		return {
			icon: 'icon-timeline-camera is-black',
			iconHelper: '',
		};
	},
	[ProjectTimelineEventType.ProjectLifeCycleEvent]: (title?: string | null) => {
		const iconHelper = 'icon-fill';

		if (title === ProjectLifeCycleEventTitles.ProjectPublished) {
			return { icon: 'icon-published', iconHelper };
		}

		if (title === ProjectLifeCycleEventTitles.ProjectExpired) {
			return { icon: 'icon-expired', iconHelper };
		}

		return {
			icon: '',
			iconHelper: '',
		};
	},
};
