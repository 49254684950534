export enum SearchPhotoByParameters {
	ParticipantName = 'ParticipantName',
	AccessCode = 'AccessCode',
	EmailAddress = 'EmailAddress',
	PhoneNumber = 'PhoneNumber',
	ImageName = 'ImageName',
	GroupName = 'GroupName',
	DownloadOrdered = 'DownloadOrdered',
	ImageDownloaded = 'ImageDownloaded',
}
