import { IStatisticRenderHelper } from 'types/statistic/statisticRenderHelper';

export const studioStatisticPriceKeys: IStatisticRenderHelper[] = [
	{
		key: 'revenue',
		title: 'Revenue',
	},
	{
		key: 'averageOrder',
		title: 'Average Order',
	},
	{
		key: 'revenuePerPerson',
		title: 'Revenue Per Person',
	},
];
