import { createSelector } from 'reselect';

import { GENERAL_SELECT_OPTION } from 'constants/projects/selectOptions/generalFilterSelectOption';
import { RootState } from 'store/store';

export const campaignsStateSelector = (state: RootState) => state.campaigns;

export const campaignsSelector = createSelector(
	campaignsStateSelector,
	({ campaigns }) => campaigns
);

export const copiedCampaignSelector = createSelector(
	campaignsStateSelector,
	({ copiedCampaign }) => copiedCampaign
);

export const targetFilterSelectOptionSelector = createSelector(
	campaignsStateSelector,
	({ targetFilterSelectOption }) => targetFilterSelectOption
);

export const journeyFilterSelectOptionSelector = createSelector(
	campaignsStateSelector,
	({ journeyFilterSelectOption }) => journeyFilterSelectOption
);

export const isCampaignsPendingSelector = createSelector(
	campaignsStateSelector,
	({ isCampaignsPending }) => isCampaignsPending
);

const filteredCampaignsByTargetSelector = createSelector(
	campaignsSelector,
	targetFilterSelectOptionSelector,
	(campaigns, targetFilterSelectOption) => {
		if (!targetFilterSelectOption) return campaigns;

		return campaigns.filter(
			(campaign) =>
				targetFilterSelectOption === GENERAL_SELECT_OPTION ||
				campaign.campaignTarget === targetFilterSelectOption
		);
	}
);

const filteredCampaignsByJourneySelector = createSelector(
	filteredCampaignsByTargetSelector,
	journeyFilterSelectOptionSelector,
	(campaigns, journeyFilterSelectOption) => {
		if (!journeyFilterSelectOption) return campaigns;

		return campaigns.filter(
			(campaign) =>
				journeyFilterSelectOption === GENERAL_SELECT_OPTION ||
				campaign.journey === journeyFilterSelectOption
		);
	}
);

export const filteredCampaignsSelector = createSelector(
	filteredCampaignsByJourneySelector,
	(campaigns) => campaigns
);

export const usedInPresetsSelector = createSelector(
	campaignsStateSelector,
	({ usedInPresets }) => usedInPresets
);

export const usedInProjectsSelector = createSelector(
	campaignsStateSelector,
	({ usedInProjects }) => usedInProjects
);

export const isDeleteCampaignRejectSelector = createSelector(
	campaignsStateSelector,
	({ isDeleteCampaignReject }) => isDeleteCampaignReject
);

export const isDeleteCampaignSuccessSelector = createSelector(
	campaignsStateSelector,
	({ isDeleteCampaignSuccess }) => isDeleteCampaignSuccess
);

export const isCopySuccessSelector = createSelector(
	campaignsStateSelector,
	({ isCopySuccess }) => isCopySuccess
);
