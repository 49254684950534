import { UnitTypes } from 'api/models/responses/general/unitTypes';
import { ISelectOption } from 'types/ui/select';

import { chargeUnitTypesUI } from '../chargeUnitTypesUI';

export const chargeUnitTypesSelectOptions: ISelectOption<UnitTypes>[] =
	Object.values(UnitTypes).map((chargeUnitType) => ({
		value: chargeUnitType,
		label: chargeUnitTypesUI[chargeUnitType],
	}));
