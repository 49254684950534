export const broadcastUrls = {
	sendSpecialBroadcast: 'api/broadcast/send-special-broadcast',

	getBroadcast: (broadcastKey: number) => `api/broadcast/${broadcastKey}`,

	createBroadcast: (campaignKey: number) =>
		`api/broadcast/create-for-campaign/${campaignKey}`,

	getBroadcastUrl: (broadcastKey: number) => `api/broadcast/${broadcastKey}`,

	getBroadcastInfo: (projectKey: number) =>
		`api/broadcast/special-broadcast-info-for-project/${projectKey}`,

	sendTestBroadcast: 'api/broadcast/send-test-broadcast',
};
