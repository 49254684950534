import { Dispatch } from '@reduxjs/toolkit';

import { ISearchPriceLists } from 'api/models/requests/priceLists/searchPriceLists';
import PriceListsService from 'api/services/PriceListsService';

import {
	setPriceLists,
	setIsPriceListsPending,
	setPaginatedPriceLists,
} from './priceListsSlice';

export const searchPriceListsAsync =
	(searchParams: ISearchPriceLists, isPaginated = false) =>
	async (dispatch: Dispatch) => {
		try {
			dispatch(setIsPriceListsPending(true));

			const priceLists = await PriceListsService.searchPriceLists(searchParams);

			if (!priceLists) return;

			if (isPaginated) {
				dispatch(setPaginatedPriceLists(priceLists));
			} else {
				dispatch(setPriceLists(priceLists));
			}

			dispatch(setIsPriceListsPending(false));
		} catch (error) {
			dispatch(setIsPriceListsPending(false));

			console.log(error);
		}
	};
