import { FC } from 'react';

import { subscriptionRowLabels } from 'constants/subscriptions/subscriptionRowLabels';

export const TableRowNames: FC = () => {
	const TableRowNamesList = subscriptionRowLabels.map(({ label, isBold }) => {
		if (isBold) {
			return (
				<li key={label}>
					<b>{label}</b>
				</li>
			);
		}

		return <li key={label}>{label}</li>;
	});

	return <ul className="panel-names">{TableRowNamesList}</ul>;
};
