import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import { FC } from 'react';

import { ColumnLabelContainer } from 'components/Table/ColumnLabelContainer';
import { CellActionMenu } from 'components/Table/CellActionMenu';
import { MultiCell } from 'components/Table/MultiCell';
import { Table } from 'components/Table';

import { PhotosActionMenu } from 'constants/photos/photosActionMenu';
import { PhotoSortLabels } from 'constants/photos/photosSortLabels';
import { defaultImageUrl } from 'constants/images/defaultImage';

import { SortParamsResult } from 'hooks/useSortParams';
import { useActionMenu } from 'hooks/useActionMenu';
import { usePagination } from 'hooks/usePagination';

import { IPhotoUI } from '../../types';

interface IPhotosListProps extends SortParamsResult<PhotoSortLabels> {
	data: IPhotoUI[];
	handleActionMenuItemClick: (
		photoUI: IPhotoUI,
		menuItem: PhotosActionMenu
	) => void;
	getPaginatedPhotos: () => Promise<void> | void;
}

export const PhotosList: FC<IPhotosListProps> = ({
	data,
	orderBy,
	setSortParams,
	orderDirection,
	getPaginatedPhotos,
	handleActionMenuItemClick,
}) => {
	const ref = usePagination(getPaginatedPhotos);

	const { actionMenuId, setActionMenuId } = useActionMenu();

	const columns: Column<IPhotoUI>[] = [
		{
			id: 'image',
			Header: (
				<ColumnLabelContainer
					label="Image"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={PhotoSortLabels.FileName}
				/>
			),
			Cell: ({ row }: CellProps<IPhotoUI>) => {
				const { fileName, smallThumbnailUrl } = row.original;

				const thumbnailUrl = smallThumbnailUrl || defaultImageUrl;

				return (
					<div className="project-table-img">
						<img src={thumbnailUrl} alt="img" />
						<span className="project-table-alt" title={fileName}>
							{fileName}
						</span>
					</div>
				);
			},
		},
		{
			Header: (
				<ColumnLabelContainer
					label="Name"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={PhotoSortLabels.Name}
				/>
			),
			accessor: 'participantName',
			Cell: ({ value }: CellProps<IPhotoUI, IPhotoUI['participantName']>) => (
				<span className="project-table-name">{value}</span>
			),
		},
		{
			Header: (
				<ColumnLabelContainer
					orderBy={orderBy}
					label="Image Type"
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={PhotoSortLabels.ImageType}
				/>
			),
			accessor: 'imageType',
		},
		{
			Header: (
				<ColumnLabelContainer
					orderBy={orderBy}
					label="Access Code"
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={PhotoSortLabels.AccessCode}
				/>
			),
			accessor: 'password',
			Cell: ({ value }: CellProps<IPhotoUI>) => {
				return <span className="table-value">{value}</span>;
			},
		},
		{
			Header: (
				<ColumnLabelContainer
					label="Phone"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={PhotoSortLabels.Phone}
				/>
			),
			accessor: 'cellNumber',
			Cell: ({ row }: CellProps<IPhotoUI>) => {
				const {
					cellNumber,
					additionalPhoneNumbers,
					orderedByUsersWithPhoneNumbers,
				} = row.original;

				const phones: string[] = [];

				if (cellNumber) phones.push(cellNumber);

				phones.push(...additionalPhoneNumbers);
				phones.push(...orderedByUsersWithPhoneNumbers);

				return <MultiCell values={phones} />;
			},
		},
		{
			Header: (
				<ColumnLabelContainer
					label="Email"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={PhotoSortLabels.Email}
				/>
			),
			accessor: 'emailAddress',
			Cell: ({ row }: CellProps<IPhotoUI>) => {
				const {
					emailAddress,
					orderedByUsersWithEmails,
					additionalEmailAddresses,
				} = row.original;

				const emails: string[] = [];

				if (emailAddress) emails.push(emailAddress);

				emails.push(...orderedByUsersWithEmails);
				emails.push(...additionalEmailAddresses);

				return <MultiCell values={emails} />;
			},
		},
		{
			Header: 'Orders',
			accessor: 'orders',
			Cell: ({ value }: CellProps<IPhotoUI, IPhotoUI['orders']>) => {
				const Orders = value.map((order) => (
					<Link key={order} to="" className="project-table-link">
						{order}
					</Link>
				));

				return <div>{Orders}</div>;
			},
		},
		{
			id: 'download',
			Header: 'Download',
			Cell: ({ row }: CellProps<IPhotoUI>) => {
				const { hiResDownload, countOfDownloads } = row.original;

				const value = !hiResDownload ? 'n/a' : countOfDownloads;

				return (
					<div className="project-table-check">
						<span>{value}</span>
					</div>
				);
			},
		},
		{
			id: 'actionMenu',
			Cell: ({ row }: CellProps<IPhotoUI>) => (
				<CellActionMenu
					menuList={Object.values(PhotosActionMenu)}
					handleMenuItemClick={(value) =>
						handleActionMenuItemClick(row.original, value)
					}
					showActionMenu={actionMenuId === row.original.id}
					setActiveActionMenu={() => setActionMenuId(row.original.id)}
				/>
			),
		},
	];

	return (
		<div className="org-project-search-container">
			<Table
				data={data}
				columns={columns}
				className="org-table table-image project-album-table table-action-menu"
			/>
			<div ref={ref} style={{ height: '1px' }} />
		</div>
	);
};
