import { FC, SyntheticEvent } from 'react';

import { validateReferenceCode } from 'utils/validations/priceLists/validateReferenceCode';
import { LazyInput } from 'components/FormControls/LazyInput';

interface IReferenceCodeColumnProps {
	referenceCode: string;
	clearError: () => void;
	referenceCodeError?: string;
	updateReferenceCode: (
		referenceCode: string,
		validationMessage: string
	) => void;
}

export const ReferenceCodeColumn: FC<IReferenceCodeColumnProps> = ({
	clearError,
	referenceCode,
	referenceCodeError,
	updateReferenceCode,
}) => {
	const handleChangeReferenceCode = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		const trimmedValue = value.trim();

		const validationMessage = validateReferenceCode(trimmedValue);

		updateReferenceCode(trimmedValue, validationMessage);
	};

	return (
		<td className="price-option-td">
			<LazyInput
				clearError={clearError}
				error={referenceCodeError}
				placeholder="Reference Code"
				defaultValue={referenceCode}
				className="input exp-wrapper"
				handleChange={handleChangeReferenceCode}
			/>
		</td>
	);
};
