import { useFormikContext } from 'formik';
import { ChangeEvent, FC, useState } from 'react';

import { validateStringNumber } from 'utils/validations/general/validateStringNumber';
import { InputField } from 'components/InputField';

import { IGetPaidFormValues } from '../../types';

interface IFields {
	id: string;
	label: string;
	placeholder: string;
}

const fields: IFields[] = [
	{
		id: 'bankRoutingNumber',
		label: 'Routing #',
		placeholder: 'Placeholder',
	},
	{
		id: 'confirmBankRoutingNumber',
		label: 'Confirm Routing #',
		placeholder: 'Placeholder',
	},
	{
		id: 'bankAccountNumber',
		label: 'Account #',
		placeholder: 'Placeholder',
	},
	{
		id: 'confirmBankAccountNumber',
		label: 'Confirm Account #',
		placeholder: 'Placeholder',
	},
];

type FieldsVisibility = Record<string, boolean>;

const hiddenFieldsInitial: FieldsVisibility = {
	bankRoutingNumber: false,
	bankAccountNumber: false,
	confirmBankRoutingNumber: false,
	confirmBankAccountNumber: false,
};

export const BankACHInformation: FC = () => {
	const [fieldsVisibility, setFieldsVisibility] =
		useState<FieldsVisibility>(hiddenFieldsInitial);

	const { errors, values, touched, handleChange } =
		useFormikContext<IGetPaidFormValues>();

	const handleValidateNumber = (e: ChangeEvent<HTMLInputElement>) => {
		const isValidNumber = validateStringNumber(e.target.value);

		if (!isValidNumber) return;

		handleChange(e);
	};

	const changeFieldsVisibility = (id: string) => {
		setFieldsVisibility((prev) => ({ ...prev, [id]: !prev[id] }));
	};

	const FieldsList = fields.map(({ id, label, placeholder }) => {
		const visibility = fieldsVisibility[id];

		const type = visibility ? 'text' : 'password';
		const showHideText = visibility ? 'Hide' : 'Show';

		return (
			<div className="acc-toggle-section" key={id}>
				<InputField
					id={id}
					required
					type={type}
					label={label}
					touched={!!touched[id]}
					error={errors[id] || ''}
					placeholder={placeholder}
					value={values[id] as string}
					wrapperClassName="form-container"
					handleChange={handleValidateNumber}
				/>
				<span
					className="acc-toggle-btn"
					onClick={() => changeFieldsVisibility(id)}
				>
					{showHideText}
				</span>
			</div>
		);
	});

	return (
		<div className="studio-form">
			<h4 className="studio-subtitle">
				This is the account we will payout your profits to.
			</h4>
			<InputField
				required
				type="text"
				id="bankAccountName"
				placeholder="Placeholder"
				handleChange={handleChange}
				label="Account Holder’s Name"
				value={values.bankAccountName}
				touched={!!touched.bankAccountName}
				wrapperClassName="acc-holder-field"
				error={errors.bankAccountName || ''}
			/>
			<span className="acc-holder-info">
				As it appears on your bank statement
			</span>
			<div className="studio-col-container">{FieldsList}</div>
		</div>
	);
};
