import { ChangeEvent, FC } from 'react';

import { PreviewImageColumn } from 'pages/PriceLists/components/PreviewImageColumn';
import { DescriptionColumn } from 'pages/PriceLists/components/DescriptionColumn';
import { BurgerColumn } from 'pages/PriceLists/components/BurgerColumn';

interface IOwnPackageOptionContentProps {
	description: string;
	previewError: string;
	showBurgerBtn: boolean;
	isImagePending: boolean;
	descriptionError: string;
	handleMouseDown: () => void;
	previewImageFile: File | null;
	previewImageUrl: string | null;
	clearDescriptionError: () => void;
	handleDeleteImage: () => Promise<void>;
	handleUploadImage: (imageFile: File) => Promise<void>;
	updateDescription: (value: string, validationMessage: string) => void;
}

export const OwnPackageOptionContent: FC<IOwnPackageOptionContentProps> = ({
	description,
	previewError,
	showBurgerBtn,
	isImagePending,
	handleMouseDown,
	previewImageUrl,
	descriptionError,
	previewImageFile,
	handleUploadImage,
	updateDescription,
	handleDeleteImage,
	clearDescriptionError,
}) => {
	const uploadImage = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];

		if (!file) return;

		void handleUploadImage(file);
	};

	const deleteImage = () => {
		void handleDeleteImage();
	};

	return (
		<tr className="price-option-tr">
			<BurgerColumn
				colSpan={1}
				showBurger={showBurgerBtn}
				handleMouseDown={handleMouseDown}
			/>
			<PreviewImageColumn
				colSpan={1}
				error={previewError}
				dimensions="300x450 recommended"
				isImagePending={isImagePending}
				handleUploadImage={uploadImage}
				handleDeleteImage={deleteImage}
				previewImageUrl={previewImageUrl}
				previewImageFile={previewImageFile}
			/>
			<DescriptionColumn
				colSpan={3}
				error={descriptionError}
				description={description}
				updateDescription={updateDescription}
				clearDescriptionError={clearDescriptionError}
			/>
			<td className="price-option-td"></td>
		</tr>
	);
};
