import { OrganizationDetailsPage } from 'pages/Organizations/pages/OrganizationDetailsPage';
import { ArchivedOrganizations } from 'pages/Organizations/pages/ArchivedOrganizations';
import { ActiveOrganizations } from 'pages/Organizations/pages/ActiveOrganizations';

import { ROUTES } from 'constants/ROUTES';

export const organizationRoutesList = [
	{
		path: ROUTES.ORGANIZATIONS.ACTIVE_ORGANIZATIONS,
		element: <ActiveOrganizations />,
	},
	{
		path: ROUTES.ORGANIZATIONS.ARCHIVED_ORGANIZATIONS,
		element: <ArchivedOrganizations />,
	},
	{
		path: ROUTES.ORGANIZATIONS.ORGANIZATION_DETAILS,
		element: <OrganizationDetailsPage />,
	},
];
