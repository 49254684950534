import { ISelectOption } from 'types/ui/select';

import { simpleAnswerUI } from './simpleAnswerUI';
import { SimpleAnswer } from './simpleAnswer';

export const simpleAnswerSelectOptions: ISelectOption<SimpleAnswer>[] =
	Object.values(SimpleAnswer).map((value) => ({
		value,
		label: simpleAnswerUI[value],
	}));
