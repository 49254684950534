import { IGeneralPriceListOption } from '../../generalPriceListOption';

export enum OrderShippingTypes {
	Batch = 'Batch',
	MailToHome = 'MailToHome',
}

export interface IShippingOption extends Omit<IGeneralPriceListOption, 'name'> {
	isDefault: boolean;
	referenceCode: string;
	shippingPrice: number;
	isCollectingMailingAddress: boolean;
	priceListStudioFulfilledKey: number;
	orderShippingType: OrderShippingTypes;
	priceListStudioFulfilledShippingOptionKey: number;
}
