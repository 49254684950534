import { IBaseOptions } from 'api/models/responses/priceLists/studio/priceListOptions/baseOptions';
import { IPriceListStudio } from 'api/models/responses/priceLists/studio/priceListStudio';
import { ICreatePriceList } from 'api/models/requests/priceLists/createPriceList';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { BaseService } from 'api/services/BaseService';

import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';

class PriceListStudioService extends BaseService {
	async getPriceListStudio(
		priceListKey: number
	): Promise<IPriceListStudio | null> {
		return this.get(
			priceListsUrls.studioFulfilled.getPriceListStudioFulfilledUrl(
				priceListKey
			)
		);
	}

	async createPriceListStudio(
		body: ICreatePriceList
	): Promise<IPriceListStudio | null> {
		return this.post(
			priceListsUrls.studioFulfilled.createPriceListStudioFulfilled,
			body
		);
	}

	async updatePriceListStudio(
		priceListKey: number,
		body: IPatchBody[]
	): Promise<IPriceListStudio | null> {
		return this.patch(
			priceListsUrls.studioFulfilled.getPriceListStudioFulfilledUrl(
				priceListKey
			),
			body
		);
	}

	async getPriceListStudioBaseOptions(
		priceListKey: number
	): Promise<IBaseOptions | null> {
		return this.get(
			priceListsUrls.studioFulfilled.getBaseOptions(priceListKey)
		);
	}
}

export default new PriceListStudioService();
