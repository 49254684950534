import { ICatalogProduct } from './catalogProducts';

export enum GiftAndAccessoriesProductCategoryTypes {
	BagTags = 'BagTags',
	Calendars = 'Calendars',
	Drinkware = 'Drinkware',
	GraphicPrints = 'GraphicPrints',
	Jewelry = 'Jewelry',
	Keychains = 'Keychains',
	Magnets = 'Magnets',
	Notepads = 'Notepads',
	Office = 'Office',
	Ornaments = 'Ornaments',
	TraderCards = 'TraderCards',
}

export interface IGiftAndAccessoriesCatalogProduct {
	value: ICatalogProduct[];
	key: GiftAndAccessoriesProductCategoryTypes;
}
