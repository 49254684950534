import { PromotionDetails } from 'pages/Promotions/pages/PromotionDetails';
import { Promotions } from 'pages/Promotions/pages/Promotions';

import { ROUTES } from 'constants/ROUTES';

export const promotionsRoutesList = [
	{
		path: ROUTES.PROMOTIONS.PROMOTIONS,
		element: <Promotions />,
	},
	{
		path: ROUTES.PROMOTIONS.CREATE_PROMOTION,
		element: <PromotionDetails />,
	},
	{
		path: ROUTES.PROMOTIONS.PROMOTIONS_DETAILS,
		element: <PromotionDetails />,
	},
];
