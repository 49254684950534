import { useEffect } from 'react';

import { MARKET_SELL } from 'constants/navigation';
import { ROUTES } from 'constants/ROUTES';

import { IBreadcrumbs, setBreadcrumbs } from 'store/breadcrumbs';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import NavigationService from 'utils/NavigationService';

export const usePromotionsBreadcrumbs = (
	promotionBreadcrumbs?: IBreadcrumbs | null
) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		const marketSellNavigation =
			NavigationService.getParentNavigation(MARKET_SELL);

		const promotionsNavigation = NavigationService.getSubNavigation(
			MARKET_SELL,
			ROUTES.PROMOTIONS.PROMOTIONS
		);

		if (!marketSellNavigation || !promotionsNavigation) return;

		const marketSellBreadcrumbs: IBreadcrumbs = {
			isActive: false,
			path: marketSellNavigation.path,
			title: marketSellNavigation.title,
		};

		const promotionsBreadcrumbs: IBreadcrumbs = {
			isActive: !promotionBreadcrumbs,
			path: promotionsNavigation.path,
			title: promotionsNavigation.title,
		};

		const breadcrumbs: IBreadcrumbs[] = [
			marketSellBreadcrumbs,
			promotionsBreadcrumbs,
		];

		if (promotionBreadcrumbs) {
			breadcrumbs.push(promotionBreadcrumbs);
		}

		dispatch(setBreadcrumbs(breadcrumbs));
	}, [promotionBreadcrumbs?.path, promotionBreadcrumbs?.title]);
};
