import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IMarketingStatistic } from 'api/models/responses/dashboard/marketingStatistic';
import { IStudioStatistic } from 'api/models/responses/dashboard/studioStatistic';
import { ILatestProject } from 'api/models/responses/projects/latestProjects';
import { IComingSoon } from 'api/models/responses/projects/comingSoon';
import { INewOrder } from 'api/models/responses/orders/newOrder';

import { IDashboardState, IDateRange } from './types';

const initialState: IDashboardState = {
	newOrders: [],
	comingSoon: [],
	latestProjects: [],
	studioStatistic: null,
	selectedDateRange: null,
	marketingStatistic: [],
	isDashboardFulfilled: false,
};

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		setMarketingStatistic: (
			state,
			{ payload }: PayloadAction<IMarketingStatistic[]>
		) => {
			state.marketingStatistic = payload;
		},

		setStudioStatistic: (
			state,
			{ payload }: PayloadAction<IStudioStatistic>
		) => {
			state.studioStatistic = payload;
		},

		setNewOrders: (state, { payload }: PayloadAction<INewOrder[]>) => {
			state.newOrders = payload;
		},

		setComingSoon: (state, { payload }: PayloadAction<IComingSoon[]>) => {
			state.comingSoon = payload;
		},

		setLatestProjects: (
			state,
			{ payload }: PayloadAction<ILatestProject[]>
		) => {
			state.latestProjects = payload;
		},

		setDate: (state, { payload }: PayloadAction<IDateRange>) => {
			state.selectedDateRange = payload;
		},

		setIsDashboardFulfilled: (state, { payload }: PayloadAction<boolean>) => {
			state.isDashboardFulfilled = payload;
		},
	},
});

export const dashboardReducer = dashboardSlice.reducer;
export const {
	setDate,
	setNewOrders,
	setComingSoon,
	setLatestProjects,
	setStudioStatistic,
	setMarketingStatistic,
	setIsDashboardFulfilled,
} = dashboardSlice.actions;
