import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IW9BillingInfoResponse } from 'api/models/responses/studioBilling/w9BillingInfo';
import { IBankAccountResponse } from 'api/models/responses/studioBilling/bankAccount';

import { IStudioBillingState } from './types';

const initialState: IStudioBillingState = {
	bankACH: null,
	studioBilling: null,
	isBankACHFulfilled: false,
	iStudioBillingFulfilled: false,
};

const studioBillingSlice = createSlice({
	name: 'studioBilling',
	initialState,
	reducers: {
		setBankACH: (state, { payload }: PayloadAction<IBankAccountResponse>) => {
			state.bankACH = payload;
			state.isBankACHFulfilled = true;
		},

		setStudioBilling: (
			state,
			{ payload }: PayloadAction<IW9BillingInfoResponse>
		) => {
			state.studioBilling = payload;
			state.iStudioBillingFulfilled = true;
		},
	},
});

export const studioBillingReducer = studioBillingSlice.reducer;
export const { setBankACH, setStudioBilling } = studioBillingSlice.actions;
