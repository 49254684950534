import { useFormikContext } from 'formik';
import cn from 'classnames';
import { FC } from 'react';

import { GENERAL_SELECT_OPTION } from 'constants/projects/selectOptions/generalFilterSelectOption';
import { ICreateProjectFromPresetValues } from 'pages/Projects/pages/CreateProjectFromPreset';
import { journeyPresetsSelector } from 'store/journeyPresets';
import { useAppSelector } from 'hooks/redux/useAppSelector';

export const JourneyPresets: FC = () => {
	const { values, setFieldValue } =
		useFormikContext<ICreateProjectFromPresetValues>();

	const projectPresets = useAppSelector(journeyPresetsSelector);

	const { projectType, journey } = values;

	const filteredByProjectType =
		projectType !== GENERAL_SELECT_OPTION
			? projectPresets.filter((preset) => preset.projectType === projectType)
			: projectPresets;

	const filteredByJourney =
		journey !== GENERAL_SELECT_OPTION
			? filteredByProjectType.filter((preset) => journey === preset.journey)
			: filteredByProjectType;

	const handleSelectPreset = (presetKey: number) => {
		const searchedPreset = projectPresets.find(
			(preset) => preset.presetKey === presetKey
		);

		void setFieldValue('journeyPreset', searchedPreset);
	};

	const isEmpty = !filteredByJourney.length;

	const PresetsList = !isEmpty ? (
		filteredByJourney.map(({ presetKey, presetName }) => {
			return (
				<li
					key={presetKey}
					className={cn('presets-journey-item', {
						'is-active': values?.journeyPreset?.presetKey === presetKey,
					})}
					onClick={() => handleSelectPreset(presetKey)}
				>
					{presetName}
				</li>
			);
		})
	) : (
		<li className="presets-journey-item">No Journey Presets Found</li>
	);

	return (
		<>
			<span className="preset-title">Journey Presets</span>
			<ul
				className={cn('presets-journey-list', {
					'is-empty': isEmpty,
				})}
			>
				{PresetsList}
			</ul>
		</>
	);
};
