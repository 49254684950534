import { FC, ReactNode } from 'react';

import { InputGrid } from 'components/FormControls/InputGrid';

interface ICampaignEditProps {
	disabled: boolean;
	children?: ReactNode;
	campaignName: string;
	handleEdit: () => void;
}

export const CampaignEdit: FC<ICampaignEditProps> = ({
	children,
	disabled,
	handleEdit,
	campaignName,
}) => (
	<div className="preset-header-double">
		<div className="marketing-section-header">
			<InputGrid
				row
				isLazy
				readOnly
				disabled={false}
				required={false}
				className="preset"
				placeholder={campaignName}
			/>
			<button
				disabled={disabled}
				onClick={handleEdit}
				className="marketing-edit"
			>
				...
			</button>
		</div>
		{children}
	</div>
);
