import { priceListNameRegEx } from 'constants/priceLists/validation/priceListNameRegEx';

import {
	requiredFieldMessage,
	invalidCharactersMessage,
} from 'constants/general/validation/generalMessages';

const maxNameLength = 100;

export const validateOptionName = (name: string): string => {
	if (!name) {
		return requiredFieldMessage;
	}

	if (!priceListNameRegEx.test(name)) {
		return invalidCharactersMessage;
	}

	if (name.length > 100) {
		return `Name should be less than ${maxNameLength} characters.`;
	}

	return '';
};
