import { CreateImagoDefaultBackgroundOption } from 'api/models/requests/priceLists/imago/priceListOptions/createDefaultBackgroundOption';
import { CreateImagoBackgroundOption } from 'api/models/requests/priceLists/imago/priceListOptions/createBackgroundOption';
import { IImagoBackgroundOption } from 'api/models/responses/priceLists/imago/priceListOptions/backgroundOptions';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { BaseService } from 'api/services/BaseService';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';

class ImagoBackgroundOptionsService extends BaseService {
	async getImagoBackgroundOptions(
		priceListKey: number
	): Promise<IImagoBackgroundOption[] | null> {
		return this.get(
			priceListsUrls.imagoFulfilled.backgroundOptions.getBackgroundOptions(
				priceListKey
			)
		);
	}

	async createImagoBackgroundOption(
		priceListKey: number,
		{ name, sequence, backgroundImage }: CreateImagoBackgroundOption
	): Promise<IImagoBackgroundOption | null> {
		const formData = new FormData();

		formData.set('Name', name);
		formData.set('Sequence', sequence.toString());
		formData.set('BackgroundImage', backgroundImage);

		return this.post(
			priceListsUrls.imagoFulfilled.backgroundOptions.createBackgroundOption(
				priceListKey
			),
			formData,
			{ ...multipartHeaders }
		);
	}

	async createDefaultBackgroundOption(
		priceListKey: number,
		body: CreateImagoDefaultBackgroundOption
	): Promise<IImagoBackgroundOption | null> {
		return this.post(
			priceListsUrls.imagoFulfilled.backgroundOptions.createDefaultBackgroundOption(
				priceListKey
			),
			body
		);
	}

	async updateBackgroundOption(
		backgroundOptionKey: number,
		body: IPatchBody[]
	): Promise<IImagoBackgroundOption | null> {
		return this.patch(
			priceListsUrls.imagoFulfilled.backgroundOptions.getBackgroundOptionUrl(
				backgroundOptionKey
			),
			body
		);
	}

	async deleteBackgroundOption(
		backgroundOptionKey: number
	): Promise<string | null> {
		return this.delete(
			priceListsUrls.imagoFulfilled.backgroundOptions.getBackgroundOptionUrl(
				backgroundOptionKey
			)
		);
	}

	async reorderBackgroundOptions(
		priceListKey: number,
		body: Record<number, number>
	): Promise<string | null> {
		return this.put(
			priceListsUrls.imagoFulfilled.backgroundOptions.reorderBackgroundOptions(
				priceListKey
			),
			body
		);
	}
}

export default new ImagoBackgroundOptionsService();
