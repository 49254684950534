import { duplicatedFormQuestion } from 'constants/projects/validation/subjectFormQuestion';
import { ISubjectFormQuestionUI } from 'components/SubjectFormQuestions/types';

export const validateForUniqueSubjectFormQuestions = (
	subjectFormQuestionsUI: ISubjectFormQuestionUI[]
): ISubjectFormQuestionUI[] => {
	const duplicatedLocalKeys = subjectFormQuestionsUI.reduce<string[]>(
		(acc, subjectFormQuestion) => {
			const isRepeated = subjectFormQuestionsUI.some(
				({ localKey, formQuestion, dataFieldType }) => {
					const sameField = subjectFormQuestion.localKey === localKey;

					if (sameField) return false;

					const isFormQuestionEmpty =
						!subjectFormQuestion.formQuestion || !formQuestion;

					const isDataFieldTypeEmpty =
						!subjectFormQuestion.dataFieldType || !dataFieldType;

					const isEmpty = isFormQuestionEmpty || isDataFieldTypeEmpty;

					const isRepeatedFormQuestion =
						subjectFormQuestion.formQuestion === formQuestion;

					const isRepeatedDataFieldType =
						subjectFormQuestion.dataFieldType === dataFieldType;

					return isRepeatedFormQuestion && isRepeatedDataFieldType && !isEmpty;
				}
			);

			if (!isRepeated) return acc;

			return [...acc, subjectFormQuestion.localKey];
		},
		[]
	);

	return subjectFormQuestionsUI.map((subjectFormQuestion) => {
		const repeated = duplicatedLocalKeys.includes(subjectFormQuestion.localKey);

		if (!repeated) {
			const isRepeatedError =
				subjectFormQuestion.error.formQuestion === duplicatedFormQuestion;

			if (!isRepeatedError) return subjectFormQuestion;

			return {
				...subjectFormQuestion,
				error: {
					...subjectFormQuestion.error,
					formQuestion: '',
				},
			};
		}

		return {
			...subjectFormQuestion,
			error: {
				...subjectFormQuestion.error,
				formQuestion: duplicatedFormQuestion,
			},
		};
	});
};
