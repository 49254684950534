export const photosUrls = {
	searchPhoto: 'api/photo/search',

	getImageSasUrl: 'imago-images-management/imago-studio/GetImageSasUrl',

	getGeneralPhotoUrl: (projectKey: number, photoKey: string) =>
		`api/photo/${photoKey}/for-project/${projectKey}`,

	uploadPasswords: (projectKey: number) =>
		`api/photo/upload-passwords/for-project/${projectKey}`,
};
