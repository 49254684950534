import { FC, useEffect, useState } from 'react';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { studioStatisticSelector } from 'store/dashboard';
import { setBreadcrumbs } from 'store/breadcrumbs';

import { studioStatisticPriceKeys } from 'constants/dashboard/studioStatisticPriceKeys';
import { studioStatisticKeys } from 'constants/dashboard/studioStatisticKeys';
import { DASHBOARD } from 'constants/navigation';

import NavigationService from 'utils/NavigationService';

import { DateRangeSelect } from './components/DateRangeSelect';
import { LatestProjects } from './components/LatestProjects';
import { PanelData } from '../../components/PanelData';
import { ComingSoon } from './components/ComingSoon';
import { DidYouKnow } from './components/DidYouKnow';
import { NewOrders } from './components/NewOrders';
import { CreateNew } from './components/CreateNew';
import { Schedule } from './components/Schedule';
import { OrderWaiting } from './components/OrderWaiting';

export const Dashboard: FC = () => {
	const studioStatistic = useAppSelector(studioStatisticSelector);

	const dispatch = useAppDispatch();

	useEffect(() => {
		const dashboardNavigation =
			NavigationService.getParentNavigation(DASHBOARD);

		if (!dashboardNavigation) return;

		const dashboardBreadcrumbs = {
			isActive: true,
			path: dashboardNavigation.path,
			title: dashboardNavigation.title,
		};

		const breadcrumbs = [dashboardBreadcrumbs];

		dispatch(setBreadcrumbs(breadcrumbs));
	}, [dispatch]);

	const [showComponent, setShowComponent] = useState(window.innerWidth < 768);

	useEffect(() => {
		const handleResize = () => setShowComponent(window.innerWidth < 768);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<div className="dashboard">
			<PanelData
				statistic={studioStatistic}
				statisticKeys={studioStatisticKeys}
				statisticPriceKeys={studioStatisticPriceKeys}
			>
				<DateRangeSelect />
			</PanelData>
			<div className="dashboard-thumb">
				{!showComponent && <Schedule />}
				<ComingSoon />
				<OrderWaiting />
				<NewOrders />
				<LatestProjects />
				<CreateNew />
				<DidYouKnow />
			</div>
		</div>
	);
};
