import { FC } from 'react';

interface IBurgerBtnProps {
	handleClick?: () => void;
	handleMouseDown?: () => void;
}

export const BurgerBtn: FC<IBurgerBtnProps> = ({
	handleClick,
	handleMouseDown,
}) => (
	<button
		onClick={handleClick}
		className="price-btn-table"
		onMouseDown={handleMouseDown}
	>
		<i className="price-icon-table icon-hamburger" />
	</button>
);
