import { FC, useCallback, useEffect } from 'react';

import { IBackgroundOptionsVisibilityProps } from 'pages/PriceLists/types/studio/priceListOptions/backgroundOptionsVisibilityProps';
import { BackgroundOption } from 'pages/PriceLists/components/BackgroundOptions/components/BackgroundOption';
import { useCreatePriceListOptionBtn } from 'pages/PriceLists/hooks/useCreatePriceListOptionBtn';
import {
	PriceTable,
	IHeaderConfig,
} from 'pages/PriceLists/components/PriceTable';

import { CommonOptionActionMenu } from 'constants/priceLists/commonOptionActionMenu';
import { PriceListFulfillment } from 'constants/priceLists/priceListFulfillment';

import { IChangeSequencePayload } from 'hooks/useDragAndDrop';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useActionMenu } from 'hooks/useActionMenu';

import { Button } from 'components/FormControls/Button';
import { NoItemsFound } from 'components/NoItemsFound';

import {
	addStudioBackground,
	IClearBgOptionError,
	IBgActionMenuParams,
	addImagoBackground,
	IUpdateBackgroundOption,
	imagoBackgroundsSelector,
	backgroundFileKeySelector,
	studioBackgroundsSelector,
	saveImagoBackgroundsAsync,
	deleteImagoBackgroundAsync,
	makeImagoBackgroundDefault,
	clearImagoBackgroundError,
	clearStudioBackgroundError,
	saveStudioBackgroundsAsync,
	defaultBackgroundsSelector,
	updateImagoBackgroundAsync,
	deleteStudioBackgroundAsync,
	makeStudioBackgroundDefault,
	updateStudioBackgroundAsync,
	reorderImagoBackgroundsAsync,
	customBackgroundFileSelector,
	deleteStudioBackgroundsAsync,
	reorderStudioBackgroundsAsync,
	changeImagoBackgroundsSequence,
	isReorderedBackgroundsSelector,
	changeStudioBackgroundsSequence,
	backgroundFileSelectOptionsSelector,
} from 'store/priceLists/priceListBackgrounds';

import { ShowBackground } from './components/ShowBackground';

const studioBackgroundsTableHeaders: IHeaderConfig[] = [
	{ name: 'Preview', required: true },
	{ name: 'Name', required: true, left: true },
	{ name: 'Background File', required: true, left: true },
	{ name: 'Reference Code', required: true, left: true },
	{
		left: true,
		required: true,
		name: 'Retail Value',
		subtitle: 'charged per pose',
	},
];
const imagoBackgroundsTableHeaders: IHeaderConfig[] = [
	{ name: 'Preview', required: true },
	{ name: 'Name', required: true, left: true },
	{ name: 'Background File', required: true, left: true },
	{
		left: true,
		required: true,
		name: 'Retail Value',
		subtitle: 'charged per pose',
	},
];

interface IBackgroundOptionsProps extends IBackgroundOptionsVisibilityProps {
	priceListKey?: number;
	fulfillment: PriceListFulfillment;
}

export const BackgroundOptions: FC<IBackgroundOptionsProps> = ({
	fulfillment,
	priceListKey,
	updatePriceListStudio,
	backgroundOptionVisibilityType,
}) => {
	const isReorderedBackgrounds = useAppSelector(isReorderedBackgroundsSelector);
	const defaultBackgroundOptions = useAppSelector(defaultBackgroundsSelector);
	const customBackgroundFile = useAppSelector(customBackgroundFileSelector);
	const studioBackgrounds = useAppSelector(studioBackgroundsSelector);
	const backgroundFileKey = useAppSelector(backgroundFileKeySelector);
	const imagoBackgrounds = useAppSelector(imagoBackgroundsSelector);
	const backgroundFileSelectOptions = useAppSelector(
		backgroundFileSelectOptionsSelector
	);

	const dispatch = useAppDispatch();

	const isStudioFulfillment = fulfillment === PriceListFulfillment.Studio;

	const { actionMenuId, setActionMenuId } = useActionMenu();

	const lastStudioBackgroundOptionUI =
		studioBackgrounds[studioBackgrounds.length - 1];

	const lastImagoBackgroundOptionUI =
		imagoBackgrounds[imagoBackgrounds.length - 1];

	const handleUpdateBackgroundOption = async (
		payload: IUpdateBackgroundOption
	) => {
		if (isStudioFulfillment) {
			return dispatch(updateStudioBackgroundAsync(payload));
		}

		return dispatch(updateImagoBackgroundAsync(payload));
	};

	const handleAddBackgroundOption = () => {
		if (!priceListKey) return;

		if (isStudioFulfillment) {
			dispatch(addStudioBackground(priceListKey));
			return;
		}

		dispatch(addImagoBackground(priceListKey));
	};

	const handleSaveBackgroundOption = async () => {
		if (!priceListKey) return;

		if (isStudioFulfillment) {
			await dispatch(saveStudioBackgroundsAsync(priceListKey));
			return;
		}

		await dispatch(saveImagoBackgroundsAsync(priceListKey));
	};

	const handleDeleteBackgroundOption = async (
		sequence: number,
		backgroundOptionKey?: number
	) => {
		if (isStudioFulfillment) {
			return dispatch(
				deleteStudioBackgroundAsync(sequence, backgroundOptionKey)
			);
		}

		return dispatch(deleteImagoBackgroundAsync(sequence, backgroundOptionKey));
	};

	const changeBackgroundOptionsUISequence = (
		payload: IChangeSequencePayload
	) => {
		if (isStudioFulfillment) {
			return dispatch(changeStudioBackgroundsSequence(payload));
		}

		return dispatch(changeImagoBackgroundsSequence(payload));
	};

	const makeBackgroundOptionAsDefault = (backgroundOptionKey: number) => {
		if (isStudioFulfillment) {
			return dispatch(makeStudioBackgroundDefault(backgroundOptionKey));
		}

		return dispatch(makeImagoBackgroundDefault(backgroundOptionKey));
	};

	const reorderBackgroundOptions = useCallback(async () => {
		if (!isReorderedBackgrounds || !priceListKey) return;

		if (isStudioFulfillment) {
			return dispatch(reorderStudioBackgroundsAsync(priceListKey));
		}

		return dispatch(reorderImagoBackgroundsAsync(priceListKey));
	}, [isReorderedBackgrounds]);

	const handleActionMenuItemClick = ({
		menuItem,
		sequence,
		backgroundOptionKey,
	}: IBgActionMenuParams) => {
		switch (menuItem) {
			case CommonOptionActionMenu.MakeAsDefault:
				if (!backgroundOptionKey) return;

				makeBackgroundOptionAsDefault(backgroundOptionKey);
				break;

			case CommonOptionActionMenu.Delete:
				void handleDeleteBackgroundOption(sequence, backgroundOptionKey);
				break;

			default:
				break;
		}
	};

	const deleteBackgroundOptions = async () => {
		if (!priceListKey) return;

		await dispatch(deleteStudioBackgroundsAsync(priceListKey));
	};

	const handleDeleteBackgroundOptions = () => {
		void deleteBackgroundOptions();
	};

	const clearBgOptionError = (payload: IClearBgOptionError) => {
		if (isStudioFulfillment) {
			return dispatch(clearStudioBackgroundError(payload));
		}

		return dispatch(clearImagoBackgroundError(payload));
	};

	const defaultBackgroundThumbnailUrl =
		defaultBackgroundOptions.find(
			({ defaultPriceListBackgroundKey }) =>
				defaultPriceListBackgroundKey === backgroundFileKey
		)?.thumbnailImageCdnUrl || null;

	const generalProps = {
		actionMenuId,
		setActionMenuId,
		backgroundFileKey,
		customBackgroundFile,
		handleActionMenuItemClick,
		backgroundFileSelectOptions,
		handleUpdateBackgroundOption,
		defaultBackgroundThumbnailUrl,
		clearError: clearBgOptionError,
		changeBackgroundOptionsSequence: changeBackgroundOptionsUISequence,
	};

	const StudioBackgroundOptionsList = studioBackgrounds.map(
		(backgroundOptionUI) => (
			<BackgroundOption
				{...generalProps}
				backgroundOptionUI={backgroundOptionUI}
				backgroundOptionKey={
					backgroundOptionUI.priceListStudioFulfilledBackgroundOptionKey
				}
				key={
					backgroundOptionUI.priceListStudioFulfilledBackgroundOptionKey ||
					backgroundOptionUI.sequence
				}
			/>
		)
	);

	const ImagoBackgroundOptionsList = imagoBackgrounds.map(
		(backgroundOptionUI) => (
			<BackgroundOption
				{...generalProps}
				backgroundOptionUI={backgroundOptionUI}
				backgroundOptionKey={
					backgroundOptionUI.priceListImagoFulfilledBackgroundKey
				}
				key={
					backgroundOptionUI.priceListImagoFulfilledBackgroundKey ||
					backgroundOptionUI.sequence
				}
			/>
		)
	);

	const BackgroundOptionsList = isStudioFulfillment
		? StudioBackgroundOptionsList
		: ImagoBackgroundOptionsList;

	useEffect(() => {
		void reorderBackgroundOptions();
	}, [reorderBackgroundOptions]);

	const isStudioBackgroundKeyExist =
		!!lastStudioBackgroundOptionUI?.priceListStudioFulfilledBackgroundOptionKey;

	const isImagoBackgroundKeyExist =
		!!lastImagoBackgroundOptionUI?.priceListImagoFulfilledBackgroundKey;

	const isBackgroundKeyExist = isStudioFulfillment
		? isStudioBackgroundKeyExist
		: isImagoBackgroundKeyExist;

	const isEmptyBackgroundOptions = isStudioFulfillment
		? !studioBackgrounds.length
		: !imagoBackgrounds.length;

	const showSaveBtn = !isEmptyBackgroundOptions && !isBackgroundKeyExist;

	const { btnValue, btnClassName, btnClickHandler } =
		useCreatePriceListOptionBtn({
			showSaveBtn,
			optionName: 'Background Option',
			handleAdd: handleAddBackgroundOption,
			handleSave: handleSaveBackgroundOption,
		});

	const headers = isStudioFulfillment
		? studioBackgroundsTableHeaders
		: imagoBackgroundsTableHeaders;

	return (
		<>
			{isStudioFulfillment && (
				<ShowBackground
					updatePriceListStudio={updatePriceListStudio}
					handleDeleteBackgroundOptions={handleDeleteBackgroundOptions}
					backgroundOptionVisibilityType={backgroundOptionVisibilityType}
				/>
			)}
			{!isEmptyBackgroundOptions ? (
				<PriceTable headers={headers} className="price-table-backgrounds">
					{BackgroundOptionsList}
				</PriceTable>
			) : (
				<NoItemsFound title="background options" />
			)}
			<Button
				value={btnValue}
				handleClick={btnClickHandler}
				className={`price-add-back ${btnClassName}`}
			/>
		</>
	);
};
