import { DragEvent, useState } from 'react';

export interface IChangeSequencePayload {
	toBottom: boolean;
	dragElemId: string;
	dropElemId: string;
}

interface IDragAndDropResult {
	draggable: boolean;
	handleDragEnd: () => void;
	handleMouseDown: () => void;
	handleDrop: (e: DragEvent<HTMLDivElement>) => void;
	handleDragOver: (e: DragEvent<HTMLDivElement>) => void;
	handleDragStart: (e: DragEvent<HTMLDivElement>, dragElemId: string) => void;
}

export const useDragAndDrop = (
	disabledDragRowsSequence: number[],
	changeSequence: (params: IChangeSequencePayload) => void
): IDragAndDropResult => {
	const [draggable, setDraggable] = useState(false);

	const handleMouseDown = () => {
		setDraggable(true);
	};

	const handleDragEnd = () => {
		setDraggable(false);
	};

	const handleDragStart = (
		e: DragEvent<HTMLDivElement>,
		dragElemId?: string
	) => {
		if (!dragElemId) return;

		const dragElemSequence = e.currentTarget.dataset?.dragSequence || '';

		e.dataTransfer.setData('sequence', dragElemSequence);
		e.dataTransfer.setData('id', dragElemId);
	};

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		const { height, y } = e.currentTarget.getBoundingClientRect();

		const posYDiff = e.clientY - y;

		const toBottom = posYDiff > height / 2;

		const dragElemSequence = e.dataTransfer.getData('sequence');
		const dropElemSequence = e.currentTarget.dataset?.dragSequence;

		const dragElemId = e.dataTransfer.getData('id');
		const dropElemId = e.currentTarget.id;

		const isEqualDrop = dragElemId === dropElemId;

		if (
			!dropElemSequence ||
			!dragElemSequence ||
			!dragElemId ||
			!dropElemId ||
			isEqualDrop
		)
			return;

		const dropElemSequenceNumber = +dropElemSequence;

		const disabledElem = disabledDragRowsSequence.includes(
			dropElemSequenceNumber
		);

		if (disabledElem) return;

		changeSequence({
			toBottom,
			dropElemId,
			dragElemId,
		});
	};

	const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	return {
		draggable,
		handleDrop,
		handleDragEnd,
		handleDragOver,
		handleMouseDown,
		handleDragStart,
	};
};
