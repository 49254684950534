import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { FC } from 'react';

import { JourneyPresetExtendedPlaceholder } from 'components/Journey/JourneyPresetExtendedPlaceholder';
import { JourneyTimeline } from 'components/Journey/JourneyTimeline';
import { StepSubjectHeader } from 'components/StepSubjectHeader';
import { JourneyInfo } from 'components/Journey/JourneyInfo';
import { JourneyView } from 'components/Journey/JourneyView';

import { projectTimelineImages } from 'constants/projects/timeline/projectTimelineImages';
import { journeyTimelines } from 'constants/projects/timeline/journeyTimelines';
import { projectJourneyInfo } from 'constants/projects/projectJourneyInfo';
import { ROUTES } from 'constants/ROUTES';

import { ICreateJourneyPresetValues } from 'store/journeyPresets/types';
import {
	setCreateJourneyPresetValues,
	createJourneyPresetValuesSelector,
} from 'store/journeyPresets';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { useJourneyPresetBreadcrumbs } from 'pages/JourneyPresets/hooks/useJourneyPresetBreadcrumbs';
import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';

import { createJourneyPresetScheme } from './validation';
import { JourneyPresetForm } from './JourneyPresetForm';

export const CreateJourneyPreset: FC = () => {
	const journeyPresetValues = useAppSelector(createJourneyPresetValuesSelector);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useJourneyPresetBreadcrumbs();

	const initialValues: ICreateJourneyPresetValues = {
		journeyName: journeyPresetValues?.journeyName || '',
		projectType: journeyPresetValues?.projectType || null,
		journey: journeyPresetValues?.journey || ProjectJourneys.A,
	};

	const onSubmit = (values: ICreateJourneyPresetValues) => {
		dispatch(setCreateJourneyPresetValues(values));

		navigate(ROUTES.PRESETS.CREATE_PRESET_DETAILS);
	};

	return (
		<div className="project-thumb">
			<StepSubjectHeader title="Create a New Preset" />
			<Formik
				onSubmit={onSubmit}
				initialValues={initialValues}
				validationSchema={createJourneyPresetScheme}
			>
				{({ values }) => {
					const { journey } = values;

					const projectTimelineImage = projectTimelineImages[journey];
					const { sales, description } = projectJourneyInfo[journey];

					const {
						journeyText,
						journeyTitle,
						journeySubText,
						journeyBlankProjectImage,
					} = journeyTimelines[journey];

					return (
						<>
							<div className="project-blank-main">
								<JourneyPresetForm />
								<JourneyPresetExtendedPlaceholder />
							</div>
							<div className="project-timeline">
								<JourneyTimeline
									journeyText={journeyText}
									journeyTitle={journeyTitle}
									journeySubText={journeySubText}
									journeyImage={journeyBlankProjectImage}
								/>
							</div>
							<div className="project-journey">
								<JourneyInfo sales={sales} description={description} />
								<JourneyView projectTimelineImage={projectTimelineImage} />
							</div>
						</>
					);
				}}
			</Formik>
		</div>
	);
};
