export const priceListNameRegEx =
	/^(?![ '‘’`.&-])(?!.* $)[a-zA-Z0-9 '‘’`.&-]+$/;

export const priceListNameMinLength = 3;
export const priceListNameMaxLength = 100;

export const priceListNameErrorMessage = 'Incorrect price list name.';

export const priceListNameMinLengthError = `Price list name must be at least ${priceListNameMinLength} characters.`;
export const priceListNameMaxLengthError = `Price list name must be at most ${priceListNameMaxLength} characters.`;
