import { PersistGate } from 'redux-persist/integration/react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { Notification } from 'components/Notification';
import { authConfig } from 'config/authConfig';
import { store, persistor } from 'store';

import { App } from './App';

import './styles/main.scss';

export const msalInstance = new PublicClientApplication(authConfig);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<Provider store={store}>
		<PersistGate loading={false} persistor={persistor}>
			<MsalProvider instance={msalInstance}>
				<App />
				<Notification />
			</MsalProvider>
		</PersistGate>
	</Provider>
);
