import cn from 'classnames';
import { FC } from 'react';

interface ImageSectionTabProps {
	value: string;
	isActive: boolean;
	handleClick: () => void;
}

export const ImagesSectionTab: FC<ImageSectionTabProps> = ({
	value,
	isActive,
	handleClick,
}) => (
	<li
		onClick={handleClick}
		className={cn('project-images-item', {
			'is-active': isActive,
		})}
	>
		{value}
	</li>
);
