export const toolbar = {
	items: [
		'fontSize',
		'fontFamily',
		'fontColor',
		'fontBackgroundColor',
		'|',
		'bulletedList',
		'numberedList',
		'|',
		'alignment',
		'insertImage',
		'link',
		'horizontalLine',
		'|',
		'bold',
		'italic',
		'strikethrough',
		'underline',
		'removeFormat',
		'outdent',
		'indent',
		'|',
		'undo',
		'redo',
		'specialCharacters',
	],
};
