export const projectNameValidationRegEx =
	/^[a-zA-Z0-9][a-zA-Z0-9\s]{1,98}[a-zA-Z0-9]$/;
export const projectNameValidationMessage =
	'Name must be 3 - 100 letters, numbers, and spaces, and must begin with a letter or number.';

export const projectUrlValidationRegEx = /^[a-zA-Z0-9_]{3,100}$/;
export const projectUrlValidationMessage =
	'URL must be 3 - 100 letters, numbers or _ (underscore), and must begin with a letter, number or _ (underscore).';

export const maxAccessCodeMessageLength = 500;
export const accessCodeMessageErrorMessage =
	'Access Code Message must be max 500 characters.';

export const maxSmsSpecialMessageLength = 120;
export const smsSpecialMessageErrorMessage =
	'SMS Special Message must be max 120 characters.';

export const maxEmailSpecialMessageLength = 500;
export const emailSpecialMessageErrorMessage =
	'Email Special Message must be max 500 characters.';

export const maxOrderNotificationEmailLength = 100;
export const orderNotificationEmailErrorMessage =
	'Order Notification Email must be max 100 characters.';

export const maxOrderConfirmationMessageLength = 1000;
export const orderConfirmationMessageErrorMessage =
	'Order Confirmation Message must be max 1000 characters.';

export const estimatedNumberOfSubjectsBottomLimit = 1;
export const estimatedNumberOfSubjectsUpLimit = 99999;
export const estimatedNumberOfSubjectsValidationMessage =
	'Estimated number of subjects must be min 1 and max 99999 integer value.';
