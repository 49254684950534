import { useFormikContext } from 'formik';
import { FC } from 'react';

import { IGetPaidFormValues } from 'pages/RegistrationSteps/SetupAccount/types';
import { BillingEntityType } from 'constants/studio/billingEntityType';

interface IRadioField {
	id: string;
	label: string;
}

const radioFields: IRadioField[][] = [
	[
		{
			id: BillingEntityType.IndividualSoleProprietor,
			label: 'Individual/Sole Proprietor',
		},
		{
			id: BillingEntityType.Partenership,
			label: 'Partenership',
		},
		{
			id: BillingEntityType.LLCPartnership,
			label: 'Limited Liability Company (Partnership)',
		},
	],
	[
		{
			id: BillingEntityType.CCorporation,
			label: 'C Corporation',
		},
		{
			id: BillingEntityType.LLCSingleMember,
			label: 'Limited Liability Company (Single member LLC)',
		},
		{
			id: BillingEntityType.LLCSCorporation,
			label: 'Limited Liability Company (S-Corporation)',
		},
	],
	[
		{
			id: BillingEntityType.SCorporation,
			label: 'S Corporation',
		},
		{
			id: BillingEntityType.LLCCCorporation,
			label: 'Limited Liability Company (C-Corporation)',
		},
		{
			id: BillingEntityType.Other,
			label: 'Other (see instructions)',
		},
	],
];

export const W9EntityType: FC = () => {
	const { values, errors, touched, setFieldValue } =
		useFormikContext<IGetPaidFormValues>();

	const CheckBoxFieldsList = radioFields.map((radioFieldsParam, index) => (
		<td
			colSpan={2}
			id="billingEntityType"
			className="acc-td-clear"
			key={`${radioFieldsParam[index].id}-${radioFieldsParam[index].label}`}
		>
			{radioFieldsParam.map(({ id, label }) => (
				<label key={id} htmlFor={id} className="acc-check-label">
					<input
						id={id}
						type="radio"
						className="acc-check"
						name="billingEntityType"
						value={values[id] as string}
						onChange={() => {
							void setFieldValue('billingEntityType', id);
						}}
					/>
					{label}
				</label>
			))}
		</td>
	));

	const entityTypeError = errors.billingEntityType;
	const entityTypeTouched = touched.billingEntityType;

	const showEntityTypeError = entityTypeError && entityTypeTouched;

	return (
		<table className="acc-table" cellSpacing={0} cellPadding={2} border={0}>
			<tbody className="acc-tbody">
				<tr className="acc-tr">
					<th className="acc-th" colSpan={6}>
						<span className="acc-title-type">
							Vendor Entity Type. Check ONE of the appropriate circles:
						</span>
					</th>
				</tr>
				<tr className="acc-tr">{CheckBoxFieldsList}</tr>
				{showEntityTypeError && (
					<tr className="acc-tr acc-tr-radio">
						<span className="acc-error-message">{entityTypeError}</span>
					</tr>
				)}
			</tbody>
		</table>
	);
};
