import { useCallback, useEffect, useState } from 'react';

import { GiftAndAccessoriesProductCategoryTypes } from 'api/models/responses/catalogProducts/giftAndAccessoriesCatalogProduct';
import { ICreateProduct } from 'api/models/requests/priceLists/imago/priceListProducts/createProduct';
import PriceListProductsService from 'api/services/PriceListsService/imago/PriceListProductsService';
import { IErrorResponse } from 'api/models/responses/errors/errorResponse';
import CatalogProductsService from 'api/services/CatalogProductsService';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import {
	ICatalogProduct,
	CatalogProductCategoryTypes,
} from 'api/models/responses/catalogProducts/catalogProducts';

import { validateRetailValue } from 'utils/validations/priceLists/validateRetailValue';
import { validateOptionName } from 'utils/validations/priceLists/validateOptionName';
import { getProductSelectOptions } from 'utils/priceLists/getProductSelectOptions';
import { changeEntitiesSequence } from 'utils/dragAndDrop/changeEntitiesSequence';
import { getProductsUI } from 'utils/priceLists/getProductsUI';

import { PoseAvailableFor } from 'constants/priceLists/poseAvailableFor/poseAvailableFor';
import { initialProductErrors } from 'constants/priceLists/imago/initialProductErrors';
import { requiredFieldMessage } from 'constants/general/validation/generalMessages';

import { IChangeSequencePayload } from 'hooks/useDragAndDrop';
import {
	IProductUI,
	IUpdateProduct,
	IDeleteProduct,
	IClearProductError,
	IUploadProductImage,
} from 'pages/PriceLists/types/imago/productUI';
import { ISelectOption } from 'types/ui/select';

import { SetGiftsAndAccessoriesProductsUI } from '../pages/PriceListGiftAndAccessories/ProductContainer';

export type UpdateProductHandler = (payload: IUpdateProduct) => Promise<void>;
export type DeleteProductImageHandler = (productKey?: number) => Promise<void>;
export type DeleteProductHandler = (payload: IDeleteProduct) => Promise<void>;
export type ClearProductErrorHandler = (payload: IClearProductError) => void;
export type ChangeProductSequenceHandler = (
	payload: IChangeSequencePayload
) => void;
export type UploadProductImageHandler = (
	payload: IUploadProductImage
) => Promise<void>;
export type UpdateCatalogProduct = (
	productKey: number,
	catalogProductKey: number
) => Promise<void>;

interface IImagoProductsParams {
	priceListKey?: number;
	specialProductsUI?: IProductUI[];
	specialCatalogProducts?: ICatalogProduct[];
	catalogProductCategoryType: CatalogProductCategoryTypes;
	gifProductCategoryType?: GiftAndAccessoriesProductCategoryTypes;
	setGiftsAndAccessoriesProductsUI?: SetGiftsAndAccessoriesProductsUI;
}

interface IImagoProductsResult {
	productsUI: IProductUI[];
	isCatalogPending: boolean;
	lastProductUI?: IProductUI;
	isProductsPending: boolean;
	handleAddProduct: () => void;
	previewImageFile: File | null;
	deleteProduct: DeleteProductHandler;
	updateProduct: UpdateProductHandler;
	handleSaveProduct: () => Promise<void>;
	updateCatalogProduct: UpdateCatalogProduct;
	clearProductError: ClearProductErrorHandler;
	deleteProductImage: DeleteProductImageHandler;
	catalogProductsSelectOptions: ISelectOption[];
	uploadProductImage: UploadProductImageHandler;
	changeProductSequence: ChangeProductSequenceHandler;
}

export const useImagoProducts = ({
	priceListKey,
	specialProductsUI,
	gifProductCategoryType,
	specialCatalogProducts,
	catalogProductCategoryType,
	setGiftsAndAccessoriesProductsUI,
}: IImagoProductsParams): IImagoProductsResult => {
	const [catalogProducts, setCatalogProducts] = useState<ICatalogProduct[]>([]);
	const [productsUI, setProductsUI] = useState<IProductUI[]>([]);

	const [previewImageFile, setPreviewImageFile] = useState<File | null>(null);

	const [isCatalogPending, setIsCatalogPending] = useState(true);
	const [isProductsPending, setIsProductsPending] = useState(true);

	const [isReorder, setIsReorder] = useState(false);

	const isSpecialProducts =
		!!specialProductsUI &&
		!!gifProductCategoryType &&
		!!specialCatalogProducts &&
		!!setGiftsAndAccessoriesProductsUI;

	const products = isSpecialProducts ? specialProductsUI : productsUI;

	const lastProductUI = products[products.length - 1];

	const getCatalogProducts = useCallback(async () => {
		if (isSpecialProducts) return;

		try {
			const data = await CatalogProductsService.getCatalogProducts(
				catalogProductCategoryType
			);

			if (data) {
				setCatalogProducts(data);
			}
		} catch (error) {
			console.log(error);
		}

		setIsCatalogPending(false);
	}, [isSpecialProducts]);

	const getProducts = useCallback(async () => {
		if (!priceListKey || isSpecialProducts) return;

		try {
			const data = await PriceListProductsService.getProducts(
				priceListKey,
				catalogProductCategoryType
			);

			if (data) {
				setProductsUI(getProductsUI(data));
			}
		} catch (error) {
			console.log(error);
		}

		setIsProductsPending(false);
	}, [priceListKey, isSpecialProducts]);

	const handleAddProduct = () => {
		if (!priceListKey) return;

		const initialSequence = products.length && lastProductUI.sequence + 1;

		const newProduct: IProductUI = {
			name: '',
			profit: 0,
			fillCost: 0,
			serviceFee: 0,
			fulfillment: 0,
			retailPrice: 0,
			previewImageUrl: '',
			catalogProduct: null,
			catalogProductKey: 0,
			previewDescription: '',
			sequence: initialSequence,
			key: gifProductCategoryType,
			errors: initialProductErrors,
			availableFor: PoseAvailableFor.AnyPose,
			priceListImagoFulfilledKey: priceListKey,
		};

		const updatedProductsUI = [...products, newProduct];

		if (isSpecialProducts) {
			setGiftsAndAccessoriesProductsUI(updatedProductsUI);
			return;
		}

		setProductsUI(updatedProductsUI);
	};

	const handleSaveProduct = async () => {
		if (!priceListKey) return;

		const {
			name,
			sequence,
			retailPrice,
			availableFor,
			catalogProductKey,
			previewDescription,
		} = lastProductUI;

		const slicedProductsUI = products.slice(0, -1);

		const nameValidationMessage = validateOptionName(name);
		const catalogProductKeyValidationMessage = !catalogProductKey
			? requiredFieldMessage
			: '';
		const retailPriceValidationMessage = validateRetailValue(retailPrice);

		const isValid =
			!nameValidationMessage &&
			!retailPriceValidationMessage &&
			!catalogProductKeyValidationMessage;

		if (!isValid) {
			const updatedProductUI: IProductUI = {
				...lastProductUI,
				errors: {
					...lastProductUI.errors,
					name: nameValidationMessage,
					retailPrice: retailPriceValidationMessage,
					catalogProductKey: catalogProductKeyValidationMessage,
				},
			};

			const updatedProductsUI = [...slicedProductsUI, updatedProductUI];

			if (isSpecialProducts) {
				setGiftsAndAccessoriesProductsUI(updatedProductsUI);
				return;
			}

			setProductsUI(updatedProductsUI);
			return;
		}

		try {
			const body: ICreateProduct = {
				name,
				sequence,
				retailPrice,
				availableFor,
				previewImageFile,
				catalogProductKey,
				previewDescription,
			};

			const data = await PriceListProductsService.createProduct(
				priceListKey,
				body
			);

			if (!data) return;

			const updatedProductUI: IProductUI = {
				...data,
				key: gifProductCategoryType,
				errors: initialProductErrors,
			};

			const updatedProductsUI = [...slicedProductsUI, updatedProductUI];

			if (isSpecialProducts) {
				setGiftsAndAccessoriesProductsUI(updatedProductsUI);
			} else {
				setProductsUI(updatedProductsUI);
			}

			setPreviewImageFile(null);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedProductUI: IProductUI = {
				...lastProductUI,
				errors: {
					...lastProductUI.errors,
					...errors,
				},
			};

			const updatedProductsUI = [...slicedProductsUI, updatedProductUI];

			if (isSpecialProducts) {
				setGiftsAndAccessoriesProductsUI(updatedProductsUI);
				return;
			}

			setProductsUI(updatedProductsUI);
		}
	};

	const updateProduct = async ({
		value,
		fieldKey,
		sequence,
		productKey,
		validationMessage,
	}: IUpdateProduct) => {
		if (!productKey || validationMessage) {
			const updatedProductsUI = products.map((productUI) =>
				productUI.sequence === sequence
					? {
							...productUI,
							[fieldKey]: value,
							errors: {
								...productUI.errors,
								[fieldKey]: validationMessage,
							},
					  }
					: productUI
			);

			if (isSpecialProducts) {
				setGiftsAndAccessoriesProductsUI(updatedProductsUI);
				return;
			}

			setProductsUI(updatedProductsUI);
			return;
		}

		if (!productKey) return;

		try {
			const updatedField: IPatchBody = {
				value,
				op: 'replace',
				path: fieldKey,
			};

			const body: IPatchBody[] = [updatedField];

			const data = await PriceListProductsService.updateProduct(
				productKey,
				body
			);

			if (!data) return;

			const updatedProductsUI = products.map((productUI) =>
				productUI.priceListImagoFulfilledProductKey === productKey
					? {
							...data,
							key: gifProductCategoryType,
							errors: initialProductErrors,
					  }
					: productUI
			);

			if (isSpecialProducts) {
				setGiftsAndAccessoriesProductsUI(updatedProductsUI);
				return;
			}

			setProductsUI(updatedProductsUI);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedProductsUI = products.map((productUI) =>
				productUI.priceListImagoFulfilledProductKey === productKey
					? {
							...productUI,
							errors: {
								...productUI.errors,
								...errors,
							},
					  }
					: productUI
			);

			if (isSpecialProducts) {
				setGiftsAndAccessoriesProductsUI(updatedProductsUI);
				return;
			}

			setProductsUI(updatedProductsUI);
		}
	};

	const updateCatalogProduct = async (
		productKey: number,
		catalogProductKey: number
	) => {
		try {
			const data = await PriceListProductsService.updateCatalogProduct(
				productKey,
				catalogProductKey
			);

			if (data) {
				const updatedProductsUI = products.map((productUI) =>
					productUI.priceListImagoFulfilledProductKey === productKey
						? {
								...data,
								key: gifProductCategoryType,
								errors: initialProductErrors,
						  }
						: productUI
				);

				if (isSpecialProducts) {
					setGiftsAndAccessoriesProductsUI(updatedProductsUI);
					return;
				}

				setProductsUI(updatedProductsUI);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const changeProductSequence = (payload: IChangeSequencePayload) => {
		const reorderedProductsUI = changeEntitiesSequence({
			...payload,
			entities: products,
			fieldKey: 'priceListImagoFulfilledProductKey',
		});

		if (isSpecialProducts) {
			setGiftsAndAccessoriesProductsUI(reorderedProductsUI);
		} else {
			setProductsUI(reorderedProductsUI);
		}

		setIsReorder(true);
	};

	const reorderProducts = useCallback(async () => {
		if (!priceListKey || !isReorder) return;

		const body = products.reduce<Record<number, number>>(
			(acc, { sequence, priceListImagoFulfilledProductKey }) =>
				priceListImagoFulfilledProductKey
					? { ...acc, [priceListImagoFulfilledProductKey]: sequence }
					: acc,
			{}
		);

		try {
			await PriceListProductsService.reorderProducts(priceListKey, body);
		} catch (error) {
			console.log(error);
		}
	}, [priceListKey, isReorder]);

	const uploadProductImage = async ({
		sequence,
		imageFile,
		productKey,
		validationMessage,
	}: IUploadProductImage) => {
		if (validationMessage) {
			const updatedProductsUI = products.map((productUI) =>
				productUI.sequence === sequence
					? {
							...productUI,
							errors: {
								...productUI.errors,
								previewImageUrl: validationMessage,
							},
					  }
					: productUI
			);

			if (isSpecialProducts) {
				setGiftsAndAccessoriesProductsUI(updatedProductsUI);
			}

			setProductsUI(updatedProductsUI);
			return;
		}

		if (!productKey) {
			setPreviewImageFile(imageFile);
			return;
		}

		try {
			const data = await PriceListProductsService.uploadProductImage(
				productKey,
				imageFile
			);

			if (!data) return;

			const updatedProductsUI = products.map((productUI) =>
				productUI.priceListImagoFulfilledProductKey === productKey
					? {
							...productUI,
							previewImageUrl: data,
							errors: { ...initialProductErrors, previewImageUrl: '' },
					  }
					: productUI
			);

			if (isSpecialProducts) {
				setGiftsAndAccessoriesProductsUI(updatedProductsUI);
			} else {
				setProductsUI(updatedProductsUI);
			}

			setPreviewImageFile(null);
		} catch (error) {
			console.log(error);
		}
	};

	const deleteProductImage = async (productKey?: number) => {
		if (!productKey) {
			setPreviewImageFile(null);
			return;
		}

		try {
			await PriceListProductsService.deleteProductImage(productKey);

			const updatedProductsUI = products.map((productUI) =>
				productUI.priceListImagoFulfilledProductKey === productKey
					? {
							...productUI,
							previewImageUrl: '',
							errors: { ...initialProductErrors, previewImageUrl: '' },
					  }
					: productUI
			);

			if (isSpecialProducts) {
				setGiftsAndAccessoriesProductsUI(updatedProductsUI);
				return;
			}

			setProductsUI(updatedProductsUI);
		} catch (error) {
			console.log(error);
		}
	};

	const deleteProduct = async ({ sequence, productKey }: IDeleteProduct) => {
		const updatedProductsUI = products.filter(
			(productUI) => productUI.sequence !== sequence
		);

		if (isSpecialProducts) {
			setGiftsAndAccessoriesProductsUI(updatedProductsUI);
		} else {
			setProductsUI(updatedProductsUI);
		}

		setPreviewImageFile(null);

		if (productKey) {
			try {
				await PriceListProductsService.deleteProduct(productKey);
			} catch (error) {
				console.log(error);
			}
		}
	};

	const clearProductError = ({ sequence, fieldKey }: IClearProductError) => {
		const updatedProductsUI = products.map((productUI) =>
			productUI.sequence === sequence
				? {
						...productUI,
						errors: {
							...productUI.errors,
							[fieldKey]: '',
						},
				  }
				: productUI
		);

		if (isSpecialProducts) {
			setGiftsAndAccessoriesProductsUI(updatedProductsUI);
			return;
		}

		setProductsUI(updatedProductsUI);
	};

	useEffect(() => {
		void getCatalogProducts();
	}, []);

	useEffect(() => {
		void getProducts();
	}, [getProducts]);

	useEffect(() => {
		void reorderProducts();
	}, [reorderProducts]);

	const catalogProductsSelectOptions = getProductSelectOptions(
		isSpecialProducts ? specialCatalogProducts : catalogProducts
	);

	return {
		productsUI,
		lastProductUI,
		updateProduct,
		deleteProduct,
		previewImageFile,
		handleAddProduct,
		isCatalogPending,
		isProductsPending,
		handleSaveProduct,
		clearProductError,
		uploadProductImage,
		deleteProductImage,
		updateCatalogProduct,
		changeProductSequence,
		catalogProductsSelectOptions,
	};
};
