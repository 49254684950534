export const htmlSupport = {
	allow: [
		{
			name: 'button',
			styles: ['border-style', 'background'],
		},
		{
			name: 'a',
		},
	],
};
