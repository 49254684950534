import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ProjectTypeFilterSelectOption } from 'types/projects/selectOptions/projectTypeFilterSelectOption';
import { JourneyFilterSelectOption } from 'types/projects/selectOptions/journeyFilterSelectOption';

import { GENERAL_SELECT_OPTION } from 'constants/projects/selectOptions/generalFilterSelectOption';
import { IPreset } from 'api/models/responses/presets/presets';

import { IJourneyPresetsState, ICreateJourneyPresetValues } from './types';

const initialState: IJourneyPresetsState = {
	journeyPresets: [],
	copiedJourneyPresetKey: null,
	isJourneyPresetsPending: false,
	createJourneyPresetValues: null,
	filterByJourney: GENERAL_SELECT_OPTION,
	filterByProjectType: GENERAL_SELECT_OPTION,
};

const journeyPresetsSlice = createSlice({
	name: 'journeyPresets',
	initialState,
	reducers: {
		setJourneyPresets: (state, { payload }: PayloadAction<IPreset[]>) => {
			state.journeyPresets = payload;
		},

		setJourneyPresetsPending: (state, { payload }: PayloadAction<boolean>) => {
			state.isJourneyPresetsPending = payload;
		},

		setFilterByJourney: (
			state,
			{ payload }: PayloadAction<JourneyFilterSelectOption>
		) => {
			state.filterByJourney = payload;
		},

		setFilterByProjectType: (
			state,
			{ payload }: PayloadAction<ProjectTypeFilterSelectOption>
		) => {
			state.filterByProjectType = payload;
		},

		setCopiedJourneyPresetKey: (
			state,
			{ payload }: PayloadAction<number | null>
		) => {
			state.copiedJourneyPresetKey = payload;
		},

		setCreateJourneyPresetValues: (
			state,
			{ payload }: PayloadAction<ICreateJourneyPresetValues>
		) => {
			state.createJourneyPresetValues = payload;
		},

		deleteJourneyPreset: (state, { payload }: PayloadAction<number>) => {
			state.journeyPresets = state.journeyPresets.filter(
				({ presetKey }) => presetKey !== payload
			);
		},
	},
});

export const journeyPresetsReducer = journeyPresetsSlice.reducer;
export const {
	setJourneyPresets,
	setFilterByJourney,
	deleteJourneyPreset,
	setFilterByProjectType,
	setJourneyPresetsPending,
	setCopiedJourneyPresetKey,
	setCreateJourneyPresetValues,
} = journeyPresetsSlice.actions;
