import { useFormikContext } from 'formik';
import { FC } from 'react';

import { projectTimelineImages } from 'constants/projects/timeline/projectTimelineImages';
import { journeyTimelines } from 'constants/projects/timeline/journeyTimelines';
import { projectJourneyInfo } from 'constants/projects/projectJourneyInfo';

import { JourneyPresetExtendedPlaceholder } from 'components/Journey/JourneyPresetExtendedPlaceholder';
import { JourneyTimeline } from 'components/Journey/JourneyTimeline';
import { StepSubjectHeader } from 'components/StepSubjectHeader';
import { JourneyInfo } from 'components/Journey/JourneyInfo';
import { JourneyView } from 'components/Journey/JourneyView';

import { IStepControl } from 'pages/Projects/types';

import { ProjectBlankForm } from './ProjectBlankForm';

import { ICreateBlankProjectValues } from '..';

export const BlankProjectStep1: FC<IStepControl> = ({ setStep }) => {
	const { values } = useFormikContext<ICreateBlankProjectValues>();

	const projectTimelineImage = projectTimelineImages[values.journey];

	const { sales, description } = projectJourneyInfo[values.journey];

	const {
		journeyText,
		journeyTitle,
		journeySubText,
		journeyBlankProjectImage,
	} = journeyTimelines[values.journey];

	return (
		<div className="project-thumb">
			<StepSubjectHeader step="Step 1 of 2" title="Create a New Project" />
			<div className="project-blank-main">
				<ProjectBlankForm setStep={setStep} />
				<JourneyPresetExtendedPlaceholder />
			</div>
			<div className="project-timeline">
				<JourneyTimeline
					journeyText={journeyText}
					journeyTitle={journeyTitle}
					journeySubText={journeySubText}
					journeyImage={journeyBlankProjectImage}
				/>
			</div>
			<div className="project-journey">
				<JourneyInfo sales={sales} description={description} />
				<JourneyView projectTimelineImage={projectTimelineImage} />
			</div>
		</div>
	);
};
