import { useFormikContext } from 'formik';
import { FC } from 'react';

import { Button } from 'components/FormControls/Button';

import {
	IGeneralBroadcastProps,
	ISendBroadcastProps,
	ISendBroadcastValues,
} from '../../types';

interface IBroadcastControlsProps
	extends Omit<
			ISendBroadcastProps,
			| 'setSentToNumberOfPeople'
			| 'showSendSpecialBroadcastInfo'
			| 'showSpecialBroadcastRejectModal'
		>,
		IGeneralBroadcastProps {}

export const BroadcastControls: FC<IBroadcastControlsProps> = ({
	broadcastInfo,
	hideSendBroadcastModal,
}) => {
	const { values, handleSubmit } = useFormikContext<ISendBroadcastValues>();

	const disableSendSpecialBroadcast =
		!values.sendByEmail &&
		!values.sendByPhone &&
		!broadcastInfo?.hasAnyBuyers &&
		!broadcastInfo?.hasAnyLeads &&
		!broadcastInfo?.hasAnyProspects;

	return (
		<div className="broadcast-btns">
			<Button
				value="Cancel"
				className="btn-secondary"
				handleClick={hideSendBroadcastModal}
			/>
			<Button
				className="btn-primary"
				handleClick={handleSubmit}
				value="Send Special Broadcast"
				disabled={disableSendSpecialBroadcast}
			/>
		</div>
	);
};
