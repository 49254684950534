import { CellProps, Column } from 'react-table';
import { FC } from 'react';

import { ToggleSection } from 'components/ToggleSection';
import { Table } from 'components/Table';

import { useToggleSection } from 'hooks/useToggleSection';
import {
	IMarketingKeyword,
	marketingKeywords,
} from 'constants/projects/marketingKeywords';

interface IMarketingKeywordsProps {
	initialIsOpen?: boolean;
	showTestBroadcastValues?: boolean;
}

export const MarketingKeywords: FC<IMarketingKeywordsProps> = ({
	initialIsOpen,
	showTestBroadcastValues,
}) => {
	const { isOpen, handleToggle } = useToggleSection(!!initialIsOpen);

	const marketing: Column<IMarketingKeyword>[] = [
		{
			Header: 'Keyword',
			accessor: 'keyword',
		},
		{
			Header: 'Description',
			accessor: 'description',
			Cell: ({ row }: CellProps<IMarketingKeyword>) => {
				const { description, extraDescription } = row.original;

				return (
					<>
						{description}
						<span className="broadcast-td-subtitle">{extraDescription}</span>
					</>
				);
			},
		},
		{
			Header: 'Test Broadcast Value',
			accessor: 'testBroadcastValue',
			Cell: ({ row }: CellProps<IMarketingKeyword>) => {
				const { testBroadcastValue } = row.original;

				return (
					<span className="broadcast-td-subtitle">{testBroadcastValue}</span>
				);
			},
		},
	];

	const marketingColumns = showTestBroadcastValues
		? marketing
		: marketing.slice(0, 2);

	return (
		<ToggleSection
			isOpen={isOpen}
			title="Marketing Keywords"
			handleToggle={handleToggle}
			className="org-details-header"
		>
			<Table
				data={marketingKeywords}
				columns={marketingColumns}
				className="org-table broadcast-table"
			/>
		</ToggleSection>
	);
};
