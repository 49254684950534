import { OrderShippingTypes } from 'api/models/responses/priceLists/studio/priceListOptions/shippingOption';
import { ISelectOption } from 'types/ui/select';

import { orderShippingTypesUI } from '../orderShippingTypesUI';

export const orderShippingTypesSelectOptions: ISelectOption<OrderShippingTypes>[] =
	Object.values(OrderShippingTypes).map((shippingOrderType) => ({
		value: shippingOrderType,
		label: orderShippingTypesUI[shippingOrderType],
	}));
