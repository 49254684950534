import { FC } from 'react';

import { BackgroundOptionVisibilityTypes } from 'api/models/responses/priceLists/studio/priceListStudio';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { SelectComponent } from 'components/FormControls/Select';
import { DeleteBtn } from 'components/Controls/DeleteBtn';

import { backgroundOptionVisibilityTypesSelectOptions } from 'constants/priceLists/studio/selectOptions/backgroundOptionVisibilityTypesSelectOptions';
import { IBackgroundOptionsVisibilityProps } from 'pages/PriceLists/types/studio/priceListOptions/backgroundOptionsVisibilityProps';
import { useAsyncOptimizedSelect } from 'hooks/formControls/useAsyncOptimizedSelect';

interface IShowBackgroundProps extends IBackgroundOptionsVisibilityProps {
	handleDeleteBackgroundOptions: () => void;
}

export const ShowBackground: FC<IShowBackgroundProps> = ({
	updatePriceListStudio,
	handleDeleteBackgroundOptions,
	backgroundOptionVisibilityType,
}) => {
	const updateBackgroundOptionVisibilityType = async (
		value: BackgroundOptionVisibilityTypes
	) => {
		if (!updatePriceListStudio) return;

		try {
			const updatedField: IPatchBody = {
				value,
				op: 'replace',
				path: 'backgroundOptionVisibilityType',
			};

			const body: IPatchBody[] = [updatedField];

			await updatePriceListStudio(body);
		} catch (error) {
			console.log(error);
		}
	};

	const { value, isPending, handleChange } =
		useAsyncOptimizedSelect<BackgroundOptionVisibilityTypes>({
			initialValue: backgroundOptionVisibilityType,
			updateSelectValue: updateBackgroundOptionVisibilityType,
		});

	return (
		<div className="price-options">
			<h4 className="price-options-title">Backgrounds</h4>
			<div className="price-title-container">
				<SelectComponent
					value={value}
					disabled={isPending}
					onChange={handleChange}
					label="Show Background Options"
					selectPlaceholder="Show Background Options"
					selectOptions={backgroundOptionVisibilityTypesSelectOptions}
				/>
			</div>
			<DeleteBtn handleClick={handleDeleteBackgroundOptions} />
		</div>
	);
};
