import { ChangeEvent, FC, KeyboardEvent, useState } from 'react';

import { EnterCode } from 'constants/general/keyboardCodes';
import {
	maxPoints,
	minPoints,
} from 'constants/general/validation/pointsValidation';

import { Checkbox } from 'components/FormControls/Checkbox';
import { Input } from 'components/FormControls/Input';

import { PoseOptionsCheckBoxes } from 'pages/PriceLists/components/PoseOptionsCheckBoxes';
import { useAsyncOptimizedCheckbox } from 'hooks/formControls/useAsyncOptimizedCheckbox';
import { IPackage } from 'api/models/responses/priceLists/studio/priceListPackages';

import { UpdatePackageHandler } from '../..';

interface IPackageOptionsProps
	extends Pick<
		IPackage,
		| 'requiredPoseOptions'
		| 'isWaiveShippingCharge'
		| 'ownPackagePointsAmount'
		| 'isIncludeImagesDownload'
		| 'isBuildOwnPackageEnabled'
		| 'isBackgroundOptionRequired'
	> {
	sequence: number;
	packageKey?: number;
	updatePackage: UpdatePackageHandler;
}

export const PackageOptions: FC<IPackageOptionsProps> = ({
	sequence,
	packageKey,
	updatePackage,
	requiredPoseOptions,
	isWaiveShippingCharge,
	ownPackagePointsAmount,
	isIncludeImagesDownload,
	isBuildOwnPackageEnabled,
	isBackgroundOptionRequired,
}) => {
	const [points, setPoints] = useState(ownPackagePointsAmount.toString());

	const updateIsIncludeImagesDownload = async (value: boolean) => {
		await updatePackage({
			value,
			sequence,
			packageKey,
			validationMessage: '',
			fieldKey: 'isIncludeImagesDownload',
		});
	};

	const includeImagesDownload = useAsyncOptimizedCheckbox(
		isIncludeImagesDownload,
		updateIsIncludeImagesDownload
	);

	const updateIsBuildOwnPackageEnabled = async (value: boolean) => {
		await updatePackage({
			value,
			sequence,
			packageKey,
			validationMessage: '',
			fieldKey: 'isBuildOwnPackageEnabled',
		});
	};

	const buildOwnPackageEnabled = useAsyncOptimizedCheckbox(
		isBuildOwnPackageEnabled,
		updateIsBuildOwnPackageEnabled
	);

	const updateIsWaveShippingCharge = async (value: boolean) => {
		await updatePackage({
			value,
			sequence,
			packageKey,
			validationMessage: '',
			fieldKey: 'isWaiveShippingCharge',
		});
	};

	const waveShippingCharge = useAsyncOptimizedCheckbox(
		isWaiveShippingCharge,
		updateIsWaveShippingCharge
	);

	const updateIsBackgroundOptionRequired = async (value: boolean) => {
		await updatePackage({
			value,
			sequence,
			packageKey,
			validationMessage: '',
			fieldKey: 'isBackgroundOptionRequired',
		});
	};

	const updateRequiredPoseOptions = async (value: string) => {
		await updatePackage({
			value,
			sequence,
			packageKey,
			validationMessage: '',
			fieldKey: 'requiredPoseOptions',
		});
	};

	const handleChangePoints = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		const valueNumber = +value;

		if (isNaN(valueNumber) || !Number.isInteger(valueNumber)) return;

		setPoints(value);
	};

	const updateOwnPackagePointsAmount = () => {
		const validatedInRangePoints = Math.max(
			Math.min(+points, maxPoints),
			minPoints
		).toString();

		void updatePackage({
			sequence,
			packageKey,
			validationMessage: '',
			value: validatedInRangePoints,
			fieldKey: 'ownPackagePointsAmount',
		});

		setPoints(validatedInRangePoints);
	};

	const handleKeyDownPoints = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === '.') {
			e.preventDefault();
		}

		if (e.key !== EnterCode) return;

		updateOwnPackagePointsAmount();
	};

	return (
		<td className="price-option-td">
			<span className="price-textarea-label">
				Package Options
				<div>
					<Checkbox
						label="Includes Download"
						id={`include-download-${sequence}`}
						checked={includeImagesDownload.checked}
						disabled={includeImagesDownload.isPending}
						handleChange={includeImagesDownload.handleChange}
					/>
					<div className="price-package-table">
						<Checkbox
							label="Build Your Own Packages"
							id={`build-own-package-${sequence}`}
							checked={buildOwnPackageEnabled.checked}
							disabled={buildOwnPackageEnabled.isPending}
							handleChange={buildOwnPackageEnabled.handleChange}
						/>
						{/* TODO: add styles for label */}
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Input
								value={points}
								className="input"
								placeholder="Points"
								handleChange={handleChangePoints}
								handleKeyDown={handleKeyDownPoints}
								disabled={!isBuildOwnPackageEnabled}
								handleBlur={updateOwnPackagePointsAmount}
							/>
							<span style={{ marginLeft: '5px' }}>Points</span>
						</div>
					</div>
					<div className="price-package-select">
						<Input value="1" disabled />
						<span>
							Poses <i>(coming soon)</i>
						</span>
					</div>
					<PoseOptionsCheckBoxes
						sequence={sequence}
						requiredPoseOptions={requiredPoseOptions}
						updateRequiredPoseOptions={updateRequiredPoseOptions}
						isBackgroundOptionRequired={isBackgroundOptionRequired}
						updateIsBackgroundOptionRequired={updateIsBackgroundOptionRequired}
					/>
					<Checkbox
						label="Waive Shipping Charge"
						checked={waveShippingCharge.checked}
						disabled={waveShippingCharge.isPending}
						id={`waive-shipping-charge-${sequence}`}
						handleChange={waveShippingCharge.handleChange}
					/>
				</div>
			</span>
		</td>
	);
};
