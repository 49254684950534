import { SaleTypes } from 'api/models/responses/projects/sales';

export interface IProjectTimelineColor {
	saleColor: string;
	saleLineColor: string;
	isOverlapSale?: boolean;
}

export const projectTimelineColors: Record<SaleTypes, IProjectTimelineColor> = {
	[SaleTypes.JourneyAPresale]: {
		saleColor: 'sale-red',
		saleLineColor: 'sale-red',
	},
	[SaleTypes.JourneyALatePresale]: {
		saleColor: 'sale-purple',
		saleLineColor: 'sale-purple',
	},
	[SaleTypes.JourneyAGallerySale]: {
		saleColor: 'sale-green',
		saleLineColor: 'sale-green',
	},
	[SaleTypes.JourneyBGallerySale]: {
		saleColor: 'sale-green',
		saleLineColor: 'sale-green',
	},
	[SaleTypes.JourneyBProofSale]: {
		saleColor: 'sale-blue',
		saleLineColor: 'sale-blue',
	},
	[SaleTypes.JourneyCGallerySale]: {
		saleColor: 'sale-green',
		saleLineColor: 'sale-green',
	},
	[SaleTypes.JourneyDGallerySale]: {
		saleColor: 'sale-green',
		saleLineColor: 'left-sale-green',
		isOverlapSale: true,
	},
	[SaleTypes.JourneyDPresale]: {
		saleColor: 'sale-red',
		saleLineColor: 'right-sale-red',
	},
	[SaleTypes.JourneyEGallerySale]: {
		saleColor: 'sale-green',
		saleLineColor: 'left-sale-green',
		isOverlapSale: true,
	},
	[SaleTypes.JourneyELatePresale]: {
		saleColor: 'sale-purple',
		saleLineColor: 'right-sale-purple',
	},
	[SaleTypes.JourneyEPresale]: {
		saleColor: 'sale-red',
		saleLineColor: 'sale-red',
	},
};
