import { FC } from 'react';

import { SelectComponent } from 'components/FormControls/Select';
import { Input } from 'components/FormControls/Input';

import { chargeOptionTypesSelectOptions } from 'constants/priceLists/studio/selectOptions/chargeOptionTypesSelectOptions';
import { ChargeOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/additionalChargeOption';

export const OptionColumn: FC = () => (
	<td className="price-option-td">
		<div className="price-table-container charge">
			<Input
				readOnly
				value="0"
				label="Charge"
				disabled={false}
				placeholder="Days amount"
				className="input exp-wrapper"
			/>
			<SelectComponent
				disabled
				readonly
				label="Days"
				selectOptions={chargeOptionTypesSelectOptions}
				value={ChargeOptionTypes.AfterXDaysAfterPublish}
			/>
		</div>
	</td>
);
