import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import { IStudioBillingState } from './studioBilling/types';
import { ISubscriptionsState } from './subscriptions/type';
import { IBreadcrumbsState } from './breadcrumbs/types';
import { IStudioState } from './studio/types';

import { priceListBaseOptionsReducer } from './priceLists/priceListBaseOptions';
import { priceListBackgroundReducer } from './priceLists/priceListBackgrounds';
import { poseOptionsReducer } from './priceLists/priceListPoseOptions';
import { journeyPresetsReducer } from './journeyPresets';
import { studioBillingReducer } from './studioBilling';
import { subscriptionsReducer } from './subscriptions';
import { organizationsReducer } from './organizations';
import { breadcrumbsReducer } from './breadcrumbs';
import { broadcastsReducer } from './broadcasts';
import { priceListsReducer } from './priceLists';
import { campaignsReducer } from './campaigns';
import { dashboardReducer } from './dashboard';
import { projectsReducer } from './projects';
import { sidebarReducer } from './sidebar';
import { studioReducer } from './studio';
import { asideReducer } from './aside';
import { imageGalleryReducer } from './imageGallery';
import { promotionsReducer } from './promotions';

const studioConfig = {
	key: 'studio',
	storage,
};

const studioBillingConfig = {
	key: 'studioBilling',
	storage,
	blacklist: ['bankACH', 'studioBilling'],
};

const rootPersistConfig = {
	key: 'root',
	storage,
	whitelist: [],
};

const subscriptionConfig = {
	key: 'subscriptions',
	storage,
	whitelist: ['subscriptionLevelKey'],
};

const breadcrumbsConfig = {
	key: 'breadcrumbs',
	storage,
};

const rootReducer = combineReducers({
	aside: asideReducer,
	sidebar: sidebarReducer,
	projects: projectsReducer,
	campaigns: campaignsReducer,
	dashboard: dashboardReducer,
	priceLists: priceListsReducer,
	broadcasts: broadcastsReducer,
	promotions: promotionsReducer,
	imageGallery: imageGalleryReducer,
	organizations: organizationsReducer,
	journeyPresets: journeyPresetsReducer,
	priceListPoseOptions: poseOptionsReducer,
	priceListBackgrounds: priceListBackgroundReducer,
	priceListBaseOptions: priceListBaseOptionsReducer,
	breadcrumbs: persistReducer<IBreadcrumbsState>(
		breadcrumbsConfig,
		breadcrumbsReducer
	),
	subscriptions: persistReducer<ISubscriptionsState>(
		subscriptionConfig,
		subscriptionsReducer
	),
	studioBilling: persistReducer<IStudioBillingState>(
		studioBillingConfig,
		studioBillingReducer
	),
	studio: persistReducer<IStudioState>(studioConfig, studioReducer),
});

export const persistedRootReducer = persistReducer(
	rootPersistConfig,
	rootReducer
);
