import { createSelector } from 'reselect';

import { RootState } from 'store/store';

const dashboardSelector = (state: RootState) => state.dashboard;

export const marketingStatisticSelector = createSelector(
	dashboardSelector,
	({ marketingStatistic }) => marketingStatistic
);

export const studioStatisticSelector = createSelector(
	dashboardSelector,
	({ studioStatistic }) => studioStatistic
);

export const newOrdersSelector = createSelector(
	dashboardSelector,
	({ newOrders }) => newOrders
);

export const comingSoonSelector = createSelector(
	dashboardSelector,
	({ comingSoon }) => comingSoon
);

export const latestProjectsSelector = createSelector(
	dashboardSelector,
	({ latestProjects }) => latestProjects
);

export const selectedDateRangeSelector = createSelector(
	dashboardSelector,
	({ selectedDateRange }) => selectedDateRange
);

export const isDashboardFulfilledSelector = createSelector(
	dashboardSelector,
	({ isDashboardFulfilled }) => isDashboardFulfilled
);
