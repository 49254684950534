import { useEffect } from 'react';

import { ROUTES } from 'constants/ROUTES';
import {
	PROJECTS,
	ACTIVE_ORGANIZATIONS,
	ARCHIVED_ORGANIZATIONS,
} from 'constants/navigation';

import { IBreadcrumbs, setBreadcrumbs } from 'store/breadcrumbs';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import NavigationService from 'utils/NavigationService';

export const useOrganizationsBreadcrumbs = (
	isActive: boolean,
	orgDetailsBreadcrumb?: IBreadcrumbs,
	projectDetailsBreadcrumb?: IBreadcrumbs
) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		const organizationsNavigation =
			NavigationService.getParentNavigation(PROJECTS);

		const activeOrganizationsNavigation = NavigationService.getSubNavigation(
			PROJECTS,
			ROUTES.ORGANIZATIONS.ACTIVE_ORGANIZATIONS
		);

		const archivedOrganizationNavigation = NavigationService.getSubNavigation(
			PROJECTS,
			ROUTES.ORGANIZATIONS.ARCHIVED_ORGANIZATIONS
		);

		if (
			!organizationsNavigation ||
			!activeOrganizationsNavigation ||
			!archivedOrganizationNavigation
		)
			return;

		const organizationsBreadcrumbs: IBreadcrumbs = {
			isActive: false,
			path: organizationsNavigation.path,
			title: organizationsNavigation.title,
		};

		const organizationTypeBreadcrumbs: IBreadcrumbs = {
			isActive: !orgDetailsBreadcrumb,
			path: isActive
				? activeOrganizationsNavigation.path
				: archivedOrganizationNavigation.path,
			title: isActive ? ACTIVE_ORGANIZATIONS : ARCHIVED_ORGANIZATIONS,
		};

		const breadcrumbs: IBreadcrumbs[] = [
			organizationsBreadcrumbs,
			organizationTypeBreadcrumbs,
		];

		if (orgDetailsBreadcrumb) {
			breadcrumbs.push(orgDetailsBreadcrumb);
		}

		if (projectDetailsBreadcrumb) {
			breadcrumbs.push(projectDetailsBreadcrumb);
		}

		dispatch(setBreadcrumbs(breadcrumbs));
	}, [
		orgDetailsBreadcrumb?.path,
		orgDetailsBreadcrumb?.title,
		projectDetailsBreadcrumb?.path,
		projectDetailsBreadcrumb?.title,
	]);
};
