import { Link } from 'react-router-dom';
import { FC } from 'react';

import { ROUTES } from 'constants/ROUTES';

export const ErrorPage: FC = () => (
	<div id="error-page" className="error-page">
		<h2 className="error-code">404</h2>
		<h3 className="error-message">Oops... that’s not supposed to happen</h3>
		<Link to={ROUTES.DASHBOARD} className="error-link">
			Let’s get you back home
		</Link>
	</div>
);
