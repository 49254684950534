export const fileToBinary = (
	file: File
): Promise<ArrayBuffer | string | null> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = () => {
			const binaryData = reader.result;
			resolve(binaryData);
		};

		reader.onerror = () => {
			reject(new Error('Unable to read the file as binary data'));
		};

		reader.readAsArrayBuffer(file);
	});
};
