export enum WatermarkTypes {
	No = 'No',
	Repeat = 'Repeat',
	Single = 'Single',
}

export interface IStudioPhotoSettings {
	watermarkType: WatermarkTypes;
	defaultSingleWatermarkImageGuid: string;
	defaultRepeatWatermarkImageGuid: string;
	customSingleWatermarkImageGuid: string | null;
	customRepeatWatermarkImageGuid: string | null;
}
