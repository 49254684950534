import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';

export const projectJourneysUI = {
	[ProjectJourneys.A]: 'Journey A Presale + Picture Day Gallery',
	[ProjectJourneys.B]: 'Journey B Proof sale + Picture Day Gallery',
	[ProjectJourneys.C]: 'Journey C Picture Day Gallery',
	[ProjectJourneys.D]: 'Journey D Presale (multi-day) + Picture Day Gallery',
	[ProjectJourneys.E]:
		'Journey E Presale (w/ absentee day) + Picture Day Gallery',
};
