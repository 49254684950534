import { useNavigate } from 'react-router-dom';
import { FC } from 'react';

import { ImagoPriceListTabs } from 'constants/priceLists/tabs/imagoPriceListTabs';
import { ROUTES } from 'constants/ROUTES';

import { PriceListControls } from 'pages/PriceLists/components/PriceListControls';

import { PackageOptionsContainer } from './components/PackageOptionsContainer';
import { PoseOptionsContainer } from './components/PoseOptionsContainer';
import { IPriceListOptionsProps } from '../..';

export const PriceListOptions: FC<IPriceListOptionsProps> = ({
	priceListKey,
	setActiveTab,
	isPriceListPending,
}) => {
	const navigate = useNavigate();

	const handleBack = () => {
		navigate(ROUTES.PRICE_LISTS.PRICE_LISTS);
	};

	const handleNext = () => {
		setActiveTab(ImagoPriceListTabs.Packages);
	};

	return (
		<>
			<PoseOptionsContainer
				priceListKey={priceListKey}
				isPriceListPending={isPriceListPending}
			/>
			<PackageOptionsContainer />
			<PriceListControls handleBack={handleBack} handleNext={handleNext} />
		</>
	);
};
