export const imageUploadTypes = {
	'image/jpg': [],
	'image/png': [],
	'image/jpeg': [],
};

export const uploadInputAccept = '.jpg, .jpeg, .png';

export const imageUploadTypesErrorMessage =
	'Image must be in format *.jpg, *.jpeg, *.png.';
