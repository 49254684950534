import { useLocation, useParams } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';

import { projectStatisticPriceKeys } from 'constants/projects/projectStatistic/projectStatisticPriceKeys';
import { projectStatisticKeys } from 'constants/projects/projectStatistic/projectStatisticKeys';

import { IProjectStatistic } from 'api/models/responses/projects/projectStatistic';
import { IProject } from 'api/models/responses/projects/projectDetails';
import OrganizationService from 'api/services/OrganizationService';
import ProjectsService from 'api/services/ProjectsService';

import { createdProjectSelector } from 'store/projects';
import { IBreadcrumbs } from 'store/breadcrumbs';
import {
	setCreatedOrganization,
	createdOrganizationSelector,
} from 'store/organizations';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { useOrganizationsBreadcrumbs } from 'pages/Organizations/hooks/useOrganizationsBreadcrumbs';
import { PanelData } from 'components/PanelData';

import { ProjectFormSectionContainer } from './components/ProjectFormSectionContainer';
import { ProjectJourneyContainer } from './components/ProjectJourneyContainer';
import { ProjectDetailsContainer } from './components/ProjectDetailsContainer';
import { useProjectTypeSubjects } from '../../hooks/useProjectTypeSubjects';
import { MarketingContainer } from './components/MarketingContainer';
import { TimelineContainer } from './components/TimelineContainer';
import { ProjectControls } from './components/ProjectControls';
import { ImagesContainer } from './components/ImagesContainer';
import { ROUTES } from 'constants/ROUTES';

export interface IDetailsGeneralProps {
	isPending: boolean;
	projectDetails: IProject | null;
	setProjectDetails: (projectDetails: IProject) => void;
}

export const ProjectDetailsPage: FC = () => {
	const createdOrganization = useAppSelector(createdOrganizationSelector);
	const createdProject = useAppSelector(createdProjectSelector);

	const [projectDetails, setProjectDetails] = useState<IProject | null>(null);
	const [isPending, setIsPending] = useState(false);
	const [projectStatistic, setProjectStatistic] =
		useState<IProjectStatistic | null>(null);

	const [isUpdateProject, setIsUpdateProject] = useState(false);
	const [isUpdateTimeline, setIsUpdateTimeline] = useState(false);

	const dispatch = useAppDispatch();
	const { projectId } = useParams();
	const { pathname } = useLocation();

	const { subject, subjects } = useProjectTypeSubjects(
		projectDetails?.projectType || ''
	);

	const projectKeyParam = (projectId && +projectId) || 0;

	const organizationDetailsBreadcrumbs: IBreadcrumbs = {
		isActive: false,
		title: createdOrganization?.organizationName || '',
		path: projectDetails?.organizationKey
			? `${ROUTES.ORGANIZATIONS.ORGANIZATIONS}/${projectDetails.organizationKey}`
			: '',
	};

	const projectDetailsBreadcrumbs: IBreadcrumbs = {
		isActive: true,
		path: pathname,
		title: projectDetails?.projectName || '',
	};

	useOrganizationsBreadcrumbs(
		!!createdOrganization?.isActive,
		organizationDetailsBreadcrumbs,
		projectDetailsBreadcrumbs
	);

	useEffect(() => {
		if (!projectKeyParam) return;

		if (projectKeyParam === createdProject?.projectKey && !isUpdateProject) {
			return setProjectDetails(createdProject);
		}

		if (projectDetails && !isUpdateProject) return;

		setIsPending(true);

		ProjectsService.getProjectDetails(projectKeyParam)
			.then((data) => {
				if (!data) return;

				setProjectDetails(data);
				setIsPending(false);
				setIsUpdateProject(false);
			})
			.catch((error) => {
				console.log(error);
				setIsPending(false);
				setIsUpdateProject(false);
			});
	}, [projectKeyParam, createdProject?.projectKey, isUpdateProject]);

	useEffect(() => {
		if (!projectKeyParam || !projectDetails?.estimatedNumberOfSubjects) return;

		ProjectsService.getProjectStatistic(projectKeyParam)
			.then((data) => {
				if (!data) return;

				setProjectStatistic(data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [projectKeyParam, projectDetails?.estimatedNumberOfSubjects]);

	useEffect(() => {
		const organizationKey = projectDetails?.organizationKey;

		if (!organizationKey) return;

		OrganizationService.getOrganizationDetails(organizationKey)
			.then((data) => {
				if (!data) return;

				dispatch(setCreatedOrganization(data));
			})
			.catch((error) => {
				console.log(error);
			});
	}, [projectDetails?.organizationKey, dispatch]);

	return (
		<div className="preset-project-container">
			<PanelData
				statistic={projectStatistic}
				statisticKeys={projectStatisticKeys(subjects)}
				statisticPriceKeys={projectStatisticPriceKeys(subject)}
			/>
			<ProjectDetailsContainer
				isPending={isPending}
				projectDetails={projectDetails}
				setProjectDetails={setProjectDetails}
			/>
			<ProjectJourneyContainer
				isPending={isPending}
				projectDetails={projectDetails}
				setProjectDetails={setProjectDetails}
				setIsUpdateTimeline={setIsUpdateTimeline}
			/>
			<TimelineContainer
				projectKey={projectKeyParam}
				isUpdateTimeline={isUpdateTimeline}
				setIsUpdateProject={setIsUpdateProject}
				setIsUpdateTimeline={setIsUpdateTimeline}
			/>
			<MarketingContainer
				projectKey={projectKeyParam}
				journey={projectDetails?.journey}
				setProjectDetails={setProjectDetails}
				setIsUpdateTimeline={setIsUpdateTimeline}
				isMarketingEnabled={!!projectDetails?.isMarketingEnabled}
				hasAnySentBroadcast={!!projectDetails?.hasAnySentBroadcast}
				smsSpecialMessage={projectDetails?.smsSpecialMessage || ''}
				accessCodeMessage={projectDetails?.accessCodeMessage || ''}
				emailSpecialMessage={projectDetails?.emailSpecialMessage || ''}
			/>
			<ProjectFormSectionContainer projectKey={projectKeyParam} />
			<ImagesContainer projectKey={projectKeyParam} />
			<ProjectControls
				projectKey={projectDetails?.projectKey}
				projectName={projectDetails?.projectName}
			/>
		</div>
	);
};
