import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISidebarState } from './types';

const initialState: ISidebarState = {
	isOpen: true,
};

const sidebarSlice = createSlice({
	name: 'sidebar',
	initialState,
	reducers: {
		setIsOpen: (state, { payload }: PayloadAction<boolean>) => {
			state.isOpen = payload;
		},
	},
});

export const sidebarReducer = sidebarSlice.reducer;
export const { setIsOpen } = sidebarSlice.actions;
