import { ChangeEvent, FC, useState } from 'react';
import { SingleValue } from 'react-select';

import { simpleAnswerSelectOptions } from 'constants/general/simpleAnswer/simpleAnswerSelectOptions';
import { searchBySelectOptions } from 'constants/photos/selectOptions/searchBySelectOptions';
import { SimpleAnswer } from 'constants/general/simpleAnswer/simpleAnswer';

import { SearchPhotoByParameters } from 'api/models/requests/photos/searchByParameters';
import { ISearchPhotosParams } from 'api/models/requests/photos/searchPhotos';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { ISelectOption } from 'types/ui/select';

interface IImageSearchProps {
	resetSearchPhotosParams: () => void;
	setIsOpen: (isOpen: boolean) => void;
	searchPhotosParams: ISearchPhotosParams;
	setUpdatePhotos: (updatePhotos: boolean) => void;
	setSearchPhotosParams: (params: ISearchPhotosParams) => void;
}

export const PhotosSearch: FC<IImageSearchProps> = ({
	setIsOpen,
	setUpdatePhotos,
	searchPhotosParams,
	setSearchPhotosParams,
	resetSearchPhotosParams,
}) => {
	const [searchValue, setSearchValue] = useState(
		searchPhotosParams.searchValue
	);
	const [searchParameter, setSearchParameter] =
		useState<SearchPhotoByParameters>(
			searchPhotosParams.searchParameter ||
				SearchPhotoByParameters.ParticipantName
		);

	const [simpleAnswer, setSimpleAnswer] = useState<SimpleAnswer>(
		SimpleAnswer.True
	);

	const isBooleanSearchValue =
		searchParameter === SearchPhotoByParameters.DownloadOrdered ||
		searchParameter === SearchPhotoByParameters.ImageDownloaded;

	const handleSearch = () => {
		const processedSearchValue = isBooleanSearchValue
			? simpleAnswer
			: searchValue;

		setSearchPhotosParams({
			searchValue: processedSearchValue,
			searchParameter,
		});

		setIsOpen(true);
		setUpdatePhotos(true);
	};

	const handleShowAll = () => {
		setSearchValue('');
		setSearchParameter(SearchPhotoByParameters.ParticipantName);

		resetSearchPhotosParams();

		setIsOpen(true);
		setUpdatePhotos(true);
	};

	const handleSearchParameterChange = (
		option: SingleValue<ISelectOption<SearchPhotoByParameters>>
	) => {
		if (!option) return;

		setSearchParameter(option.value);
	};

	const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		setSearchValue(value);
	};

	const handleSimpleAnswerChange = (
		option: SingleValue<ISelectOption<SimpleAnswer>>
	) => {
		if (!option) return;

		setSimpleAnswer(option.value);
	};

	return (
		<div className="project-images-header">
			<span className="project-images-title">Find an Image</span>
			<SelectComponent
				touched
				label="Search by"
				value={searchParameter}
				selectPlaceholder="Search by"
				selectOptions={searchBySelectOptions}
				onChange={handleSearchParameterChange}
			/>
			<div className="project-images-select">
				{!isBooleanSearchValue ? (
					<InputGrid
						label="for"
						required={false}
						className="images"
						value={searchValue}
						placeholder="Search value"
						handleChange={handleSearchValueChange}
					/>
				) : (
					<SelectComponent
						touched
						label="for"
						value={simpleAnswer}
						onChange={handleSimpleAnswerChange}
						selectOptions={simpleAnswerSelectOptions}
					/>
				)}
			</div>
			<Button
				value="Search"
				handleClick={handleSearch}
				className="btn-secondary org-details-btn"
			/>
			<Button
				value="Show All"
				handleClick={handleShowAll}
				className="btn-secondary org-details-btn"
			/>
		</div>
	);
};
