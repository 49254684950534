import { FC } from 'react';

import { Button } from 'components/FormControls/Button';

interface IUploadingMediaProps {
	addedImagesCount: number;
	hideUploadMediaModal: () => void;
}

export const UploadingMedia: FC<IUploadingMediaProps> = ({
	addedImagesCount,
	hideUploadMediaModal,
}) => (
	<div className="modal-body">
		<div className="org-modal">
			<h4 className="media-modal-title">
				Adding {addedImagesCount} files to your library
			</h4>
			<div className="media-modal-btn">
				<Button
					value="Close"
					className="btn-primary"
					handleClick={hideUploadMediaModal}
				/>
			</div>
		</div>
	</div>
);
