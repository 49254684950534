import { ChangeEvent, FC, RefObject } from 'react';
import cn from 'classnames';

interface IUploadButtonProps {
	text: string;
	accept: string;
	disabled?: boolean;
	fileInputRef: RefObject<HTMLInputElement>;
	handleUpload: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const UploadButton: FC<IUploadButtonProps> = ({
	text,
	accept,
	disabled,
	handleUpload,
	fileInputRef,
}) => (
	<label
		className={cn('btn-secondary org-details-btn', {
			'is-disabled': disabled,
		})}
	>
		{text}
		<input
			type="file"
			accept={accept}
			ref={fileInputRef}
			disabled={disabled}
			onChange={handleUpload}
			style={{ display: 'none' }}
		/>
	</label>
);
