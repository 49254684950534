import { PoseOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/poseOption';

interface IPoseOptionTypesUI {
	singleLabel: string;
	multiLabel: string;
}

export const poseOptionTypesUI: Record<PoseOptionTypes, IPoseOptionTypesUI> = {
	[PoseOptionTypes.None]: {
		singleLabel: 'None',
		multiLabel: 'None',
	},
	[PoseOptionTypes.Pose]: {
		singleLabel: 'Pose',
		multiLabel: 'Poses',
	},
	[PoseOptionTypes.Style]: {
		singleLabel: 'Style',
		multiLabel: 'Styles',
	},
	[PoseOptionTypes.ColorFlavor]: {
		singleLabel: 'Color Flavor',
		multiLabel: 'Color Flavors',
	},
};
