import { FC, useEffect, useState } from 'react';

import { ToggleSection } from 'components/ToggleSection';
import { Loader } from 'components/Loader';

import { useToggleSection } from 'hooks/useToggleSection';

import { ProjectJourney } from './ProjectJourney';

import { IDetailsGeneralProps } from '../..';
import { IUpdateTimeline } from 'pages/Projects/types';

interface ProjectJourneyContainerProps
	extends IDetailsGeneralProps,
		IUpdateTimeline {}

export const ProjectJourneyContainer: FC<ProjectJourneyContainerProps> = ({
	isPending,
	projectDetails,
	setProjectDetails,
	setIsUpdateTimeline,
}) => {
	const { isOpen, handleToggle } = useToggleSection(false);
	const [showComponent, setShowComponent] = useState(window.innerWidth < 768);

	useEffect(() => {
		const handleResize = () => setShowComponent(window.innerWidth < 768);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return showComponent ? null : (
		<ToggleSection
			isOpen={isOpen}
			title="Project Journey"
			handleToggle={handleToggle}
			className="preset-details-header"
		>
			{!isPending && projectDetails ? (
				<ProjectJourney
					projectDetails={projectDetails}
					setProjectDetails={setProjectDetails}
					setIsUpdateTimeline={setIsUpdateTimeline}
				/>
			) : (
				<Loader />
			)}
		</ToggleSection>
	);
};
