import { ChangeEvent, DragEvent, FC, useState } from 'react';

import { disabledBgOptionsDragSequence } from 'constants/priceLists/studio/disabledBgOptionsDragSequence';
import { CommonOptionActionMenu } from 'constants/priceLists/commonOptionActionMenu';

import { ActionMenuControlColumn } from 'pages/PriceLists/components/ActionMenuControlColumn';
import { ReferenceCodeColumn } from 'pages/PriceLists/components/ReferenceCodeColumn';
import { PreviewImageColumn } from 'pages/PriceLists/components/PreviewImageColumn';
import { RetailValueColumn } from 'pages/PriceLists/components/RetailValueColumn';
import { BurgerColumn } from 'pages/PriceLists/components/BurgerColumn';
import { NameColumn } from 'pages/PriceLists/components/NameColumn';
import {
	IPoseOptionUI,
	IUpdatePoseOption,
	IClearPoseOptionError,
	IPoseActionMenuParams,
	IUploadPoseOptionImage,
} from 'pages/PriceLists/types/studio/priceListOptions/poseOptions';

import { validateFloatingImageDimensions } from 'utils/images/validateFloatingImageDimensions';
import { validateImageFile } from 'utils/validations/general/validateImageFile';
import {
	minWidth,
	minHeight,
	requiredImageRatio,
	requiredImageRatioUI,
} from 'utils/validations/priceLists/studio/poseOptions/previewImage';
import { validateImageRatio } from 'utils/images/validateImageRatio';

import { IChangeSequencePayload, useDragAndDrop } from 'hooks/useDragAndDrop';
import { ActionMenuId } from 'hooks/useActionMenu';

interface IPoseOptionProps {
	deleteImagePreview: (
		sequence: number,
		poseOptionKey?: number
	) => Promise<void>;
	actionMenuId: ActionMenuId;
	poseOptionUI: IPoseOptionUI;
	previewImageFile: File | null;
	clearError: (params: IClearPoseOptionError) => void;
	setActionMenuId: (actionMenuId: ActionMenuId) => void;
	updatePoseOption: (params: IUpdatePoseOption) => Promise<void>;
	handleActionMenuItemClick: (params: IPoseActionMenuParams) => void;
	changePoseOptionsSequence: (params: IChangeSequencePayload) => void;
	uploadImagePreview: (params: IUploadPoseOptionImage) => Promise<void>;
}

export const PoseOption: FC<IPoseOptionProps> = ({
	clearError,
	poseOptionUI,
	actionMenuId,
	setActionMenuId,
	previewImageFile,
	updatePoseOption,
	deleteImagePreview,
	uploadImagePreview,
	changePoseOptionsSequence,
	handleActionMenuItemClick,
}) => {
	const [isImagePending, setIsImagePending] = useState(false);

	const {
		name,
		errors,
		sequence,
		retailPrice,
		referenceCode,
		previewImageUrl,
		priceListStudioFulfilledPoseOptionKey: poseOptionKey,
	} = poseOptionUI;

	const {
		name: nameError,
		retailPrice: retailPriceError,
		referenceCode: referenceCodeError,
		previewImageUrl: previewImageUrlError,
	} = errors;

	const {
		draggable,
		handleDrop,
		handleDragEnd,
		handleDragOver,
		handleDragStart,
		handleMouseDown,
	} = useDragAndDrop(disabledBgOptionsDragSequence, changePoseOptionsSequence);

	const poseOptionKeyString = poseOptionKey?.toString() || '';

	const onDragStart = (e: DragEvent<HTMLTableSectionElement>) => {
		handleDragStart(e, poseOptionKeyString);
	};

	const updateName = (value: string, validationMessage: string) => {
		void updatePoseOption({
			value,
			sequence,
			poseOptionKey,
			fieldKey: 'name',
			validationMessage,
		});
	};

	const clearNameError = () => {
		if (!nameError) return;

		clearError({
			sequence,
			fieldKey: 'name',
		});
	};

	const updateReferenceCode = (value: string, validationMessage: string) => {
		void updatePoseOption({
			value,
			sequence,
			poseOptionKey,
			validationMessage,
			fieldKey: 'referenceCode',
		});
	};

	const clearReferenceCodeError = () => {
		if (!referenceCodeError) return;

		clearError({
			sequence,
			fieldKey: 'referenceCode',
		});
	};

	const updateRetailPrice = (value: number, validationMessage: string) => {
		void updatePoseOption({
			value,
			sequence,
			poseOptionKey,
			validationMessage,
			fieldKey: 'retailPrice',
		});
	};

	const clearRetailPriceError = () => {
		if (!retailPriceError) return;

		clearError({
			sequence,
			fieldKey: 'retailPrice',
		});
	};

	const actionMenuItemClick = (menuItem: CommonOptionActionMenu) => {
		handleActionMenuItemClick({
			menuItem,
			sequence,
			poseOptionKey,
		});
	};

	const setActiveActionMenuId = () => {
		setActionMenuId(sequence);
	};

	const handleUploadImage = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];

		if (!file) return;

		const imageFileValidationMessage = validateImageFile({
			type: file.type,
			validateJpg: true,
			validatePng: true,
		});

		if (imageFileValidationMessage) {
			void uploadImagePreview({
				sequence,
				poseOptionKey,
				imageFile: file,
				validationMessage: imageFileValidationMessage,
			});

			return;
		}

		setIsImagePending(true);

		const reader = new FileReader();

		reader.onload = (event) => {
			const img = new Image();

			img.onload = async () => {
				const { width, height } = img;

				const imageRatio = width / height;

				const rationValidationMessage = validateImageRatio({
					imageRatio,
					requiredImageRatio,
					requiredImageRatioUI,
				});

				const dimensionsValidationMessage = validateFloatingImageDimensions({
					width,
					height,
					minWidth,
					minHeight,
				});

				await uploadImagePreview({
					sequence,
					poseOptionKey,
					imageFile: file,
					validationMessage:
						rationValidationMessage || dimensionsValidationMessage,
				});

				setIsImagePending(false);
			};

			img.onerror = () => {
				setIsImagePending(false);
				console.log('There was an error loading the image.');
			};

			if (typeof event.target?.result === 'string') {
				img.src = event.target.result;
			}
		};

		reader.onerror = () => {
			setIsImagePending(false);
			console.log(reader.error);
		};

		reader.readAsDataURL(file);
	};

	const deleteImage = async () => {
		setIsImagePending(true);

		await deleteImagePreview(sequence, poseOptionKey);

		setIsImagePending(false);
	};

	const handleDeleteImage = () => {
		void deleteImage();
	};

	const unsavedPoseOption = !poseOptionKey;
	const isDefaultPoseOption = !sequence;

	const menuList = Object.values(CommonOptionActionMenu);
	const processedMenuList = unsavedPoseOption ? menuList.slice(1) : menuList;

	const showBurger = !isDefaultPoseOption && !unsavedPoseOption;

	const uniqPreviewImageFile = unsavedPoseOption ? previewImageFile : null;

	return (
		<tbody
			onDrop={handleDrop}
			id={poseOptionKeyString}
			onDragStart={onDragStart}
			onDragEnd={handleDragEnd}
			onDragOver={handleDragOver}
			data-drag-sequence={sequence}
			className="price-option-body"
			draggable={draggable && !isDefaultPoseOption}
		>
			<tr className="price-option-tr">
				<BurgerColumn
					showBurger={showBurger}
					handleMouseDown={handleMouseDown}
				/>
				<PreviewImageColumn
					error={previewImageUrlError}
					isImagePending={isImagePending}
					previewImageUrl={previewImageUrl}
					handleUploadImage={handleUploadImage}
					handleDeleteImage={handleDeleteImage}
					previewImageFile={uniqPreviewImageFile}
				/>
				<NameColumn
					name={name}
					nameError={nameError}
					updateName={updateName}
					clearError={clearNameError}
				/>
				<ReferenceCodeColumn
					referenceCode={referenceCode}
					clearError={clearReferenceCodeError}
					referenceCodeError={referenceCodeError}
					updateReferenceCode={updateReferenceCode}
				/>
				<RetailValueColumn
					retailValue={retailPrice}
					clearError={clearRetailPriceError}
					retailValueError={retailPriceError}
					updateRetailValue={updateRetailPrice}
				/>
				<ActionMenuControlColumn
					menuList={processedMenuList}
					isDefault={isDefaultPoseOption}
					handleMenuItemClick={actionMenuItemClick}
					showActionMenu={actionMenuId === sequence}
					setActiveActionMenu={setActiveActionMenuId}
				/>
			</tr>
		</tbody>
	);
};
