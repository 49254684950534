import { useEffect } from 'react';

import { MARKET_SELL } from 'constants/navigation';
import { ROUTES } from 'constants/ROUTES';

import { IBreadcrumbs, setBreadcrumbs } from 'store/breadcrumbs';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import NavigationService from 'utils/NavigationService';

export const usePriceListsBreadcrumbs = (breadcrumbsParam?: IBreadcrumbs) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		const marketSellNavigation =
			NavigationService.getParentNavigation(MARKET_SELL);

		const priceListsNavigation = NavigationService.getSubNavigation(
			MARKET_SELL,
			ROUTES.PRICE_LISTS.PRICE_LISTS
		);

		if (!marketSellNavigation || !priceListsNavigation) return;

		const marketSellBreadcrumbs: IBreadcrumbs = {
			isActive: false,
			path: marketSellNavigation.path,
			title: marketSellNavigation.title,
		};

		const priceListsBreadcrumbs: IBreadcrumbs = {
			isActive: !breadcrumbsParam,
			path: priceListsNavigation.path,
			title: priceListsNavigation.title,
		};

		const breadcrumbs: IBreadcrumbs[] = [
			marketSellBreadcrumbs,
			priceListsBreadcrumbs,
		];

		if (breadcrumbsParam) {
			breadcrumbs.push(breadcrumbsParam);
		}

		dispatch(setBreadcrumbs(breadcrumbs));
	}, [breadcrumbsParam?.title]);
};
