import { date, object, string } from 'yup';

import {
	requiredFieldMessage,
	invalidCharactersMessage,
} from 'constants/general/validation/generalMessages';
import {
	broadcastNameRegEx,
	emailBodyMaxLength,
	maxEmailSubjectLength,
	broadcastNameMaxLength,
	emailBodyMaxLengthError,
} from 'constants/broadcasts/validation';

import { getMaxLengthMessage } from 'utils/validations/general/getMaxLengthMessage';

export const getBroadcastValidationSchema = (validateSpecialDate: boolean) =>
	object().shape({
		broadcastName: string()
			.required(requiredFieldMessage)
			.max(broadcastNameMaxLength, getMaxLengthMessage(broadcastNameMaxLength))
			.matches(broadcastNameRegEx, invalidCharactersMessage),
		days: string().required(requiredFieldMessage),
		specialDate: validateSpecialDate
			? date().default(null).required(requiredFieldMessage)
			: date().default(null).notRequired(),
		emailSubject: string()
			.required(requiredFieldMessage)
			.max(maxEmailSubjectLength, getMaxLengthMessage(maxEmailSubjectLength)),
		emailMessage: string()
			.required(requiredFieldMessage)
			.max(emailBodyMaxLength, emailBodyMaxLengthError),
		textMessage: string().required(requiredFieldMessage),
	});
