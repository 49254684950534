import { FC, useEffect, useState } from 'react';
import { ParseResult, parse } from 'papaparse';
import { SingleValue } from 'react-select';

import { ICreateProspects } from 'api/models/responses/projects/createProspects';
import ProjectsService from 'api/services/ProjectsService';

import { SelectComponent } from 'components/FormControls/Select';
import { Button } from 'components/FormControls/Button';

import { ISelectOption } from 'types/ui/select';

interface IAddProspectsProps {
	projectKey: number;
	uploadedFile: File | null;
	hideAddProspectsModal: () => void;
	showAddProspectsConfirmationModal: () => void;
	setConfirmationData: (confirmationData: ICreateProspects) => void;
}

interface IValues extends Record<string, number> {
	emailKey: number;
	phoneKey: number;
}

interface IField {
	id: string;
	label: string;
	selectPlaceholder: string;
	selectOptions: ISelectOption[];
}

const defaultEmailSelectOption: ISelectOption = {
	value: 0,
	label: 'No Emails to load',
};

const defaultPhoneNumberSelectOption: ISelectOption = {
	value: 0,
	label: 'No Phone Numbers to load',
};

const initialValues: IValues = { emailKey: 0, phoneKey: 0 };

export const AddProspects: FC<IAddProspectsProps> = ({
	projectKey,
	uploadedFile,
	setConfirmationData,
	hideAddProspectsModal,
	showAddProspectsConfirmationModal,
}) => {
	const [values, setValues] = useState<IValues>(initialValues);
	const [parsedSelectOptions, setParsedSelectOptions] = useState<
		ISelectOption[]
	>([]);
	const [isDuplicatedColumns, setIsDuplicatedColumns] = useState(false);
	const [error, setError] = useState('');

	const handleChange = (id: string, option: SingleValue<ISelectOption>) => {
		if (!option) return;

		setValues({
			...values,
			[id]: option.value,
		});
	};

	const handleFocus = () => {
		setError('');
	};

	const prospectsFields: IField[] = [
		{
			id: 'emailKey',
			label: 'Email Addresses',
			selectPlaceholder: 'Select email column',
			selectOptions: [defaultEmailSelectOption, ...parsedSelectOptions],
		},
		{
			id: 'phoneKey',
			label: 'Phone Numbers',
			selectPlaceholder: 'Select phone column',
			selectOptions: [defaultPhoneNumberSelectOption, ...parsedSelectOptions],
		},
	];

	const handleSubmit = () => {
		if (!uploadedFile) return;

		const { emailKey, phoneKey } = values;

		const [emailFields, phoneFields] = prospectsFields;

		const emailColumnName =
			emailKey !== 0
				? emailFields.selectOptions.find(({ value }) => value === emailKey)
						?.label || ''
				: '';

		const phoneNumberColumnName =
			phoneKey !== 0
				? phoneFields.selectOptions.find(({ value }) => value === phoneKey)
						?.label || ''
				: '';

		if (!emailColumnName && !phoneNumberColumnName) {
			return setError('Please select a column for email or/and phone data.');
		}

		if (emailColumnName === phoneNumberColumnName) {
			return setError('Please select different columns.');
		}

		ProjectsService.createProspects(projectKey, {
			file: uploadedFile,
			emailColumnName,
			phoneNumberColumnName,
		})
			.then((data) => {
				if (!data) return;

				setConfirmationData(data);
				hideAddProspectsModal();
				showAddProspectsConfirmationModal();
			})
			.catch((errorParam) => {
				console.log(errorParam);
			});
	};

	const FieldsList = prospectsFields.map(
		({ id, label, selectOptions, selectPlaceholder }) => (
			<div key={id} className="org-select-form">
				<SelectComponent
					id={id}
					label={label}
					value={values[id]}
					handleFocus={handleFocus}
					selectOptions={selectOptions}
					selectPlaceholder={selectPlaceholder}
					onChange={(option: SingleValue<ISelectOption>) =>
						handleChange(id, option)
					}
				/>
			</div>
		)
	);

	useEffect(() => {
		if (!uploadedFile) return;

		parse(uploadedFile, {
			preview: 1,
			header: false,
			skipEmptyLines: true,
			complete: (results: ParseResult<string[]>) => {
				const keys = results.data[0];

				const isDuplicatedKeys = new Set(keys).size !== keys.length;

				if (isDuplicatedKeys) {
					setError(
						'You have duplicated columns in your .csv file. Please rename them or upload another file.'
					);

					return setIsDuplicatedColumns(true);
				}

				setParsedSelectOptions(
					keys.map((key, index) => ({
						label: key,
						value: index + 1,
					}))
				);
			},
		});
	}, [uploadedFile]);

	return (
		<div className="org-prospects-modal">
			{!isDuplicatedColumns && FieldsList}
			{error && <p className="org-prospects-modal-error">{error}</p>}
			<div className="org-modal-confirm">
				{isDuplicatedColumns ? (
					<Button
						value="OK"
						className="btn-primary"
						handleClick={hideAddProspectsModal}
					/>
				) : (
					<Button
						value="Add Prospects"
						handleClick={handleSubmit}
						className="btn-primary"
					/>
				)}
				<Button
					value="Cancel"
					className="btn-secondary"
					handleClick={hideAddProspectsModal}
				/>
			</div>
		</div>
	);
};
