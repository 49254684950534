import DatePicker from 'react-datepicker';
import { FC } from 'react';

import { classicDatePickerConfig } from 'constants/general/datePickerConfigs';

import 'react-datepicker/dist/react-datepicker.css';

interface IDatePickerProps {
	id: string;
	disabled?: boolean;
	selected: Date | null;
	onChange: (date: Date) => void;
}

export const ReactDatePicker: FC<IDatePickerProps> = ({
	id,
	disabled,
	selected,
	onChange,
}) => (
	<DatePicker
		{...classicDatePickerConfig}
		id={id}
		autoComplete="off"
		disabled={disabled}
		selected={selected}
		onChange={onChange}
	/>
);

ReactDatePicker.defaultProps = {
	disabled: false,
};
