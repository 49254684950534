export interface IMarketingKeyword {
	keyword: string;
	description: string;
	extraDescription?: string;
	testBroadcastValue: string;
}

export const marketingKeywords: IMarketingKeyword[] = [
	{
		keyword: '[:ProjectCoverImage:]',
		description: 'Display the image used for the event cover image.',
		extraDescription: '(600x312px)',
		testBroadcastValue: 'Image placeholder',
	},
	{
		keyword: '[:PictureDay:]',
		description: 'The date of the picture day of the project.',
		extraDescription: '(in MM/DD format)',
		testBroadcastValue: '01/01',
	},
	{
		keyword: '[:ProjectLink:]',
		description:
			'This will show the Project Name as a hyperlink to the project.',
		extraDescription: '(Only for use in email body)',
		testBroadcastValue: '<Test Project>',
	},
	{
		keyword: '[:ProjectMessage:]',
		description:
			"Insert contents from the appropriate 'Special Message' for that project.",
		extraDescription: "(If 'Special Message' is blank, nothing will show.)",
		testBroadcastValue:
			'For email: This is the test welcome message! For sms: Test message!',
	},
	{
		keyword: '[:ProjectName:]',
		description: 'The Project Name',
		testBroadcastValue: 'Test Project',
	},
	{
		keyword: '[:ProjectURL:]',
		description:
			'This will show the URL of the project as a hyperlink to the project.',
		testBroadcastValue:
			'For email: https://accessmyevent.com/123456 For sms: https://a-me.io/123456',
	},
	{
		keyword: '[:SaleEndDate:]',
		description: 'The date the current sale ends.',
		extraDescription:
			'(Date used is in MM/DD format for current sale running at time broadcast sent.)',
		testBroadcastValue: '01/30',
	},
	{
		keyword: '[:GalleryPreview:]',
		description:
			'Watermarked thumbnail of the first image associated to that access code.',
		extraDescription:
			'(If no access code is found for this person, a placeholder image will show. Only for use in email body.)',
		testBroadcastValue: 'Image placeholder',
	},
	{
		keyword: '[:OrganizationName:]',
		description: 'The name of the organization the project is connected to.',
		testBroadcastValue: 'Test Organization',
	},
	{
		keyword: '[:OrganizationURL:]',
		description:
			'This will show the URL of the organization as a hyperlink to the organization.',
		testBroadcastValue: '<Test Organization>',
	},
	{
		keyword: '[:AccessCode:]',
		description: 'The access code(s) this person can use to see their images.',
		extraDescription:
			"(If more than one access code is found for this person, each access code will be on it's own line.)",
		testBroadcastValue: '123456',
	},
	{
		keyword: '[:StudioContactInfo:]',
		description: 'Your studio name, email address and phone number.',
		extraDescription:
			"(Each piece of info on it's own line. Only for use in email body.)",
		testBroadcastValue: 'Studio Name help@studioname.com 1 234 567-8910',
	},
	{
		keyword: '[:StudioName:]',
		description: 'The name of your studio.',
		testBroadcastValue: 'Studio Name',
	},
	{
		keyword: '[:StudioLogo:]',
		description: 'Your studio logo.',
		extraDescription: '(Only for use in email body)',
		testBroadcastValue: 'Image Placeholder',
	},
];
