export const broadcastNameRegEx = /^[0-9a-zA-Z'‘’`\-_!?,.""\s]{1,100}$/;
export const broadcastNameMaxLength = 100;

export const emailBodyMaxLength = 10000;
export const emailBodyMaxLengthError = 'Email Body max length is 10000 chars.';

export const maxEmailSubjectLength = 100;

export const maxTextMessageLength = 120;

export const minDaysNumber = 0;
export const maxDaysNumber = 365;
