import { ChangeEvent, FC } from 'react';

import { InputGrid } from 'components/FormControls/InputGrid';
import { TextEditorRef } from 'components/TextEditor/types';
import { ToggleSection } from 'components/ToggleSection';
import { TextEditor } from 'components/TextEditor';

import { useToggleSection } from 'hooks/useToggleSection';

export interface IEmailSectionProps {
	replyTo?: string;
	emailFrom?: string;
	senderName?: string;
	emailSubject: string;
	disabledEdit?: boolean;
	emailBodyError?: string;
	editorRef: TextEditorRef;
	initialEmailBody?: string;
	emailSubjectError?: string;
	emailSubjectTouched?: boolean;
	disabledEmailSubject?: boolean;
	clearEmailBodyError: () => void;
	handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const BroadcastEmail: FC<IEmailSectionProps> = ({
	replyTo,
	emailFrom,
	editorRef,
	senderName,
	emailSubject,
	handleChange,
	disabledEdit,
	emailBodyError,
	initialEmailBody,
	emailSubjectError,
	emailSubjectTouched,
	disabledEmailSubject,
	clearEmailBodyError,
}) => {
	const { isOpen, handleToggle } = useToggleSection(true);

	return (
		<ToggleSection
			title="Email"
			isOpen={isOpen}
			handleToggle={handleToggle}
			className="org-details-header"
		>
			<div className="broadcast-section">
				<h4 className="broadcast-section-title">
					<b>From:</b>
					<ul className="broadcast-header-list">
						<li className="broadcast-header-item">
							{senderName}
							<span>{emailFrom}</span>
						</li>
						<li className="broadcast-header-item">reply to: {replyTo}</li>
					</ul>
				</h4>
				<InputGrid
					row
					id="emailSubject"
					label="Subject:"
					required={false}
					value={emailSubject}
					placeholder="Subject"
					error={emailSubjectError}
					handleChange={handleChange}
					touched={emailSubjectTouched}
					className="preset broadcast-input"
					disabled={disabledEmailSubject || disabledEdit}
				/>
				<TextEditor
					editorRef={editorRef}
					errorMessage={emailBodyError}
					clearError={clearEmailBodyError}
					initialContent={initialEmailBody || ''}
					disabled={disabledEmailSubject || disabledEdit}
				/>
			</div>
		</ToggleSection>
	);
};
