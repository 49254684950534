export const formQuestionRegEx =
	/^(?![ '‘’`.,?!&-])(?!.* $)[a-zA-Z0-9 '‘’`.,?!&-]+$/;
export const optionsRegEx = /^[a-zA-Z0-9]+[a-zA-Z0-9 '.?!&-]*$/;

export const formQuestionInvalidFormatErrorMessage =
	'There are some invalid characters, please try again.';
export const minFormQuestionLength = 5;
export const maxFormQuestionLength = 500;
export const minFormQuestionLengthErrorMessage =
	"The field FormQuestion must be a string type with a minimum length of '5'";
export const maxFormQuestionLengthErrorMessage =
	"The field FormQuestion must be a string type with a maximum length of '500'";

export const duplicatedFormQuestion = 'Duplicated form question.';

export const maxOptionsLength = 999;
export const optionsInvalidFormatErrorMessage =
	'Please enter at least two options. Allowed symbols: a-z, A-Z, 0-9, space, apostrophe, dot, ampersand and hyphen.';
export const optionsLengthErrorMessage = 'Options must be max 999 characters.';

export const SUBJECT_FORM_QUESTION_LIMIT = 25;
