import { ProjectTypeFilterSelectOption } from 'types/projects/selectOptions/projectTypeFilterSelectOption';
import { ISelectOption } from 'types/ui/select';

import { ProjectTypes } from 'api/models/requests/projects/projectTypes';

import { generalFilterSelectOption } from './generalFilterSelectOption';
import { projectTypesUI } from '../projectTypesUI';

export const projectTypesSelectOptions: ISelectOption<ProjectTypes>[] =
	Object.values(ProjectTypes).map((projectType) => ({
		label: projectTypesUI[projectType],
		value: projectType,
	}));

export const projectTypeFilterSelectOptions: ISelectOption<ProjectTypeFilterSelectOption>[] =
	[generalFilterSelectOption, ...projectTypesSelectOptions];
