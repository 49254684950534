import { FC, useRef, useState } from 'react';

import { Button } from 'components/FormControls/Button';
import { NoItemsFound } from 'components/NoItemsFound';
import { Modal, ModalRef } from 'components/Modal';
import { Loader } from 'components/Loader';
import { Search } from 'components/Search';

import { usePriceListsBreadcrumbs } from 'pages/PriceLists/hooks/usePriceListsBreadcrumbs';
import { PriceListsTabs } from 'pages/PriceLists/components/PriceListsTabs';

import { priceListsTabsList } from 'constants/priceLists/tabs/priceListsTabsList';
import { isPriceListsPendingSelector } from 'store/priceLists';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { GeneralTabs } from 'types/generalTabs';

import { CreateNewPriceList } from './components/CreateNewPriceList';
import { useSearchPriceLists } from './hooks/useSearchPriceLists';
import { PriceListsList } from './components/PriceListsList';

const priceListsTypes: Record<GeneralTabs, boolean> = {
	[GeneralTabs.Active]: true,
	[GeneralTabs.Archived]: false,
};

export const PriceLists: FC = () => {
	const [activeTab, setActiveTab] = useState(GeneralTabs.Active);

	const isPriceListsPending = useAppSelector(isPriceListsPendingSelector);

	const createPriceListModalRef = useRef<ModalRef>(null);

	const {
		orderBy,
		results,
		handleSearch,
		setSortParams,
		orderDirection,
		setPaginatedEntity,
	} = useSearchPriceLists({
		isActive: priceListsTypes[activeTab],
	});

	usePriceListsBreadcrumbs();

	const showCreatePriceListModal = () => {
		createPriceListModalRef.current?.open();
	};

	const showPriceListTable = !!results.length && !isPriceListsPending;
	const priceListNotFound = !results.length && !isPriceListsPending;

	return (
		<div className="price">
			<div className="org-search-container">
				<Search
					title="Find a Price List"
					handleSearch={handleSearch}
					placeholder="Find a Price List"
				/>
			</div>
			<div className="org-container">
				<div className="price-header">
					<PriceListsTabs
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						tabsList={priceListsTabsList}
						className="price-header-controls"
					/>
					<Button
						value="+ New Price List"
						className="btn-primary price-btn"
						handleClick={showCreatePriceListModal}
					/>
				</div>
				{showPriceListTable && (
					<PriceListsList
						orderBy={orderBy}
						results={results}
						setSortParams={setSortParams}
						orderDirection={orderDirection}
						setPaginatedEntity={setPaginatedEntity}
					/>
				)}
				{isPriceListsPending && <Loader isFullHeight />}
				{priceListNotFound && <NoItemsFound title="price lists" />}
			</div>
			<Modal ref={createPriceListModalRef} title="Create New Price List">
				<CreateNewPriceList />
			</Modal>
		</div>
	);
};
