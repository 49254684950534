import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAdditionalChargeOptionUI } from 'pages/PriceLists/types/studio/priceListOptions/additionalChargeOptions';
import { IShippingOptionUI } from 'pages/PriceLists/types/studio/priceListOptions/shippingOptions';

import { IPriceListBaseOptionsState } from '.';

const initialState: IPriceListBaseOptionsState = {
	shippingOptions: [],
	isPendingBaseOptions: true,
	additionalChargeOptions: [],
};

// temporary solution
const priceListBaseOptionsSlice = createSlice({
	name: 'priceListBaseOptions',
	initialState,
	reducers: {
		setShippingOptions: (
			state,
			{ payload }: PayloadAction<IShippingOptionUI[]>
		) => {
			state.shippingOptions = payload;
		},

		setAdditionalChargeOptions: (
			state,
			{ payload }: PayloadAction<IAdditionalChargeOptionUI[]>
		) => {
			state.additionalChargeOptions = payload;
		},

		setIsPendingBaseOptions: (state, { payload }: PayloadAction<boolean>) => {
			state.isPendingBaseOptions = payload;
		},
	},
});

export const priceListBaseOptionsReducer = priceListBaseOptionsSlice.reducer;
export const {
	setShippingOptions,
	setIsPendingBaseOptions,
	setAdditionalChargeOptions,
} = priceListBaseOptionsSlice.actions;
