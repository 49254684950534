import { IUpdateCampaign } from 'api/models/requests/marketing/updateCampaign';
import { IPreset, IPresetSale } from 'api/models/responses/presets/presets';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import {
	ICreatePreset,
	ICreatePresetFromProjectBody,
} from 'api/models/requests/presets/createPresets';

import { presetsUrls } from 'constants/apiUrls/presetsUrls';

import { BaseService } from '../BaseService';

class PresetsService extends BaseService {
	async getPresets(): Promise<IPreset[] | null> {
		return this.get(presetsUrls.presetsUrl);
	}

	async getPreset(presetKey: number): Promise<IPreset | null> {
		return this.get(presetsUrls.getPresetsUrlIncludingKey(presetKey));
	}

	async createPreset(body: ICreatePreset): Promise<IPreset | null> {
		return this.post(presetsUrls.presetsUrl, body);
	}

	async deletePreset(presetKey: number): Promise<string | null> {
		return this.delete(presetsUrls.getPresetsUrlIncludingKey(presetKey));
	}

	async updatePreset(
		presetKey: number,
		body: IPatchBody[]
	): Promise<IPreset | null> {
		return this.patch(presetsUrls.getPresetsUrlIncludingKey(presetKey), body);
	}

	async createPresetFromProject(
		projectKey: number,
		body: ICreatePresetFromProjectBody
	): Promise<null> {
		return this.post(presetsUrls.createPresetFromProject(projectKey), body);
	}

	async updatePresetCampaign(
		presetKey: number,
		body: IUpdateCampaign
	): Promise<IPreset | null> {
		return this.put(presetsUrls.updatePresetCampaign(presetKey), body);
	}

	async getPresetSales(presetKey: number): Promise<IPresetSale[] | null> {
		return this.get(presetsUrls.presetSales(presetKey));
	}

	async updatePresetSale(
		presetKey: number,
		body: IPatchBody[]
	): Promise<IPresetSale | null> {
		return this.patch(presetsUrls.getPresetSaleUrl(presetKey), body);
	}
}

export default new PresetsService();
