import {
	maxEmailSpecialMessageLength,
	emailSpecialMessageErrorMessage,
} from 'constants/projects/validation/generalProjectFields';

export const validateEmailSpecialMessage = (
	emailSpecialMessage: string
): string => {
	if (emailSpecialMessage.length > maxEmailSpecialMessageLength) {
		return emailSpecialMessageErrorMessage;
	}

	return '';
};
