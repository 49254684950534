import { SingleValue } from 'react-select';
import {
	FC,
	useState,
	useEffect,
	ChangeEvent,
	KeyboardEvent,
	SyntheticEvent,
} from 'react';

import { projectTypesSelectOptions } from 'constants/projects/selectOptions/projectTypesSelectOptions';
import { commaRegEx } from 'constants/projects/validation/estimatedSubjectNumber';
import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	projectUrlValidationRegEx,
	projectNameValidationRegEx,
	projectUrlValidationMessage,
	projectNameValidationMessage,
	estimatedNumberOfSubjectsUpLimit,
	estimatedNumberOfSubjectsBottomLimit,
	estimatedNumberOfSubjectsValidationMessage,
} from 'constants/projects/validation/generalProjectFields';
import { EnterCode } from 'constants/general/keyboardCodes';

import { ProjectTypes } from 'api/models/requests/projects/projectTypes';
import { IProject } from 'api/models/responses/projects/projectDetails';
import { IErrorResponse } from 'api/models/responses/errors/errorResponse';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import ProjectsService from 'api/services/ProjectsService';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';

import { useProjectTypeSubjects } from 'pages/Projects/hooks/useProjectTypeSubjects';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { studioNetworkDomainSelector } from 'store/studio';
import { normalizeDigit } from 'utils/ui/normalizeDigit';
import { ISelectOption } from 'types/ui/select';

interface IErrors {
	projectUrl: string;
	projectName: string;
	estimatedNumberOfSubjects: string;
}

const initialErrors: IErrors = {
	projectUrl: '',
	projectName: '',
	estimatedNumberOfSubjects: '',
};

interface IFormFieldsProps {
	projectKey: number;
	projectUrl: string;
	projectName: string;
	projectType: ProjectTypes;
	estimatedNumberOfSubject: number;
	setProjectDetails: (projectDetails: IProject) => void;
}

export const FormFields: FC<IFormFieldsProps> = ({
	projectUrl,
	projectKey,
	projectName,
	projectType,
	setProjectDetails,
	estimatedNumberOfSubject,
}) => {
	const [errors, setErrors] = useState<IErrors>(initialErrors);
	const [isPending, setIsPending] = useState(false);

	const [projectTypeState, setProjectTypeState] =
		useState<ProjectTypes>(projectType);
	const [estimatedNumberState, setEstimatedNumberState] = useState(
		normalizeDigit({ value: +estimatedNumberOfSubject })
	);

	const studioNetworkDomain = useAppSelector(studioNetworkDomainSelector);

	const { subjects } = useProjectTypeSubjects(projectType);

	const createError = (id: string, message: string) => ({
		...errors,
		[id]: message,
	});

	const clearError = (id: string) => {
		setErrors({ ...errors, [id]: '' });
	};

	const handleProjectUpdate = async (value: string, path: string) => {
		try {
			if (!projectKey) return;

			setIsPending(true);

			const updatedProjectFields: IPatchBody = {
				path,
				value,
				op: 'replace',
			};

			const projectUpdateBody: IPatchBody[] = [updatedProjectFields];

			const data = await ProjectsService.partialProjectUpdate(
				projectKey,
				projectUpdateBody
			);

			if (!data) return;

			setProjectDetails(data);
			setIsPending(false);
		} catch (error) {
			const typedError = error as IErrorResponse;

			setIsPending(false);

			const isValidationErrors = typedError.traceId;

			const updatedErrorMessages = Object.entries(
				typedError.errors
			).reduce<IErrors>((acc, [key, message]) => {
				const splittedKey = key.split('.');

				const parsedKey = splittedKey[splittedKey.length - 1];

				const doesKeyExist = parsedKey in errors;

				const parsedMessage = isValidationErrors
					? (message as string[]).join(' ')
					: (message as string);

				if (!doesKeyExist) return acc;

				return {
					...acc,
					[parsedKey]: parsedMessage,
				};
			}, {} as IErrors);

			setErrors({ ...errors, ...updatedErrorMessages });
		}
	};

	const handleChangeProjectName = (e: SyntheticEvent<HTMLInputElement>) => {
		const { id, value } = e.currentTarget;

		if (!value.length) {
			return setErrors(createError(id, requiredFieldMessage));
		}

		if (!projectNameValidationRegEx.test(value)) {
			return setErrors(createError(id, projectNameValidationMessage));
		}

		void handleProjectUpdate(value, id);
	};

	const handleChangeProjectUrl = (e: SyntheticEvent<HTMLInputElement>) => {
		const { id, value } = e.currentTarget;

		if (!value.length) {
			return setErrors(createError(id, requiredFieldMessage));
		}

		if (!projectUrlValidationRegEx.test(value)) {
			return setErrors(createError(id, projectUrlValidationMessage));
		}

		void handleProjectUpdate(value, id);
	};

	const handleChangeProjectType = (
		option: SingleValue<ISelectOption<ProjectTypes>>
	) => {
		if (!option) return;

		setProjectTypeState(option.value);
	};

	const handleLazyChangeEstimatedNumberOfSubject = (
		id: string,
		value: string
	) => {
		const purifiedValue = value.replace(commaRegEx, '');
		const valueNumber = +purifiedValue;

		if (!valueNumber) {
			return setErrors(createError(id, requiredFieldMessage));
		}

		if (isNaN(valueNumber)) {
			return setErrors(
				createError(id, estimatedNumberOfSubjectsValidationMessage)
			);
		}

		if (valueNumber < estimatedNumberOfSubjectsBottomLimit) {
			return setErrors(
				createError(id, estimatedNumberOfSubjectsValidationMessage)
			);
		}

		if (valueNumber > estimatedNumberOfSubjectsUpLimit) {
			return setErrors(
				createError(id, estimatedNumberOfSubjectsValidationMessage)
			);
		}

		void handleProjectUpdate(purifiedValue, id);
	};

	const handleChangeEstimatedNumberState = (
		e: ChangeEvent<HTMLInputElement>
	) => {
		const { id, value } = e.target;

		const purifiedValue = value.replace(commaRegEx, '');

		if (isNaN(+purifiedValue)) return;

		const normalizedValue =
			purifiedValue && normalizeDigit({ value: +purifiedValue });

		setEstimatedNumberState(normalizedValue);
		clearError(id);
	};

	const handleBlurEstimatedNumber = (e: ChangeEvent<HTMLInputElement>) => {
		const { id } = e.target;

		handleLazyChangeEstimatedNumberOfSubject(id, estimatedNumberState);
	};

	const handleKeyDownEstimatedNumber = (e: KeyboardEvent<HTMLInputElement>) => {
		const { id } = e.currentTarget;

		const { code } = e;

		if (code !== EnterCode) return;

		handleLazyChangeEstimatedNumberOfSubject(id, estimatedNumberState);
	};

	const projectUrlSupLabel = `${studioNetworkDomain}.accessmyevent.com/`;

	useEffect(() => {
		if (projectTypeState === projectType) return;

		void handleProjectUpdate(projectTypeState, 'projectType');
	}, [projectType, projectTypeState]);

	return (
		<div className="org-form-fields">
			<InputGrid
				row
				isLazy
				touched
				id="projectName"
				className="preset"
				label="Project Name"
				clearError={clearError}
				defaultValue={projectName}
				placeholder="Project Name"
				error={errors.projectName}
				supLabel="visible to consumers"
				handleLazyChange={handleChangeProjectName}
			/>
			<InputGrid
				row
				isLazy
				touched
				id="projectUrl"
				className="preset"
				label="Project URL"
				clearError={clearError}
				error={errors.projectUrl}
				placeholder="Project URL"
				defaultValue={projectUrl}
				supLabel={projectUrlSupLabel}
				handleLazyChange={handleChangeProjectUrl}
			/>
			<div className="input-form-preset">
				<SelectComponent
					id="projectType"
					label="Project Type"
					disabled={isPending}
					value={projectTypeState}
					className="select-required"
					selectPlaceholder="Elementary"
					onChange={handleChangeProjectType}
					selectOptions={projectTypesSelectOptions}
				/>
			</div>
			<InputGrid
				row
				touched
				className="preset"
				value={estimatedNumberState}
				label={`Est # of ${subjects}`}
				id="estimatedNumberOfSubjects"
				handleBlur={handleBlurEstimatedNumber}
				error={errors.estimatedNumberOfSubjects}
				handleKeyDown={handleKeyDownEstimatedNumber}
				placeholder={`# of ${subjects.toLowerCase()}`}
				handleChange={handleChangeEstimatedNumberState}
			/>
		</div>
	);
};
