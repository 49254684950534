import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import PriceListsStudioService from 'api/services/PriceListsService/studio/PriceListsStudioService';
import { IPriceListStudio } from 'api/models/responses/priceLists/studio/priceListStudio';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { studioPriceListTabsList } from 'constants/priceLists/tabs/studioPriceListTabsList';
import { StudioPriceListTabs } from 'constants/priceLists/tabs/studioPriceListTabs';
import { PriceListFulfillment } from 'constants/priceLists/priceListFulfillment';

import { usePriceListsBreadcrumbs } from 'pages/PriceLists/hooks/usePriceListsBreadcrumbs';
import { PriceListHeader } from 'pages/PriceLists/components/PriceListHeader';
import { PriceListsTabs } from 'pages/PriceLists/components/PriceListsTabs';

import { getPriceListBaseOptionsAsync } from 'store/priceLists/priceListBaseOptions';
import { getDefaultBackgroundsAsync } from 'store/priceLists/priceListBackgrounds';
import { IBreadcrumbs } from 'store/breadcrumbs';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { PriceListAlaCartes } from './pages/PriceListAlaCartes';
import { PriceListPackages } from './pages/PriceListPackages';
import { PriceListOptions } from './pages/PriceListOptions';

export const PriceListStudio: FC = () => {
	const [priceListStudio, setPriceListStudio] =
		useState<IPriceListStudio | null>(null);

	const [isPriceListPending, setIsPriceListPending] = useState(true);

	const [activeTab, setActiveTab] = useState<StudioPriceListTabs>(
		StudioPriceListTabs.PriceListOptions
	);

	const { priceListId } = useParams();
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();

	const priceListStudioBreadcrumb: IBreadcrumbs = {
		path: pathname,
		isActive: true,
		title: priceListStudio?.name || '',
	};

	usePriceListsBreadcrumbs(priceListStudioBreadcrumb);

	const getPriceListStudioAsync = useCallback(async () => {
		try {
			if (!priceListId) return;

			const data = await PriceListsStudioService.getPriceListStudio(
				+priceListId
			);

			if (!data) return;

			setPriceListStudio(data);
		} catch (error) {
			console.log(error);
		}

		setIsPriceListPending(false);
	}, [priceListId]);

	const updatePriceListStudio = async (body: IPatchBody[]) => {
		if (!priceListId) return;

		const data = await PriceListsStudioService.updatePriceListStudio(
			+priceListId,
			body
		);

		if (!data) return;

		setPriceListStudio(data);
	};

	useEffect(() => {
		void dispatch(getDefaultBackgroundsAsync());
	}, []);

	useEffect(() => {
		if (!priceListId) return;

		void dispatch(getPriceListBaseOptionsAsync(+priceListId));
	}, [priceListId]);

	useEffect(() => {
		void getPriceListStudioAsync();
	}, [getPriceListStudioAsync]);

	const PriceListsTabsPages: Record<StudioPriceListTabs, ReactElement | null> =
		{
			[StudioPriceListTabs.PriceListOptions]: (
				<PriceListOptions
					setActiveTab={setActiveTab}
					priceListStudio={priceListStudio}
					isPriceListPending={isPriceListPending}
					updatePriceListStudio={updatePriceListStudio}
				/>
			),
			[StudioPriceListTabs.Packages]: (
				<PriceListPackages
					setActiveTab={setActiveTab}
					isPriceListPending={isPriceListPending}
					isPackageSelectionRequired={
						priceListStudio?.isPackageSelectionRequired
					}
					updatePriceListStudio={updatePriceListStudio}
					priceListKey={priceListStudio?.priceListStudioFulfilledKey}
				/>
			),
			[StudioPriceListTabs.ALaCarte]: (
				<PriceListAlaCartes
					setActiveTab={setActiveTab}
					isPriceListPending={isPriceListPending}
					priceListKey={priceListStudio?.priceListStudioFulfilledKey}
				/>
			),
			[StudioPriceListTabs.AddOns]: null,
		};

	return (
		<div className="price">
			<PriceListHeader
				name={priceListStudio?.name}
				updatePriceList={updatePriceListStudio}
				fulfillment={PriceListFulfillment.Studio}
			/>
			<div className="org-container">
				<div className="price-header">
					<PriceListsTabs
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						className="price-header-list"
						tabsList={studioPriceListTabsList}
					/>
				</div>
			</div>
			{PriceListsTabsPages[activeTab]}
		</div>
	);
};
