import { SaleTypes } from 'api/models/responses/projects/sales';

export interface ISaleUI {
	icon: string;
	text: string;
	title: string;
	enableKioskMode: boolean;
	saleExpiresLabel: string;
}

export const salesUI: Record<SaleTypes, ISaleUI> = {
	[SaleTypes.JourneyAPresale]: {
		icon: 'presale',
		title: 'Presale',
		enableKioskMode: true,
		saleExpiresLabel: 'Picture Day',
		text: 'Sale Before Picture Day',
	},
	[SaleTypes.JourneyALatePresale]: {
		icon: 'late',
		title: 'Late Prepay',
		enableKioskMode: false,
		saleExpiresLabel: 'Images Uploaded',
		text: 'Sale just after Picture Day',
	},
	[SaleTypes.JourneyAGallerySale]: {
		icon: 'gallery',
		title: 'Gallery Sale',
		enableKioskMode: false,
		text: 'After Images Uploaded',
		saleExpiresLabel: 'Picture Day',
	},
	[SaleTypes.JourneyBGallerySale]: {
		icon: 'gallery',
		title: 'Gallery Sale',
		enableKioskMode: false,
		text: 'After Proof Sale',
		saleExpiresLabel: 'Picture Day',
	},
	[SaleTypes.JourneyBProofSale]: {
		icon: 'proof',
		title: 'Proof Sale',
		enableKioskMode: false,
		saleExpiresLabel: 'Images Uploaded',
		text: 'Sale just after Picture Day',
	},
	[SaleTypes.JourneyCGallerySale]: {
		icon: 'gallery',
		title: 'Gallery Sale',
		enableKioskMode: false,
		text: 'Sale after Picture Day',
		saleExpiresLabel: 'Picture Day',
	},
	[SaleTypes.JourneyDGallerySale]: {
		icon: 'gallery',
		title: 'Gallery Sale',
		enableKioskMode: false,
		text: 'After Images Uploaded',
		saleExpiresLabel: 'Picture Day',
	},
	[SaleTypes.JourneyDPresale]: {
		icon: 'presale',
		title: 'Presale',
		enableKioskMode: true,
		text: 'Sale Before Picture Day',
		saleExpiresLabel: 'Last Picture Day',
	},
	[SaleTypes.JourneyEGallerySale]: {
		icon: 'gallery',
		title: 'Gallery Sale',
		enableKioskMode: false,
		text: 'After Images Uploaded',
		saleExpiresLabel: 'Picture Day',
	},
	[SaleTypes.JourneyELatePresale]: {
		icon: 'late',
		title: 'Absentee Sale',
		enableKioskMode: false,
		text: 'Picture Day to Absentee',
		saleExpiresLabel: 'Absentee Day',
	},
	[SaleTypes.JourneyEPresale]: {
		icon: 'presale',
		title: 'Presale',
		enableKioskMode: true,
		text: 'Sale Before Picture Day',
		saleExpiresLabel: 'Picture Day',
	},
};
