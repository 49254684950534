import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';
import moment, { utc } from 'moment';
import { Formik } from 'formik';
import { lazy } from 'yup';

import { ICreateBroadcastBody } from 'api/models/requests/broadcast/createBroadcast';
import { SaleCondition } from 'api/models/requests/presets/createPresets';
import { ICampaign } from 'api/models/responses/campaigns/campaign';
import CampaignsService from 'api/services/CampaignsService';
import BroadcastService from 'api/services/BroadcastService';
import {
	IBroadcast,
	ProjectDateTypes,
} from 'api/models/responses/broadcasts/broadcast';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { studioMarketingSelector } from 'store/studio';
import { IBreadcrumbs } from 'store/breadcrumbs';
import {
	setCampaignKey,
	campaignKeySelector,
	setCopiedBroadcastKey,
	copiedBroadcastKeySelector,
} from 'store/broadcasts';

import { minTimeH } from 'constants/broadcasts/time';
import { ROUTES } from 'constants/ROUTES';

import { useCampaignsBreadcrumbs } from 'pages/Campaigns/hooks/useCampaignsBreadcrumbs';
import { HHMMA } from 'utils/dates/timeFormats';

import { getBroadcastValidationSchema } from './validation';
import { BroadcastForm } from './BroadcastForm';

export interface IBroadcastValues
	extends Omit<ICreateBroadcastBody, 'time' | 'specialDate'> {
	time: Date | null;
	specialDate: Date | null;
}

const getInitialTime = (time: string | null) =>
	(time && moment(time, HHMMA).toDate()) ||
	moment().set({ hours: minTimeH, minutes: 0, seconds: 0 }).toDate();

const getInitialSpecialDate = (specialDate: string | null) =>
	(specialDate && moment(specialDate).toDate()) || null;

export const BroadcastDetails: FC = () => {
	const [broadcastDetails, setBroadcastDetails] = useState<IBroadcast | null>(
		null
	);
	const [campaignDetails, setCampaignDetails] = useState<ICampaign | null>(
		null
	);

	const copiedBroadcastKey = useAppSelector(copiedBroadcastKeySelector);
	const campaignKeyForCreation = useAppSelector(campaignKeySelector);
	const studioMarketing = useAppSelector(studioMarketingSelector);

	const editorRef = useRef<CKEditor<Editor>>(null);

	const dispatch = useAppDispatch();
	const { broadcastId } = useParams();
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const isCreateBroadcast = !broadcastId;

	const campaignKeyForEdit = broadcastDetails?.campaignKey || 0;
	const broadcastName = broadcastDetails?.broadcastName || '';
	const specialDate = broadcastDetails?.specialDate || null;
	const time = broadcastDetails?.time || null;
	const days = broadcastDetails?.days || 0;

	const campaignDetailsBreadcrumbs: IBreadcrumbs | null = campaignDetails && {
		isActive: isCreateBroadcast,
		title: campaignDetails.name,
		path: `${ROUTES.CAMPAIGNS.CAMPAIGNS}/${campaignDetails.campaignKey}`,
	};

	const broadcastDetailsBreadcrumbs: IBreadcrumbs | null = broadcastDetails && {
		path: pathname,
		isActive: true,
		title: broadcastName,
	};

	useCampaignsBreadcrumbs(
		campaignDetailsBreadcrumbs,
		isCreateBroadcast ? null : broadcastDetailsBreadcrumbs
	);

	const initialCondition = broadcastDetails?.condition || SaleCondition.Until;
	const initialProjectDateType =
		broadcastDetails?.projectDateType || ProjectDateTypes.FirstPictureDay;
	const initialEmailSubject = broadcastDetails?.emailSubject || '';
	const initialSpecialDate = getInitialSpecialDate(specialDate);
	const initialEmailMessage = broadcastDetails?.emailBody || '';
	const initialTextMessage = broadcastDetails?.smsMessage || '';
	const initialTime = getInitialTime(time);
	const initialDays = days || 0;

	const initialBroadcastValues: IBroadcastValues = {
		broadcastName,
		days: initialDays,
		time: initialTime,
		condition: initialCondition,
		textMessage: initialTextMessage,
		specialDate: initialSpecialDate,
		emailMessage: initialEmailMessage,
		emailSubject: initialEmailSubject,
		projectDateType: initialProjectDateType,
	};

	const campaignKey = campaignKeyForCreation || campaignKeyForEdit;

	const getCampaignDetails = useCallback(async () => {
		if (!campaignKey || campaignDetails) return;

		try {
			const data = await CampaignsService.getCampaign(campaignKey);

			if (!data) return;

			setCampaignDetails(data);
		} catch (error) {
			console.log(error);
		}
	}, [campaignKey]);

	const getBroadcastDetails = useCallback(async () => {
		const broadcastKey = (broadcastId && +broadcastId) || copiedBroadcastKey;

		if (!broadcastKey || broadcastDetails) return;

		try {
			const data = await BroadcastService.getBroadcast(broadcastKey);

			if (!data) return;

			setBroadcastDetails(data);
		} catch (error) {
			console.log(error);
		}
	}, [broadcastId, copiedBroadcastKey]);

	const onSubmit = async (values: IBroadcastValues) => {
		const timeString = moment(values.time).format(HHMMA);
		const specialDateUtc =
			values.specialDate && utc(values.specialDate).toISOString();

		const body: ICreateBroadcastBody = {
			...values,
			time: timeString,
			specialDate: specialDateUtc,
		};

		try {
			if (isCreateBroadcast) {
				const data = await BroadcastService.createBroadcast(campaignKey, body);

				if (!data) return;

				dispatch(setCampaignKey(null));
				dispatch(setCopiedBroadcastKey(null));

				navigate(`${ROUTES.CAMPAIGNS.BROADCASTS}/${data.key}`);
				return;
			}

			const data = await BroadcastService.updateBroadcast(+broadcastId, body);

			if (!data || !broadcastDetails) return;

			setBroadcastDetails({
				...broadcastDetails,
				...body,
			});
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		void getCampaignDetails();
	}, [getCampaignDetails]);

	useEffect(() => {
		void getBroadcastDetails();
	}, [getBroadcastDetails]);

	const validationSchema = lazy((values: IBroadcastValues) => {
		const validateSpecialDate =
			values.projectDateType === ProjectDateTypes.SpecialDate;

		return getBroadcastValidationSchema(validateSpecialDate);
	});

	const replyTo =
		broadcastDetails?.replyTo || studioMarketing?.replyToEmailAddress;
	const senderName =
		broadcastDetails?.senderName || studioMarketing?.emailSenderName;
	const phoneNumber =
		broadcastDetails?.phoneNumber || studioMarketing?.twillioPhoneNumber;

	return (
		<Formik
			enableReinitialize
			onSubmit={onSubmit}
			validationSchema={validationSchema}
			initialValues={initialBroadcastValues}
		>
			<BroadcastForm
				replyTo={replyTo}
				editorRef={editorRef}
				senderName={senderName}
				phoneNumber={phoneNumber}
				isCreateBroadcast={isCreateBroadcast}
				emailFrom={broadcastDetails?.emailFrom}
				initialEmailBody={broadcastDetails?.emailBody}
				campaignTarget={campaignDetails?.campaignTarget}
			/>
		</Formik>
	);
};
