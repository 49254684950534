import { useEffect } from 'react';
import moment from 'moment';

import { ActiveOrgSortLabels } from 'constants/organizations/activeOrgSortLabels';
import { DataOrder } from 'constants/general/dataOrder';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useSortParams } from 'hooks/useSortParams';

import {
	activeOrganizationSelector,
	setActiveOrganizations,
} from 'store/organizations';

interface ISortActiveOrganizationsResult {
	orderDirection: DataOrder;
	orderBy: ActiveOrgSortLabels;
	setSortParams: (sortLabel: ActiveOrgSortLabels) => void;
}

export const useSortActiveOrganizations =
	(): ISortActiveOrganizationsResult => {
		const { orderBy, setSortParams, orderDirection } =
			useSortParams<ActiveOrgSortLabels>(ActiveOrgSortLabels.LastModifyDate);

		const activeOrganizations = useAppSelector(activeOrganizationSelector);

		const dispatch = useAppDispatch();

		useEffect(() => {
			if (!activeOrganizations.length) return;

			switch (orderBy) {
				case ActiveOrgSortLabels.OrganizationName: {
					const sortedActiveOrganization = [...activeOrganizations].sort(
						(a, b) =>
							orderDirection === DataOrder.ASC
								? a.organizationName.localeCompare(b.organizationName)
								: b.organizationName.localeCompare(a.organizationName)
					);

					dispatch(setActiveOrganizations(sortedActiveOrganization));
					break;
				}

				case ActiveOrgSortLabels.Private: {
					const sortedActiveOrganization = [...activeOrganizations].sort(
						(a, b) => {
							if (a.isPrivate && b.isPrivate) return 0;

							if (orderDirection === DataOrder.ASC) {
								return a.isPrivate ? 1 : -1;
							}

							return a.isPrivate ? -1 : 1;
						}
					);

					dispatch(setActiveOrganizations(sortedActiveOrganization));
					break;
				}

				case ActiveOrgSortLabels.ActiveProjects: {
					const sortedActiveOrganization = [...activeOrganizations].sort(
						(a, b) =>
							orderDirection === DataOrder.ASC
								? a.activeProjectsCount - b.activeProjectsCount
								: b.activeProjectsCount - a.activeProjectsCount
					);

					dispatch(setActiveOrganizations(sortedActiveOrganization));
					break;
				}

				case ActiveOrgSortLabels.CreatedDate: {
					const sortedActiveOrganization = [...activeOrganizations].sort(
						(a, b) => {
							const firstDate = moment(a.createDate);
							const secondDate = moment(b.createDate);

							if (orderDirection === DataOrder.ASC) {
								return secondDate.isAfter(firstDate) ? 1 : -1;
							}

							return secondDate.isAfter(firstDate) ? -1 : 1;
						}
					);

					dispatch(setActiveOrganizations(sortedActiveOrganization));
					break;
				}

				case ActiveOrgSortLabels.LastModifyDate: {
					const sortedActiveOrganization = [...activeOrganizations].sort(
						(a, b) => {
							const firstDate = moment(a.lastModifyDate);
							const secondDate = moment(b.lastModifyDate);

							if (orderDirection === DataOrder.ASC) {
								return secondDate.isAfter(firstDate) ? 1 : -1;
							}

							return secondDate.isAfter(firstDate) ? -1 : 1;
						}
					);

					dispatch(setActiveOrganizations(sortedActiveOrganization));
					break;
				}

				default:
					break;
			}
		}, [orderBy, orderDirection]);

		return { orderBy, setSortParams, orderDirection };
	};
