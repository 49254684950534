import { ISendSpecialBroadcastBody } from 'api/models/requests/broadcast/sendSpecialBroadcast';
import { ISendSpecialBroadcastInfo } from 'api/models/responses/projects/sendSpecialBroadcast';
import { ISendTestBroadcastBody } from 'api/models/requests/broadcast/sendTestBroadcast';
import { IUpdatedBroadcast } from 'api/models/responses/broadcasts/updatedBroadcast';
import { ICreateBroadcastBody } from 'api/models/requests/broadcast/createBroadcast';
import { IBroadcastInfo } from 'api/models/responses/broadcasts/broadcastInfo';
import { IBroadcast } from 'api/models/responses/broadcasts/broadcast';

import { broadcastUrls } from 'constants/apiUrls/broadcastUrls';

import { BaseService } from '../BaseService';

class BroadcastService extends BaseService {
	async getBroadcast(broadcastKey: number): Promise<IBroadcast | null> {
		return this.get(broadcastUrls.getBroadcast(broadcastKey));
	}

	async createBroadcast(
		campaignKey: number,
		body: ICreateBroadcastBody
	): Promise<IUpdatedBroadcast | null> {
		return this.post(broadcastUrls.createBroadcast(campaignKey), body);
	}

	async updateBroadcast(
		broadcastKey: number,
		body: ICreateBroadcastBody
	): Promise<IUpdatedBroadcast | null> {
		return this.put(broadcastUrls.getBroadcastUrl(broadcastKey), body);
	}

	async deleteBroadcast(broadcastKey: number): Promise<string | null> {
		return this.delete(broadcastUrls.getBroadcastUrl(broadcastKey));
	}

	async sendSpecialBroadcast(
		body: ISendSpecialBroadcastBody
	): Promise<ISendSpecialBroadcastInfo | null> {
		return this.put(broadcastUrls.sendSpecialBroadcast, body);
	}

	async getBroadcastInfo(projectKey: number): Promise<IBroadcastInfo | null> {
		return this.get(broadcastUrls.getBroadcastInfo(projectKey));
	}

	async sendTestBroadcast(
		body: ISendTestBroadcastBody
	): Promise<string | null> {
		return this.put(broadcastUrls.sendTestBroadcast, body);
	}
}

export default new BroadcastService();
