import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import { ICreateBlankProjectValues } from 'pages/Projects/pages/CreateBlankProject';
import { projectJourneyInfo } from 'constants/projects/projectJourneyInfo';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { ISelectOption } from 'types/ui/select';
import {
	priceListsSale1SelectOptionsSelector,
	priceListsSale2SelectOptionsSelector,
	priceListsSale3SelectOptionsSelector,
} from 'store/projects';

import { SalePriceList } from './SalePriceList';

export const SalePriceLists: FC = () => {
	const { values, errors, touched, setFieldValue } =
		useFormikContext<ICreateBlankProjectValues>();

	const priceListsSale1SelectOptions = useAppSelector(
		priceListsSale1SelectOptionsSelector
	);

	const priceListsSale2SelectOptions = useAppSelector(
		priceListsSale2SelectOptionsSelector
	);

	const priceListsSale3SelectOptions = useAppSelector(
		priceListsSale3SelectOptionsSelector
	);

	const priceListsSelectOptions = [
		{
			id: 'priceListSale1',
			selectOptions: priceListsSale1SelectOptions,
		},
		{
			id: 'priceListSale2',
			selectOptions: priceListsSale2SelectOptions,
		},
		{
			id: 'priceListSale3',
			selectOptions: priceListsSale3SelectOptions,
		},
	];

	const { sales } = projectJourneyInfo[values.journey];

	const handleSelectChange = (
		id: string,
		option: SingleValue<ISelectOption<string>>
	) => {
		if (!option) return;

		void setFieldValue(id, option.value);
	};

	const PriceListsSelectFieldsList = sales.map(({ icon, title }, index) => {
		const id = priceListsSelectOptions[index].id;
		const selectOptions = priceListsSelectOptions[index].selectOptions;

		const typedId = id as keyof ICreateBlankProjectValues;

		const value = values[typedId] as string;
		const error = errors[typedId] || '';
		const isTouched = !!touched[typedId];

		const handleChange = (option: SingleValue<ISelectOption<string>>) =>
			handleSelectChange(id, option);

		return (
			<SalePriceList
				id={id}
				key={id}
				icon={icon}
				label={title}
				value={value}
				error={error}
				touched={isTouched}
				placeholder={title}
				handleChange={handleChange}
				selectOptions={selectOptions}
			/>
		);
	});

	return (
		<div className="project-section">
			<h2 className="project-section-title">sale price lists</h2>
			<div className="project-section-container">
				<div className="project-section-form section-sale">
					{PriceListsSelectFieldsList}
				</div>
			</div>
		</div>
	);
};
