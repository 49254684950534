import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getInitialPaginationResponse } from 'constants/general/pagination/getInitialPaginationResponse';
import {
	IPromotion,
	PromotionsResponse,
} from 'api/models/responses/promotions/promotion';

import { IPromotionsState } from './types';

const initialState: IPromotionsState = {
	copiedPromotionKey: null,
	isPromotionsPending: false,
	isPromotionsPaginationPending: false,
	promotions: getInitialPaginationResponse<IPromotion>(),
};

const promotionsSlice = createSlice({
	name: 'promotions',
	initialState,
	reducers: {
		setPromotions: (state, { payload }: PayloadAction<PromotionsResponse>) => {
			state.promotions.results = payload.results;
			state.promotions.totalCount = payload.totalCount;
		},

		setPaginatedPromotions: (
			state,
			{ payload }: PayloadAction<PromotionsResponse>
		) => {
			state.promotions.results = [
				...state.promotions.results,
				...payload.results,
			];
			state.promotions.totalCount = payload.totalCount;
		},

		deletePromotion: (state, { payload }: PayloadAction<number>) => {
			state.promotions.results = state.promotions.results.filter(
				(promotion) => promotion.promotionKey !== payload
			);
		},

		setCopiedPromotionKey: (
			state,
			{ payload }: PayloadAction<number | null>
		) => {
			state.copiedPromotionKey = payload;
		},

		setPromotionsPending: (state, { payload }: PayloadAction<boolean>) => {
			state.isPromotionsPending = payload;
		},

		setPromotionsPaginationPending: (
			state,
			{ payload }: PayloadAction<boolean>
		) => {
			state.isPromotionsPaginationPending = payload;
		},
	},
});

export const promotionsReducer = promotionsSlice.reducer;
export const {
	setPromotions,
	deletePromotion,
	setPromotionsPending,
	setCopiedPromotionKey,
	setPaginatedPromotions,
	setPromotionsPaginationPending,
} = promotionsSlice.actions;
