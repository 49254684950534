import { IStatisticRenderHelper } from 'types/statistic/statisticRenderHelper';

export const studioStatisticKeys: IStatisticRenderHelper[] = [
	{
		key: 'organizationsCount',
		title: 'Organizations',
	},
	{
		key: 'projectsCount',
		title: 'Projects',
	},
	{
		key: 'prospectsCount',
		title: 'Prospects',
	},
	{
		key: 'leadsCount',
		title: 'Leads',
	},
	{
		key: 'buyersCount',
		title: 'Buyers',
	},
];
