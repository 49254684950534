import {
	requiredFieldMessage,
	invalidCharactersMessage,
} from 'constants/general/validation/generalMessages';

const referenceCodeRegEx = /^[A-Za-z0-9 ]+$/;
const referenceCodeMaxLength = 6;

export const validateReferenceCode = (referenceCode: string): string => {
	if (!referenceCode) {
		return requiredFieldMessage;
	}

	if (!referenceCodeRegEx.test(referenceCode)) {
		return invalidCharactersMessage;
	}

	if (referenceCode.length > referenceCodeMaxLength) {
		return 'Reference code must be less than 7 characters.';
	}

	return '';
};
