import { FC, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';

import { StepSubjectHeader } from 'components/StepSubjectHeader';
import { TextEditorRef } from 'components/TextEditor/types';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useToastify } from 'hooks/useToastify';

import { toastifyRequiredFields } from 'constants/general/toast/toastifyRequiredFields';
import { ProjectTypes } from 'api/models/requests/projects/projectTypes';
import { IStepControl } from 'pages/Projects/types';
import {
	getReferencedItemsAsync,
	isValidSubjectFormQuestionsSelector,
} from 'store/projects';

import { SubjectFormQuestionsContainer } from './components/SubjectFormQuestionsContainer';
import { ProjectJourneyDates } from './components/ProjectJourneyDates';
import { MarketingFunnel } from './components/MarketingFunnel';
import { SalePriceLists } from './components/SalePriceLists';
import { OrderMessages } from './components/OrderMessages';
import { StepControls } from './components/StepControls';
import { Greeting } from './components/Greeting';

import { ICreateBlankProjectValues } from '..';

interface IBlankProjectStep2Props extends IStepControl {
	editorRef: TextEditorRef;
}

const smoothScroll: ScrollIntoViewOptions = {
	behavior: 'smooth',
	block: 'center',
};

export const BlankProjectStep2: FC<IBlankProjectStep2Props> = ({
	setStep,
	editorRef,
}) => {
	const { values, errors, isSubmitting } =
		useFormikContext<ICreateBlankProjectValues>();

	const subjectFormQuestionsRef = useRef<HTMLDivElement>(null);

	const isValidSubjectFormQuestions = useAppSelector(
		isValidSubjectFormQuestionsSelector
	);

	const { showErrorsToastify } = useToastify();

	const dispatch = useAppDispatch();

	useEffect(() => {
		void dispatch(
			getReferencedItemsAsync({
				journey: values.journey,
				projectType: values.projectType as ProjectTypes,
			})
		);
	}, [dispatch, values.journey, values.projectType]);

	useEffect(() => {
		if (!isSubmitting) return;

		const doesErrorExist =
			Object.values(errors).length || !isValidSubjectFormQuestions;

		const firstErrorKey = Object.keys(errors)[0];

		const $field = document.getElementById(firstErrorKey);

		if ($field) {
			$field.scrollIntoView(smoothScroll);
		}

		if (!$field && !isValidSubjectFormQuestions) {
			subjectFormQuestionsRef?.current?.scrollIntoView(smoothScroll);
		}

		if (doesErrorExist) {
			showErrorsToastify(toastifyRequiredFields);
		}
	}, [isSubmitting, isValidSubjectFormQuestions, errors]);

	return (
		<div className="project-thumb">
			<StepSubjectHeader title="Create a New Project" step="Step 2 of 2" />
			<ProjectJourneyDates />
			<SalePriceLists />
			<MarketingFunnel />
			<SubjectFormQuestionsContainer parentRef={subjectFormQuestionsRef} />
			<OrderMessages />
			<Greeting editorRef={editorRef} />
			<StepControls setStep={setStep} />
		</div>
	);
};
