import { FC, ChangeEvent, useId } from 'react';

import { uploadInputAccept } from 'constants/images/validation/imageUploadTypes';

import imagePlaceholder from 'images/imagePlaceholder.jpg';

interface IImageUploadAreaProps {
	disabledUpload?: boolean;
	handleUploadImage: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const ImageUploadArea: FC<IImageUploadAreaProps> = ({
	disabledUpload,
	handleUploadImage,
}) => {
	const uploadId = useId();

	return (
		<>
			<input
				type="file"
				id={uploadId}
				disabled={disabledUpload}
				accept={uploadInputAccept}
				style={{ display: 'none' }}
				onChange={handleUploadImage}
			/>
			<label htmlFor={uploadId} style={{ cursor: 'pointer' }}>
				<img
					src={imagePlaceholder}
					alt="background preview"
					className="price-preview-img"
				/>
			</label>
		</>
	);
};
