import { CellProps, Column } from 'react-table';
import { FC } from 'react';

import { Button } from 'components/FormControls/Button';
import { Table } from 'components/Table';

import {
	IErredRecord,
	ICreateProspects,
} from 'api/models/responses/projects/createProspects';

interface IAddProspectReportProps {
	hideAddProspectsReportModal: () => void;
	confirmationData: ICreateProspects | null;
}

export const AddProspectsReport: FC<IAddProspectReportProps> = ({
	confirmationData,
	hideAddProspectsReportModal,
}) => {
	if (!confirmationData) return null;

	const {
		erredRecords,
		totalNumberOfRowsWithData,
		totalNumberOfImportedRecords,
	} = confirmationData;

	const errorRecordsCount =
		totalNumberOfRowsWithData - totalNumberOfImportedRecords;

	const isPlural = errorRecordsCount > 1;

	const firstMessagePart = isPlural ? 'these issues' : 'this issue';
	const secondMessagePart = isPlural
		? `these ${errorRecordsCount} people`
		: 'this 1 person';

	const resolveMessage = `If you are able to resolve ${firstMessagePart}, you can try adding ${secondMessagePart} again.`;

	const columns: Column<IErredRecord>[] = [
		{
			Header: 'Row number',
			accessor: 'rowNumber',
		},
		{
			Header: 'EmailAddress',
			accessor: 'emailAddress',
			Cell: ({ row }: CellProps<IErredRecord>) => {
				const { emailAddress, isEmailValid } = row.original;

				return !isEmailValid ? (
					<span className="table-err" title={emailAddress}>
						{emailAddress}
					</span>
				) : (
					<></>
				);
			},
		},
		{
			Header: 'PhoneNumber',
			accessor: 'phoneNumber',
			Cell: ({ row }: CellProps<IErredRecord>) => {
				const { phoneNumber, isPhoneValid } = row.original;

				return !isPhoneValid ? (
					<span className="table-err" title={phoneNumber}>
						{phoneNumber}
					</span>
				) : (
					<></>
				);
			},
		},
		{
			Header: 'Comment',
		},
	];

	return (
		<div>
			<Table
				isEqual
				columns={columns}
				data={erredRecords}
				className="org-table project-details-table modal-table"
			/>
			<p className="modal-prospect-subtitle">{resolveMessage}</p>
			<Button
				value="OK"
				className="btn-primary org-modal-btn"
				handleClick={hideAddProspectsReportModal}
			/>
		</div>
	);
};
