import { ISubscription } from 'api/models/responses/subscriptions/subscription';
import { subscriptionsUrls } from 'constants/apiUrls/subscriptionsUrls';

import { BaseService } from '../BaseService';

class SubscriptionsService extends BaseService {
	async getSubscription(): Promise<ISubscription | null> {
		return this.get<ISubscription>(subscriptionsUrls.subscription);
	}

	async getSubscriptionsList(): Promise<ISubscription[] | null> {
		return this.get<ISubscription[]>(subscriptionsUrls.subscriptions);
	}

	async setSubscriptionForStudio(
		subscriptionLevelKey: number,
		studioKey: number
	): Promise<void | null> {
		return this.put(subscriptionsUrls.setSubscription, {
			studioKey,
			subscriptionLevelKey,
		});
	}
}

export default new SubscriptionsService();
