import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';
import { CampaignsTarget } from 'api/models/responses/campaigns/campaign';

export const projectsUrls = {
	latest: 'api/projects/latest',
	searchProject: 'api/projects/search',
	comingSoon: 'api/projects/coming-soon',
	createBlankProject: 'api/projects/create/source/journey',
	validateNameUrl: 'api/projects/create/validate-name-url',

	getProjectStatistic: (projectKey: number) =>
		`api/dashboard/project/${projectKey}`,

	projectUrlIncludingKey: (projectKey: number) => `api/projects/${projectKey}`,

	activeStudioProjects: 'api/projects/active-for-studio',

	activeForOrganization: (orgKey: number) =>
		`api/projects/active-for-organization/${orgKey}`,

	expiredForOrganization: (orgKey: number) =>
		`api/projects/expired-for-organization/${orgKey}`,

	getProjectCreateFormData: (journey: ProjectJourneys) =>
		`api/projects/create/source/journey/${journey}/get-referenced-items`,

	createProjectFromPreset: (presetKey: number) =>
		`api/projects/create/source/preset/${presetKey}`,

	coverImageUrl: (projectKey: number) =>
		`api/projects/${projectKey}/cover-image`,

	getProjectTimeline: (projectKey: number) =>
		`api/projects/${projectKey}/timeline`,

	getMarketingCampaignStatistic: (projectKey: number) =>
		`api/projects/${projectKey}/marketing/campaigns-statistics`,

	getMarketingCampaignDetails: (projectKey: number) =>
		`api/projects/${projectKey}/marketing/campaigns-details`,

	getProjectSaleUrl: (saleKey: number) => `api/projects/sales/${saleKey}`,

	getProjectFormQuestions: (projectKey: number) =>
		`api/projects/${projectKey}/form-questions`,

	createProjectFormQuestion: (projectKey: number) =>
		`api/projects/${projectKey}/form-question`,

	getProjectFormQuestionUrl: (formQuestionKey: number) =>
		`api/projects/form-question/${formQuestionKey}`,

	reorderProjectFormQuestions: (projectKey: number) =>
		`api/projects/${projectKey}/form-questions/reorder`,

	createProspects: (projectKey: number) =>
		`api/projects/${projectKey}/marketing/upload-prospects`,

	getMarketingCampaigns: (
		campaignTarget: CampaignsTarget,
		journey: ProjectJourneys
	) =>
		`api/projects/marketing-campaigns/${campaignTarget}/for-journey/${journey}`,
};
