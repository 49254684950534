import { createSelector } from 'reselect';

import { backgroundFileCustomSelectOption } from 'constants/priceLists/selectOptions/backgroundFileCustomSelectOption';
import { RootState } from 'store/store';

export const priceListBackgroundsStateSelector = (state: RootState) =>
	state.priceListBackgrounds;

export const defaultBackgroundsSelector = createSelector(
	priceListBackgroundsStateSelector,
	({ defaultBackgrounds }) => defaultBackgrounds
);

export const backgroundFileSelectOptionsSelector = createSelector(
	defaultBackgroundsSelector,
	(defaultBackgrounds) => [
		backgroundFileCustomSelectOption,
		...defaultBackgrounds.map(({ name, defaultPriceListBackgroundKey }) => ({
			label: name,
			value: defaultPriceListBackgroundKey,
		})),
	]
);

export const studioBackgroundsSelector = createSelector(
	priceListBackgroundsStateSelector,
	({ studioBackgrounds }) => studioBackgrounds
);

export const imagoBackgroundsSelector = createSelector(
	priceListBackgroundsStateSelector,
	({ imagoBackgrounds }) => imagoBackgrounds
);

export const isReorderedBackgroundsSelector = createSelector(
	priceListBackgroundsStateSelector,
	({ isReorderedBackgrounds }) => isReorderedBackgrounds
);

export const customBackgroundFileSelector = createSelector(
	priceListBackgroundsStateSelector,
	({ customBackgroundFile }) => customBackgroundFile
);

export const backgroundFileKeySelector = createSelector(
	priceListBackgroundsStateSelector,
	({ backgroundFileKey }) => backgroundFileKey
);

export const initialStudioIsBackgroundRequiredSelector = createSelector(
	studioBackgroundsSelector,
	(studioBackgrounds) => !!studioBackgrounds.length
);
