import { FC, useEffect, useState } from 'react';

import { IErrorResponse } from 'api/models/responses/errors/errorResponse';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import ProjectsService from 'api/services/ProjectsService';

import { getDisabledDateById } from 'pages/Projects/helpers/getDisabledDateById';
import { IProjectUpdate, IUpdateTimeline } from 'pages/Projects/types';
import { getDateErrorMessage } from 'pages/Projects/validation';

import { getProcessedErrors } from 'utils/errors/getProcessedErrors';
import { prepareDate } from 'utils/dates/setCurrentTimeToDate';
import { getInitialDate } from 'utils/dates/getInitialDate';

import { journeyTimelines } from 'constants/projects/timeline/journeyTimelines';
import { JourneyTimeline } from 'components/Journey/JourneyTimeline';
import { DateItem } from 'pages/Projects/components/DateItem';
import { useToastify } from 'hooks/useToastify';

interface IProjectJourneyProps extends IProjectUpdate, IUpdateTimeline {}

export interface IValues {
	[key: string]: Date | null;
	expireDate: Date | null;
	publishDate: Date | null;
	lastPictureDate: Date | null;
	firstPictureDate: Date | null;
}

interface IErrors {
	[key: string]: string;
	expireDate: string;
	publishDate: string;
	lastPictureDate: string;
	firstPictureDate: string;
}

const initialErrors: IErrors = {
	expireDate: '',
	publishDate: '',
	lastPictureDate: '',
	firstPictureDate: '',
};

export const ProjectJourney: FC<IProjectJourneyProps> = ({
	projectDetails,
	setProjectDetails,
	setIsUpdateTimeline,
}) => {
	const [errors, setErrors] = useState<IErrors>(initialErrors);
	const [isPending, setIsPending] = useState(false);

	const { showSuccessToastify } = useToastify();

	const { journey, projectKey } = projectDetails;

	const {
		journeyDate,
		journeyText,
		journeyTitle,
		journeySubText,
		journeyBlankProjectImage,
	} = journeyTimelines[journey];

	const initialLastPictureDate = getInitialDate(projectDetails.lastPictureDate);
	const initialPublishDate = getInitialDate(projectDetails.publishDate);
	const initialExpireDate = getInitialDate(projectDetails.expireDate);
	const initialFirstPictureDate = getInitialDate(
		projectDetails.firstPictureDate
	);

	const [values, setValues] = useState<IValues>({
		expireDate: initialExpireDate,
		publishDate: initialPublishDate,
		lastPictureDate: initialLastPictureDate,
		firstPictureDate: initialFirstPictureDate,
	});

	const validateDate = (): IErrors => {
		const {
			datesLabels,
			validateBlankProjectLastPictureDate: validateLastPictureDate,
			validateBlankProjectFirstPictureDate: validateFirstPictureDate,
		} = journeyDate;
		const {
			expireDateLabel,
			publishDateLabel,
			lastPictureDateLabel,
			firstPictureDateLabel,
		} = datesLabels;

		const { publishDate, firstPictureDate, lastPictureDate, expireDate } =
			values;

		const lastPictureDateExist =
			validateLastPictureDate && lastPictureDate && lastPictureDateLabel;

		const newErrors: IErrors = { ...initialErrors };

		if (!publishDate || !firstPictureDate || !expireDate) return errors;

		if (validateFirstPictureDate) {
			if (publishDate >= firstPictureDate) {
				newErrors.publishDate = getDateErrorMessage(
					firstPictureDateLabel,
					false
				).message;
			}

			if (firstPictureDate <= publishDate) {
				newErrors.firstPictureDate =
					getDateErrorMessage(publishDateLabel).message;
			}
		}

		if (publishDate >= expireDate) {
			newErrors.publishDate = getDateErrorMessage(
				expireDateLabel,
				false
			).message;
		}

		if (firstPictureDate >= expireDate) {
			newErrors.firstPictureDate = getDateErrorMessage(
				expireDateLabel,
				false
			).message;
		}

		if (lastPictureDateExist) {
			if (publishDate >= lastPictureDate) {
				newErrors.publishDate = getDateErrorMessage(
					lastPictureDateLabel,
					false
				).message;
			}

			if (firstPictureDate >= lastPictureDate) {
				newErrors.firstPictureDate = getDateErrorMessage(
					lastPictureDateLabel,
					false
				).message;
			}

			if (lastPictureDate >= expireDate) {
				newErrors.lastPictureDate = getDateErrorMessage(
					expireDateLabel,
					false
				).message;
			}

			if (
				lastPictureDate <= publishDate ||
				lastPictureDate <= firstPictureDate
			) {
				newErrors.lastPictureDate = getDateErrorMessage(
					`${publishDateLabel} and ${firstPictureDateLabel}`
				).message;
			}

			if (expireDate <= lastPictureDate) {
				newErrors.expireDate =
					getDateErrorMessage(lastPictureDateLabel).message;
			}
		}

		if (expireDate <= publishDate || expireDate <= firstPictureDate) {
			newErrors.expireDate = getDateErrorMessage(
				`${publishDateLabel} and ${firstPictureDateLabel}`
			).message;
		}

		return newErrors;
	};

	const updateProjectDate = async (
		id: string,
		prevDate: Date,
		newDate: Date
	) => {
		if (prevDate === newDate) return;

		const newErrors = validateDate();

		setErrors(newErrors);

		if (Object.values(newErrors).some((message) => message)) return;

		const updatedProjectFields: IPatchBody = {
			path: id,
			value: prepareDate(newDate),
			op: 'replace',
		};

		const projectUpdateBody: IPatchBody[] = [updatedProjectFields];

		setIsPending(true);

		try {
			const data = await ProjectsService.partialProjectUpdate(
				projectKey,
				projectUpdateBody
			);

			if (data) {
				setProjectDetails(data);
				setIsUpdateTimeline(true);

				showSuccessToastify({
					title: 'Date Updated',
					message: 'Double-check updated sale and marketing dates.',
				});
			}
		} catch (error) {
			const typedError = error as IErrorResponse;

			const processedErrors = getProcessedErrors(values, typedError.errors);

			setErrors(processedErrors as IErrors);
		}

		setIsPending(false);
	};

	const handleChange = (id: string, date: Date) => {
		setValues({ ...values, [id]: date });
	};

	const DateItemsList = journeyDate.journeyBlankProjectDates.map(
		({ id, icon, label }) => {
			const value = values[id];
			const error = errors[id];

			const disabled = getDisabledDateById(id, value);

			return (
				<DateItem
					id={id}
					key={id}
					icon={icon}
					error={error}
					label={label}
					value={value}
					touched={true}
					disabled={disabled || isPending}
					handleChange={(date: Date) => handleChange(id, date)}
					wrapperClassName="project-section-wrapper project-section-datepicker"
				/>
			);
		}
	);

	useEffect(() => {
		if (!values.publishDate || !initialPublishDate) return;

		void updateProjectDate(
			'publishDate',
			initialPublishDate,
			values.publishDate
		);
	}, [values.publishDate]);

	useEffect(() => {
		if (!values.firstPictureDate || !initialFirstPictureDate) return;

		void updateProjectDate(
			'firstPictureDate',
			initialFirstPictureDate,
			values.firstPictureDate
		);
	}, [values.firstPictureDate]);

	useEffect(() => {
		if (!initialLastPictureDate || !values.lastPictureDate) return;

		void updateProjectDate(
			'lastPictureDate',
			initialLastPictureDate,
			values.lastPictureDate
		);
	}, [values.lastPictureDate]);

	useEffect(() => {
		if (!values.expireDate || !initialExpireDate) return;

		void updateProjectDate('expireDate', initialExpireDate, values.expireDate);
	}, [values.expireDate]);

	return (
		<div className="project-section-container prj-journey-section">
			<JourneyTimeline
				journeyText={journeyText}
				journeyTitle={journeyTitle}
				journeySubText={journeySubText}
				journeyImage={journeyBlankProjectImage}
			/>
			<div className="project-section-form">{DateItemsList}</div>
		</div>
	);
};
