import { FC } from 'react';

import { Checkbox } from 'components/FormControls/Checkbox';

import { MarketingItem } from './MarketingItem';

export interface IMarketingItem {
	label: string;
	value: string;
}

interface IMarketingProps {
	leadCampaignName: string;
	buyerCampaignName: string;
	isMarketingEnable: boolean;
	prospectCampaignName: string;
}

export const Marketing: FC<IMarketingProps> = ({
	leadCampaignName,
	buyerCampaignName,
	isMarketingEnable,
	prospectCampaignName,
}) => {
	const campaigns: IMarketingItem[] = [
		{
			label: 'Prospect Campaign',
			value: prospectCampaignName,
		},
		{
			label: 'Lead Campaign',
			value: leadCampaignName,
		},
		{
			label: 'Buyer Campaign',
			value: buyerCampaignName,
		},
	];

	const CampaignList = campaigns.map(({ label, value }) => (
		<MarketingItem key={label} label={label} value={value} />
	));

	return (
		<div className="preset-form-marketing preset-header-border">
			<span className="preset-form-header">
				Marketing
				<Checkbox
					disabled
					id="isMarketingEnabled"
					handleChange={() => {}}
					checked={isMarketingEnable}
					label="Enable Marketing For This Project"
				/>
			</span>
			<div className="preset-form-layout">
				<div className="preset-message-marketing">{CampaignList}</div>
			</div>
		</div>
	);
};
