import { FC } from 'react';

interface IHeaderControlProps {
	showAside: () => void;
}

export const HeaderControl: FC<IHeaderControlProps> = ({ showAside }) => (
	<ul className="header-control" onClick={showAside}>
		<li className="header-user"></li>
		<li className="header-search"></li>
		<li className="header-profile">
			<div className="header-profile-thumb">
				<img
					className="header-profile-image"
					src="/image/ShawnCantrell.jpeg"
					alt="user"
				/>
			</div>
			<span className="header-profile-name">Skoolhouse Photography</span>
		</li>
	</ul>
);
