import { PromoApplyToTypes } from 'api/models/responses/promotions/promotionDetails';
import { ISelectOption } from 'types/ui/select';

import { promoApplyToTypesUI } from '../promoApplyToTypesUI';

export const promoApplyToSelectOptions: ISelectOption<PromoApplyToTypes>[] =
	Object.values(PromoApplyToTypes).map((applyToType) => ({
		value: applyToType,
		label: promoApplyToTypesUI[applyToType],
	}));
