import { Route, Routes } from 'react-router-dom';

import { LoadingPage } from 'pages/LoadingPage';
import { ErrorPage } from 'pages/ErrorPage';

import { useRedirectToRegistrationSteps } from 'hooks/auth/useRedirectToRegistrationSteps';
import { ROUTES } from 'constants/ROUTES';
import { Layout } from 'layout';

import { registrationStepsRoutesList } from './routes/registrationStepsRoutesList';
import { mainLayoutRouterList } from './routes';

export const Router = () => {
	const isStepsAvailable = useRedirectToRegistrationSteps();

	return (
		<Routes>
			{!isStepsAvailable ? (
				<Route path={ROUTES.DASHBOARD} element={<Layout />}>
					{mainLayoutRouterList.map(({ path, element }) => (
						<Route key={path} path={path} element={element} />
					))}
				</Route>
			) : (
				<Route path="*" element={<LoadingPage />} />
			)}
			<Route path="*" element={<ErrorPage />} />
			{registrationStepsRoutesList.map(({ path, element }) => (
				<Route key={path} path={path} element={element} />
			))}
		</Routes>
	);
};
