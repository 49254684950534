import { Card } from 'components/Card';
import { Table } from 'components/Table';
import { Column } from 'react-table';

export const OrderWaiting = () => {
	const columns: Column[] = [
		{
			Header: 'Project',
			accessor: 'project',
		},
		{
			Header: '# of Orders',
			accessor: 'orders',
		},
		{
			Header: 'Waiting Since',
			accessor: 'waiting',
		},
	];

	return (
		<Card
			title="Orders Waiting"
			bodyClassName="card-table"
			subtitle="(‘received’ status > than 14 days)"
		>
			<Table
				columns={columns}
				className="org-table"
				data={[
					{
						isPrivate: false,
						activeProjectsCount: 23,
						organizationKey: 1,
						organizationName: 'Summit Christian Academy',
						createDate: '2023-12-04T09:36:56.511648',
						lastModifyDate: '2024-06-24T10:50:30.4315093',
						searchResultsImageRelativePath:
							'/serviceimage/a924f0c8-5ba0-4dc2-b3f8-2acaf7a43564.png',
					},
				]}
			/>
		</Card>
	);
};
