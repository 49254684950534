import { FC, ReactNode } from 'react';
import cn from 'classnames';

interface IAccessCodeMessageProps {
	error?: string;
	touched?: boolean;
	children: ReactNode;
}

export const AccessCodeMessage: FC<IAccessCodeMessageProps> = ({
	error,
	touched,
	children,
}) => {
	const showError = error && touched;

	return (
		<div className="project-message-section">
			<span className="project-message-title is-pad">
				Access Code Message
				<b>
					Shown when consumer clicks “Need help finding your access code?” link.
				</b>
			</span>
			<div
				className={cn('project-textarea-section', {
					'is-error': showError,
				})}
			>
				{children}
				{showError && <span className="input-error">{error}</span>}
				<span className="project-textarea-info t-right">
					500 Characters Max
				</span>
			</div>
		</div>
	);
};
