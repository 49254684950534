import { PackageOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/ownPackageOption';
import { ISelectOption } from 'types/ui/select';

import { packageOptionTypesUI } from '../packageOptionTypesUI';

export const packageOptionTypesSelectOptions: ISelectOption<PackageOptionTypes>[] =
	Object.values(PackageOptionTypes).map((value) => ({
		value,
		label: packageOptionTypesUI[value],
	}));
