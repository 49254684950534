import { FormQuestionDataFieldType } from 'api/models/responses/general/generalFormQuestion';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	optionsRegEx,
	formQuestionRegEx,
	maxOptionsLength,
	minFormQuestionLength,
	maxFormQuestionLength,
	optionsLengthErrorMessage,
	optionsInvalidFormatErrorMessage,
	minFormQuestionLengthErrorMessage,
	maxFormQuestionLengthErrorMessage,
	formQuestionInvalidFormatErrorMessage,
} from 'constants/projects/validation/subjectFormQuestion';

export const validateFormQuestion = (formQuestion: string) => {
	const length = formQuestion.length;
	const invalidFormat = !formQuestionRegEx.test(formQuestion);

	if (!length) {
		return requiredFieldMessage;
	}

	if (invalidFormat) {
		return formQuestionInvalidFormatErrorMessage;
	}

	if (length < minFormQuestionLength) {
		return minFormQuestionLengthErrorMessage;
	}

	if (length > maxFormQuestionLength) {
		return maxFormQuestionLengthErrorMessage;
	}

	return '';
};

export const validateDataFieldType = (
	dataFieldType: FormQuestionDataFieldType | null
) => {
	if (!dataFieldType) {
		return requiredFieldMessage;
	}

	return '';
};

export const validateOptions = (options: string | null) => {
	if (!options) return '';

	const parsedOptions = options.split(',');

	if (parsedOptions.length === 1) {
		return optionsInvalidFormatErrorMessage;
	}

	const validFormat = parsedOptions.every((value) =>
		optionsRegEx.test(value.trim())
	);

	if (!validFormat) {
		return optionsInvalidFormatErrorMessage;
	}

	if (options.length > maxOptionsLength) {
		return optionsLengthErrorMessage;
	}

	return '';
};
