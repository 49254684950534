import { States } from 'types/ui/states';

export const statesUI: Record<States, string> = {
	[States.AL]: 'Alabama - AL',
	[States.AK]: 'Alaska - AK',
	[States.AZ]: 'Arizona - AZ',
	[States.AR]: 'Arkansas - AR',
	[States.CA]: 'California - CA',
	[States.CO]: 'Colorado - CO',
	[States.CT]: 'Connecticut - CT',
	[States.DE]: 'Delaware - DE',
	[States.DC]: 'District of Columbia - DC',
	[States.FL]: 'Florida - FL',
	[States.GA]: 'Georgia - GA',
	[States.HI]: 'Hawaii - HI',
	[States.ID]: 'Idaho - ID',
	[States.IL]: 'Illinois - IL',
	[States.IN]: 'Indiana - IN',
	[States.IA]: 'Iowa - IA',
	[States.KS]: 'Kansas - KS',
	[States.KY]: 'Kentucky - KY',
	[States.LA]: 'Louisiana - LA',
	[States.ME]: 'Maine - ME',
	[States.MD]: 'Maryland - MD',
	[States.MA]: 'Massachusetts - MA',
	[States.MI]: 'Michigan - MI',
	[States.MN]: 'Minnesota - MN',
	[States.MS]: 'Mississippi - MS',
	[States.MO]: 'Missouri - MO',
	[States.MT]: 'Montana - MT',
	[States.NE]: 'Nebraska - NE',
	[States.NV]: 'Nevada - NV',
	[States.NH]: 'New Hampshire - NH',
	[States.NJ]: 'New Jersey - NJ',
	[States.NM]: 'New Mexico - NM',
	[States.NY]: 'New York - NY',
	[States.NC]: 'North Carolina - NC',
	[States.ND]: 'North Dakota - ND',
	[States.OH]: 'Ohio - OH',
	[States.OK]: 'Oklahoma - OK',
	[States.OR]: 'Oregon - OR',
	[States.PA]: 'Pennsylvania - PA',
	[States.RI]: 'Rhode Island - RI',
	[States.SC]: 'South Carolina - SC',
	[States.SD]: 'South Dakota - SD',
	[States.TN]: 'Tennessee - TN',
	[States.TX]: 'Texas - TX',
	[States.UT]: 'Utah - UT',
	[States.VT]: 'Vermont - VT',
	[States.VA]: 'Virginia - VA',
	[States.WA]: 'Washington - WA',
	[States.WV]: 'West Virginia - WV',
	[States.WI]: 'Wisconsin - WI',
	[States.WY]: 'Wyoming - WY',
};
