import { useCallback, useEffect, useState } from 'react';

import { ImageSasUrlResponse } from 'api/models/responses/photos/imageSasUrl';
import { CloudPhotoType } from 'api/models/responses/photos/cloudPhotoType';
import PhotoService from 'api/services/PhotoService';
import AlbumService from 'api/services/AlbumService';

import { removeDomainFromUrl } from 'utils/general/removeDomainFromUrl';
import { prepareSasUrl } from 'utils/photos/prepareSasUrl';

import { photosBlobStorageLowResUrl } from 'constants/images/blobUrls';

import { IAlbumUI } from '../components/Albums/types';

interface ISasUrls {
	firstSasUrl: ImageSasUrlResponse;
	secondSasUrl: ImageSasUrlResponse;
	thirdSasUrl: ImageSasUrlResponse;
}

interface IUseAlbumsResult {
	isPending: boolean;
	albumsUI: IAlbumUI[];
	setAlbumsUI: (albumsUI: IAlbumUI[]) => void;
	setUpdateAlbums: (updateAlbums: boolean) => void;
}

export const useAlbums = (
	projectKey: number,
	isCall?: boolean
): IUseAlbumsResult => {
	const [albumsUI, setAlbumsUI] = useState<IAlbumUI[]>([]);

	const [updateAlbums, setUpdateAlbums] = useState(true);
	const [isPending, setIsPending] = useState(false);

	const getAlbumsUI = useCallback(async () => {
		try {
			if (!projectKey || !updateAlbums || !isCall) return;

			setIsPending(true);

			const albums = await AlbumService.getAlbums(projectKey);

			if (albums) {
				const sasUrlsPromises: Promise<ISasUrls | null>[] = albums.map(
					async ({ triplePhotos }) => {
						if (!triplePhotos) return null;

						const { firstPhoto, secondPhoto, thirdPhoto } = triplePhotos;

						const photoType = CloudPhotoType.SmallThumbnail;

						return {
							firstSasUrl:
								firstPhoto &&
								(await PhotoService.getImageSasUrl(firstPhoto, photoType)),
							secondSasUrl:
								secondPhoto &&
								(await PhotoService.getImageSasUrl(secondPhoto, photoType)),
							thirdSasUrl:
								thirdPhoto &&
								(await PhotoService.getImageSasUrl(thirdPhoto, photoType)),
						};
					}
				);

				const sasUrlsResponse = await Promise.all(sasUrlsPromises);

				const preparedSmallThumbnail = (sasUrl: string) =>
					`${photosBlobStorageLowResUrl}${removeDomainFromUrl(sasUrl)}`;

				const albumsUIMapped: IAlbumUI[] = albums.map((album, index) => {
					const smallThumbnailSasUrls = sasUrlsResponse[index];

					const firstPreparedSasUrl = prepareSasUrl(
						CloudPhotoType.SmallThumbnail,
						smallThumbnailSasUrls?.firstSasUrl
					);

					const secondPreparedSasUrl = prepareSasUrl(
						CloudPhotoType.SmallThumbnail,
						smallThumbnailSasUrls?.secondSasUrl
					);

					const thirdPreparedSasUrl = prepareSasUrl(
						CloudPhotoType.SmallThumbnail,
						smallThumbnailSasUrls?.thirdSasUrl
					);

					const firstSmallThumbnailUrl =
						preparedSmallThumbnail(firstPreparedSasUrl);

					const secondSmallThumbnailUrl =
						preparedSmallThumbnail(secondPreparedSasUrl);

					const thirdSmallThumbnailUrl =
						preparedSmallThumbnail(thirdPreparedSasUrl);

					return {
						...album,
						firstSmallThumbnailUrl,
						secondSmallThumbnailUrl,
						thirdSmallThumbnailUrl,
					};
				});

				setAlbumsUI(albumsUIMapped);
			}
		} catch (error) {
			console.log(error);
		}

		setUpdateAlbums(false);
		setIsPending(false);
	}, [isCall, projectKey, updateAlbums]);

	useEffect(() => {
		void getAlbumsUI();
	}, [getAlbumsUI]);

	return { albumsUI, setAlbumsUI, isPending, setUpdateAlbums };
};
