import { UnitTypes } from 'api/models/responses/general/unitTypes';

import { IGeneralPriceListOption } from '../../generalPriceListOption';

export enum ChargeOptionTypes {
	Always = 'Always',
	AfterXDaysAfterPublish = 'AfterXDaysAfterPublish',
	AfterXDaysAfterDateOfEvent = 'AfterXDaysAfterDateOfEvent',
}

export interface IAdditionalChargeOption extends IGeneralPriceListOption {
	amount: number;
	daysAmount: number;
	chargeUnitType: UnitTypes;
	chargeOptionType: ChargeOptionTypes;
	priceListStudioFulfilledKey: number;
	priceListStudioFulfilledAdditionalChargeOptionKey: number;
}
