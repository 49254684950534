import { Column, useTable } from 'react-table';

interface ITableProps<Data extends {}> {
	data: Data[];
	isEqual?: boolean;
	className?: string;
	columns: Column<Data>[];
}

export const Table = <T extends object>({
	data,
	isEqual,
	columns,
	className,
}: ITableProps<T>) => {
	const { rows, headerGroups, getTableProps, getTableBodyProps, prepareRow } =
		useTable<T>({ data, columns });
	const colIndex = 10;
	const colCountIndex = 10 / columns.length;

	const calculateColumnWidth = (index: number) => ({
		width: `${
			100 / columns.length +
			(index === 0 ? colIndex - colCountIndex : -colCountIndex)
		}%`,
		maxWidth: `${
			100 / columns.length +
			(index === 0 ? colIndex - colCountIndex : -colCountIndex)
		}%`,
	});

	const calculateEqualColumnWidth = () => ({
		width: `${100 / columns.length}%`,
		maxWidth: `${100 / columns.length}%`,
	});

	const getStyles = (index: number) =>
		isEqual ? calculateEqualColumnWidth() : calculateColumnWidth(index);

	return (
		<table className={className} {...getTableProps()}>
			<thead>
				{headerGroups.map(({ headers, getHeaderGroupProps }) => {
					const { key: trKey, ...restHeaderGroupProps } = getHeaderGroupProps();

					return (
						<tr key={trKey} {...restHeaderGroupProps}>
							{headers.map(({ getHeaderProps, render, Header }, index) => {
								const { key: thKey, ...restHeaderProps } = getHeaderProps();

								const title = typeof Header === 'string' ? Header : undefined;

								return (
									<th
										key={thKey}
										title={title}
										{...restHeaderProps}
										style={getStyles(index)}
									>
										{render('Header')}
									</th>
								);
							})}
						</tr>
					);
				})}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row) => {
					prepareRow(row);

					const { key: rowKey, ...restRowProps } = row.getRowProps();

					return (
						<tr key={rowKey} {...restRowProps}>
							{row.cells.map(({ getCellProps, render, value }, index) => {
								const { key: cellKey, ...restCellProps } = getCellProps();

								const title =
									typeof value === 'string' || typeof value === 'number'
										? value.toString()
										: undefined;

								return (
									<td
										key={cellKey}
										{...restCellProps}
										style={getStyles(index)}
										title={title}
									>
										{render('Cell')}
									</td>
								);
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

Table.defaultProps = {
	isEqual: false,
};
