import cn from 'classnames';

import { IPriceListsTab } from 'types/priceLists/priceListsTab';

interface IPriceListsTabsProps<Tabs> {
	activeTab: Tabs;
	className: string;
	tabsList: IPriceListsTab<Tabs>[];
	setActiveTab: (activeTab: Tabs) => void;
}

export const PriceListsTabs = <Tabs extends string>({
	tabsList,
	activeTab,
	className,
	setActiveTab,
}: IPriceListsTabsProps<Tabs>) => {
	const TabsList = tabsList.map(({ id, title, disabled }) => (
		<li
			key={id}
			onClick={() => setActiveTab(id)}
			className={cn('price-header-item', {
				'is-disabled': disabled,
				'is-active': activeTab === id,
			})}
		>
			{title}
		</li>
	));

	return <ul className={className}>{TabsList}</ul>;
};
