import { Formik, FormikHelpers, useFormikContext } from 'formik';
import { FC, useCallback, useEffect, useState } from 'react';

import { ISendTestBroadcastBody } from 'api/models/requests/broadcast/sendTestBroadcast';
import { IActiveStudioProject } from 'api/models/responses/projects/activeStudioProject';
import BroadcastService from 'api/services/BroadcastService';
import ProjectsService from 'api/services/ProjectsService';

import { validateSendToEmails } from 'utils/validations/broadcasts/validateSendToEmails';
import { validateSendToPhones } from 'utils/validations/broadcasts/validateSendToPhones';

import { TextEditorRef } from 'components/TextEditor/types';
import { ToggleSection } from 'components/ToggleSection';

import { testBroadcastSelectOption } from 'constants/broadcasts/selectOptions/testBroadcastSelectOption';
import { useToggleSection } from 'hooks/useToggleSection';
import { ISelectOption } from 'types/ui/select';

import { sendTestBroadcastValidationSchema } from './validation';
import { SendTestBroadcastForm } from './SendTestBroadcastForm';
import { IBroadcastValues } from '../../..';

export type SendTestBroadcastValues = Omit<
	ISendTestBroadcastBody,
	'isUsingTestData'
>;

interface ISendTestBroadcastProps {
	editorRef: TextEditorRef;
	disableSendTest: boolean;
}

export const SendTestBroadcast: FC<ISendTestBroadcastProps> = ({
	editorRef,
	disableSendTest,
}) => {
	const [activeStudioProjects, setActiveStudioProjects] = useState<
		IActiveStudioProject[]
	>([]);

	const { values, validateForm } = useFormikContext<IBroadcastValues>();

	const { isOpen, handleToggle } = useToggleSection(false);

	const emailMessage = editorRef.current?.editor?.data.get() || '';

	const initialValues: SendTestBroadcastValues = {
		emailMessage,
		sendToPhoneNumber: '',
		sendToEmailAddress: '',
		textMessage: values.textMessage,
		emailSubject: values.emailSubject,
		projectKey: testBroadcastSelectOption.value,
	};

	const getActiveStudioProjects = useCallback(async () => {
		try {
			const data = await ProjectsService.getActiveStudioProjects();

			if (!data) return;

			setActiveStudioProjects(data);
		} catch (error) {
			console.log(error);
		}
	}, []);

	const onSubmit = async (
		sendTestValues: SendTestBroadcastValues,
		{ setErrors }: FormikHelpers<SendTestBroadcastValues>
	) => {
		const broadcastErrors = await validateForm();

		const isValid = !Object.keys(broadcastErrors).length;

		if (!isValid) return;

		const sendToEmailAddressError = validateSendToEmails(
			sendTestValues.sendToEmailAddress
		);
		const sendToPhoneNumberError = validateSendToPhones(
			sendTestValues.sendToPhoneNumber
		);

		if (sendToPhoneNumberError || sendToEmailAddressError) {
			return setErrors({
				sendToPhoneNumber: sendToPhoneNumberError,
				sendToEmailAddress: sendToEmailAddressError,
			});
		}

		const isTestBroadcast =
			sendTestValues.projectKey === testBroadcastSelectOption.value;

		const sendTestBroadcastBody: ISendTestBroadcastBody = {
			...sendTestValues,
			emailMessage,
			isUsingTestData: isTestBroadcast,
			projectKey: isTestBroadcast ? null : sendTestValues.projectKey,
		};

		try {
			await BroadcastService.sendTestBroadcast(sendTestBroadcastBody);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		void getActiveStudioProjects();
	}, [getActiveStudioProjects]);

	const selectOptions: ISelectOption[] = [
		testBroadcastSelectOption,
		...activeStudioProjects.map(({ key, name }) => ({
			label: name,
			value: key,
		})),
	];

	return (
		<div className="campaign-toggle-section">
			<ToggleSection
				isOpen={isOpen}
				title="Send a Test"
				handleToggle={handleToggle}
				className="campaign-section-header"
			>
				<Formik
					enableReinitialize
					onSubmit={onSubmit}
					initialValues={initialValues}
					validationSchema={sendTestBroadcastValidationSchema}
				>
					<SendTestBroadcastForm
						selectOptions={selectOptions}
						disableSendTest={disableSendTest}
					/>
				</Formik>
			</ToggleSection>
		</div>
	);
};
