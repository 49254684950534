import { Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';

import PriceListsStudioService from 'api/services/PriceListsService/studio/PriceListsStudioService';
import PriceListsImagoService from 'api/services/PriceListsService/imago/PriceListsImagoService';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/errors/errorResponse';

import { PriceListFulfillment } from 'constants/priceLists/priceListFulfillment';
import { ROUTES } from 'constants/ROUTES';

import { getProcessedErrors } from 'utils/errors/getProcessedErrors';

import { createPriceListValidationScheme } from './NewPriceListForm/validation';
import { NewPriceListForm } from './NewPriceListForm';

export interface ICreatePriceListValues {
	name: string;
	fulfillment: PriceListFulfillment | string;
}

export const CreateNewPriceList: FC = () => {
	const navigate = useNavigate();

	const initialValues: ICreatePriceListValues = {
		name: '',
		fulfillment: '',
	};

	const onSubmit = async (
		values: ICreatePriceListValues,
		{ setErrors }: FormikHelpers<ICreatePriceListValues>
	) => {
		try {
			const { name, fulfillment } = values;

			if (fulfillment === PriceListFulfillment.Studio) {
				const data = await PriceListsStudioService.createPriceListStudio({
					name,
				});

				if (!data) return;

				navigate(
					`${ROUTES.PRICE_LISTS.PRICE_LISTS_STUDIO}/${data.priceListStudioFulfilledKey}`
				);
			}

			if (fulfillment === PriceListFulfillment.Imago) {
				const data = await PriceListsImagoService.createPriceListImago({
					name,
				});

				if (!data) return;

				navigate(
					`${ROUTES.PRICE_LISTS.PRICE_LISTS_IMAGO}/${data.priceListImagoFulfilledKey}`
				);
			}
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (
				type === ErrorTypes.BusinessError ||
				type === ErrorTypes.ValidationError
			) {
				const updatedErrors = getProcessedErrors(values, errors);

				setErrors(updatedErrors);
			}
		}
	};

	return (
		<div className="modal-body">
			<Formik
				onSubmit={onSubmit}
				initialValues={initialValues}
				validationSchema={createPriceListValidationScheme}
			>
				<NewPriceListForm />
			</Formik>
		</div>
	);
};
