import { useFormikContext } from 'formik';
import { FC, RefObject } from 'react';

import { IChangeSequencePayload } from 'hooks/useDragAndDrop';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { getInitialSubjectFormQuestion } from 'utils/subjectFormQuestions/getInitialSubjectFormQuestion';
import { IClearErrorParams, IHandleUpdateParams } from 'pages/Projects/types';
import { SubjectFormQuestions } from 'components/SubjectFormQuestions';
import {
	changeSequence,
	createSubjectFormQuestion,
	deleteSubjectFormQuestion,
	updateSubjectFormQuestion,
	subjectFormQuestionsSelector,
	setSubjectFormQuestionFiledError,
} from 'store/projects';

import { IJourneyPresetValues } from '../../../types';

interface CreatePresetFormSectionProps {
	parentRef: RefObject<HTMLDivElement>;
}

export const JourneyPresetFormQuestions: FC<CreatePresetFormSectionProps> = ({
	parentRef,
}) => {
	const { submitCount } = useFormikContext<IJourneyPresetValues>();

	const subjectFormQuestions = useAppSelector(subjectFormQuestionsSelector);

	const dispatch = useAppDispatch();

	const handleDelete = (localKey: string) => {
		dispatch(deleteSubjectFormQuestion(localKey));
	};

	const handleUpdate = (params: IHandleUpdateParams) => {
		dispatch(updateSubjectFormQuestion({ ...params, validate: !!submitCount }));
	};

	const handleCreate = () => {
		const newFormQuestion = getInitialSubjectFormQuestion(
			subjectFormQuestions.length
		);

		dispatch(createSubjectFormQuestion(newFormQuestion));
	};

	const handleChangeSequence = (params: IChangeSequencePayload) => {
		dispatch(changeSequence(params));
	};

	const clearError = (params: IClearErrorParams) => {
		dispatch(
			setSubjectFormQuestionFiledError({
				...params,
				message: '',
			})
		);
	};

	return (
		<SubjectFormQuestions
			title="Form"
			parentRef={parentRef}
			clearError={clearError}
			handleUpdate={handleUpdate}
			handleCreate={handleCreate}
			deleteFormRow={handleDelete}
			changeSequence={handleChangeSequence}
			subjectFormQuestions={subjectFormQuestions}
		/>
	);
};
