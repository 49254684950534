import { object, string, AnySchema } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	projectNameValidationRegEx,
	projectNameValidationMessage,
} from 'constants/projects/validation/generalProjectFields';

import { ICreateJourneyPresetValues } from 'store/journeyPresets/types';

export const createJourneyPresetScheme = object().shape<
	Record<keyof ICreateJourneyPresetValues, AnySchema>
>({
	journey: string(),
	journeyName: string()
		.required(requiredFieldMessage)
		.matches(projectNameValidationRegEx, projectNameValidationMessage),
	projectType: string().required(requiredFieldMessage),
});
