import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { ISelectOption } from 'types/ui/select';

import { SendTestBroadcastValues } from '..';

interface ISendTestBroadcastFormProps {
	disableSendTest: boolean;
	selectOptions: ISelectOption[];
}

export const SendTestBroadcastForm: FC<ISendTestBroadcastFormProps> = ({
	selectOptions,
	disableSendTest,
}) => {
	const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
		useFormikContext<SendTestBroadcastValues>();

	const handleChangeProjectKey = (option: SingleValue<ISelectOption>) => {
		if (!option) return;

		void setFieldValue('projectKey', option.value);
	};

	return (
		<div className="campaign-section-wrapper campaign-section-test">
			<SelectComponent
				value={values.projectKey}
				disabled={disableSendTest}
				selectOptions={selectOptions}
				onChange={handleChangeProjectKey}
				label="Fill keywords using project"
				selectPlaceholder="Project keyword"
			/>
			<label className="label">Send to Email Address</label>
			<InputGrid
				row
				required={false}
				className="autorow"
				id="sendToEmailAddress"
				disabled={disableSendTest}
				handleChange={handleChange}
				label="Send to Email Address"
				error={errors.sendToEmailAddress}
				value={values.sendToEmailAddress}
				placeholder="Send to Email Address"
				touched={touched.sendToEmailAddress}
			/>
			<label className="label">Send to Phone Number</label>
			<InputGrid
				row
				required={false}
				className="autorow"
				id="sendToPhoneNumber"
				disabled={disableSendTest}
				handleChange={handleChange}
				label="Send to Phone Number"
				value={values.sendToPhoneNumber}
				error={errors.sendToPhoneNumber}
				placeholder="Send to Phone Number"
				touched={touched.sendToPhoneNumber}
			/>
			<Button
				value="Send Test"
				className="btn-primary"
				disabled={disableSendTest}
				handleClick={handleSubmit}
			/>
		</div>
	);
};
