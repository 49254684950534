import { DragEvent, FC } from 'react';

import { DeleteControlColumn } from 'pages/PriceLists/components/DeleteControlColumn';
import { BurgerColumn } from 'pages/PriceLists/components/BurgerColumn';
import { NameColumn } from 'pages/PriceLists/components/NameColumn';
import {
	IAdditionalChargeOptionUI,
	IDeleteAdditionalChargeOption,
	IUpdateAdditionalChargeOption,
	IClearAdditionalChargeOptionError,
} from 'pages/PriceLists/types/studio/priceListOptions/additionalChargeOptions';

import { IChangeSequencePayload, useDragAndDrop } from 'hooks/useDragAndDrop';

import { AmountColumn } from './components/AmountColumn';
import { OptionColumn } from './components/OptionColumn';

type UpdateAdditionalOptionHandler = (
	params: IUpdateAdditionalChargeOption
) => Promise<void>;

type ClearAdditionalChargeOptionError = (
	params: IClearAdditionalChargeOptionError
) => void;

type DeleteAdditionalChargeOptionHandler = (
	params: IDeleteAdditionalChargeOption
) => Promise<void>;

type ChangeAdditionalChargeOptionsSequence = (
	params: IChangeSequencePayload
) => void;

export interface IGeneralColumnProps {
	sequence: number;
	additionalChargeOptionKey?: number;
	updateAdditionalChargeOption: UpdateAdditionalOptionHandler;
	clearAdditionalChargeOptionError: ClearAdditionalChargeOptionError;
}

interface IAdditionalChargeOptionProps {
	additionalChargeOptionUI: IAdditionalChargeOptionUI;
	updateAdditionalChargeOption: UpdateAdditionalOptionHandler;
	deleteAdditionalChargeOption: DeleteAdditionalChargeOptionHandler;
	clearAdditionalChargeOptionError: ClearAdditionalChargeOptionError;
	changeAdditionalChargeOptionsSequence: ChangeAdditionalChargeOptionsSequence;
}

export const AdditionalChargeOption: FC<IAdditionalChargeOptionProps> = ({
	additionalChargeOptionUI,
	updateAdditionalChargeOption,
	deleteAdditionalChargeOption,
	clearAdditionalChargeOptionError,
	changeAdditionalChargeOptionsSequence,
}) => {
	const {
		name,
		amount,
		errors,
		sequence,
		daysAmount,
		chargeUnitType,
		chargeOptionType,
		priceListStudioFulfilledAdditionalChargeOptionKey:
			additionalChargeOptionKey,
	} = additionalChargeOptionUI;

	const {
		name: nameError,
		amount: amountError,
		daysAmount: daysAmountError,
	} = errors;

	const additionalChargeOptionKeyString =
		additionalChargeOptionKey?.toString() || '';

	const {
		draggable,
		handleDrop,
		handleDragEnd,
		handleDragOver,
		handleDragStart,
		handleMouseDown,
	} = useDragAndDrop([], changeAdditionalChargeOptionsSequence);

	const onDragStart = (e: DragEvent<HTMLDivElement>) => {
		handleDragStart(e, additionalChargeOptionKeyString);
	};

	const updateName = (value: string, validationMessage: string) => {
		void updateAdditionalChargeOption({
			value,
			sequence,
			fieldKey: 'name',
			validationMessage,
			additionalChargeOptionKey,
		});
	};

	const clearNameError = () => {
		if (!nameError) return;

		clearAdditionalChargeOptionError({ sequence, fieldKey: 'name' });
	};

	const handleDelete = () => {
		void deleteAdditionalChargeOption({
			sequence,
			additionalChargeOptionKey,
		});
	};

	const generalColumnProps: IGeneralColumnProps = {
		sequence,
		additionalChargeOptionKey,
		updateAdditionalChargeOption,
		clearAdditionalChargeOptionError,
	};

	return (
		<tbody
			onDrop={handleDrop}
			draggable={draggable}
			onDragStart={onDragStart}
			onDragEnd={handleDragEnd}
			onDragOver={handleDragOver}
			data-drag-sequence={sequence}
			className="price-option-tbody"
			id={additionalChargeOptionKeyString}
		>
			<tr>
				<BurgerColumn showBurger handleMouseDown={handleMouseDown} />
				<NameColumn
					name={name}
					nameError={nameError}
					updateName={updateName}
					clearError={clearNameError}
				/>
				<AmountColumn
					{...generalColumnProps}
					amount={amount}
					amountError={amountError}
					chargeUnitType={chargeUnitType}
				/>
				<OptionColumn
					{...generalColumnProps}
					daysAmount={daysAmount}
					daysAmountError={daysAmountError}
					chargeOptionType={chargeOptionType}
				/>
				<DeleteControlColumn handleDelete={handleDelete} />
			</tr>
		</tbody>
	);
};
