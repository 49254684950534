import { FC, MouseEvent } from 'react';
import cn from 'classnames';

interface ISubNavItemProps {
	subPath: string;
	subTitle: string;
	breadcrumbPath: string;
	handleSubNavItemClick: (
		e: MouseEvent<HTMLLIElement>,
		subPath: string
	) => void;
}

export const SubNavItem: FC<ISubNavItemProps> = ({
	subPath,
	subTitle,
	breadcrumbPath,
	handleSubNavItemClick,
}) => {
	const handleClick = (e: MouseEvent<HTMLLIElement>) => {
		handleSubNavItemClick(e, subPath);
	};

	const active = breadcrumbPath === subPath;

	return (
		<li
			key={subTitle}
			onClick={handleClick}
			className={cn('subnav-item', {
				active,
			})}
		>
			<span className="subnav-item-title">{subTitle}</span>
		</li>
	);
};
