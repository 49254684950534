import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import { FC } from 'react';

import { Button } from 'components/FormControls/Button';
import { Table } from 'components/Table';

import { IActiveChildItem } from 'api/models/responses/organizations/deleteOrganization';
import { normalizeDate } from 'utils/ui/normalizeDate';
import { ROUTES } from 'constants/ROUTES';

interface IDeleteOrganizationErrorProps {
	hideModal: () => void;
	activeChildItems: IActiveChildItem[];
}

export const DeleteOrganizationReject: FC<IDeleteOrganizationErrorProps> = ({
	hideModal,
	activeChildItems,
}) => {
	const columns: Column<IActiveChildItem>[] = [
		{
			Header: () => <div className="tr-arrow">Name</div>,
			accessor: 'projectName',
			Cell: ({ row }: CellProps<IActiveChildItem>) => {
				const { projectName, projectKey } = row.original;

				return (
					<Link
						title={projectName}
						className="card-table-link"
						to={`${ROUTES.PROJECTS.PROJECTS}/${projectKey}`}
					>
						{projectName}
					</Link>
				);
			},
		},
		{
			Header: 'Expiration Date',
			accessor: 'expireDate',
			Cell: ({
				value,
			}: CellProps<IActiveChildItem, IActiveChildItem['expireDate']>) => (
				<>{normalizeDate(value)}</>
			),
		},
	];

	return (
		<div className="org-delete-modal">
			<div className="price-table">
				<Table<IActiveChildItem> columns={columns} data={activeChildItems} />
			</div>
			<Button
				value="OK"
				handleClick={hideModal}
				className="org-modal-btn btn-primary"
			/>
		</div>
	);
};
