import { phoneErrorMessage } from 'constants/general/validation/phoneE164USValidation';

import {
	isValidPhone,
	convertUSPhoneToE164Format,
} from '../general/validatePhone';

export const validateSendToPhones = (phones: string) => {
	const isValidPhones = phones
		.trim()
		.split(',')
		.filter((phone) => phone)
		.map((phone) => {
			const trimPhone = phone.trim();

			const E164PhoneFormat = convertUSPhoneToE164Format(trimPhone);

			return isValidPhone(E164PhoneFormat);
		})
		.every((phone) => phone);

	return isValidPhones ? '' : phoneErrorMessage;
};
