export const fontFamily = {
	options: [
		'default',
		'Arial, Helvetica, sans-serif',
		'Courier New, Courier, monospace',
		'Georgia, serif',
		'Lucida Sans Unicode, Lucida Grande, sans-serif',
		'Tahoma, Geneva, sans-serif',
		'Times New Roman, Times, serif',
		'Trebuchet MS, Helvetica, sans-serif',
		'Verdana, Geneva, sans-serif',
		"Montserrat', sans-serif",
	],
	supportAllValues: true,
};

export const fontFamilySelectOptions = fontFamily.options
	.slice(1)
	.map((family) => {
		const commaIndex = family.indexOf(',');
		const label = family.slice(0, commaIndex);

		return {
			label,
			value: family,
		};
	});
