import { FC } from 'react';

import { InputGrid } from 'components/FormControls/InputGrid';
import { Checkbox } from 'components/FormControls/Checkbox';

export const MultiPoseOwnPackageOptions: FC = () => (
	<div className="price-multi-wrapper">
		<span className="price-multi-header">
			<b>Multi-Pose Packages Options</b>
			<i>(coming soon)</i>For a package that can contain multiple poses
		</span>
		<div className="price-multi-check">
			<Checkbox
				disabled
				checked={false}
				handleChange={() => {}}
				label="Allow multiple poses in a package"
			/>
			<span className="price-multi-price">
				Price to charge per extra pose over max
				<InputGrid
					disabled
					required={false}
					placeholder="$0.00"
					className="autorow"
					handleChange={() => {}}
				/>
			</span>
		</div>
	</div>
);
