import { INewOrder } from 'api/models/responses/orders/newOrder';
import { ordersUrls } from 'constants/apiUrls/ordersUrls';

import { BaseService } from '../BaseService';

class OrderService extends BaseService {
	async getNewOrders(): Promise<INewOrder[] | null> {
		return this.get<INewOrder[]>(ordersUrls.newOrders);
	}
}

export default new OrderService();
