import { object, string } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import { maxEmailSubjectLength } from 'constants/broadcasts/validation';

import { getMaxLengthMessage } from 'utils/validations/general/getMaxLengthMessage';

export const getSendSpecialBroadcastScheme = (
	validateEmailSubject: boolean,
	validateTextMessage: boolean
) =>
	object().shape({
		emailSubject: validateEmailSubject
			? string()
					.required(requiredFieldMessage)
					.max(
						maxEmailSubjectLength,
						getMaxLengthMessage(maxEmailSubjectLength)
					)
			: string().notRequired(),
		textMessage: validateTextMessage
			? string().required(requiredFieldMessage)
			: string().notRequired(),
		sendToEmailAddresses: string().notRequired(),
		sendToPhoneNumbers: string().notRequired(),
	});
