import { useFormikContext } from 'formik';
import { FC } from 'react';

import { IUpdateSalePayload } from 'components/Sales/Sale/types';
import { Sale } from 'components/Sales/Sale';

import { getPriceListsSelectOptions } from 'utils/priceLists/getPriceListsSelectOptions';
import { salesPriceListsSelectOptionsSelector } from 'store/projects';
import { IPresetSale } from 'api/models/responses/presets/presets';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { salesUI } from 'constants/projects/salesUI';

import { IJourneyPresetValues } from '../../../types';
import { SaleTypes } from 'api/models/responses/projects/sales';

interface IPresetSalesProps {
	presetSales: IPresetSale[];
}

export const JourneyPresetSales: FC<IPresetSalesProps> = ({ presetSales }) => {
	const { values, errors, touched, setFieldValue } =
		useFormikContext<IJourneyPresetValues>();

	const salesPriceListsSelectOptions = useAppSelector(
		salesPriceListsSelectOptionsSelector
	);

	const updatePresetSale = ({ key, value, saleType }: IUpdateSalePayload) => {
		const updatedPresetSales = values.presetSales.map((sale) =>
			sale.saleType === saleType ? { ...sale, [key]: value } : sale
		);

		void setFieldValue('presetSales', updatedPresetSales);
	};

	const SalesList = values.presetSales.map((saleValues, index) => {
		const saleType = saleValues.saleType;
		const presetSale = presetSales[index];

		if (!saleType) return null;

		const salePriceListsSelectOptions = !presetSale
			? salesPriceListsSelectOptions[index]
			: getPriceListsSelectOptions(presetSale.allowedPriceLists);

		const saleUI = saleType && salesUI[saleType];

		const saleErrors = errors.presetSales?.[index];
		const saleTouched = touched.presetSales?.[index];

		const typedSaleErrors = typeof saleErrors === 'object' ? saleErrors : {};
		const typedSaleTouched = typeof saleTouched === 'object' ? saleTouched : {};

		const disabledSaleExpiresInDays =
			saleType === SaleTypes.JourneyALatePresale;

		return (
			<Sale
				saleUI={saleUI}
				values={saleValues}
				saleType={saleType}
				errors={typedSaleErrors}
				key={saleValues.saleType}
				touched={typedSaleTouched}
				updateSale={updatePresetSale}
				priceListsSelectOptions={salePriceListsSelectOptions}
				disabledSaleExpiresInDays={disabledSaleExpiresInDays}
			/>
		);
	});

	return <>{SalesList}</>;
};
