import { FC } from 'react';

import { MultiPoseOwnPackageOptions } from 'pages/PriceLists/components/MultiPoseOwnPackageOptions';
import { useToggleSection } from 'hooks/useToggleSection';
import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';

export const PackageOptionsContainer: FC = () => {
	const { isOpen, handleToggle } = useToggleSection(true);

	return (
		<ToggleSection
			isOpen={isOpen}
			title="Package Options"
			handleToggle={handleToggle}
			className="price-search-container price-sub-search"
		>
			<MultiPoseOwnPackageOptions />
			<Button
				disabled
				className="price-add-type btn-secondary"
				value="+ Add Another Type of Package Option"
			/>
		</ToggleSection>
	);
};
