import { v4 as uuidv4 } from 'uuid';

import { subjectFormQuestionInitialError } from 'constants/projects/subjectFormQuestions/subjectFormQuestionInitialError';
import { ISubjectFormQuestionUI } from 'components/SubjectFormQuestions/types';

export const getInitialSubjectFormQuestion = (
	sequence: number
): ISubjectFormQuestionUI => ({
	sequence,
	options: '',
	formQuestion: '',
	dataFieldType: null,
	localKey: uuidv4(),
	isAnswerRequired: true,
	error: subjectFormQuestionInitialError,
});
