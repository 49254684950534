import { Link } from 'react-router-dom';
import cn from 'classnames';
import { FC } from 'react';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { breadcrumbsSelector } from 'store/breadcrumbs';

export const HeaderBreadcrumbs: FC = () => {
	const breadcrumbs = useAppSelector(breadcrumbsSelector);

	const [parentBreadcrumb, ...restBreadcrumbs] = breadcrumbs;

	const SubBreadcrumbsList = restBreadcrumbs.map(
		({ title, path, isActive }) => (
			<li
				key={path}
				className={cn('breadcrumb-list-item', {
					'header-active': isActive,
				})}
			>
				<Link to={path} className="breadcrumb-item" title={title}>
					{title}
				</Link>
			</li>
		)
	);

	return (
		<>
			<h1
				className={cn('header-title', {
					'header-active': parentBreadcrumb?.isActive,
				})}
			>
				{parentBreadcrumb?.title}
			</h1>
			{!!restBreadcrumbs.length && (
				<ul className="breadcrumb">{SubBreadcrumbsList}</ul>
			)}
		</>
	);
};
