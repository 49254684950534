interface INormalizeDigitConfig {
	value?: number;
	isPrice?: boolean;
	minimumFractionDigits?: number;
	maximumFractionDigits?: number;
}

export const normalizeDigit = ({
	value = 0,
	isPrice = false,
	minimumFractionDigits = 0,
	maximumFractionDigits = 2,
}: INormalizeDigitConfig) => {
	const firstChar = isPrice ? '$' : '';

	return `${firstChar}${new Intl.NumberFormat('en-US', {
		minimumFractionDigits,
		maximumFractionDigits,
	}).format(value)}`;
};
