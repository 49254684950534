import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IArchivedOrganization } from 'api/models/responses/organizations/archivedOrganization';
import { IOrganizationDetails } from 'api/models/responses/organizations/organizationDetails';
import { IActiveOrganization } from 'api/models/responses/organizations/activeOrganization';

import { IOrganizationState } from './types';

const initialState: IOrganizationState = {
	isOrgPending: false,
	activeOrganizations: [],
	archivedOrganizations: [],
	createdOrganization: null,
};

const organizationsSlice = createSlice({
	name: 'organizations',
	initialState,
	reducers: {
		setActiveOrganizations: (
			state,
			{ payload }: PayloadAction<IActiveOrganization[]>
		) => {
			state.activeOrganizations = payload;
		},

		setArchivedOrganizations: (
			state,
			{ payload }: PayloadAction<IArchivedOrganization[]>
		) => {
			state.archivedOrganizations = payload;
		},

		setCreatedOrganization: (
			state,
			{ payload }: PayloadAction<IOrganizationDetails>
		) => {
			state.createdOrganization = payload;
		},

		setIsOrgPending: (state, { payload }: PayloadAction<boolean>) => {
			state.isOrgPending = payload;
		},
	},
});

export const organizationsReducer = organizationsSlice.reducer;
export const {
	setIsOrgPending,
	setActiveOrganizations,
	setCreatedOrganization,
	setArchivedOrganizations,
} = organizationsSlice.actions;
