import { useFormikContext } from 'formik';
import { FC } from 'react';

import { InputGrid } from 'components/FormControls/InputGrid';
import { ToggleSection } from 'components/ToggleSection';

import { useToggleSection } from 'hooks/useToggleSection';

import { IGeneralBroadcastProps, ISendBroadcastValues } from '../../types';

export const AlsoInclude: FC<IGeneralBroadcastProps> = ({ broadcastInfo }) => {
	const { values, errors, touched, handleChange } =
		useFormikContext<ISendBroadcastValues>();

	const { isOpen, handleToggle } = useToggleSection(true);

	const disableSendToPhoneNumber =
		!values.sendByPhone || !broadcastInfo?.hasVerifiedPhoneNumber;

	return (
		<ToggleSection
			isOpen={isOpen}
			title="Also Include"
			handleToggle={handleToggle}
			className="org-details-header"
		>
			<div className="broadcast-form">
				<InputGrid
					row
					required={false}
					id="sendToEmailAddress"
					handleChange={handleChange}
					label="Send to Email Address"
					disabled={!values.sendByEmail}
					error={errors.sendToEmailAddress}
					value={values.sendToEmailAddress}
					placeholder="Send to Email Address"
					touched={touched.sendToEmailAddress}
					className="preset broadcast-include-input"
				/>
				<InputGrid
					row
					required={false}
					id="sendToPhoneNumber"
					handleChange={handleChange}
					label="Send to Phone Number"
					error={errors.sendToPhoneNumber}
					value={values.sendToPhoneNumber}
					touched={touched.sendToPhoneNumber}
					placeholder="Send to Phone Number"
					disabled={disableSendToPhoneNumber}
					className="preset broadcast-include-input"
				/>
			</div>
		</ToggleSection>
	);
};
