import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const baseOptionsStateSelector = (state: RootState) =>
	state.priceListBaseOptions;

export const shippingOptionsSelector = createSelector(
	baseOptionsStateSelector,
	(state) => state.shippingOptions
);

export const additionalChargeOptionsSelector = createSelector(
	baseOptionsStateSelector,
	(state) => state.additionalChargeOptions
);

export const isPendingBaseOptionsSelector = createSelector(
	baseOptionsStateSelector,
	(state) => state.isPendingBaseOptions
);
