import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { packageOptionTypesSelectOptions } from 'constants/priceLists/studio/selectOptions/packageOptionTypesSelectOptions';
import { initialOwnPackageOptionErrors } from 'constants/priceLists/studio/initialOwnPackageOptionErrors';

import { PackageOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/ownPackageOption';
import OwnPackageOptionsService from 'api/services/PriceListsService/studio/OwnPackageOptionsService';

import { IOwnPackageOptionUI } from 'pages/PriceLists/types/studio/priceListOptions/ownPackageOptions';
import { MultiPoseOwnPackageOptions } from 'pages/PriceLists/components/MultiPoseOwnPackageOptions';

import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Modal, ModalRef } from 'components/Modal';
import { Loader } from 'components/Loader';

import { useToggleSection } from 'hooks/useToggleSection';

import { AddOptionTypeModalBody } from '../AddOptionTypeModalBody';
import { OwnPackageOptions } from './OwnPackageOptions';

type AddedPackageOptionTypes = Record<PackageOptionTypes, boolean>;

const initialAddedPackageOptionTypes: AddedPackageOptionTypes = {
	[PackageOptionTypes.BuildYourOwn]: false,
};

interface IPackageOptionsContainerProps {
	className: string;
	priceListKey?: number;
	isPriceListPending: boolean;
}

export const OwnPackageOptionsContainer: FC<IPackageOptionsContainerProps> = ({
	className,
	priceListKey,
	isPriceListPending,
}) => {
	const [ownPackageOptionsUI, setOwnPackageOptionsUI] = useState<
		IOwnPackageOptionUI[]
	>([]);

	const [addedPackageOptionTypes, setAddedPackageOptionTypes] =
		useState<AddedPackageOptionTypes>(initialAddedPackageOptionTypes);

	const [isPending, setIsPending] = useState(true);

	const addPackageOptionModalRef = useRef<ModalRef>(null);

	const { isOpen, handleToggle } = useToggleSection(true);

	const showAddPackageOptionModal = () => {
		addPackageOptionModalRef?.current?.open();
	};

	const hideAddPackageOptionModal = () => {
		addPackageOptionModalRef?.current?.close();
	};

	const addPackageOptionType = (packageOptionType: PackageOptionTypes) => {
		setAddedPackageOptionTypes((prevState) => ({
			...prevState,
			[packageOptionType]: true,
		}));

		hideAddPackageOptionModal();
	};

	const getOwnPackageOptions = useCallback(async () => {
		if (!priceListKey) return;

		setIsPending(true);

		try {
			const data = await OwnPackageOptionsService.getOwnPackageOptions(
				priceListKey
			);

			if (!data) return;

			const processedOwnPackageOptions: IOwnPackageOptionUI[] = data.map(
				(ownPackageOption) => ({
					...ownPackageOption,
					errors: initialOwnPackageOptionErrors,
				})
			);

			setOwnPackageOptionsUI(processedOwnPackageOptions);
		} catch (error) {
			console.log(error);
		}

		setIsPending(false);
	}, [priceListKey]);

	useEffect(() => {
		void getOwnPackageOptions();
	}, [getOwnPackageOptions]);

	const showBuildYourOwnOptions =
		!!ownPackageOptionsUI.length ||
		addedPackageOptionTypes[PackageOptionTypes.BuildYourOwn];

	const filteredPackageOptionTypesSelectOptions =
		packageOptionTypesSelectOptions.filter(({ value }) => {
			const buildYourOwnOptionsExist =
				value === PackageOptionTypes.BuildYourOwn && showBuildYourOwnOptions;

			if (buildYourOwnOptionsExist) return false;

			return true;
		});

	const disableAddPackageOptionBtn = showBuildYourOwnOptions;

	const showLoader = isPending || isPriceListPending;

	return (
		<>
			<ToggleSection
				isOpen={isOpen}
				className={className}
				title="Package Options"
				handleToggle={handleToggle}
			>
				{showLoader ? (
					<Loader />
				) : (
					<div className="price-container">
						{showBuildYourOwnOptions && (
							<OwnPackageOptions
								priceListKey={priceListKey}
								ownPackageOptionsUI={ownPackageOptionsUI}
								setOwnPackageOptionsUI={setOwnPackageOptionsUI}
							/>
						)}
						<MultiPoseOwnPackageOptions />
						<Button
							disabled={disableAddPackageOptionBtn}
							handleClick={showAddPackageOptionModal}
							className="price-add-type btn-secondary"
							value="+ Add Another Type of Package Option"
						/>
					</div>
				)}
			</ToggleSection>
			<Modal ref={addPackageOptionModalRef} title="Add Type of Package Option">
				<AddOptionTypeModalBody<PackageOptionTypes>
					selectLabel="Package Option"
					btnValue="Add This Package Option"
					addOptionType={addPackageOptionType}
					selectPlaceholder="Select Pose Option Type"
					selectOptions={filteredPackageOptionTypesSelectOptions}
				/>
			</Modal>
		</>
	);
};
