import { FC } from 'react';

interface ToastifyContentProps {
	info?: string;
	title: string;
}

export const ToastifyContent: FC<ToastifyContentProps> = ({ info, title }) => (
	<div className="notif-content">
		<span className="notif-title">{title}</span>
		{info && <span className="notif-info">{info}</span>}
	</div>
);
