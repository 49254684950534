import { JourneyPresetDetails } from 'pages/JourneyPresets/pages/JourneyPresetDetails';
import { CreateJourneyPreset } from 'pages/JourneyPresets/pages/CreateJourneyPreset';
import { JourneyPresets } from 'pages/JourneyPresets/pages/JourneyPresets';

import { ROUTES } from 'constants/ROUTES';
import { CreateJourneyPresetDetails } from 'pages/JourneyPresets/pages/CreateJourneyPresetDetails';

export const journeyPresetRoutesList = [
	{
		path: ROUTES.PRESETS.PRESETS,
		element: <JourneyPresets />,
	},
	{
		path: ROUTES.PRESETS.CREATE_PRESET,
		element: <CreateJourneyPreset />,
	},
	{
		path: ROUTES.PRESETS.PRESET_DETAILS,
		element: <JourneyPresetDetails />,
	},
	{
		path: ROUTES.PRESETS.CREATE_PRESET_DETAILS,
		element: <CreateJourneyPresetDetails />,
	},
];
