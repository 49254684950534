import { FC, useEffect } from 'react';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useDefineDevice } from 'hooks/useDefineDevice';

import { setIsOpen as setIsOpenSidebar } from 'store/sidebar';

import { Sidebar } from './Sidebar';
import { Content } from './Content';
import { Aside } from './Aside';

export const Layout: FC = () => {
	const dispatch = useAppDispatch();

	const { isDesktop } = useDefineDevice();

	useEffect(() => {
		if (isDesktop) return;

		dispatch(setIsOpenSidebar(false));
	}, [isDesktop]);

	return (
		<div className="page-container">
			<Sidebar />
			<Content />
			<Aside />
		</div>
	);
};
