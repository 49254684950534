import { Formik } from 'formik';
import { FC } from 'react';

import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';
import { CampaignsTarget } from 'api/models/responses/campaigns/campaign';
import { ICopyCampaignBody } from 'api/models/requests/campaigns';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { copyCampaignAsync } from 'store/campaigns';

import { CampaignCopyControls } from './components/CampaignCopyControls';
import { CampaignCopyForm } from './components/CampaignCopyForm';
import { copyCampaignValidationSchema } from './validation';

interface ICampaignCopyProps {
	campaignKey: number | null;
	hideCampaignCopyModal: () => void;
}

export const CampaignCopy: FC<ICampaignCopyProps> = ({
	campaignKey,
	hideCampaignCopyModal,
}) => {
	const dispatch = useAppDispatch();

	const initialValues: ICopyCampaignBody = {
		name: '',
		journey: ProjectJourneys.A,
		target: CampaignsTarget.Prospect,
	};

	const onSubmit = async (values: ICopyCampaignBody) => {
		if (!campaignKey) return;

		await dispatch(copyCampaignAsync(campaignKey, values));

		hideCampaignCopyModal();
	};

	return (
		<div className="modal-body">
			<Formik
				onSubmit={onSubmit}
				initialValues={initialValues}
				validationSchema={copyCampaignValidationSchema}
			>
				<>
					<CampaignCopyForm />
					<CampaignCopyControls hideCampaignCopyModal={hideCampaignCopyModal} />
				</>
			</Formik>
		</div>
	);
};
