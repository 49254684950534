import { FC } from 'react';

import { IJourneyTimeline } from 'constants/projects/timeline/journeyTimelines';

interface IProjectTimelineProps
	extends Omit<
		IJourneyTimeline,
		'journeyDate' | 'journeyBlankProjectImage' | 'journeyProjectPresetImage'
	> {
	journeyImage: string;
}

export const JourneyTimeline: FC<IProjectTimelineProps> = ({
	journeyText,
	journeyTitle,
	journeyImage,
	journeySubText,
}) => (
	<>
		<h3 className="project-timeline-title">
			{journeyTitle}
			<span className="project-timeline-subtitle">{journeyText}</span>
			<span className="project-timeline-info">{journeySubText}</span>
		</h3>
		<img src={journeyImage} alt="timeline" className="project-timeline-img" />
	</>
);
