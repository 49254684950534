import { FC } from 'react';

import { useLazyDollarInput } from 'hooks/formControls/useLazyDollarInput';
import { InputField } from 'components/InputField';

interface IRetailValueColumnProps {
	retailValue?: number;
	clearError: () => void;
	retailValueError?: string;
	updateRetailValue: (value: number, validationMessage: string) => void;
}

export const RetailValueColumn: FC<IRetailValueColumnProps> = ({
	clearError,
	retailValue,
	retailValueError,
	updateRetailValue,
}) => {
	const { value, handleBlur, handleChange, handleKeyDown } = useLazyDollarInput(
		{
			clearError,
			initialValue: retailValue || 0,
			handleUpdate: updateRetailValue,
		}
	);

	return (
		<td className="price-option-td">
			<InputField
				touched
				value={value}
				handleBlur={handleBlur}
				error={retailValueError}
				placeholder="Retail Value"
				handleChange={handleChange}
				handleKeyDown={handleKeyDown}
			/>
		</td>
	);
};
