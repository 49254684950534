import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PoseOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/poseOption';
import { IPoseOptionUI } from 'pages/PriceLists/types/studio/priceListOptions/poseOptions';

import {
	IPoseOptionsState,
	GroupedPoseOptions,
	AddedPoseOptionTypes,
	ISetGroupedPoseOptionsPayload,
} from './';

const initialGroupedPoseOptions: GroupedPoseOptions = {
	[PoseOptionTypes.None]: [],
	[PoseOptionTypes.Pose]: [],
	[PoseOptionTypes.Style]: [],
	[PoseOptionTypes.ColorFlavor]: [],
};

const addedPoseOptionTypesInitial: AddedPoseOptionTypes = {
	[PoseOptionTypes.None]: false,
	[PoseOptionTypes.Pose]: false,
	[PoseOptionTypes.Style]: false,
	[PoseOptionTypes.ColorFlavor]: false,
};

const initialState: IPoseOptionsState = {
	groupedPoseOptions: initialGroupedPoseOptions,
	addedPoseOptionTypes: addedPoseOptionTypesInitial,
};

const poseOptionsSlice = createSlice({
	name: 'poseOptions',
	initialState,
	reducers: {
		setPoseOptions: (state, { payload }: PayloadAction<IPoseOptionUI[]>) => {
			const groupedPoseOptions = payload.reduce((acc, poseOption) => {
				const poseOptionType = poseOption.poseOptionType;

				return {
					...acc,
					[poseOptionType]: [...acc[poseOptionType], poseOption],
				};
			}, initialGroupedPoseOptions);

			state.groupedPoseOptions = groupedPoseOptions;
		},

		setGroupedPoseOptions: (
			state,
			{ payload }: PayloadAction<ISetGroupedPoseOptionsPayload>
		) => {
			state.groupedPoseOptions[payload.poseOptionType] = payload.poseOptions;
		},

		addPoseOptionType: (state, { payload }: PayloadAction<PoseOptionTypes>) => {
			state.addedPoseOptionTypes[payload] = true;
		},

		deletePoseOptionSection: (
			state,
			{ payload }: PayloadAction<PoseOptionTypes>
		) => {
			state.groupedPoseOptions[payload] = [];
			state.addedPoseOptionTypes[payload] = false;
		},
	},
});

export const poseOptionsReducer = poseOptionsSlice.reducer;
export const {
	setPoseOptions,
	addPoseOptionType,
	setGroupedPoseOptions,
	deletePoseOptionSection,
} = poseOptionsSlice.actions;
