import { useState } from 'react';

export const useToggleSection = (isOpenInitial: boolean) => {
	const [isOpen, setIsOpen] = useState(isOpenInitial);

	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	return { isOpen, setIsOpen, handleToggle };
};
