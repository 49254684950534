export const presetsUrls = {
	presetsUrl: 'api/presets',

	getPresetsUrlIncludingKey: (presetKey: number) => `api/presets/${presetKey}`,

	createPresetFromProject: (projectKey: number) =>
		`api/presets/create/source/project/${projectKey}`,

	updatePresetCampaign: (presetKey: number) =>
		`api/presets/${presetKey}/campaign`,

	getPresetSaleUrl: (presetKey: number) =>
		`api/preset/preset-sales/${presetKey}`,

	presetSales: (presetKey: number) =>
		`api/preset/preset-sales/for-preset/${presetKey}`,

	getPresetsFormQuestions: (presetKey: number) =>
		`api/presets/${presetKey}/form-questions`,

	createPresetsFormQuestion: (presetKey: number) =>
		`api/presets/${presetKey}/form-question`,

	getPresetsFormQuestionUrl: (formQuestionKey: number) =>
		`api/presets/form-question/${formQuestionKey}`,

	reorderPresetsFormQuestions: (presetKey: number) =>
		`api/presets/${presetKey}/form-questions/reorder`,
};
