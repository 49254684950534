import { FC } from 'react';

import { useAsyncOptimizedSelect } from 'hooks/formControls/useAsyncOptimizedSelect';
import { CampaignsTarget } from 'api/models/responses/campaigns/campaign';
import { SelectComponent } from 'components/FormControls/Select';
import { CampaignKeys } from 'constants/campaigns/campaignKeys';
import { ISelectOption } from 'types/ui/select';

import { PresetErrors, UpdateCampaignHandler } from '../../../types';

interface IJourneyPresetCampaignProps {
	value: number;
	label: string;
	error?: string;
	id: CampaignKeys;
	placeholder: string;
	target: CampaignsTarget;
	disabledMarketing?: boolean;
	selectOptions: ISelectOption[];
	updateCampaign: UpdateCampaignHandler;
	clearPresetError: (key: keyof PresetErrors) => void;
}

export const JourneyPresetCampaign: FC<IJourneyPresetCampaignProps> = ({
	id,
	value,
	label,
	error,
	target,
	placeholder,
	selectOptions,
	updateCampaign,
	clearPresetError,
	disabledMarketing,
}) => {
	const updateCampaignKey = async (campaignKey: number) => {
		clearPresetError(id);

		await updateCampaign({ value: campaignKey, target });
	};

	const {
		isPending,
		handleChange,
		value: campaignKey,
	} = useAsyncOptimizedSelect({
		initialValue: value,
		updateSelectValue: updateCampaignKey,
	});

	const disabled = disabledMarketing || isPending;

	return (
		<div className="project-section-wrapper-lg">
			<SelectComponent
				label={label}
				error={error}
				value={campaignKey}
				disabled={disabled}
				onChange={handleChange}
				selectOptions={selectOptions}
				selectPlaceholder={placeholder}
			/>
		</div>
	);
};
