import { FC, useEffect, useState } from 'react';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { NoItemsFound } from 'components/NoItemsFound';
import { Loader } from 'components/Loader';

import { useWatermarkMediaBreadcrumbs } from 'pages/WatermarkMedia/hooks/useWatermarkMediaBreadcrumbs';
import {
	galleryImagesSelector,
	getGalleryImagesAsync,
	isImageGalleryPendingSelector,
} from 'store/imageGallery';

import { UploadNewMedia } from './components/UploadNewMedia';
import { MediaHeader } from './components/MediaHeader';
import { MediaItem } from './components/MediaItem';

export const MediaLibrary: FC = () => {
	const [searchValue, setSearchValue] = useState('');

	const isImageGalleryPending = useAppSelector(isImageGalleryPendingSelector);
	const galleryImages = useAppSelector(galleryImagesSelector);

	useWatermarkMediaBreadcrumbs(true);

	const dispatch = useAppDispatch();

	const filteredGalleryImages = galleryImages.filter(
		({ imageFileName }) => !searchValue || imageFileName.includes(searchValue)
	);

	useEffect(() => {
		void dispatch(getGalleryImagesAsync());
	}, []);

	const GalleryImagesList = filteredGalleryImages.map((image) => (
		<MediaItem {...image} key={image.galleryImageKey} />
	));

	const isEmptyLibrary = !filteredGalleryImages.length;

	return (
		<div className="media-container">
			<MediaHeader setSearchValue={setSearchValue} />
			<div className="media-section">
				<h4 className="media-section-title">Media Library</h4>
				{isImageGalleryPending ? (
					<Loader />
				) : (
					<>
						{!isEmptyLibrary && (
							<div className="media-list">{GalleryImagesList}</div>
						)}
						{isEmptyLibrary && <NoItemsFound title="Media items" />}
					</>
				)}
			</div>
			<UploadNewMedia />
		</div>
	);
};
