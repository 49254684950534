import { IPromotion } from 'api/models/responses/promotions/promotion';

export enum PromoApplyToTypes {
	EachSubject = 'EachSubject',
	CartSubtotal = 'CartSubtotal',
	ShippingAmount = 'ShippingAmount',
}

export interface IPromoCode {
	code: string;
	studioKey: number;
	promoCodeKey: number;
	promotionKey: number;
}

export interface IPromotionDetails extends Omit<IPromotion, 'numberOfUses'> {
	maxUses: number;
	studioKey: number;
	createdDate: string;
	minOrderValue: number;
	lastModifyDate: string;
	promoCodes: IPromoCode[];
	applyTo: PromoApplyToTypes;
}
