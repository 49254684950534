export enum TimeZones {
	EasternTime = 'EasternTime',
	CentralTime = 'CentralTime',
	MountainTime = 'MountainTime',
	PacificTime = 'PacificTime',
}

export interface IStudio {
	studioKey: number;
	studioName: string;
	networkDomain: string;
	isEnable: boolean;
	allowSales: boolean;
	subscriptionExpireDate: string;
	createDate: string;
	lastModifyDate: string;
	statementBalance: number;
	statementDate: string;
	payoutMethod: string;
	bankInfoExists: boolean;
	lockAchAfter: string;
	closedAmount: number;
	closedProcess: boolean;
	closedDate: string;
	timeZone: TimeZones;
}
