import { FC } from 'react';
import cn from 'classnames';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { NavItems } from 'layout/Sidebar/NavItems';
import { Logo } from 'components/Logo';

import { isOpenSelector, setIsOpen } from 'store/sidebar';
import NavigationService from 'utils/NavigationService';

export const Sidebar: FC = () => {
	const isOpen = useAppSelector(isOpenSelector);

	const dispatch = useAppDispatch();

	const hideSidebar = () => dispatch(setIsOpen(false));

	return (
		<>
			<div
				className={cn('sidebar', {
					'is-open': isOpen,
				})}
			>
				<button className="sidebar-close" onClick={hideSidebar}></button>
				<Logo />
				<NavItems navigationItems={NavigationService.navigationList} />
			</div>
			{isOpen && <div className="sidebar-mob-back" />}
		</>
	);
};
