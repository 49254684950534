import { IPaginationResponse } from 'api/models/responses/general/paginationResponse';
import { ISearchPhotosRequest } from 'api/models/requests/photos/searchPhotos';
import { ImageSasUrlResponse } from 'api/models/responses/photos/imageSasUrl';
import { CloudPhotoType } from 'api/models/responses/photos/cloudPhotoType';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { IPhoto } from 'api/models/responses/photos/photo';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { photosUrls } from 'constants/apiUrls/photosUrls';

import { BaseService } from '../BaseService';

class PhotoService extends BaseService {
	async searchPhoto({
		skip,
		take,
		orderBy,
		projectKey,
		searchValue,
		orderDirection,
		searchParameter,
		searchByAlbumIds,
	}: ISearchPhotosRequest): Promise<IPaginationResponse<IPhoto> | null> {
		return this.get(photosUrls.searchPhoto, {
			params: {
				Skip: skip,
				Take: take,
				OrderBy: orderBy,
				ProjectKey: projectKey,
				SearchValue: searchValue,
				OrderDirection: orderDirection,
				SearchParameter: searchParameter,
				SearchByAlbumIds: searchByAlbumIds,
			},
		});
	}

	async getImageSasUrl(
		cloudImageId: string,
		photoType: CloudPhotoType
	): Promise<ImageSasUrlResponse> {
		const formData = new FormData();

		formData.set('ImageId', cloudImageId);
		formData.set('ImageVariantId', photoType);

		return this.post(photosUrls.getImageSasUrl, formData, {
			...multipartHeaders,
		});
	}

	async partialPhotoUpdate(
		photoKey: string,
		projectKey: number,
		body: IPatchBody[]
	): Promise<IPhoto | null> {
		return this.patch(
			photosUrls.getGeneralPhotoUrl(projectKey, photoKey),
			body
		);
	}

	async deletePhoto(
		projectKey: number,
		photoKey: string
	): Promise<boolean | null> {
		return this.delete(photosUrls.getGeneralPhotoUrl(projectKey, photoKey));
	}

	async uploadPasswords(
		projectKey: number,
		file: File
	): Promise<string | null> {
		const formData = new FormData();

		formData.set('file', file);

		return this.post(photosUrls.uploadPasswords(projectKey), formData, {
			...multipartHeaders,
		});
	}
}

export default new PhotoService();
