import { FC, KeyboardEvent, SyntheticEvent, useEffect, useRef } from 'react';

import {
	EnterCode,
	regularKeyboardCodes,
} from 'constants/general/keyboardCodes';

import { ILazyInputProps } from './types';

export const LazyInput: FC<ILazyInputProps> = ({
	id,
	type,
	error,
	readOnly,
	disabled,
	className,
	clearError,
	placeholder,
	defaultValue,
	handleChange,
}) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const checkChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const currentValue = e.currentTarget.value;

		const isEqualValue = defaultValue === currentValue;

		if (isEqualValue || !handleChange) return;

		handleChange(e);
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		const input = inputRef.current;

		if (!input) return;

		const { code } = e;

		if (regularKeyboardCodes.includes(code) && clearError) {
			clearError(id || '');
		}
		if (code !== EnterCode) return;

		checkChange(e);
	};

	useEffect(() => {
		const input = inputRef.current;

		if (!input) return;

		input.value = defaultValue;
	}, [inputRef, defaultValue]);

	return (
		<>
			<input
				id={id}
				type={type}
				ref={inputRef}
				readOnly={readOnly}
				disabled={disabled}
				onBlur={checkChange}
				className={className}
				placeholder={placeholder}
				onKeyDown={handleKeyDown}
				defaultValue={defaultValue}
			/>
			{error && <span className="input-error">{error}</span>}
		</>
	);
};

LazyInput.defaultProps = {
	type: 'text',
	className: '',
	readOnly: false,
	defaultValue: '',
	handleChange: () => {},
};
