import { ICreateProjectFromPresetBody } from 'api/models/requests/projects/createProjectFromPreset';
import { UnitedOrgDetailsProjectSortLabels } from 'api/models/requests/projects/projectsParams';
import { IProjectCreationFormParams } from 'api/models/requests/projects/projectCreationForm';
import { ICreateBlankProjectBody } from 'api/models/requests/projects/createBlankProject';
import { IProjectCreationForm } from 'api/models/responses/projects/projectCreationForm';
import { IPaginationResponse } from 'api/models/responses/general/paginationResponse';
import { ISearchProjectParams } from 'api/models/requests/projects/searchProjectParams';
import { IUpdateProjectSale } from 'api/models/requests/projects/updateProjectSale';
import { ICreateProspectsBody } from 'api/models/requests/projects/createProspects';
import { IProjectStatistic } from 'api/models/responses/projects/projectStatistic';
import { ICreateProspects } from 'api/models/responses/projects/createProspects';
import { IValidateNameUrl } from 'api/models/requests/projects/validateNameUrl';
import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';
import { ILatestProject } from 'api/models/responses/projects/latestProjects';
import { IProjectTimeline } from 'api/models/responses/projects/timeline';
import { CampaignsTarget } from 'api/models/responses/campaigns/campaign';
import { IProject } from 'api/models/responses/projects/projectDetails';
import { IComingSoon } from 'api/models/responses/projects/comingSoon';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { ISale } from 'api/models/responses/projects/sales';
import {
	ISearchedProject,
	IOrganizationProject,
} from 'api/models/responses/projects/organizationProjects';
import {
	ISelectCampaign,
	ICampaignDetails,
	ICampaignStatistic,
} from 'api/models/responses/projects/marketing';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { projectsUrls } from 'constants/apiUrls/projectsUrls';

import { ISortParams } from 'types/sortParams';

import { BaseService } from '../BaseService';
import { IActiveStudioProject } from 'api/models/responses/projects/activeStudioProject';

class ProjectsService extends BaseService {
	async getProjectStatistic(
		projectKey: number
	): Promise<IProjectStatistic | null> {
		return this.get<IProjectStatistic>(
			projectsUrls.getProjectStatistic(projectKey)
		);
	}

	async getProjectDetails(projectKey: number): Promise<IProject | null> {
		return this.get<IProject>(projectsUrls.projectUrlIncludingKey(projectKey));
	}

	async getActiveStudioProjects(): Promise<IActiveStudioProject[] | null> {
		return this.get(projectsUrls.activeStudioProjects);
	}

	async partialProjectUpdate(
		projectKey: number,
		body: IPatchBody[]
	): Promise<IProject | null> {
		return this.patch<IProject>(
			projectsUrls.projectUrlIncludingKey(projectKey),
			body
		);
	}

	async deleteProject(projectKey: number): Promise<null> {
		return this.delete(projectsUrls.projectUrlIncludingKey(projectKey));
	}

	async getComingSoonAsync(): Promise<IComingSoon[] | null> {
		return this.get<IComingSoon[]>(projectsUrls.comingSoon);
	}

	async getLatestProjects(): Promise<ILatestProject[] | null> {
		return this.get<ILatestProject[]>(projectsUrls.latest);
	}

	async getActiveOrganizationProjects(
		orgKey: number,
		params: ISortParams<UnitedOrgDetailsProjectSortLabels>
	): Promise<IPaginationResponse<IOrganizationProject> | null> {
		return this.get(projectsUrls.activeForOrganization(orgKey), {
			params,
		});
	}

	async getExpiredOrganizationProjects(
		orgKey: number,
		params: ISortParams<UnitedOrgDetailsProjectSortLabels>
	): Promise<IPaginationResponse<IOrganizationProject> | null> {
		return this.get(projectsUrls.expiredForOrganization(orgKey), {
			params,
		});
	}

	async searchProject(
		params: ISearchProjectParams
	): Promise<IPaginationResponse<ISearchedProject> | null> {
		return this.get(projectsUrls.searchProject, {
			params,
		});
	}

	async validateNameUrl({
		projectName,
		projectUrl,
	}: IValidateNameUrl): Promise<boolean | null> {
		return this.get(projectsUrls.validateNameUrl, {
			params: {
				ProjectName: projectName,
				ProjectUrl: projectUrl,
			},
		});
	}

	async getReferencedItems({
		journey,
		projectType,
	}: IProjectCreationFormParams): Promise<IProjectCreationForm | null> {
		return this.get(projectsUrls.getProjectCreateFormData(journey), {
			params: {
				ProjectType: projectType,
			},
		});
	}

	async createBlankProject(
		body: ICreateBlankProjectBody
	): Promise<IProject | null> {
		return this.post(projectsUrls.createBlankProject, body);
	}

	async createProjectFromPreset(
		presetKey: number,
		body: ICreateProjectFromPresetBody
	): Promise<IProject | null> {
		return this.post(projectsUrls.createProjectFromPreset(presetKey), body);
	}

	async setCoverImage(projectKey: number, file: File): Promise<string | null> {
		const formData = new FormData();

		formData.set('imageFile', file);

		return this.post(projectsUrls.coverImageUrl(projectKey), formData, {
			...multipartHeaders,
		});
	}

	async deleteCoverImage(projectKey: number): Promise<boolean | null> {
		return this.delete(projectsUrls.coverImageUrl(projectKey));
	}

	async getMarketingCampaignStatistics(
		projectKey: number
	): Promise<ICampaignStatistic[] | null> {
		return this.get(projectsUrls.getMarketingCampaignStatistic(projectKey));
	}

	async getMarketingCampaignDetails(
		projectKey: number
	): Promise<ICampaignDetails[] | null> {
		return this.get(projectsUrls.getMarketingCampaignDetails(projectKey));
	}

	async getProjectTimeline(
		projectKey: number
	): Promise<IProjectTimeline | null> {
		return this.get(projectsUrls.getProjectTimeline(projectKey));
	}

	async getProjectSale(saleKey: number): Promise<ISale | null> {
		return this.get(projectsUrls.getProjectSaleUrl(saleKey));
	}

	async updateProjectSale(
		saleKey: number,
		body: IUpdateProjectSale
	): Promise<string | null> {
		return this.put(projectsUrls.getProjectSaleUrl(saleKey), body);
	}

	async getMarketingCampaigns(
		campaignTarget: CampaignsTarget,
		journey: ProjectJourneys
	): Promise<ISelectCampaign[] | null> {
		return this.get(
			projectsUrls.getMarketingCampaigns(campaignTarget, journey)
		);
	}

	async createProspects(
		projectKey: number,
		{ file, emailColumnName, phoneNumberColumnName }: ICreateProspectsBody
	): Promise<ICreateProspects | null> {
		const formData = new FormData();

		formData.set('file', file);
		formData.set('EmailColumnName', emailColumnName);
		formData.set('PhoneNumberColumnName', phoneNumberColumnName);

		return this.post(projectsUrls.createProspects(projectKey), formData);
	}
}

export default new ProjectsService();
