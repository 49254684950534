import { CellProps, Column } from 'react-table';
import { FC, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { ActiveOrgSortLabels } from 'constants/organizations/activeOrgSortLabels';
import { imagesBlobStorageUrl } from 'constants/images/blobUrls';
import { ROUTES } from 'constants/ROUTES';

import { ColumnLabelContainer } from 'components/Table/ColumnLabelContainer';
import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { NoItemsFound } from 'components/NoItemsFound';
import { Modal, ModalRef } from 'components/Modal';
import { Loader } from 'components/Loader';
import { Search } from 'components/Search';
import { Table } from 'components/Table';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToggleSection } from 'hooks/useToggleSection';
import { useDefineDevice } from 'hooks/useDefineDevice';

import { useOrganizationsBreadcrumbs } from 'pages/Organizations/hooks/useOrganizationsBreadcrumbs';
import { IActiveOrganization } from 'api/models/responses/organizations/activeOrganization';
import { normalizeDate } from 'utils/ui/normalizeDate';
import {
	isOrgPendingSelector,
	activeOrganizationSelector,
	getActiveOrganizationsAsync,
} from 'store/organizations';

import { useSortActiveOrganizations } from '../../hooks/useSortActiveOrganizations';
import { SearchedProjectList } from '../../components/SearchedProjectList';
import { useSearchProjects } from '../../hooks/useSearchProjects';
import { CreateNewOrganization } from './CreateNewOrganization';

const mobileRenderColumnsId = ['organizationName', 'activeProjectsCount'];

export const ActiveOrganizations: FC = () => {
	const modalRef = useRef<ModalRef>(null);

	const activeOrganizations = useAppSelector(activeOrganizationSelector);
	const isOrgPending = useAppSelector(isOrgPendingSelector);

	const dispatch = useAppDispatch();

	const { isMobile } = useDefineDevice();

	const { isOpen, setIsOpen, handleToggle } = useToggleSection(false);

	useOrganizationsBreadcrumbs(true);

	const showSearch = () => {
		setIsOpen(true);
	};

	const {
		results,
		isPending,
		handleSearch,
		setPaginatedEntity,
		orderBy: projectOrderBy,
		setSortParams: setProjectSortParams,
		orderDirection: projectOrderDirection,
	} = useSearchProjects(showSearch);

	const {
		orderBy: orgOrderBy,
		setSortParams: setOrgSortParams,
		orderDirection: orgOrderDirection,
	} = useSortActiveOrganizations();

	const onCreateOrgOpen = () => modalRef.current?.open();

	const columns: Column<IActiveOrganization>[] = [
		{
			Header: (
				<ColumnLabelContainer<ActiveOrgSortLabels>
					label="Name"
					orderBy={orgOrderBy}
					setSortParams={setOrgSortParams}
					orderDirection={orgOrderDirection}
					sortLabel={ActiveOrgSortLabels.OrganizationName}
				/>
			),
			accessor: 'organizationName',
			Cell: ({ row }: CellProps<IActiveOrganization>) => {
				const {
					organizationKey,
					organizationName,
					searchResultsImageRelativePath,
				} = row.original;

				const backgroundImage = `url(${imagesBlobStorageUrl}${searchResultsImageRelativePath})`;

				return (
					<span className="card-link-icon" title={organizationName}>
						<i
							style={{
								backgroundImage,
							}}
							className="card-table-icon"
						/>
						<Link
							title={organizationName}
							className="card-table-link"
							to={`${ROUTES.ORGANIZATIONS.ORGANIZATIONS}/${organizationKey}`}
						>
							{organizationName}
						</Link>
					</span>
				);
			},
		},
		{
			Header: (
				<ColumnLabelContainer<ActiveOrgSortLabels>
					label="Search Status"
					orderBy={orgOrderBy}
					setSortParams={setOrgSortParams}
					orderDirection={orgOrderDirection}
					sortLabel={ActiveOrgSortLabels.Private}
				/>
			),
			accessor: 'isPrivate',
			Cell: ({
				value,
			}: CellProps<IActiveOrganization, IActiveOrganization['isPrivate']>) => (
				<>{value ? 'Hidden' : 'Visible'}</>
			),
		},
		{
			Header: (
				<ColumnLabelContainer<ActiveOrgSortLabels>
					orderBy={orgOrderBy}
					label="Active Projects"
					setSortParams={setOrgSortParams}
					orderDirection={orgOrderDirection}
					sortLabel={ActiveOrgSortLabels.ActiveProjects}
				/>
			),
			accessor: 'activeProjectsCount',
		},
		{
			Header: (
				<ColumnLabelContainer<ActiveOrgSortLabels>
					orderBy={orgOrderBy}
					label="Created Date"
					setSortParams={setOrgSortParams}
					orderDirection={orgOrderDirection}
					sortLabel={ActiveOrgSortLabels.CreatedDate}
				/>
			),
			accessor: 'createDate',
			Cell: ({
				value,
			}: CellProps<IActiveOrganization, IActiveOrganization['createDate']>) => (
				<>{normalizeDate(value)}</>
			),
		},
		{
			Header: (
				<ColumnLabelContainer<ActiveOrgSortLabels>
					orderBy={orgOrderBy}
					label="Last Modified"
					setSortParams={setOrgSortParams}
					orderDirection={orgOrderDirection}
					sortLabel={ActiveOrgSortLabels.LastModifyDate}
				/>
			),
			accessor: 'lastModifyDate',
			Cell: ({
				value,
			}: CellProps<
				IActiveOrganization,
				IActiveOrganization['lastModifyDate']
			>) => <>{normalizeDate(value)}</>,
		},
	];

	const filteredColumns = isMobile
		? columns.filter(({ accessor }) =>
				mobileRenderColumnsId.includes(accessor as string)
		  )
		: columns;

	useEffect(() => {
		void dispatch(getActiveOrganizationsAsync());
	}, [dispatch]);

	return (
		<div className="org">
			<ToggleSection
				isOpen={isOpen}
				title="Find a Project:"
				handleToggle={handleToggle}
				className="org-search-container"
				header={
					<Search placeholder="Find a Project" handleSearch={handleSearch} />
				}
			>
				<div className="org-active-container">
					{!isPending && !!results.length && (
						<SearchedProjectList
							results={results}
							className="org-table"
							orderBy={projectOrderBy}
							setSortParams={setProjectSortParams}
							orderDirection={projectOrderDirection}
							setPaginatedEntity={setPaginatedEntity}
						/>
					)}
					{isPending && <Loader />}
					{!isPending && !results.length && (
						<div className="page-loader-container is-section">
							<NoItemsFound title="projects" />
						</div>
					)}
				</div>
			</ToggleSection>
			<div className="org-active-container">
				<div className="org-header">
					<span className="org-title">Active Organizations</span>
					<Button
						value="+ New Organization"
						className="btn-primary org-btn"
						handleClick={onCreateOrgOpen}
					/>
				</div>
				{isOrgPending ? (
					<Loader isFullHeight />
				) : (
					<div className="org-project-search-container">
						<Table<IActiveOrganization>
							columns={filteredColumns}
							data={activeOrganizations}
							className="org-table"
						/>
					</div>
				)}
			</div>
			<Modal
				ref={modalRef}
				title="Create New Organization"
				subtitle="What would you like to call your new organization? (You can change this later)"
			>
				<CreateNewOrganization />
			</Modal>
		</div>
	);
};
