import { ProjectDateTypes } from 'api/models/responses/broadcasts/broadcast';

export const projectDateTypesUI: Record<ProjectDateTypes, string> = {
	[ProjectDateTypes.Christmas]: 'Christmas',
	[ProjectDateTypes.LoginDate]: 'Login Date',
	[ProjectDateTypes.MothersDay]: "Mother's Day",
	[ProjectDateTypes.FathersDay]: "Father's Day",
	[ProjectDateTypes.SpecialDate]: 'Special Date',
	[ProjectDateTypes.PublishDate]: 'Publish Date',
	[ProjectDateTypes.PurchaseDate]: 'Purchase Date',
	[ProjectDateTypes.FirstPictureDay]: 'Picture Day',
	[ProjectDateTypes.ValentinesDay]: "Valentine's Day",
	[ProjectDateTypes.ExpirationDate]: 'Expiration Date',
	[ProjectDateTypes.ThanksGivingDay]: 'Thanksgiving Day',
	[ProjectDateTypes.DatePersonAdded]: 'Date Person Added',
};
