import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';

export enum CampaignsTarget {
	Lead = 'Lead',
	Buyer = 'Buyer',
	Prospect = 'Prospect',
}

export interface ICampaign {
	name: string;
	isCopy: boolean;
	campaignKey: number;
	broadcastsCount: number;
	journey: ProjectJourneys;
	campaignTarget: CampaignsTarget;
}
