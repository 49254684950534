import { useFormikContext } from 'formik';
import { ChangeEvent, FC } from 'react';

import { getMaxLengthMessage } from 'utils/validations/general/getMaxLengthMessage';
import { BroadcastTextMessage } from 'components/Broadcast/BroadcastTextMessage';
import { maxTextMessageLength } from 'constants/broadcasts/validation';

import { IBroadcastValues } from '../../..';

interface IBroadcastTextMessageContainerProps {
	phoneNumber?: string;
	disabledEdit: boolean;
}

export const BroadcastTextMessageContainer: FC<
	IBroadcastTextMessageContainerProps
> = ({ phoneNumber, disabledEdit }) => {
	const { values, errors, touched, setErrors, setFieldValue } =
		useFormikContext<IBroadcastValues>();

	const handleChangeTextMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
		const { value } = e.target;

		if (value.length > maxTextMessageLength) {
			return setErrors({
				...errors,
				textMessage: getMaxLengthMessage(maxTextMessageLength),
			});
		}

		void setFieldValue('textMessage', value);
	};

	const showError = !!(touched.textMessage && errors.textMessage);

	const charactersLeft = maxTextMessageLength - values.textMessage.length;

	return (
		<BroadcastTextMessage
			showError={showError}
			phoneNumber={phoneNumber}
			disabledEdit={disabledEdit}
			charactersLeft={charactersLeft}
			textMessage={values.textMessage}
			textMessageError={errors.textMessage}
			handleChangeTextMessage={handleChangeTextMessage}
		/>
	);
};
