import { FC } from 'react';

import { projectJourneyFilterSelectOptions } from 'constants/projects/selectOptions/projectJourneysSelectOptions';
import { projectTypeFilterSelectOptions } from 'constants/projects/selectOptions/projectTypesSelectOptions';

import { SelectComponent } from 'components/FormControls/Select';
import { Button } from 'components/FormControls/Button';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import {
	filterByJourneySelector,
	filterByProjectTypeSelector,
	setFilterByJourney,
	setFilterByProjectType,
} from 'store/journeyPresets';

import { JourneySelectOption, ProjectTypeSelectOption } from '../../types';
import { useAppSelector } from 'hooks/redux/useAppSelector';

interface IJourneyPresetsHeaderProps {
	handleNewJourneyPreset: () => void;
}

export const JourneyPresetsHeader: FC<IJourneyPresetsHeaderProps> = ({
	handleNewJourneyPreset,
}) => {
	const filterByProjectType = useAppSelector(filterByProjectTypeSelector);
	const filterByJourney = useAppSelector(filterByJourneySelector);

	const dispatch = useAppDispatch();

	const handleChangeJourney = (option: JourneySelectOption) => {
		if (!option) return;

		dispatch(setFilterByJourney(option.value));
	};

	const handleChangeProjectType = (option: ProjectTypeSelectOption) => {
		if (!option) return;

		dispatch(setFilterByProjectType(option.value));
	};

	return (
		<div className="journey-header">
			<div className="journey-header-select">
				<div className="select-column">
					<SelectComponent
						value={filterByProjectType}
						className="select-required"
						label="Filter by Project Type"
						onChange={handleChangeProjectType}
						selectOptions={projectTypeFilterSelectOptions}
					/>
				</div>
				<div className="select-column">
					<SelectComponent
						value={filterByJourney}
						label="Filter by Journey"
						className="select-required"
						onChange={handleChangeJourney}
						selectOptions={projectJourneyFilterSelectOptions}
					/>
				</div>
			</div>
			<Button
				value="+ New Preset"
				handleClick={handleNewJourneyPreset}
				className="btn-primary journey-mob-hide"
			/>
		</div>
	);
};
