import { useFormikContext } from 'formik';
import { FC } from 'react';

import { ICreateProjectFromPresetValues } from 'pages/Projects/pages/CreateProjectFromPreset';
import { presetDetailsImages } from 'constants/projects/presetDetailsImages';

import presetHeaderImage from 'images/projects/preset-header.png';

export const PresetDetails: FC = () => {
	const { values } = useFormikContext<ICreateProjectFromPresetValues>();

	if (!values.journeyPreset) return null;

	const presetDetailsImage = presetDetailsImages[values.journeyPreset.journey];

	return (
		<div className="preset-form">
			<div className="preset-back">
				<img
					alt="preset"
					src={presetHeaderImage}
					className="preset-header-img"
				/>
				<span className="preset-placeholder" />
			</div>
			<div className="preset-scroll">
				<img
					alt="preset details"
					src={presetDetailsImage}
					className="preset-details-img"
				/>
			</div>
		</div>
	);
};
