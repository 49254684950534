import { useCallback, useEffect, useState } from 'react';

import { IGiftAndAccessoriesCatalogProduct } from 'api/models/responses/catalogProducts/giftAndAccessoriesCatalogProduct';
import PriceListProductsService from 'api/services/PriceListsService/imago/PriceListProductsService';
import CatalogProductsService from 'api/services/CatalogProductsService';

import { PriceListControls } from 'pages/PriceLists/components/PriceListControls';
import { IProductUI } from 'pages/PriceLists/types/imago/productUI';

import { ImagoPriceListTabs } from 'constants/priceLists/tabs/imagoPriceListTabs';
import { getProductsUI } from 'utils/priceLists/getProductsUI';
import { Loader } from 'components/Loader';

import { ProductContainer } from './ProductContainer';
import { IPriceListOptionsProps } from '../..';

export const PriceListGiftAndAccessories = ({
	priceListKey,
	setActiveTab,
	isPriceListPending,
}: IPriceListOptionsProps) => {
	const [giftsAndAccessoriesProductsUI, setGiftsAndAccessoriesProductsUI] =
		useState<IProductUI[]>([]);

	const [
		giftAndAccessoriesCatalogProduct,
		setGiftAndAccessoriesCatalogProduct,
	] = useState<IGiftAndAccessoriesCatalogProduct[]>([]);

	const [isCatalogPending, setIsCatalogPending] = useState(true);
	const [isProductsPending, setIsProductsPending] = useState(true);

	const showLoader =
		isPriceListPending || isCatalogPending || isProductsPending;

	const getGiftsAndAccessoriesProducts = useCallback(async () => {
		if (!priceListKey) return;

		try {
			const data = await PriceListProductsService.getSpecificProducts(
				priceListKey
			);

			if (data) {
				const giftAndAccessoriesProductsUI = data.reduce<IProductUI[]>(
					(acc, { key, value }) => [...acc, ...getProductsUI(value, key)],
					[]
				);

				setGiftsAndAccessoriesProductsUI(giftAndAccessoriesProductsUI);
			}
		} catch (error) {
			console.log(error);
		}

		setIsProductsPending(false);
	}, [priceListKey]);

	const getGiftAndAccessoriesCatalogProducts = async () => {
		try {
			const data = await CatalogProductsService.getSpecificCatalogProducts();

			if (data) {
				setGiftAndAccessoriesCatalogProduct(data);
			}
		} catch (error) {
			console.log(error);
		}

		setIsCatalogPending(false);
	};

	const handleBack = () => {
		setActiveTab(ImagoPriceListTabs.Prints);
	};

	const handleNext = () => {
		setActiveTab(ImagoPriceListTabs.Downloads);
	};

	useEffect(() => {
		void getGiftAndAccessoriesCatalogProducts();
	}, []);

	useEffect(() => {
		void getGiftsAndAccessoriesProducts();
	}, [getGiftsAndAccessoriesProducts]);

	const GiftAndAccessoriesList = giftAndAccessoriesCatalogProduct.map(
		({ key, value }) => (
			<ProductContainer
				key={key}
				catalogProducts={value}
				priceListKey={priceListKey}
				gifProductCategoryType={key}
				giftsAndAccessoriesProductsUI={giftsAndAccessoriesProductsUI}
				setGiftsAndAccessoriesProductsUI={setGiftsAndAccessoriesProductsUI}
			/>
		)
	);

	return (
		<>
			{showLoader ? <Loader /> : GiftAndAccessoriesList}
			<PriceListControls handleBack={handleBack} handleNext={handleNext} />
		</>
	);
};
