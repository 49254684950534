import { ChangeEvent, FC, useState } from 'react';

import { PreviewImageColumn } from 'pages/PriceLists/components/PreviewImageColumn';
import { DescriptionColumn } from 'pages/PriceLists/components/DescriptionColumn';
import { IPackageUI } from 'pages/PriceLists/types/studio/priceListPackageUI';
import { BurgerColumn } from 'pages/PriceLists/components/BurgerColumn';

import { validateImageFile } from 'utils/validations/general/validateImageFile';
import { validateFileSize } from 'utils/validations/general/validateFileSize';

import { MAX_IMAGE_SIZE_MB } from 'constants/priceLists/validation/maxImageSize';

import { PackageOptions } from '../PackageOptions';

import {
	UploadPackageImage,
	DeletePackageImage,
	UpdatePackageHandler,
	ClearPackageErrorHandler,
} from '../..';

interface IPackageContentProps {
	showBurger: boolean;
	packageUI: IPackageUI;
	handleMouseDown: () => void;
	previewImageFile: File | null;
	updatePackage: UpdatePackageHandler;
	deletePackageImage: DeletePackageImage;
	uploadPackageImage: UploadPackageImage;
	clearPackageError: ClearPackageErrorHandler;
}

export const PackageContent: FC<IPackageContentProps> = ({
	packageUI,
	showBurger,
	updatePackage,
	handleMouseDown,
	previewImageFile,
	clearPackageError,
	deletePackageImage,
	uploadPackageImage,
}) => {
	const [isImagePending, setIsImagePending] = useState(false);

	const {
		errors,
		sequence,
		description,
		previewImageUrl,
		requiredPoseOptions,
		isWaiveShippingCharge,
		ownPackagePointsAmount,
		isIncludeImagesDownload,
		isBuildOwnPackageEnabled,
		isBackgroundOptionRequired,
		priceListStudioFulfilledPackageKey: packageKey,
	} = packageUI;

	const {
		description: descriptionError,
		previewImageUrl: previewImageUrlError,
	} = errors;

	const updateDescription = (value: string, validationMessage: string) => {
		void updatePackage({
			value,
			sequence,
			packageKey,
			validationMessage,
			fieldKey: 'description',
		});
	};

	const clearDescriptionError = () => {
		if (!descriptionError) return;

		clearPackageError({
			sequence,
			fieldKey: 'description',
		});
	};

	const uploadImageAsync = async (imageFile: File) => {
		setIsImagePending(true);

		const fileTypeValidationMessage = validateImageFile({
			type: imageFile.type,
			validateJpg: true,
			validatePng: true,
		});

		const imageSizeValidationMessage = validateFileSize(
			imageFile.size,
			MAX_IMAGE_SIZE_MB
		);

		await uploadPackageImage({
			sequence,
			imageFile,
			packageKey,
			validationMessage:
				fileTypeValidationMessage || imageSizeValidationMessage,
		});

		setIsImagePending(false);
	};

	const deleteImageAsync = async () => {
		setIsImagePending(true);

		await deletePackageImage(packageKey);

		setIsImagePending(false);
	};

	const handleUploadImage = (e: ChangeEvent<HTMLInputElement>) => {
		const imageFile = e.target.files?.[0];

		if (!imageFile) return;

		void uploadImageAsync(imageFile);
	};

	const handleDeleteImage = () => {
		void deleteImageAsync();
	};

	return (
		<tr className="price-option-tr">
			<BurgerColumn
				colSpan={1}
				showBurger={showBurger}
				handleMouseDown={handleMouseDown}
			/>
			<PreviewImageColumn
				colSpan={1}
				dimensions="300x450 recommended"
				error={previewImageUrlError}
				isImagePending={isImagePending}
				previewImageUrl={previewImageUrl}
				previewImageFile={previewImageFile}
				handleDeleteImage={handleDeleteImage}
				handleUploadImage={handleUploadImage}
			/>
			<td className="price-option-td" colSpan={3}>
				<div className="price-td-container">
					<DescriptionColumn
						error={descriptionError}
						description={description}
						updateDescription={updateDescription}
						clearDescriptionError={clearDescriptionError}
					/>
					<PackageOptions
						sequence={sequence}
						packageKey={packageKey}
						updatePackage={updatePackage}
						requiredPoseOptions={requiredPoseOptions}
						isWaiveShippingCharge={isWaiveShippingCharge}
						ownPackagePointsAmount={ownPackagePointsAmount}
						isIncludeImagesDownload={isIncludeImagesDownload}
						isBuildOwnPackageEnabled={isBuildOwnPackageEnabled}
						isBackgroundOptionRequired={isBackgroundOptionRequired}
					/>
				</div>
			</td>
		</tr>
	);
};
