import { ActionMeta, SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';
import { Checkbox } from 'components/FormControls/Checkbox';
import { Button } from 'components/FormControls/Button';

import { imageTypesSelectOptions } from 'constants/photos/selectOptions/imageTypesSelectOptions';
import { ImageTypes } from 'api/models/responses/photos/photo';
import { ISelectOption } from 'types/ui/select';

import { IUpdatePhotoValues } from '../../../types';

export const UpdatePhoto: FC = () => {
	const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
		useFormikContext<IUpdatePhotoValues>();

	const handleImageTypeChange = (
		option: SingleValue<ISelectOption<ImageTypes>>,
		actionMeta?: ActionMeta<ISelectOption<ImageTypes>>
	) => {
		if (!option || !actionMeta?.name) return;

		void setFieldValue(actionMeta.name, option.value);
	};

	return (
		<div className="image-modal-container">
			<div className="modal-inline-preset">
				<SelectComponent
					name="imageType"
					label="Image Type"
					className="label-grid"
					value={values.imageType}
					onChange={handleImageTypeChange}
					selectOptions={imageTypesSelectOptions}
				/>
			</div>
			<InputGrid
				id="password"
				required={false}
				label="Access Code"
				error={errors.password}
				value={values.password}
				touched={touched.password}
				handleChange={handleChange}
				className="preset label-grid"
			/>
			<Checkbox
				id="freeDownload"
				handleChange={handleChange}
				checked={values.freeDownload}
				label="Available for Download"
			/>
			<Button
				value="Update"
				className="btn-primary image-modal-btn"
				handleClick={handleSubmit}
			/>
		</div>
	);
};
