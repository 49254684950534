import { FC } from 'react';

import { Button } from 'components/FormControls/Button';

interface IAgreementProps {
	handleAgreement: () => void;
}

export const Agreement: FC<IAgreementProps> = ({ handleAgreement }) => (
	<div className="org-modal-agreement">
		<Button value="OK" className="btn-primary" handleClick={handleAgreement} />
	</div>
);
