import { Chart as ReactChart } from 'react-chartjs-2';
import { FC } from 'react';
import {
	Chart as Chartjs,
	ChartOptions,
	ChartData,
	ChartType,
	registerables,
} from 'chart.js';

Chartjs.register(...registerables);

interface IProps {
	type: ChartType;
	options: ChartOptions<ChartType>;
	data: ChartData<ChartType>;
}

export const Chart: FC<IProps> = ({ type, data, options }) => {
	return <ReactChart type={type} data={data} options={options} />;
};
