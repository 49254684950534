import { Dispatch } from '@reduxjs/toolkit';

import PresetsService from 'api/services/PresetsService';

import {
	deleteJourneyPreset,
	setJourneyPresets,
	setJourneyPresetsPending,
} from './journeyPresetsSlice';

export const getJourneyPresetsAsync = () => async (dispatch: Dispatch) => {
	dispatch(setJourneyPresetsPending(true));

	try {
		const presets = await PresetsService.getPresets();

		if (presets) {
			dispatch(setJourneyPresets(presets));
		}
	} catch (error) {
		console.log(error);
	}

	dispatch(setJourneyPresetsPending(false));
};

export const deleteJourneyPresetAsync =
	(presetKey: number) => async (dispatch: Dispatch) => {
		dispatch(deleteJourneyPreset(presetKey));

		try {
			await PresetsService.deletePreset(presetKey);
		} catch (error) {
			console.log(error);
		}
	};
