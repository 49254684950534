export const reservedOrgUrlWords = [
	'organizations',
	'studiourl',
	'participants',
	'gallery',
	'compare',
	'packages',
	'productdetails',
	'alacarte',
	'cart',
	'shipping',
	'billing',
	'confirmation',
];
